import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import { CreatePayrollDto, PayrollDto, UpdatePayrollDto, PeriodDates } from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  constructor(
    private apiService: ApiService
  ) {
  }

  async getPayrollsForUser(): Promise<PayrollDto[]> {
    return await this.apiService.get('/api/payroll');
  }

  async getPayrollsForMasterAccount(masterAccountId: string): Promise<PayrollDto[]> {
    return await this.apiService.get('/api/payroll?masterAccountId=' + masterAccountId);
  }

  async getPayroll(payrollId: string): Promise<PayrollDto> {
    return await this.apiService.get(`/api/payroll/${payrollId}`);
  }

  async getPayrollsForCompany(companyId: string): Promise<PayrollDto[]> {
    return await this.apiService.get(`/api/payroll?companyId=${companyId}`);
  }

  async createPayroll(payroll: CreatePayrollDto): Promise<string> {
    const res = await this.apiService.post(`/api/payroll`, payroll);
    return res.id;
  }

  async updatePayroll(payrollId: string, update: UpdatePayrollDto): Promise<void> {
    await this.apiService.patch(`/api/payroll/${payrollId}`, update);
  }

  async deletePayroll(id: string): Promise<void> {
    await this.apiService.delete(`/api/payroll/${id}`);
  }

  async manualDeductionRollover(payrollId: string): Promise<void> {
    return await this.apiService.post(`/api/payroll/${payrollId}/manual-deduction-rollover`, {});
  }

  async manualPeriodRollover(payrollId: string): Promise<void> {
    return await this.apiService.post(`/api/payroll/${payrollId}/manual-period-rollover`, {});
  }

  async getCurrentPeriodDates(payroll: UpdatePayrollDto): Promise<PeriodDates> {
    return await this.apiService.post(`/api/payroll/payroll-dates`, payroll);
  }
}



import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { IntegrationService } from 'common-ui/services/integration.service';
import {
  IntegrationReportDto,
  IntegrationReportQuery,
  SortDirection,
  PayType,
  EmailEventType,
  JobStatus
} from 'common-ui/open-api';
import { ToolbarDataService, FilterService } from 'common-ui/services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  IntegrationReportDialogComponent
} from 'common-ui/integration-report-dialog/integration-report-dialog.component';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, from, Subject } from 'rxjs';
import { finalize, debounceTime, takeUntil } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Pages } from 'common-ui/services/filter.service';

class IntegrationJobDataSource extends DataSource<IntegrationReportDto> {

  private dataSubject = new BehaviorSubject<IntegrationReportDto[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  public total$ = new BehaviorSubject<number>(0);

  constructor(
    private integrationService: IntegrationService
  ) {
    super();
  }

  get currentValue() {
    return this.dataSubject.value;
  }

  connect(): Observable<IntegrationReportDto[] | readonly IntegrationReportDto[]> {
    return this.dataSubject.asObservable();
  }

  disconnect() {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.total$.complete();
  }

  load(query: IntegrationReportQuery) {
    this.loadingSubject.next(true);
    from(this.integrationService.queryIntegrationJobs(query))
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe(async result => {
        this.total$.next(result.total);
        this.dataSubject.next(result.jobs);
      });
  }
}


@Component({
  selector: 'lib-integration-reports-page',
  templateUrl: './integration-reports-page.component.html',
  styleUrls: ['./integration-reports-page.component.css']
})
export class IntegrationReportsPageComponent implements OnInit, AfterViewInit, OnDestroy {

  dataSource: IntegrationJobDataSource;
  columns = [
    'startTime',
    'endTime',
    'duration',
    'status',
    'errors',
    'warnings',
    'report'
  ];

  private ngUnsubscribe = new Subject();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private integrationService: IntegrationService,
    private toolbarService: ToolbarDataService,
    private dialog: MatDialog,
    @Inject('env') private environment,
    private filterService: FilterService
  ) {
    if (this.isConsole) {
      this.columns = [
        'masterAccountName',
        ...this.columns,
        'menu'
      ];
    }

    this.dataSource = new IntegrationJobDataSource(integrationService);
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  async ngOnInit() {
    this.toolbarService.setupToolbar({title: 'Integration Reports'});
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => await this.loadData());

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      await this.loadData();
      this.paginator.firstPage();
    });

    const initialSort = this.filterService.getPageSort(Pages.INTEGRATION_REPORTS);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    } else {
      this.sort.sort({
        id: 'startTime',
        start: 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.INTEGRATION_REPORTS, newSort.active, newSort.direction as SortDirection);
      });

    await this.loadData();
  }

  async loadData() {
    const query: IntegrationReportQuery = {
      offset: this.paginator.pageIndex * this.pageSize,
      limit: this.pageSize
    };

    const currentFilter = this.filterService.currentFilter;
    query.jobStatuses = currentFilter.jobStatuses;
    query.masterAccountIds = currentFilter.masterAccounts;

    if (this.sort.active) {
      const sort = {};
      sort[this.sort.active] = this.sort.direction;
      query.sort = sort;
    }

    this.dataSource.load(query);
  }

  errorCount(integrationReport: IntegrationReportDto) {
    const errorCount = integrationReport.counts.reduce((acc, cur) => acc + cur.errors, 0);
    return errorCount > 0 ? errorCount : 'No errors';
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  openReportDialog(report: IntegrationReportDto) {
    this.dialog.open(IntegrationReportDialogComponent, {
      data: report,
      autoFocus: false,
      width: '1000px'
    });
  }

  protected readonly PayType = PayType;
  protected readonly EmailEventType = EmailEventType;

  async setJobFailed(integrationReport: IntegrationReportDto) {
    await this.integrationService.setJobFailed(integrationReport._id);
    await this.loadData();
  }

  isRunning(integrationReport: IntegrationReportDto) {
    return integrationReport.status === JobStatus.RUNNING;
  }

  warningCount(integrationReport: IntegrationReportDto) {
    const warningCount = integrationReport.counts.reduce((acc, cur) => acc + cur.warnings, 0);
    return warningCount > 0 ? warningCount : 'No warnings';
  }
}

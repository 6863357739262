<lib-page-container>
  <lib-filter-bar [textSearchType]="FilterType.COMPANY_SEARCH"
                  header>
    <div control-options *ngIf="!isConsole">
      <button mat-button (click)="openEditCompanyDialog(true)">Create</button>
    </div>
    <lib-entity-status-selector *ngIf="isConsole">
    </lib-entity-status-selector>

    <lib-master-account-selector *ngIf="isConsole">
    </lib-master-account-selector>
  </lib-filter-bar>

  <table [dataSource]="dataSource" mat-table matSort="">

    <ng-container matColumnDef="name">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Company Name</th>
      <td *matCellDef="let element" mat-cell>
        <div>
          {{ element.name }}
        </div>
        <span *ngIf="element.isCompanyNameInvalid"
              class="sub-text-error">
          Name is duplicated in Legal Entity
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="legalEntity">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Legal Entity</th>
      <td *matCellDef="let element" mat-cell>
        <div>
          {{ element.isLegalEntityNameDifferent ? element.legalEntityName : element.name }}
        </div>
        <span *ngIf="element.isCompanyNameInvalid"
              class="sub-text-error">
          Name is duplicated in Legal Entity
        </span>

      </td>
    </ng-container>

    <ng-container matColumnDef="masterAccountName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Master Account</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.masterAccountName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="mandateId">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Mandate Id</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.mandateId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fullAddress">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Address</th>
      <td *matCellDef="let element" mat-cell>
        <div *ngIf="element.address.isValid">
          {{ element.fullAddress }}
        </div>
        <div class="tick-cross-td" *ngIf="!element.address.isValid">
          <lib-tick-cross [value]="false"></lib-tick-cross>
          <a (click)="openEditCompanyDialog(false, element._id)"
             class="error-anchor">
            (set)
          </a>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="unsettledEurAmount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Unsettled EUR</th>
      <td *matCellDef="let element" mat-cell>
        {{element.unsettledEurAmount | pennyToPound: Currency.EUR:false}}
      </td>
    </ng-container>

    <ng-container matColumnDef="unsettledGbpAmount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Unsettled GBP</th>
      <td *matCellDef="let element" mat-cell>
        {{element.unsettledGbpAmount | pennyToPound: Currency.GBP:false}}
      </td>
    </ng-container>

    <ng-container matColumnDef="unsettledCount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Unsettled Count</th>
      <td *matCellDef="let element" mat-cell>{{element.unsettledCount}}</td>
    </ng-container>

    <ng-container matColumnDef="contractSigned">
      <th *matHeaderCellDef mat-header-cell>Contract</th>
      <td *matCellDef="let element" mat-cell>
        <div class="tick-cross-td">
          <lib-tick-cross [value]="isContractSigned(element)"></lib-tick-cross>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell>
        Status
      </th>
      <td *matCellDef="let element" mat-cell>
        <div>
          {{ element.status | titlecase }}
        </div>
        <span *ngIf="element.isStatusInherited"
              class="sub-text">
          Inherited from Account
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="menu" stickyEnd>
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" mat-cell>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">

          <button (click)="openEditCompanyDialog(false, element._id)" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit Company</span>
          </button>

          <button
            *ngIf="!isContractSigned(element) && isConsole"
            (click)="openContractSignedDialog(element)"
                  mat-menu-item>
            <mat-icon>file_copy</mat-icon>
            <span>Set Contract Signed</span>
          </button>

          <button
            *ngIf="isContractSigned(element) && isConsole"
            (click)="openContractSignedDialog(element)"
                  mat-menu-item>
            <mat-icon>file_copy</mat-icon>
            <span>Set Contract Revoked</span>
          </button>

          <button
            mat-menu-item
            (click)="openCreatePayrollDialog(element)">
            <mat-icon>payments</mat-icon>
            <span>Create Payroll</span>
          </button>

          <button
            mat-menu-item
            (click)="openCreateEmployeeDialog(element)">
            <mat-icon>group</mat-icon>
            <span>Create Employee</span>
          </button>

          <button
            mat-menu-item
            *ngIf="element.hasPayCodes"
            (click)="openCompanyPayCodesDialog(element)">
            <mat-icon>code</mat-icon>
            <span>Pay Codes</span>
          </button>

          <button mat-menu-item
            (click)="blockCompany(element)">
            <mat-icon>block</mat-icon>
            <span>{{getBlockButtonText(element)}}</span>
          </button>

          <button
            mat-menu-item
            (click)="openDeleteCompanyDialog(element)">
            <mat-icon>group</mat-icon>
            <span>Delete Company</span>
          </button>

        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="10">{{ isLoading ? "Loading..." : "No data" }}</td>
    </tr>
  </table>

</lib-page-container>

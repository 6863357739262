<div class="page mat-typography">

  <div class="container">

    <h1>404 - Page not found</h1>
    <p>Sorry, we do not recognise this page.</p>

    <button
      class="goto-login-button"
      mat-stroked-button
      color="primary"
      routerLink="/login">Go to Login
    </button>
  </div>

</div>

import { Component, Input, Inject } from '@angular/core';
import { MasterAccountDto } from 'common-ui/open-api';
import { ActivatedRoute } from '@angular/router';
import { Environment } from 'common-ui/models/environment.type';
import { MasterAccountService } from 'common-ui/services';


@Component({
  selector: 'lib-master-account-settings',
  templateUrl: './master-account-settings.component.html',
  styleUrls: ['./master-account-settings.component.css']
})
export class MasterAccountSettingsComponent {

  @Input() masterAccount: MasterAccountDto;
  selectedTab = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    @Inject('env') private env: Environment,
    private masterAccountService: MasterAccountService
  ) {
    this.activatedRoute.queryParams.subscribe(async params => {
      if (params.tab) {
        this.selectedTab = params.tab;
      }
    });
  }

  get isConsole() {
    return this.env.adminConsole;
  }

  async reloadMasterAccount() {
    this.masterAccount = await this.masterAccountService.get(this.masterAccount._id);
  }
}

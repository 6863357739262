<mat-form-field appearance="legacy">
  <mat-label>{{ label ?? 'Time' }}</mat-label>
  <input matInput [formControl]="control"
         placeholder="00:00"
         mask="00:00"
         [dropSpecialCharacters]="false"
         (input)="onInput($event)">
  <mat-error *ngIf="control.errors?.required">{{ label ?? 'Time' }} is required</mat-error>
  <mat-error *ngIf="control.errors?.invalidTime">{{ label ?? 'Time' }} must be of format HH:MM</mat-error>
  <mat-error *ngIf="control.errors?.invalidHours">Hours must be between 0 and 24</mat-error>
  <mat-error *ngIf="control.errors?.invalidMinutes">Minutes must be between 0 and 60</mat-error>
</mat-form-field>

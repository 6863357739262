import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EmployeeDto, InviteEmployeesDto, EmploymentStatus, PayType } from 'common-ui/open-api';
import { EmployeeService } from 'common-ui/services';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export interface EmployeeInviteData {
  selectedEmployees: EmployeeDto[];
  masterAccountId: string;
}

@Component({
  selector: 'lib-employee-invite-dialog',
  templateUrl: './employee-invite-dialog.component.html',
  styleUrls: ['./employee-invite-dialog.component.css']
})
export class EmployeeInviteDialogComponent implements OnInit {

  errorMessage: string;
  displayedColumns = ['name', 'email',  'exclusion-reason', 'remove'];
  isWorking = false;
  inviteAll: boolean;
  form: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmployeeInviteData,
    private dialogRef: MatDialogRef<EmployeeInviteDialogComponent>,
    private employeeService: EmployeeService,
    private fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      excludeInvited: true,
      excludeActive: true,
      excludeBlocked: true,
      excludeLeaving: true,
      timesheetEmployeesOnly: false,
      amountAvailableThreshold: 50
    });
  }

  async ngOnInit() {
    this.inviteAll = this.data.selectedEmployees.length === 0;
  }

  get employeeIdsToInvite() {
    return this.data.selectedEmployees
      .filter(e => !this.disableEmployee(e))
      .map(emp => emp._id);
  }

  disableEmployee(e: EmployeeDto) {
    const amountAvailableThreshold = this.form.controls['amountAvailableThreshold'].value * 100;
    if (this.form.controls['excludeInvited'].value && e.invited) {
      return true;
    } else if (this.form.controls['excludeBlocked'].value && e.isBlocked) {
      return true;
    } else if (this.form.controls['excludeLeaving'].value && e.employmentStatus === EmploymentStatus.LEAVING) {
      return true;
    } else if (this.form.controls['excludeActive'].value && e.hasLoggedIn) {
      return true;
    } else if (this.form.controls['timesheetEmployeesOnly'].value && e.payType !== PayType.TIMESHEET) {
      return true;
    } else if (e.amountAvailable < amountAvailableThreshold) {
      return true;
    }
    return false;
  }

  excludedReason(e: EmployeeDto) {
    const amountAvailableThreshold = this.form.controls['amountAvailableThreshold'].value * 100;
    if (this.form.controls['excludeInvited'].value && e.invited) {
      return 'Already Invited';
    } else if (this.form.controls['excludeBlocked'].value && e.isBlocked) {
      return 'Blocked';
    } else if (this.form.controls['excludeLeaving'].value && e.employmentStatus === EmploymentStatus.LEAVING) {
      return 'Leaving';
    } else if (this.form.controls['excludeActive'].value && e.hasLoggedIn) {
      return 'Logged In';
    } else if (this.form.controls['timesheetEmployeesOnly'].value && e.payType !== PayType.TIMESHEET) {
      return 'Salaried';
    } else if (e.amountAvailable < amountAvailableThreshold) {
      return 'Low Accrual';
    }
    return 'Invite';
  }

  close(reload: boolean) {
    this.dialogRef.close(reload);
  }

  removeEmployee(employeeId: string) {
    this.inviteAll = false;
    this.data.selectedEmployees = this.data.selectedEmployees.filter(e => e._id !== employeeId);
  }

  async sendInvites() {
    this.isWorking = true;
    try {
      const inviteDto: InviteEmployeesDto = {
        ...this.form.value,
        masterAccountId: this.data.masterAccountId,
        amountAvailableThreshold: this.form.value.amountAvailableThreshold * 100
      };

      if (!this.inviteAll) {
        inviteDto.employeeIds = this.employeeIdsToInvite;
      }

      await this.employeeService.inviteEmployees(inviteDto);
      this.close(true);
      this.isWorking = false;
    } catch (err) {
      this.isWorking = false;
      this.errorMessage = err.message;
    }
  }

}

<lib-page-container>

  <lib-filter-bar header>
    <lib-job-status-selector></lib-job-status-selector>
    <lib-master-account-selector *ngIf="isConsole">
    </lib-master-account-selector>
  </lib-filter-bar>

  <table mat-table
         [dataSource]="dataSource"
         matSort>

    <ng-container matColumnDef="startTime">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Start Time</th>
      <td *matCellDef="let element" mat-cell>{{element.startTime | formatDateTimeString: true}}</td>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>End Time</th>
      <td *matCellDef="let element" mat-cell>{{element.endTime | formatDateTimeString: true}}</td>
    </ng-container>

    <ng-container matColumnDef="masterAccountName">
      <th *matHeaderCellDef mat-header-cell>Account Name</th>
      <td *matCellDef="let element" mat-cell>{{element.masterAccountName}}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
      <td *matCellDef="let element" mat-cell>{{element.status | hyphenatedToRegular}}</td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th *matHeaderCellDef mat-header-cell>Duration</th>
      <td *matCellDef="let element" mat-cell>{{element.duration}}</td>
    </ng-container>

    <ng-container matColumnDef="errors">
      <th *matHeaderCellDef mat-header-cell>Errors</th>
      <td *matCellDef="let element" mat-cell>
        {{ errorCount(element)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="warnings">
      <th *matHeaderCellDef mat-header-cell>Warnings</th>
      <td *matCellDef="let element" mat-cell>
        {{ warningCount(element)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="report">
      <th *matHeaderCellDef mat-header-cell>Report</th>
      <td *matCellDef="let element" mat-cell>
        <button mat-icon-button (click)="openReportDialog(element)">
          <mat-icon>launch</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="menu">
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" mat-cell (click)="$event.stopPropagation()">
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button (click)="setJobFailed(element)"
                  [disabled]="!isRunning(element)"
                  mat-menu-item>
            <mat-icon>dangerous</mat-icon>
            <span>Set Failed</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns;" mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td colspan="9" class="mat-cell">No data</td>
    </tr>
  </table>

  <div footer>
    <mat-paginator [length]="dataSource.total$ | async" [pageSize]="pageSize" [showFirstLastButtons]=true></mat-paginator>
  </div>
</lib-page-container>

<lib-dialog-container (exit)="close(false)"
                      [header]="'Confirm Delete'"
                      [subHeader]="subHeader">

  <div class="mat-typography">This cannot be un-done.</div>

  <lib-error-message [message]="errorMessage"></lib-error-message>
  <lib-button-panel>
    <button
      mat-button
      color="primary"
      (click)="close(false)">
      Cancel
    </button>

    <button
      mat-flat-button
      color="primary"
      (click)="deletePayroll()">
      Delete
    </button>
  </lib-button-panel>
</lib-dialog-container>

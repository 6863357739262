import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from 'common-ui/open-api';

export function pennyToPound(pennyAmount: number, zeroSymbol: string, chopDecimal: boolean, currency: 'GBP' | 'EUR') {
  if (!pennyAmount) {
    return zeroSymbol;
  }
  if (pennyAmount < 1 && pennyAmount > -1) {
    return zeroSymbol;
  }
  const fixedLength = chopDecimal ? 0 : 2;
  const pennyValue = Math.abs(pennyAmount);
  const negative = pennyAmount < 0;

  const fixedValueString = (pennyValue / 100).toFixed(fixedLength).toString();
  const parts = fixedValueString.split('.');
  const separateThousands = [];
  for (let i = 0; i < parts[0].length; i++) {
    if (i % 3 === 0 && i > 0) {
      separateThousands.unshift(',');
    }
    separateThousands.unshift(parts[0][parts[0].length - 1 - i]);
  }
  const currencySymbol = currency === Currency.EUR ? '€' : currency === Currency.GBP ? '£' : '?';
  const integerPart = `${separateThousands.join('')}`;
  if (chopDecimal) {
    return `${currencySymbol}${negative ? '(' : ''}${integerPart} ${negative ? ')' : ''}`;
  } else {
    return `${currencySymbol}${negative ? '(' : ''}${integerPart}.${parts[1]}${negative ? ')' : ''}`;
  }
}

@Pipe({name: 'pennyToPound'})
export class PennyToPoundPipe implements PipeTransform {
  public transform(pennyAmount: number, currency: Currency, chopDecimal?: boolean, zeroSymbol = '-') {
    return pennyToPound(pennyAmount, zeroSymbol, chopDecimal, currency);
  }
}

<lib-spinner-container [isWorking]="isWorking">
  <lib-dialog-container
    header="Invoice Status"
    subHeader="Normally invoice status is automatically updated by mapping to banking transaction.  Only use this dialog in exceptional circumstancess.">

    <form class="form"
          (ngSubmit)="save()"
          [formGroup]="form">

      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let status of statuses" [value]="status">{{status | hyphenatedToRegular }}</mat-option>
        </mat-select>
      </mat-form-field>

<!--      <mat-form-field>-->
<!--        <mat-label>Payment Reference</mat-label>-->
<!--        <input matInput-->
<!--               placeholder="Payment Reference"-->
<!--               formControlName="paymentReference">-->
<!--        <mat-error *ngIf="form.get('paymentReference').hasError('unknownReference')">-->
<!--          Unknown Reference-->
<!--        </mat-error>-->
<!--        <mat-error *ngIf="form.get('paymentReference').hasError('noPaymentReferenceOnPending')">-->
<!--          Pending invoice cannot have a payment reference.-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->

      <lib-button-panel>
        <button
          (click)="cancel()"
          mat-stroked-button
          color="primary"
          type="button">
          Cancel
        </button>

        <button
          [disabled]="form.invalid || isWorking"
          color="primary"
          type="submit"
          mat-flat-button>
          Save
        </button>
      </lib-button-panel>
    </form>
  </lib-dialog-container>
</lib-spinner-container>

<lib-page-container>

  <lib-filter-bar [textSearchType]="FilterType.EMPLOYEE_SEARCH"
                  header>

    <lib-custom-dates-range-selector></lib-custom-dates-range-selector>
    <lib-company-selector></lib-company-selector>
    <lib-master-account-selector *ngIf="environment.adminConsole">
    </lib-master-account-selector>

  </lib-filter-bar>

  <table [dataSource]="dataSource"
         mat-table
         matSort
         matSortActive="lastName"
         matSortDirection="asc">

    <ng-container matColumnDef="employeeFirstName">
      <th *matHeaderCellDef mat-header-cell>First Name</th>
      <td *matCellDef="let element" mat-cell>{{ element.employeeFirstName }}</td>
    </ng-container>

    <ng-container matColumnDef="employeeLastName">
      <th *matHeaderCellDef mat-header-cell>Last Name</th>
      <td *matCellDef="let element" mat-cell>{{ element.employeeLastName }}</td>
    </ng-container>

    <ng-container matColumnDef="masterAccountName">
      <th *matHeaderCellDef mat-header-cell>Master Account</th>
      <td *matCellDef="let element" mat-cell>{{ element.masterAccountName }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th *matHeaderCellDef mat-header-cell>Company</th>
      <td *matCellDef="let element" mat-cell>{{ element.companyName }}</td>
    </ng-container>

    <ng-container matColumnDef="purchaseDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
      <td *matCellDef="let element" mat-cell>{{ element.purchaseDate | date: "dd MMM yyyy" }}</td>
    </ng-container>

    <ng-container matColumnDef="time">
      <th *matHeaderCellDef mat-header-cell>Time</th>
      <td *matCellDef="let element" mat-cell>{{ element.purchaseDate | date: "HH:mm" }}</td>
    </ng-container>

    <ng-container matColumnDef="brandName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Brand</th>
      <td *matCellDef="let element" mat-cell>{{ element.brandName }}</td>
    </ng-container>

    <ng-container matColumnDef="brandDiscount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Brand Discount</th>
      <td *matCellDef="let element" mat-cell>{{ element.brandDiscount | percent: '1.1-1' }}</td>
    </ng-container>

    <ng-container matColumnDef="customerDiscount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Employee Discount</th>
      <td *matCellDef="let element" mat-cell>{{ element.customerDiscount | percent: '1.1-1' }}</td>
    </ng-container>

    <ng-container matColumnDef="faceValueInMinor">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Face Value</th>
      <td *matCellDef="let element" mat-cell>{{ element.faceValueInMinor | pennyToPound: element.currency }}</td>
    </ng-container>

    <ng-container matColumnDef="customerCostInMinor">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Employee Cost</th>
      <td *matCellDef="let element" mat-cell>{{ element.customerCostInMinor | pennyToPound: element.currency }}</td>
    </ng-container>

    <ng-container matColumnDef="flexEarnMarginInMinor">
      <th *matHeaderCellDef mat-header-cell>Margin</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.flexEarnMarginInMinor | pennyToPound: element.currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="vatPayableInMinor">
      <th *matHeaderCellDef mat-header-cell>VAT</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.vatPayableInMinor | pennyToPound: element.currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" mat-cell>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu" xPosition="before">

          <button
            mat-menu-item
            (click)="openJsonDataDialog(element)">
            <mat-icon>open_in_new</mat-icon>
            View JSON
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;"
        class="clickable"
        mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="13">{{
          (this.dataSource.loading$ | async) ? "Loading..." :
            (this.dataSource.queryToLarge$ | async) ? "Too many records returned - please add a filter" : "No data"
        }}
      </td>
    </tr>
  </table>

  <div footer>
    <mat-paginator
      [length]="dataSource.total$ | async"
      [pageSize]="pageSize"
      [showFirstLastButtons]=true></mat-paginator>
  </div>

</lib-page-container>

import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import {
  UserDto,
  UserQueryDto,
  UserQueryResultDto,
  CreateUserDto,
  UserEmailAndId,
  UpdateUserDto,
  UpdateNotificationPrefsDto
} from 'common-ui/open-api';
import * as Sentry from '@sentry/angular';

export interface UserQuery {
  isAdmin: boolean,
  masterAccountId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apiService: ApiService
  ) {
  }

  get currentUser() {
    return this.apiService.currentUser;
  }

  clearCurrentUser() {
    this.apiService.currentUser = null;
  }

  async getCurrentUser(): Promise<UserDto> {
    if (!this.apiService.currentUser) {
      this.apiService.currentUser = await this.apiService.get('/api/user/current');
      Sentry.setUser({id: this.currentUser._id, email: this.currentUser.email});
    }
    return this.currentUser;
  }

  async isEmployee(): Promise<boolean> {
    const user = await this.getCurrentUser();
    return user.isEmployee;
  }

  async isEmployerPortalUser(): Promise<boolean> {
    const user = await this.getCurrentUser();
    return !!user && (user.isMasterAccount || user.isSystemAdmin || user.isCompanyAdmin);
  }

  async isSysAdminUser(): Promise<boolean> {
    return (await this.getCurrentUser())?.isSystemAdmin;
  }

  async isMasterAccountUser(): Promise<boolean> {
    return (await this.getCurrentUser())?.isMasterAccount;
  }

  async getUsers(queryParams?: UserQuery): Promise<UserDto[]> {
    let query = '';
    if (queryParams) {
      query += '?';
      if (queryParams.masterAccountId) {
        query += `masterAccountId=${queryParams.masterAccountId}`;
      }
      if (queryParams.isAdmin) {
        if (query) {
          query += '&';
        }
        query += `isAdmin=true`;
      }
    }
    return await this.apiService.get(`/api/user${query}`);
  }

  async searchEmails(contains: string): Promise<UserEmailAndId[]> {
    return await this.apiService.get(`/api/user/search?email-contains=${contains}`);
  }

  async query(userQueryDto: UserQueryDto): Promise<UserQueryResultDto> {
    return await this.apiService.post('/api/user/query', userQueryDto);
  }

  async createUser(createUserDto: CreateUserDto) {
    return await this.apiService.post('/api/user', createUserDto);
  }

  async updateUser(updateUserDto: UpdateUserDto) {
    await this.apiService.patch('/api/user', updateUserDto);
  }

  async updateUserNotificationPreferences(notificationPreferences: UpdateNotificationPrefsDto) {
    this.apiService.currentUser = await this.apiService.patch('/api/user/notification-preferences', notificationPreferences);
  }

  async deleteUser(userId: string) {
    await this.apiService.delete(`/api/user/${userId}`);
  }

  async getUser(userId: string): Promise<UserDto> {
    return await this.apiService.get(`/api/user/${userId}`);
  }

  async resendAdminAccountInvite(userId: string) {
    await this.apiService.post(`/api/user/${userId}/invite-master-account-admin`, {});
  }
}


<lib-dialog-container (exit)="close(false)"
                      [showCross]="true"
                      [subHeader]="subHeader"
                      header="Vulnerability Reports">

  <lib-spinner-container [isWorking]="isWorking">

    <div class="log-table-container">
      <table
        mat-table
        class="log-table"
        [dataSource]="auditLog">

        <ng-container matColumnDef="createdDate">
          <th *matHeaderCellDef mat-header-cell>Date/Time</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.createdDate | date: 'dd/MM/yy HH:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="isVulnerable">
          <th *matHeaderCellDef mat-header-cell>Vulnerable?</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.event === EmployeeAuditEvent.SET_IS_VULNERABLE ? 'Yes' : 'No' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th *matHeaderCellDef mat-header-cell>Reported By</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.createdBy }}
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th *matHeaderCellDef mat-header-cell>Report</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.note }}
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

        <tr *matNoDataRow class="mat-row">
          <td class="mat-cell" colspan="4">{{ isWorking ? 'Loading...' : 'No previous reports' }}</td>
        </tr>
      </table>
    </div>

    <form [formGroup]="form">

      <mat-form-field class="note-field">
        <mat-label>Report</mat-label>
        <textarea matInput
                  id="note-field"
                  #noteField
                  placeholder="Vulnerability Description"
                  formControlName="note">
        </textarea>
        <mat-hint align="end">{{ noteField.value.length }}/100</mat-hint>
        <mat-error *ngIf="note.invalid">{{ getNoteErrorMessage() }}</mat-error>
      </mat-form-field>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <lib-button-panel>
        <button (click)="close(false)"
                mat-stroked-button
                color="primary"
                type="button">
          Cancel
        </button>
        <button type="button"
                (click)="updateStatus(true)"
                color="primary"
                [disabled]="form.invalid"
                mat-flat-button>
          Set Vulnerable
        </button>
        <button type="submit"
                (click)="updateStatus(false)"
                color="primary"
                [disabled]="form.invalid"
                mat-flat-button>
          Unset Vulnerable
        </button>
      </lib-button-panel>
    </form>

  </lib-spinner-container>

</lib-dialog-container>

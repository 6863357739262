import { Pipe, PipeTransform } from '@angular/core';
import { PayFrequency } from 'common-ui/open-api';
import { hyphenatedToRegular } from 'common-ui/pipes/hyphenated-to-regular';

@Pipe({
  name: 'payFrequency'
})
export class PayFrequencyPipe implements PipeTransform {
  transform(value: PayFrequency): string {
    return hyphenatedToRegular(value);
  }
}

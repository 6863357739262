<div class="file-chooser-container mat-typography">
  <button mat-button color="primary" (click)="onClick()">
    <mat-icon>file_upload</mat-icon>
    Choose File
  </button>
  <div class="file">{{ selectedFile | truncate: truncateChars }}</div>
</div>
<input #fileInput
       hidden
       accept=".csv"
       type="file">

import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService } from 'common-ui/services/user.service';

@Component({
  selector: 'lib-about-dialog',
  templateUrl: './about-dialog.component.html',
  styleUrls: ['./about-dialog.component.css']
})
export class AboutDialogComponent implements OnInit {

  version: string;
  userId: string;

  constructor(
    private dialogRef: MatDialogRef<AboutDialogComponent>,
    private userService: UserService,
    @Inject('env') public environment: any
  ) {
  }

  async ngOnInit() {
    this.userId = (await this.userService.getCurrentUser())._id;
    this.version = this.environment.version;
  }

  close() {
    this.dialogRef.close();
  }
}

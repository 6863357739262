<lib-dialog-container
  (exit)="close()"
  class="dialog-container"
  header="Payment Instructions History"
  [showCross]="true">

  <div class="table-container">

    <table mat-table [dataSource]="dataSource" class="table">

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="fileName">
        <th mat-header-cell *matHeaderCellDef>File Name</th>
        <td mat-cell *matCellDef="let element"> {{ element.fileName }}</td>
      </ng-container>

      <ng-container matColumnDef="exportedDate">
        <th mat-header-cell *matHeaderCellDef>Exported Date</th>
        <td mat-cell *matCellDef="let element"> {{ element.exportedDate | date }}</td>
      </ng-container>

      <ng-container matColumnDef="exportedTime">
        <th *matHeaderCellDef mat-header-cell>Exported Time</th>
        <td *matCellDef="let element" mat-cell>
          {{ element.exportedDate | date: "HH:mm" }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell none-found" colspan="4">
          {{ isLoading ? 'Loading...' : 'No History' }}
        </td>
      </tr>
    </table>
  </div>

  <div class="button-panel">
    <lib-button-panel>
      <button mat-stroked-button
              color="primary"
              (click)="close()">
        Close
      </button>

      <button mat-flat-button
              [disabled]="!selection.hasValue()"
              color="primary"
              (click)="downloadFile()">
        Download
      </button>
    </lib-button-panel>
  </div>

</lib-dialog-container>

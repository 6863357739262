import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MasterAccountCoreDto } from 'common-ui/open-api';
import { FormControl } from '@angular/forms';
import { MasterAccountService } from 'common-ui/services';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent
} from '@angular/material/legacy-autocomplete';

@Component({
  selector: 'lib-master-account-typeahead-selector',
  templateUrl: './master-account-typeahead-selector.component.html',
  styleUrls: ['./master-account-typeahead-selector.component.css']
})
export class MasterAccountTypeaheadSelectorComponent implements OnInit, OnDestroy, OnChanges {

  @Output() accountSelected = new EventEmitter<MasterAccountCoreDto>;
  @Input() masterAccountId: string;
  @Input() disabled = false;

  isLoading = true;
  filteredMasterAccounts: MasterAccountCoreDto[];
  masterAccountControl = new FormControl();
  masterAccounts: MasterAccountCoreDto[];
  ngUnsubscribe = new Subject<void>();

  displayWith(masterAccount: MasterAccountCoreDto) {
    return masterAccount ? masterAccount.name : '';
  }

  constructor(
    private masterAccountService: MasterAccountService
  ) {
  }

  selectMasterAccount(event: MatAutocompleteSelectedEvent) {
    this.accountSelected.emit(event.option.value);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.masterAccountControl.disable();
      } else {
        this.masterAccountControl.enable();
      }
    }

    if (changes.masterAccountId) {
      if (changes.masterAccountId.currentValue) {
        const masterAccount = await this.masterAccountService.getCoreMasterAccount(this.masterAccountId);
        if (masterAccount) {
          this.masterAccountControl.setValue(masterAccount);
        }
      }
    }
  }


  async ngOnInit() {
    if (this.masterAccountId) {
      const selectedMasterAccount = await this.masterAccountService.getCoreMasterAccount(this.masterAccountId);
      if (selectedMasterAccount) {
        this.masterAccountControl.setValue(selectedMasterAccount);
        this.accountSelected.emit(selectedMasterAccount);
      }
    }

    this.masterAccountControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(700)
    ).subscribe(async filter => {
      if (typeof filter === 'string') {
        this.accountSelected.emit(null);
        this.filteredMasterAccounts = await this.masterAccountService.search(filter);
      }
    });
    this.isLoading = false;
  }

  async ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  clear() {
    this.masterAccountControl.setValue(undefined);
    this.accountSelected.emit(null);
  }

}

import { Component, Input, OnChanges } from '@angular/core';
import { IntegrationImportCount } from 'common-ui/open-api';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'lib-integration-counts',
  templateUrl: './integration-counts.component.html',
  styleUrls: ['./integration-counts.component.scss']
})
export class IntegrationCountsComponent implements OnChanges {

  @Input() showSourceColumn = false;
  @Input() importCounts: IntegrationImportCount[];
  dataSource = new MatTableDataSource<IntegrationImportCount>();
  displayedColumns = [
    'target', 'processed', 'created', 'updated', 'deleted', 'errors', 'warnings'
  ];

  constructor() {
    if (this.showSourceColumn) {
      this.displayedColumns.push('source');
    }
  }

  ngOnChanges(): void {
    this.dataSource.data = this.importCounts;
  }
}

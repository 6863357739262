import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/index';
import { CarePlannerPaymentGroupDto } from 'common-ui/open-api'

@Injectable()
export class CarePlannerService {

  constructor(private apiService: ApiService) {
  }

  getPaymentGroups(masterAccountId: string): Promise<CarePlannerPaymentGroupDto[]> {
    return this.apiService.get(`/api/care-planner/payment-groups/${masterAccountId}`);
  }
}

import { Component, Input, Inject, NgZone, Renderer2, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'common-ui/services/auth.service';
import { Router } from '@angular/router';
import { PasswordResetType } from 'common-ui/open-api';
import { getAuthErrorDescription } from 'common-ui/util/get-auth-error-description';
import { LogoutTimerService } from 'common-ui/services/logout-timer.service';
import { Environment } from 'common-ui/models/environment.type';
import { emailValidator } from 'common-ui/util';

type LoginType = 'login-password' | 'forgot-password' | 'register';

@Component({
  selector: 'lib-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  form: FormGroup;
  passwordControl: FormControl;
  imageSource: string;
  emailSent = false;
  isWorking = false;
  @Input() errorMessage: string;
  @Input() redirectUrl = '/employees';
  loginType: LoginType = 'login-password';

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    @Inject('env') private environment: Environment,
    private router: Router,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private logoutService: LogoutTimerService
  ) {
    this.imageSource = environment.locations.images;
    this.passwordControl = new FormControl('', Validators.required);
    this.form = this.fb.group({
      email: ['', [
        emailValidator,
        Validators.required
      ]],
      password: this.passwordControl
    });
  }

  ngOnInit() {
    this.logoutService.clearBailOnStartup();
    (window as any)['onSignIn'] = (user: any) => this.ngZone.run(async () => {
      await this.afterSignUp(user);
    });

    const script = this.renderer.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.defer = true;
    script.async = true;
    this.renderer.appendChild(document.body, script);
  }

  async afterSignUp(user: any) {
    if (user?.credential) {
      try {
        this.isWorking = true;
        this.errorMessage = '';
        await this.authService.signInWithGoogle(user.credential, this.redirectUrl);
        this.errorMessage = this.authService.loginError;
      } catch (err) {
        this.handleLoginError(err, '');
      }
      this.isWorking = false;
    }
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  get clientId() {
    return this.environment.googleSignInClientId;
  }

  private async sendPasswordReset() {
    if (this.form.invalid) {
      this.form.controls.email.markAllAsTouched();
      return;
    }
    try {
      this.isWorking = true;
      this.errorMessage = '';
      const mode = this.loginType === 'forgot-password' ? PasswordResetType.RESET : PasswordResetType.VERIFY;
      await this.authService.sendPasswordReset(this.form.value.email, mode);
      this.emailSent = true;
    } catch (err) {
      this.handleLoginError(err, this.loginType === 'forgot-password' ? 'Reset' : 'Register');
    }
    this.isWorking = false;
  }

  get actionButtonLabel() {
    switch (this.loginType) {
      case 'forgot-password':
        return 'Reset';
      case 'login-password':
        return 'Login';
      case 'register':
        return 'Register';
    }
  }

  async selectPasswordMethod() {
    this.loginType = 'login-password';
    this.form.addControl('password', this.passwordControl);
    this.emailSent = false;
    this.form.reset();
    this.errorMessage = undefined;
  }

  async selectForgotPassword() {
    this.loginType = 'forgot-password';
    this.form.removeControl('password');
    this.form.reset();
    this.errorMessage = undefined;
  }

  async selectRegister() {
    this.loginType = 'register';
    this.form.removeControl('password');
    this.form.reset();
    this.errorMessage = undefined;
  }

  async selectRequestAccount() {
    this.form.reset();
    this.errorMessage = undefined;
    await this.router.navigate(['request-account']);
  }

  private async signInWithPassword() {
    if (this.form.controls.email.value === '' || this.form.controls.password.value === '') {
      return;
    }
    try {
      this.isWorking = true;
      await this.authService.signInWithEmailAndPassword(
        this.form.value.email,
        this.form.value.password,
        this.redirectUrl
      );
    } catch (err) {
      this.handleLoginError(err, 'Login');
    }
    this.isWorking = false;
  }

  handleLoginError(err, actionName: string) {
    const message = getAuthErrorDescription(err.message);
    this.errorMessage = message || `${actionName} failed, please try later.`;
  }

  async submitForm() {
    if (this.form.valid) {
      if (this.loginType === 'login-password') {
        await this.signInWithPassword();
      } else if (this.loginType === 'forgot-password' || this.loginType === 'register') {
        await this.sendPasswordReset();
      }
    }
  }
}

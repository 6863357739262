<lib-spinner-container [isWorking]="isWorking">
  <div class="login-area mat-typography">

    <!--suppress HtmlRequiredAltAttribute -->
    <img class="main-logo-image" draggable="false" src="{{ imageSource }}/logo_purpletext.svg">

    <div *ngIf="!emailSent" class="form-area">

      <form (ngSubmit)="submitForm()"
            class="input-form"
            *ngIf="!emailSent"
            [formGroup]="form"
            novalidate>

        <mat-form-field appearance="standard">
          <mat-label>Email</mat-label>
          <input
            appFirstFocus
            matInput
            formControlName="email"
            placeholder="Email">
          <mat-error *ngIf="form.get('email').hasError('required') && form.get('email').touched">
            You must enter your email address
          </mat-error>
          <mat-error *ngIf="form.get('email').hasError('invalidEmail') && form.get('email').touched">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="loginType==='login-password'" appearance="standard">
          <mat-label>Password</mat-label>
          <input
            matInput
            formControlName="password"
            placeholder="Password"
            type="password">
          <mat-error *ngIf="form.get('password').hasError('required') && form.get('password').touched">
            You must enter your password
          </mat-error>
        </mat-form-field>

        <lib-error-message [message]="errorMessage"></lib-error-message>

        <div class="login-button-area">

          <button color="primary"
                  type="submit"
                  [disabled]="!form.valid"
                  mat-flat-button
                  class="password-reset-button">
            {{ actionButtonLabel }}
          </button>

          <a class="anchor"
             *ngIf="loginType ==='forgot-password' || loginType === 'register'"
             (click)="selectPasswordMethod()">
            Back to login
          </a>

          <div class="login-anchors"
               *ngIf="loginType==='login-password'">
            <a class="anchor"
               (click)="selectForgotPassword()">
              Forgot password
            </a>

            <a class="anchor"
               *ngIf="!isConsole"
               (click)="selectRegister()">
              Register
            </a>
          </div>
        </div>
      </form>

      <mat-divider></mat-divider>

      <div class="bottom-area">
        <p class="login-options-header">Or, login with</p>

        <div id="g_id_onload"
             [attr.data-client_id]="clientId"
             data-auto_prompt="false"
             data-callback="onSignIn">
        </div>

        <div class="google-button">
          <div class="g_id_signin"
               data-type="standard">
          </div>
        </div>


        <div class="register-footer" *ngIf="!isConsole">
          <span>Not a customer? </span>
          <a class="sign-up-now-link" (click)="selectRequestAccount()">Request a demo</a>
        </div>
      </div>

    </div>

    <div class="message-box" *ngIf="emailSent">
      <p>You should receive an email to reset your password. This make take a few minutes.</p>
      <a class="anchor"
         (click)="selectPasswordMethod()">
        Go back to login
      </a>
    </div>

  </div>
</lib-spinner-container>

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmailEventType = 'not-sent' | 'sending' | 'group_resubscribe' | 'group_unsubscribe' | 'unsubscribe' | 'spamreport' | 'dropped' | 'bounce' | 'click' | 'open' | 'delivered' | 'deferred' | 'processed' | 'failed';

export const EmailEventType = {
    NOT_SENT: 'not-sent' as EmailEventType,
    SENDING: 'sending' as EmailEventType,
    GROUP_RESUBSCRIBE: 'group_resubscribe' as EmailEventType,
    GROUP_UNSUBSCRIBE: 'group_unsubscribe' as EmailEventType,
    UNSUBSCRIBE: 'unsubscribe' as EmailEventType,
    SPAMREPORT: 'spamreport' as EmailEventType,
    DROPPED: 'dropped' as EmailEventType,
    BOUNCE: 'bounce' as EmailEventType,
    CLICK: 'click' as EmailEventType,
    OPEN: 'open' as EmailEventType,
    DELIVERED: 'delivered' as EmailEventType,
    DEFERRED: 'deferred' as EmailEventType,
    PROCESSED: 'processed' as EmailEventType,
    FAILED: 'failed' as EmailEventType
};


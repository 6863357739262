import { formatDateYYYYMMDD } from 'common-ui/util';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import format from 'date-fns/format';
import { subWeeks } from 'date-fns';

export enum DateRangeType {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  CUSTOM = 'custom'
}

export interface NewDateRange {
  dateRangeType: DateRangeType;
  dateRangeAmount?: number;
  fromDate?: string;
  toDate?: string;
}

export interface JustDateRange {
  fromDate: string;
  toDate: string;
}

export const getJustDateRange = (dateRange: NewDateRange, now: Date): JustDateRange => {
  if (dateRange.dateRangeType === DateRangeType.CUSTOM) {
    return {
      toDate: dateRange.toDate,
      fromDate: dateRange.fromDate
    };
  }

  const toDate = formatDateYYYYMMDD(now);
  let fromDate: string;
  switch (dateRange.dateRangeType) {
    case DateRangeType.DAYS:
      fromDate = formatDateYYYYMMDD(subDays(now, dateRange.dateRangeAmount));
      break;
    case DateRangeType.WEEKS:
      fromDate = formatDateYYYYMMDD(subWeeks(now, dateRange.dateRangeAmount));
      break;
    case DateRangeType.MONTHS:
      fromDate = formatDateYYYYMMDD(addDays(subMonths(now, dateRange.dateRangeAmount), 1));
      break;
  }

  return {fromDate, toDate};
};

export const getDateRangeSummary = (dateRange: NewDateRange) => {
  switch (dateRange.dateRangeType) {
    case DateRangeType.DAYS:
      return dateRange.dateRangeAmount + ' Day' + (dateRange.dateRangeAmount > 1 ? 's' : '');
    case DateRangeType.WEEKS:
      return dateRange.dateRangeAmount + ' Week' + (dateRange.dateRangeAmount > 1 ? 's' : '');
    case DateRangeType.MONTHS:
      return dateRange.dateRangeAmount + ' Month' + (dateRange.dateRangeAmount > 1 ? 's' : '');
    default:
      return format(new Date(dateRange.fromDate), 'dd MMM yy') + ' - ' + format(new Date(dateRange.toDate), 'dd MMM yy');
  }
};

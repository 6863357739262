import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormatDateYYYYMMDDPipe, ParseDateYYYYMMDDPipe, FormatDateTimeStringPipe, TruncatePipe } from 'common-ui/pipes';
import { ControlBarComponent } from 'common-ui/control-bar/control-bar.component';
import { CompanyPageComponent } from 'common-ui/company-page/company-page.component';
import { TickCrossComponent } from 'common-ui/tick-cross/tick-cross.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CompanyEditComponent } from 'common-ui/company-edit/company-edit.component';
import { CompanyEditDialogComponent } from 'common-ui/company-edit-dialog/company-edit-dialog.component';
import { PayrollEditComponent } from 'common-ui/payroll-edit/payroll-edit.component';
import { PayrollEditDialogComponent } from 'common-ui/payroll-edit-dialog/payroll-edit-dialog.component';
import { EmployeeEditComponent } from 'common-ui/employee-edit/employee-edit.component';
import { EmployeeEditDialogComponent } from 'common-ui/employee-edit-dialog/employee-edit-dialog.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ErrorMessageComponent } from 'common-ui/error-message/error-message.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CamelToRegularPipe } from 'common-ui/pipes/camel-to-regular.pipe';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS, MatRippleModule } from '@angular/material/core';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { IntegrationSourceComponent } from 'common-ui/integration-source/integration-source.component';
import { DialogHeaderComponent } from 'common-ui/dialog-header/dialog-header.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { EnumPipe } from 'common-ui/pipes/enum.pipe';
import { SpinnerContainerComponent } from 'common-ui/spinner-container/spinner-container.component';
import { SpinnerOverlayComponent } from 'common-ui/spinner-overlay/spinner-overlay.component';
import { AccountNumberInputComponent } from 'common-ui/account-number-input/account-number-input.component';
import { SortCodeInputComponent } from 'common-ui/sort-code-input/sort-code-input.component';
import { EmployeeDeleteDialogComponent } from 'common-ui/employee-delete-dialog/employee-delete-dialog.component';
import { PayTypeHelperDialogComponent } from 'common-ui/pay-type-helper-dialog/pay-type-helper-dialog.component';
import {
  ExternalIdHelperDialogComponent
} from 'common-ui/external-id-helper-dialog/external-id-helper-dialog.component';
import {
  MatLegacyDialogModule as MatDialogModule,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { EmployeeInviteDialogComponent } from 'common-ui/employee-invite-dialog/employee-invite-dialog.component';
import { EmployeeStatusDialogComponent } from 'common-ui/employee-status-dialog/employee-status-dialog.component';
import { EmployeePageComponent } from 'common-ui/employee-page/employee-page.component';
import { EmployeeTimesheetComponent } from 'common-ui/employee-timesheet/employee-timesheet.component';
import {
  EmployeeTimesheetDialogComponent
} from 'common-ui/employee-timesheet-dialog/employee-timesheet-dialog.component';
import {
  EmployeeWithdrawalDialogComponent
} from 'common-ui/employee-withdrawal-dialog/employee-withdrawal-dialog.component';
import { EmployeeWithdrawalsComponent } from 'common-ui/employee-withdrawals/employee-withdrawals.component';
import { ControlBarFilterComponent } from 'common-ui/control-bar-filter/control-bar-filter.component';
import { ListSelectorComponent } from 'common-ui/list-selector/list-selector.component';
import { PayTypeSelectorComponent } from 'common-ui/pay-type-selector/pay-type-selector.component';
import { PayFrequencySelectorComponent } from 'common-ui/payroll-selector/pay-frequency-selector.component';
import { PennyToPoundPipe } from 'common-ui/pipes/pennyToPound.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AnimatedIconComponent } from 'common-ui/animated-icon/animated-icon.component';
import { PayrollPageComponent } from 'common-ui/payroll-page/payroll-page.component';
import { WeekSelectorComponent } from 'common-ui/week-selector/week-selector.component';
import { RolloverPayrollDialogComponent } from 'common-ui/confirm-end-period-dialog/rollover-payroll-dialog.component';
import {
  ConfirmDeletePayrollDialogComponent
} from 'common-ui/confirm-delete-payroll-dialog/confirm-delete-payroll-dialog.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PeriodHistoryPageComponent } from 'common-ui/invoice-history-page/period-history-page.component';
import { DateFnsModule, DateFnsAdapter } from '@angular/material-date-fns-adapter';
import {
  ObscuredNumberClearOnFocusDirective,
  AutoFocusDirective,
  DragDropDirective,
  PercentageFormatterDirective
} from 'common-ui/directives';
import { CheckboxSelectorComponent } from './checkbox-selector/checkbox-selector.component';
import { LoginDialogComponent } from 'common-ui/login-dialog/login-dialog.component';
import { LoginGoogleButtonComponent } from 'common-ui/login-google-button/login-google-button.component';
import { LoginPasswordResetComponent } from 'common-ui/login-password-reset/login-password-reset.component';
import { RequestAccountComponent } from 'common-ui/request-account/request-account.component';
import { LoginComponent } from 'common-ui/login/login.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { PayrollDatesEditDialogComponent } from './payroll-dates-edit-dialog/payroll-dates-edit-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { MasterAccountSelectorComponent } from './master-account-selector/master-account-selector.component';
import {
  MasterAccountUserSettingsComponent
} from './master-account-user-settings/master-account-user-settings.component';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { TimesheetTablePageComponent } from 'common-ui/timesheets-table-page/timesheet-table-page.component';
import { EmploymentStatusSelectorComponent } from './employment-status-selector/employment-status-selector.component';
import { ButtonPanelComponent } from './button-panel/button-panel.component';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import {
  MasterAccountIntegrationSettingsComponent
} from './master-account-integration-settings/master-account-integration-settings.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { AreYouSureDialogComponent } from './are-you-sure-dialog/are-you-sure-dialog.component';
import { IntegrationCountsComponent } from 'common-ui/integration-counts/integration-counts.component';
import { IntegrationErrorsTableComponent } from 'common-ui/integration-errors-table/integration-errors-table.component';
import { IntegrationResultComponent } from 'common-ui/integration-result/integration-result.component';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { IntegrationCountsDialogComponent } from './integration-counts-dialog/integration-counts-dialog.component';
import { IntegrationReportDialogComponent } from './integration-report-dialog/integration-report-dialog.component';
import { JobStatusSelectorComponent } from './job-status-selector/job-status-selector.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TrafficLightsComponent } from 'common-ui/traffic-lights/traffic-lights.component';
import { FileChooserComponent } from 'common-ui/file-chooser/file-chooser.component';
import { WarningDialogComponent } from 'common-ui/warning-dialog/warning-dialog.component';
import { ApiKeyCreatedComponent } from 'common-ui/api-key-created/api-key-created.component';
import { ApiKeyCreatedDialogComponent } from 'common-ui/api-key-created-dialog/api-key-created-dialog.component';
import { CreateApiKeyDialogComponent } from './create-api-key-dialog/create-api-key-dialog.component';
import { ValidationErrorsKeyValuePipe } from './pipes/validation-errors-key-value.pipe';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { NthPipe } from 'common-ui/pipes/nth.pipe';
import { MatStepperModule } from '@angular/material/stepper';
import { OverlayModule } from '@angular/cdk/overlay';
import { ContactSupportDialogComponent } from 'common-ui/contact-support-dialog/contact-support-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PageContainerComponent } from './page-container/page-container.component';
import { SystemDownComponent } from 'common-ui/system-down/system-down.component';
import { FourOFourComponent } from 'common-ui/four-o-four/four-o-four.component';
import { MasterAccountSettingsComponent } from './master-account-settings/master-account-settings.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import {
  MasterAccountGeneralSettingsComponent
} from './master-account-general-settings/master-account-general-settings.component';
import { UserEditDialogComponent } from './user-edit-dialog/user-edit-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { IntegrationRunDialogComponent } from 'common-ui/integration-run-dialog/integration-run-dialog.component';
import { IconErrorComponent } from './icon-error/icon-error.component';
import { PayFrequencyPipe } from 'common-ui/pipes/pay-frequency.pipe';
import { EntityStatusSelectorComponent } from './master-account-status-selector/entity-status-selector.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { CommsLogPageComponent } from './comms-log-page/comms-log-page.component';
import { JsonDialogComponent } from './json-dialog/json-dialog.component';
import {
  EmailTemplateTypeSelectorComponent
} from './email-template-type-selector/email-template-type-selector.component';
import { CommsLogStatusSelectorComponent } from './comms-log-status-selector/comms-log-status-selector.component';
import {
  CustomDatesRangeSelectorComponent
} from 'common-ui/custom-dates-range-selector/custom-dates-range-selector.component';
import { TimesheetListPageComponent } from 'common-ui/timesheet-list-page/timesheet-list-page.component';
import { TimesheetPageRouterComponent } from './timesheet-page-router/timesheet-page-router.component';
import { ReportingPageComponent } from './reporting-page/reporting-page.component';
import { WithdrawalsPageComponent } from 'common-ui/withdrawals-page/withdrawals-page.component';
import { PayrollPeriodSelectorComponent } from 'common-ui/payroll-period-selector/payroll-period-selector.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { CustomContentPageComponent } from './custom-content-page/custom-content-page.component';
import { CustomContentEditDialogComponent } from './custom-content-edit-dialog/custom-content-edit-dialog.component';
import { CustomContentSelectorComponent } from './custom-content-selector/custom-content-selector.component';
import { MasterAccountDetailComponent } from './master-account-detail/master-account-detail.component';
import {
  MasterAccountLimitSettingsComponent
} from './master-account-limit-settings/master-account-limit-settings.component';
import { TimesheetStatusSelectorComponent } from './timesheet-status-selector/timesheet-status-selector.component';
import { TriStateCheckboxComponent } from './tri-state-checkbox/tri-state-checkbox.component';
import { TriListSelectorComponent } from 'common-ui/tri-list-selector/tri-list-selector.component';
import { enGB } from 'date-fns/locale';
import { PeriodHistoryEditDialogComponent } from './period-history-edit-dialog/period-history-edit-dialog.component';
import { PayrollExportTypePipe } from './pipes/payroll-export-type.pipe';
import {
  NotificationPreferencesDialogComponent
} from './notification-preferences-dialog/notification-preferences-dialog.component';
import { CompanyPayCodesDialogComponent } from 'common-ui/company-pay-codes-dialog/company-pay-codes-dialog.component';
import { BankTransactionPageComponent } from 'common-ui/bank-transaction-page/bank-transaction-page.component';
import { InvoiceStatusSelectorComponent } from 'common-ui/invoice-status-selector/invoice-status-selector.component';
import { InvoiceMappingDialogComponent } from 'common-ui/invoice-mapping-dialog/invoice-mapping-dialog.component';
import { PaymentReferenceSelectorComponent } from './payment-reference-selector/payment-reference-selector.component';
import { InvoiceTableComponent } from './invoice-table/invoice-table.component';
import { WithdrawalPeriodEndTypePipe } from 'common-ui/pipes/withdrawal-period-end-type.pipe';
import {
  MasterAccountCustomisationSettingsComponent
} from './master-account-customisation-settings/master-account-customisation-settings.component';
import { EmailRenderComponent } from './email-render/email-render.component';
import { HyphenatedToRegularPipe } from './pipes/hyphenated-to-regular.pipe';
import { DecimalInputDirective } from './directives/decimal-input.directive';
import { WeekOrMonthSelectorComponent } from './week-or-month-selector/week-or-month-selector.component';
import {
  MasterAccountTypeaheadSelectorComponent
} from './master-account-typeahead-selector/master-account-typeahead-selector.component';
import {
  MasterAccountReportExportSettingsComponent
} from './master-account-report-export-settings/master-account-report-export-settings.component';
import { AccountSettingsMenuComponent } from 'common-ui/account-settings-menu/account-settings-menu.component';
import { CurrencySymbolPipe } from 'common-ui/pipes/currency-symbol.pipe';
import { CsvConfigEditComponent } from 'common-ui/csv-config-edit/csv-config-edit.component';
import { CsvImportComponent } from 'common-ui/csv-import-page/csv-import.component';
import { CsvFileChooserComponent } from 'common-ui/csv-file-chooser/csv-file-chooser.component';
import { CsvNewConfigDialogComponent } from 'common-ui/csv-new-config-dialog/csv-new-config-dialog.component';
import { CsvImportResultsComponent } from 'common-ui/csv-import-results/csv-import-results.component';
import { CsvRunImportDialogComponent } from 'common-ui/csv-run-import-dialog/csv-run-import-dialog.component';
import { CsvOutputPreviewComponent } from 'common-ui/csv-output-preview/csv-output-preview.component';
import {
  CsvSingleMappingEditDialogComponent
} from 'common-ui/csv-single-mapping-edit-dialog/csv-single-mapping-edit-dialog.component';
import { CsvConfigEditDialogComponent } from 'common-ui/csv-config-edit-dialog/csv-config-edit-dialog.component';
import {
  CsvColumnEditSimpleDialogComponent
} from 'common-ui/csv-column-edit-simple-dialog/csv-column-edit-simple-dialog.component';
import { PaymentStatusSelectorComponent } from './payment-status-selector/payment-status-selector.component';
import { IntegrationReportsPageComponent } from 'common-ui/integration-reports-page/integration-reports-page.component';
import { PayrollInputComponent } from './payroll-input/payroll-input.component';
import { PayrollDayInputComponent } from './payroll-day-input/payroll-day-input.component';
import { PayrollScheduleComponent } from './payroll-schedule/payroll-schedule.component';
import { GenerateTestWithdrawalsComponent } from './generate-test-withdrawals/generate-test-withdrawals.component';
import { TotpComponent } from './totp/totp.component';
import {
  AmountAvailableBreakdownComponent
} from 'common-ui/amount-available-breakdown/amount-available-breakdown.component';
import { AvailableExplainerDialogComponent } from './available-explainer-dialog/available-explainer-dialog.component';
import { EmailChangedComponent } from 'common-ui/email-changed/email-changed.component';
import { LoginContainerComponent } from './login-container/login-container.component';
import { MasterAccountInputComponent } from './master-account-input/master-account-input.component';
import { UpdateApiKeyDialogComponent } from './update-api-key-dialog/update-api-key-dialog.component';
import {
  CarePlannerPaymentGroupsDialogComponent
} from 'common-ui/care-planner-payment-groups-dialog/care-planner-payment-groups-dialog.component';
import { ManualPaymentsPageComponent } from 'common-ui/manual-payments-page/manual-payments-page.component';
import {
  ManualPaymentCreateDialogComponent
} from './manual-payment-create-dialog/manual-payment-create-dialog.component';
import {
  PaymentInstructionHistoryDialogComponent
} from './payment-instruction-history-dialog/payment-instruction-history-dialog.component';
import { TimesheetDialogComponent } from './timesheet-dialog/timesheet-dialog.component';
import { TimeInputComponent } from './time-input/time-input.component';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { EmployeeInputComponent } from 'common-ui/employee-input/employee-input.component';
import {
  ImportGcPaymentsExportDialogComponent
} from './import-gc-payments-export-dialog/import-gc-payments-export-dialog.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { HoursInputComponent } from './hours-input/hours-input.component';
import { RoundPipe } from 'common-ui/pipes/round.pipe';
import { TimesheetTypeSelectorComponent } from './timesheet-type-selector/timesheet-type-selector.component';
import {
  NotificationPreferencesEditComponent
} from 'common-ui/notification-preferences-edit/notification-preferences-edit.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CenteredPageComponent } from './centered-page/centered-page.component';
import {
  EmployeeUsageRatingSelectorComponent
} from 'common-ui/employee-risk-rating-selector/employee-risk-rating-selector.component';
import {
  EmployeeVulnerabilityDialogComponent
} from 'common-ui/employee-vulnerability-dialog/employee-vulnerability-dialog.component';
import { BooleanSelectorComponent } from './boolean-selector/boolean-selector.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { EnterStringDialogComponent } from './enter-string-dialog/enter-string-dialog.component';
import {
  BankTransactionTypeSelectorComponent
} from 'common-ui/bank-transaction-type-selector/bank-transaction-type-selector.component';
import {
  PaymentApiTypeSelectorComponent
} from 'common-ui/payment-api-type-selector/payment-api-type-selector.component';
import { EmailEventSelectorComponent } from 'common-ui/email-event-selector/email-event-selector.component';
import { ApiModule, BASE_PATH } from 'common-ui/open-api';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'common-ui/services/auth-interceptor';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import {
  PayrollCustomScheduleInputComponent
} from 'common-ui/payroll-custom-schedule-input/payroll-custom-schedule-input.component';
import { UserRoleSelectorComponent } from 'common-ui/user-role-selector/user-role-selector.component';
import {
  EmployeeAuditLogDialogComponent
} from 'common-ui/employee-audit-log-dialog/employee-audit-log-dialog.component';
import { WithdrawalTypeSelectorComponent } from 'common-ui/withdrawal-type-selector/withdrawal-type-selector.component';
import { DiscountCardPageComponent } from 'common-ui/discount-card-page/discount-card-page.component';
import { FinanceExportsDialogComponent } from 'common-ui/finance-exports-dialog/finance-exports-dialog.component';
import { PayCodePageComponent } from 'common-ui/pay-code-page/pay-code-page.component';
import { PayCodesEditComponent } from './pay-codes-edit/pay-codes-edit.component';
import { CompanyInputComponent } from 'common-ui/company-input/company-input.component';
import {
  CompanyTypeaheadSelectorComponent
} from 'common-ui/company-typeahead-selector/company-typeahead-selector.component';
import { CompanySelectInputComponent } from 'common-ui/company-select-input/company-select-input.component';
import { ReferralFormComponent } from 'common-ui/referral-form/referral-form.component';
import { ReferralPageComponent } from './referral-page/referral-page.component';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { PayrollScheduleTableComponent } from './payroll-schedule-table/payroll-schedule-table.component';
import { PermissionsListComponent } from './permissions-list/permissions-list.component';
import { PullNotificationsComponent } from './pull-notifications/pull-notifications.component';
import { SavingsPageComponent } from './savings-page/savings-page.component';
import { AgoPipe } from 'common-ui/pipes/ago-pipe';
import { CustomSnackBarComponent } from './custom-snack-bar/custom-snack-bar.component';
import {
  SavingsTransactionsDialogComponent
} from 'common-ui/savings-transactions-dialog/savings-transactions-dialog.component';
import {
  AreYouSureAsyncDialogComponent
} from 'common-ui/are-you-sure-async-dialog/are-you-sure-async-dialog.component';
import { DateOperationDialogComponent } from 'common-ui/date-operation-dialog/date-operation-dialog.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'dd/MM/yyyy'
  },
  display: {
    dateInput: 'dd/MM/yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM yyyy'
  }
};

@NgModule({
  declarations: [
    DateOperationDialogComponent,
    AreYouSureAsyncDialogComponent,
    SavingsTransactionsDialogComponent,
    AgoPipe,
    ReferralFormComponent,
    CompanySelectInputComponent,
    CompanyTypeaheadSelectorComponent,
    CompanyInputComponent,
    PayCodePageComponent,
    FinanceExportsDialogComponent,
    DiscountCardPageComponent,
    WithdrawalTypeSelectorComponent,
    EmployeeAuditLogDialogComponent,
    PayrollCustomScheduleInputComponent,
    EmailEventSelectorComponent,
    PaymentApiTypeSelectorComponent,
    EmployeeVulnerabilityDialogComponent,
    RoundPipe,
    EmployeeInputComponent,
    ManualPaymentsPageComponent,
    CarePlannerPaymentGroupsDialogComponent,
    CsvRunImportDialogComponent,
    CsvConfigEditComponent,
    CsvImportComponent,
    CsvFileChooserComponent,
    CsvNewConfigDialogComponent,
    CsvImportResultsComponent,
    CsvOutputPreviewComponent,
    CsvSingleMappingEditDialogComponent,
    CsvConfigEditDialogComponent,
    CsvColumnEditSimpleDialogComponent,
    CurrencySymbolPipe,
    InvoiceMappingDialogComponent,
    WithdrawalPeriodEndTypePipe,
    InvoiceStatusSelectorComponent,
    BankTransactionPageComponent,
    CompanyPayCodesDialogComponent,
    NotificationPreferencesEditComponent,
    NotificationPreferencesDialogComponent,
    TriListSelectorComponent,
    CustomDatesRangeSelectorComponent,
    PayFrequencyPipe,
    IntegrationRunDialogComponent,
    FormatDateTimeStringPipe,
    FormatDateYYYYMMDDPipe,
    ParseDateYYYYMMDDPipe,
    ControlBarComponent,
    CompanyPageComponent,
    TickCrossComponent,
    CompanyEditComponent,
    CompanyEditDialogComponent,
    PayrollEditComponent,
    PayrollEditDialogComponent,
    EmployeeEditComponent,
    EmployeeEditDialogComponent,
    ErrorMessageComponent,
    CamelToRegularPipe,
    IntegrationSourceComponent,
    DialogHeaderComponent,
    EnumPipe,
    SpinnerContainerComponent,
    SpinnerOverlayComponent,
    AccountNumberInputComponent,
    SortCodeInputComponent,
    EmployeeDeleteDialogComponent,
    PayTypeHelperDialogComponent,
    ExternalIdHelperDialogComponent,
    EmployeeInviteDialogComponent,
    EmployeeStatusDialogComponent,
    EmployeePageComponent,
    EmployeeTimesheetComponent,
    EmployeeTimesheetDialogComponent,
    EmployeeWithdrawalDialogComponent,
    EmployeeWithdrawalsComponent,
    ControlBarFilterComponent,
    ListSelectorComponent,
    PayTypeSelectorComponent,
    PennyToPoundPipe,
    AnimatedIconComponent,
    PayrollPageComponent,
    PayFrequencySelectorComponent,
    WeekSelectorComponent,
    RolloverPayrollDialogComponent,
    ConfirmDeletePayrollDialogComponent,
    PeriodHistoryPageComponent,
    ObscuredNumberClearOnFocusDirective,
    AutoFocusDirective,
    DragDropDirective,
    PercentageFormatterDirective,
    TruncatePipe,
    CheckboxSelectorComponent,
    LoginDialogComponent,
    LoginGoogleButtonComponent,
    LoginPasswordResetComponent,
    RequestAccountComponent,
    LoginComponent,
    PayrollDatesEditDialogComponent,
    MasterAccountSelectorComponent,
    MasterAccountUserSettingsComponent,
    AboutDialogComponent,
    TimesheetTablePageComponent,
    EmploymentStatusSelectorComponent,
    ButtonPanelComponent,
    DialogContainerComponent,
    MasterAccountIntegrationSettingsComponent,
    AreYouSureDialogComponent,
    IntegrationCountsComponent,
    IntegrationErrorsTableComponent,
    IntegrationResultComponent,
    IntegrationReportsPageComponent,
    CompanySelectorComponent,
    IntegrationCountsDialogComponent,
    IntegrationReportDialogComponent,
    JobStatusSelectorComponent,
    ErrorPageComponent,
    TrafficLightsComponent,
    FileChooserComponent,
    WarningDialogComponent,
    ApiKeyCreatedComponent,
    ApiKeyCreatedDialogComponent,
    CreateApiKeyDialogComponent,
    ValidationErrorsKeyValuePipe,
    DatepickerComponent,
    ContactSupportDialogComponent,
    NthPipe,
    PageContainerComponent,
    SystemDownComponent,
    FourOFourComponent,
    MasterAccountSettingsComponent,
    MasterAccountGeneralSettingsComponent,
    UserEditDialogComponent,
    MessageDialogComponent,
    IconErrorComponent,
    EntityStatusSelectorComponent,
    TimesheetTypeSelectorComponent,
    ErrorDialogComponent,
    CommsLogPageComponent,
    JsonDialogComponent,
    EmailTemplateTypeSelectorComponent,
    CommsLogStatusSelectorComponent,
    TimesheetListPageComponent,
    TimesheetPageRouterComponent,
    ReportingPageComponent,
    WithdrawalsPageComponent,
    PayrollPeriodSelectorComponent,
    CustomContentPageComponent,
    CustomContentEditDialogComponent,
    CustomContentSelectorComponent,
    UserRoleSelectorComponent,
    MasterAccountDetailComponent,
    MasterAccountLimitSettingsComponent,
    TimesheetStatusSelectorComponent,
    TriStateCheckboxComponent,
    PeriodHistoryEditDialogComponent,
    PayrollExportTypePipe,
    PaymentReferenceSelectorComponent,
    InvoiceTableComponent,
    MasterAccountCustomisationSettingsComponent,
    EmailRenderComponent,
    HyphenatedToRegularPipe,
    DecimalInputDirective,
    WeekOrMonthSelectorComponent,
    MasterAccountTypeaheadSelectorComponent,
    MasterAccountReportExportSettingsComponent,
    AccountSettingsMenuComponent,
    PaymentStatusSelectorComponent,
    PayrollInputComponent,
    PayrollDayInputComponent,
    PayrollScheduleComponent,
    GenerateTestWithdrawalsComponent,
    TotpComponent,
    EmailChangedComponent,
    LoginContainerComponent,
    MasterAccountInputComponent,
    UpdateApiKeyDialogComponent,
    ManualPaymentCreateDialogComponent,
    TimesheetDialogComponent,
    TimeInputComponent,
    CurrencyInputComponent,
    PaymentInstructionHistoryDialogComponent,
    ImportGcPaymentsExportDialogComponent,
    FileUploadComponent,
    HoursInputComponent,
    CenteredPageComponent,
    AmountAvailableBreakdownComponent,
    AvailableExplainerDialogComponent,
    EmployeeUsageRatingSelectorComponent,
    BooleanSelectorComponent,
    EnterStringDialogComponent,
    BankTransactionTypeSelectorComponent,
    PayCodesEditComponent,
    ReferralPageComponent,
    PayrollScheduleTableComponent,
    PermissionsListComponent,
    PullNotificationsComponent,
    SavingsPageComponent,
    CustomSnackBarComponent
  ],
  imports: [
    ApiModule,
    MatChipsModule,
    MatCardModule,
    MatRippleModule,
    MatAutocompleteModule,
    DateFnsModule,
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatSelectModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatPaginatorModule,
    FormsModule,
    MatBottomSheetModule,
    MatRadioModule,
    MatSnackBarModule,
    MatSortModule,
    MatDividerModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatStepperModule,
    OverlayModule,
    BrowserAnimationsModule,
    RouterModule,
    MatTabsModule,
    ClipboardModule,
    NgxMaskDirective,
    MatLegacyListModule
  ],
  exports: [
    CenteredPageComponent,
    MasterAccountInputComponent,
    EmailChangedComponent,
    CurrencySymbolPipe,
    AccountSettingsMenuComponent,
    InvoiceTableComponent,
    CustomDatesRangeSelectorComponent,
    ErrorDialogComponent,
    EntityStatusSelectorComponent,
    TimesheetTypeSelectorComponent,
    PayrollPageComponent,
    PayFrequencyPipe,
    IntegrationRunDialogComponent,
    MasterAccountSettingsComponent,
    FormatDateYYYYMMDDPipe,
    ParseDateYYYYMMDDPipe,
    FormatDateTimeStringPipe,
    ControlBarComponent,
    CompanyPageComponent,
    TickCrossComponent,
    CompanyEditComponent,
    CompanyEditDialogComponent,
    PayrollEditComponent,
    PayrollEditDialogComponent,
    EmployeeEditComponent,
    EmployeeEditDialogComponent,
    ErrorMessageComponent,
    CamelToRegularPipe,
    IntegrationSourceComponent,
    DialogHeaderComponent,
    EnumPipe,
    SpinnerContainerComponent,
    SpinnerOverlayComponent,
    AccountNumberInputComponent,
    SortCodeInputComponent,
    EmployeeDeleteDialogComponent,
    PayTypeHelperDialogComponent,
    ExternalIdHelperDialogComponent,
    EmployeeInviteDialogComponent,
    EmployeeStatusDialogComponent,
    EmployeePageComponent,
    EmployeeTimesheetComponent,
    EmployeeTimesheetDialogComponent,
    EmployeeWithdrawalDialogComponent,
    EmployeeWithdrawalsComponent,
    ControlBarFilterComponent,
    ListSelectorComponent,
    CompanySelectorComponent,
    PayTypeSelectorComponent,
    PayFrequencySelectorComponent,
    PennyToPoundPipe,
    AnimatedIconComponent,
    PayrollPageComponent,
    WeekSelectorComponent,
    RolloverPayrollDialogComponent,
    ConfirmDeletePayrollDialogComponent,
    PeriodHistoryPageComponent,
    ObscuredNumberClearOnFocusDirective,
    AutoFocusDirective,
    DragDropDirective,
    PercentageFormatterDirective,
    TruncatePipe,
    CheckboxSelectorComponent,
    LoginDialogComponent,
    LoginGoogleButtonComponent,
    LoginPasswordResetComponent,
    RequestAccountComponent,
    LoginComponent,
    MasterAccountSelectorComponent,
    TimesheetTablePageComponent,
    ButtonPanelComponent,
    DialogContainerComponent,
    MasterAccountIntegrationSettingsComponent,
    IntegrationCountsComponent,
    IntegrationErrorsTableComponent,
    IntegrationResultComponent,
    JobStatusSelectorComponent,
    ApiKeyCreatedComponent,
    ApiKeyCreatedDialogComponent,
    ValidationErrorsKeyValuePipe,
    DatepickerComponent,
    NthPipe,
    ContactSupportDialogComponent,
    PageContainerComponent,
    FourOFourComponent,
    CommsLogStatusSelectorComponent,
    TimesheetListPageComponent,
    MasterAccountDetailComponent,
    PayrollExportTypePipe,
    TrafficLightsComponent,
    HyphenatedToRegularPipe,
    MasterAccountTypeaheadSelectorComponent,
    CsvImportComponent,
    PayrollInputComponent,
    PayrollDayInputComponent,
    TimeInputComponent,
    EmployeeInputComponent,
    CurrencyInputComponent,
    GenerateTestWithdrawalsComponent,
    FileUploadComponent,
    HoursInputComponent,
    NotificationPreferencesEditComponent,
    AmountAvailableBreakdownComponent,
    EmployeeUsageRatingSelectorComponent,
    PayrollCustomScheduleInputComponent,
    UserRoleSelectorComponent,
    CompanyInputComponent,
    CompanySelectInputComponent,
    PermissionsListComponent,
    AgoPipe
  ]
})
export class CommonUiModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: CommonUiModule,
      providers: [
        provideNgxMask(),
        {provide: BASE_PATH, useValue: '/api'},
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        CurrencyPipe,
        DecimalInputDirective,
        {
          provide: MAT_DATE_LOCALE,
          useValue: enGB
        }, {
          provide: DateAdapter,
          useClass: DateFnsAdapter,
          deps: [MAT_DATE_LOCALE]
        }, {
          provide: MAT_DATE_FORMATS,
          useValue: MY_DATE_FORMATS
        },
        {provide: 'env', useValue: environment},
        {provide: MatDialogRef, useValue: {}},
        {provide: HyphenatedToRegularPipe, useClass: HyphenatedToRegularPipe}
      ]
    };
  }
}

import { Component, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor } from '@angular/forms';
import { Subject } from 'rxjs';
import { MasterAccountCoreDto } from 'common-ui/open-api';


export const MASTER_ACCOUNT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MasterAccountInputComponent),
  multi: true
};

const MASTER_ACCOUNT_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MasterAccountInputComponent),
  multi: true
};

@Component({
  selector: 'lib-master-account-input',
  templateUrl: './master-account-input.component.html',
  styleUrls: ['./master-account-input.component.css'],
  providers: [MASTER_ACCOUNT_VALUE_ACCESSOR, MASTER_ACCOUNT_VALIDATOR]
})
export class MasterAccountInputComponent implements OnDestroy, ControlValueAccessor {
  onTouched: () => void;
  onChange: (val: string) => void;
  masterAccountId: string;
  isDisabled = false;
  private ngUnsubscribe$ = new Subject<void>();

  constructor() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  validate() {
    return null;
  }

  onBlur() {
    this.onTouched();
  }

  onInputBlur() {
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(v: any) {
    this.masterAccountId = v;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  accountSelected(masterAccountCoreDto: MasterAccountCoreDto) {
    this.onChange(masterAccountCoreDto?._id ?? '');
  }
}

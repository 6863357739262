<lib-spinner-container
  [isWorking]="loading"
  class="mat-typography">

  <form [formGroup]="form"
        class="payroll-form"
        (ngSubmit)="onSubmit()">

    <mat-form-field class="input-field">
      <mat-label>Company</mat-label>
      <mat-select formControlName="companyId">
        <mat-option *ngFor="let company of companies" [value]="company._id">
          {{ company.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <lib-payroll-input
      formControlName="payroll">
    </lib-payroll-input>

    <lib-integration-source *ngIf="loadedPayroll"
                            [timesheetIntegrationSource]="loadedPayroll.timesheetIntegrationSource"
                            target="payroll">
    </lib-integration-source>

    <lib-error-message [message]="errorMessage"></lib-error-message>

    <lib-button-panel>
      <button mat-stroked-button
              color="primary"
              type="button"
              (click)="cancel()">
        Cancel
      </button>

      <button mat-flat-button
              color="primary"
              data-e2e="payroll-save-btn"
              [disabled]="form.invalid || (!form.dirty && !isCreate)"
              type="submit">
        Save
      </button>

    </lib-button-panel>
  </form>
</lib-spinner-container>

import { Component } from '@angular/core';
import { FilterType } from 'common-ui/services';
import { PayType } from 'common-ui/open-api';
import { formatPayType } from '../util/formatPayType';

@Component({
  selector: 'lib-pay-type-selector',
  templateUrl: './pay-type-selector.component.html'
})
export class PayTypeSelectorComponent {
  payTypes = Object.values(PayType).map(pt => ({
    value: pt.toString(),
    description: formatPayType(pt)
  }));
  FilterType = FilterType;
}

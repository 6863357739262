import { Component } from '@angular/core';
import { CommsLogStatus } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';
import { HyphenatedToRegularPipe } from 'common-ui/pipes/hyphenated-to-regular.pipe';

@Component({
  selector: 'lib-comms-log-status-selector',
  templateUrl: './comms-log-status-selector.component.html',
  styleUrls: ['./comms-log-status-selector.component.css']
})
export class CommsLogStatusSelectorComponent {
  commsLogStatuses = Object.values(CommsLogStatus).map(value => ({
    value: value.toString(),
    description: new HyphenatedToRegularPipe().transform(value.toString())
  }));
  FilterType = FilterType;
}

<div class="container mat-typography">

  <mat-toolbar color="primary">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="app-name">FlexEarn</h1>
    <span class="toolbar-spacer"></span>
  </mat-toolbar>

  <mat-sidenav-container>

    <mat-sidenav #snav
                 [opened]="sideNavOpenMediaQuery.matches"
                 [mode]="isMobileMediaQuery.matches ? 'over' : 'side'">

      <mat-nav-list>

        <div class="sidenav-header" *ngIf="profile">
          <div class="sidenav-header-label">
            {{profile.firstName}} {{ profile.lastName}}
          </div>
          <div class="sidenav-header-label" *ngIf="user">
            {{ user.email }}
          </div>
        </div>
        <mat-divider></mat-divider>

        <a mat-list-item routerLink="/employee-portal/withdraw">Withdraw</a>
        <a mat-list-item routerLink="/employee-portal/history">History</a>
        <a mat-list-item routerLink="/employee-portal/account">Account</a>
        <a mat-list-item routerLink="/employee-portal/breakdown">Breakdown</a>
        <mat-divider></mat-divider>
        <a mat-list-item routerLink="/employee-portal/notifications">Notifications</a>
        <a mat-list-item (click)="openContactSupportDialog()">Contact Us</a>
        <a mat-list-item *ngIf="showEmployerPortalLink"
           routerLink="/employees">
          Employer Portal
        </a>
        <a mat-list-item (click)="openAboutDialog()">About</a>
        <a mat-list-item (click)="logOut()">Logout</a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div class="footer" *ngIf="!isMobileMediaQuery.matches">
  <lib-employee-portal-footer></lib-employee-portal-footer>
</div>

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import '@angular/compiler';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function addGtmScriptTag(gtmId: string) {
  if (!gtmId) {
    console.log('no gtm');
    return;
  }
  ((w, d, s, l, i) => {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    });
    const f = d.getElementsByTagName(s)[0];
    const j: any = d.createElement(s);
    const dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-PPM7VCR');
}

if (environment.production) {
  enableProdMode();
}

if (environment.sentry) {
  Sentry.init({
    dsn: environment.sentry,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

if (environment.gtmTagId) {
  addGtmScriptTag(environment.gtmTagId);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

import { Component, Inject, OnInit } from '@angular/core';
import { EmployeeDto } from 'common-ui/open-api';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';
import { SystemService } from 'common-ui/services';

export interface EmployeeTimesheetDialogData {
  employee: EmployeeDto;
}

@Component({
  selector: 'lib-employee-timesheet-dialog',
  templateUrl: './employee-timesheet-dialog.component.html',
  styleUrls: ['./employee-timesheet-dialog.component.css']
})
export class EmployeeTimesheetDialogComponent implements OnInit {

  dateRange: JustDateRange;
  isLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmployeeTimesheetDialogData,
    private dialogRef: MatDialogRef<EmployeeTimesheetDialogComponent>,
    private systemService: SystemService
  ) {
  }

  async ngOnInit() {
    this.dateRange = this.systemService.getCurrentWeek();
    this.isLoading = false;
  }

  async selectPeriod(dateRange: JustDateRange) {
    this.isLoading = true;
    this.dateRange = dateRange;
    this.isLoading = false;
  }

  close() {
    this.dialogRef.close();
  }
}

import { ChangeDetectorRef, Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
  // eslint-disable-next-line
  selector: '[appFirstFocus]'
})
export class AutoFocusDirective implements OnInit {
  constructor(private elementRef: ElementRef, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.focus();
    this.cd.detectChanges();
  }
}

import { Component, OnInit } from '@angular/core';
import { UserDto } from 'common-ui/open-api';
import { UserService } from 'common-ui/services/user.service';

@Component({
  selector: 'lib-employee-portal-notifications-prefs',
  templateUrl: './employee-portal-notifications-prefs.component.html',
  styleUrls: ['./employee-portal-notifications-prefs.component.css']
})
export class EmployeePortalNotificationsPrefsComponent implements OnInit {
  user: UserDto;

  constructor(
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    this.user = await this.userService.getCurrentUser();
  }

}

import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DateGroupByType } from 'common-ui/open-api';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-week-or-month-selector',
  templateUrl: './week-or-month-selector.component.html',
  styleUrls: ['./week-or-month-selector.component.css']
})
export class WeekOrMonthSelectorComponent implements OnInit, OnDestroy {

  DateGroupByType = DateGroupByType;
  control = new FormControl();
  ngUnsubscribe = new Subject();

  @Output() dateGroupByTypeChange = new EventEmitter<DateGroupByType>();

  constructor() {
    this.control.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        localStorage.setItem('date-group-by-type', value);
        this.dateGroupByTypeChange.emit(value);
      });
  }

  ngOnInit(): void {

    const storedDateGroupByType = localStorage.getItem('date-group-by-type');
    let dateGroupByType: DateGroupByType;

    if (storedDateGroupByType) {
      dateGroupByType = storedDateGroupByType as DateGroupByType;
    } else {
      dateGroupByType = DateGroupByType.MONTH;
      localStorage.setItem('date-group-by-type', DateGroupByType.MONTH);
    }

    this.control.setValue(dateGroupByType);

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next('');
    this.ngUnsubscribe.complete();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { EmployeeDto, EmployeeAuditLogDto } from 'common-ui/open-api';
import { EmployeeService } from 'common-ui/services';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'lib-employee-audit-log-dialog',
  templateUrl: './employee-audit-log-dialog.component.html',
  styleUrls: ['./employee-audit-log-dialog.component.css']
})
export class EmployeeAuditLogDialogComponent implements OnInit {

  errorMessage: string;
  isWorking = true;
  auditLog = new MatTableDataSource<EmployeeAuditLogDto>();
  displayedColumns: string[] = [
    'event',
    'createdDate',
    'createdBy',
    'note'
  ];
  note = new FormControl('', {
    validators: [Validators.required, Validators.maxLength(100)
    ]
  });

  get subHeader() {
    return `Audit Log for ${this.employee.firstName} ${this.employee.lastName}`;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public employee: EmployeeDto,
    private dialogRef: MatDialogRef<EmployeeAuditLogDialogComponent>,
    private employeeService: EmployeeService
  ) {
  }

  close() {
    this.dialogRef.close();
  }

  get employeeName() {
    return this.employee.firstName + ' ' + this.employee.lastName;
  }

  async ngOnInit() {
    try {
      this.auditLog.data = await this.employeeService.getAuditLog(this.employee._id);
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { hyphenatedToRegular } from 'common-ui/pipes/hyphenated-to-regular';

@Pipe({
  name: 'hyphenatedToRegular'
})
export class HyphenatedToRegularPipe implements PipeTransform {

  transform(value: string): string {
    return hyphenatedToRegular(value);
  }

}

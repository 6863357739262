import { Component } from '@angular/core';
import { InvoiceStatus } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';
import { HyphenatedToRegularPipe } from 'common-ui/pipes/hyphenated-to-regular.pipe';

@Component({
  selector: 'lib-invoice-status-selector',
  templateUrl: './invoice-status-selector.component.html',
  styleUrls: ['./invoice-status-selector.component.css']
})
export class InvoiceStatusSelectorComponent {
  invoiceStatuses = Object.values(InvoiceStatus).map(value => ({
    value: value.toString(),
    description: new HyphenatedToRegularPipe().transform(value.toString())
  }));
  FilterType = FilterType;
}

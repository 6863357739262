export const convertCamelToRegular = (key: string) => {
  if (!key) {
    return '-';
  }
  // return key.replace(/([A-Z])/g, ' $1')
  //   .replace(/^./, str => str.toUpperCase());

  return key.replace(/([A-Z])/g, ' $1')
    .replace(/-/g, ' ')
    .replace(/^./, str => str.toUpperCase());
};

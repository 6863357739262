import { Component, Input } from '@angular/core';
import { ProfileDto } from 'common-ui/open-api';

@Component({
  selector: 'lib-employee-portal-withdraw-page-in-closed-period',
  templateUrl: './employee-portal-withdraw-company-in-closed-period.component.html',
  styleUrls: ['./employee-portal-withdraw-company-in-closed-period.component.css']
})
export class EmployeePortalWithdrawCompanyInClosedPeriodComponent {
  @Input() profile: ProfileDto;
}

import { Component, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RequestAccountService, AnalyticsService, CookieService } from 'common-ui/services';
import { CreateAccountRequestDto, AccountRequestRole } from 'common-ui/open-api';
import { ActivatedRoute, Router } from '@angular/router';
import { emailValidator } from 'common-ui/util';

@Component({
  selector: 'lib-request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.scss']
})
export class RequestAccountComponent {

  form: FormGroup;
  isLoading = false;
  numEmployeeValues = ['0 - 10', '10 - 500', '500 - 2,000', '2,000 - 10,000', '10,000+'];

  errorMessage: string;
  AccountRequestRole = AccountRequestRole;
  affiliatePartnerParam: string;
  @Output() demoRequested = new EventEmitter();

  constructor(
    fb: FormBuilder,
    private requestAccountService: RequestAccountService,
    private analyticsService: AnalyticsService,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.form = fb.group({
      requesterName: ['', Validators.required],
      email: ['', [Validators.required, emailValidator]],
      phone: ['', [Validators.required]],
      companyName: ['', Validators.required],
      numberOfEmployees: ['', Validators.required],
      requesterRole: ['', Validators.required],
      jobTitle: ['', Validators.required]
    });

    this.affiliatePartnerParam = this.activatedRoute.snapshot.queryParamMap.get('ap');

    this.form.controls.requesterRole.valueChanges.subscribe((value: AccountRequestRole) => {
      if (value === AccountRequestRole.EMPLOYEE) {
        this.form.addControl('hrContactEmail', new FormControl('', [Validators.required, emailValidator]));
      } else {
        this.form.removeControl('hrContactEmail');
      }
    });
  }

  async onSubmit() {
    try {
      this.isLoading = true;
      this.demoRequested.emit();
      const accountRequestDto = this.form.value as CreateAccountRequestDto;
      const affiliatePartnerCookie = this.cookieService.get('flx_ap');
      if (affiliatePartnerCookie) {
        accountRequestDto.affiliatePartner = affiliatePartnerCookie;
      } else if (this.affiliatePartnerParam) {
        accountRequestDto.affiliatePartner = this.affiliatePartnerParam;
      }
      await this.requestAccountService.sendAccountRequest(accountRequestDto);
      this.errorMessage = undefined;

      if (accountRequestDto.requesterRole === AccountRequestRole.EMPLOYER) {
        await this.router.navigate(['thank-you'], {
          state: {
            message: 'We will be in contact shortly to arrange a demo.'
          }
        });
        this.analyticsService.accountRequestSuccessEvent();
      } else {
        await this.router.navigate(['thank-you'], {
          state: {
            message: 'We will be in contact with your HR Team shortly.'
          }
        });
      }

    } catch (err) {
      this.errorMessage = err.message;
    } finally {
      this.isLoading = false;
    }
  }

  hasError(control: AbstractControl, key: string) {
    return control.touched && control.hasError(key);
  }

}

import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  EmployeeMultiCompanyWithdrawalsDialogComponent
} from 'common-ui/employee-multi-company-withdrawals-dialog/employee-multi-company-withdrawals-dialog.component';
import { EmployeePortalComponent } from './employee-portal/employee-portal.component';
import {
  EmployeePortalBankDetailsDialogComponent
} from './employee-portal-bank-details-dialog/employee-portal-bank-details-dialog.component';
import {
  EmployeePortalAccountSettingsComponent
} from './employee-portal-account-settings/employee-portal-account-settings.component';
import { EmployeePortalWithdrawalComponent } from './employee-portal-withdrawal/employee-portal-withdrawal.component';
import {
  EmployeePortalAcceptTermsComponent
} from './employee-portal-accept-terms/employee-portal-accept-terms.component';
import {
  EmployeePortalBankDetailsComponent
} from './employee-portal-bank-details/employee-portal-bank-details.component';
import { EmployeePortalFooterComponent } from './employee-portal-footer/employee-portal-footer.component';
import {
  EmployeePortalInfoPanelComponent
} from 'employee-portal/employee-portal-info-panel/employee-portal-info-panel.component';
import {
  EmployeePortalWithdrawalHistoryComponent
} from './employee-portal-withdrawal-history/employee-portal-withdrawal-history.component';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MarkdownModule } from 'ngx-markdown';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { CommonUiModule } from 'common-ui/common-ui.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { EmployeePortalPageComponent } from './employee-portal-page/employee-portal-page.component';
import { EmployeePortalTimeLeftComponent } from './employee-portal-time-left/employee-portal-time-left.component';
import {
  EmployeePortalWithdrawCompanyInClosedPeriodComponent
} from './employee-portal-withdraw-page-in-closed-period/employee-portal-withdraw-company-in-closed-period.component';
import {
  EmployeePortalCompanyWithdrawalComponent
} from './employee-portal-company-withdrawal/employee-portal-company-withdrawal.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import {
  EmployeePortalNotificationsPrefsComponent
} from './employee-portal-notifications-prefs/employee-portal-notifications-prefs.component';
import {
  EmployeePortalAmountAvailableBreakdownComponent
} from './employee-portal-amount-available-breakdown/employee-portal-amount-available-breakdown.component';
import {
  EmployeePortalSingleLineInfoPanelComponent
} from 'employee-portal/employee-portal-info-panel-small/employee-portal-single-line-info-panel.component';
import {
  EmployeePortalWithdrawalLimitPanelComponent
} from 'employee-portal/employee-portal-withdrawal-limit-panel/employee-portal-withdrawal-limit-panel.component';

@NgModule({
  declarations: [
    EmployeePortalWithdrawalLimitPanelComponent,
    EmployeePortalWithdrawalComponent,
    EmployeePortalAcceptTermsComponent,
    EmployeePortalBankDetailsComponent,
    EmployeePortalFooterComponent,
    EmployeePortalComponent,
    EmployeePortalInfoPanelComponent,
    EmployeeMultiCompanyWithdrawalsDialogComponent,
    EmployeePortalWithdrawalHistoryComponent,
    EmployeePortalAccountSettingsComponent,
    EmployeePortalBankDetailsDialogComponent,
    EmployeePortalPageComponent,
    EmployeePortalTimeLeftComponent,
    EmployeePortalWithdrawCompanyInClosedPeriodComponent,
    EmployeePortalCompanyWithdrawalComponent,
    EmployeePortalNotificationsPrefsComponent,
    EmployeePortalAmountAvailableBreakdownComponent,
    EmployeePortalSingleLineInfoPanelComponent
  ],
  imports: [
    CommonUiModule,
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MarkdownModule.forRoot(),
    MatTabsModule,
    BrowserAnimationsModule,
    MatDividerModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatSelectModule,
    MatSelectModule,
    FormsModule,
    MatProgressSpinnerModule
  ],
  exports: [
    EmployeePortalComponent
  ]
})
export class EmployeePortalModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: EmployeePortalModule,
      providers: [
        {provide: 'env', useValue: environment},
        {provide: MatDialogRef, useValue: {}}
      ]
    };
  }
}

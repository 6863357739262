import { EmployeeDto, AggregatedTimesheetDto, EmployeeTimesheetDto, Currency } from 'common-ui/open-api';
import { parseDateYYYYMMDD } from 'common-ui/util';
import { Day, days } from 'common-ui/timesheets-table-page/days';
import { WeeklyWages } from 'common-ui/timesheets-table-page/weekly-wages';
import { WeeklyHours } from 'common-ui/timesheets-table-page/weekly-hours';

export class TimesheetTableRow {
  constructor(employee: EmployeeDto) {
    this.employeeId = employee._id;
    this.masterAccountName = employee.masterAccountName;
    this.companyName = employee.companyName;
    this.firstName = employee.firstName;
    this.lastName = employee.lastName;
    this.payFrequency = employee.payFrequency;
    this.amountAccrued = employee.amountAccrued;
    this.accrualStart = employee.accrualStart;
    this.accrualEnd = employee.accrualEnd;
    this.isSourcedFromIntegration = !!employee.timesheetIntegrationSource;
    this.approvedWages = new WeeklyWages();
    this.unapprovedWages = new WeeklyWages();
    this.hoursWithNoRate = new WeeklyHours();
    this.cancelledHours = new WeeklyHours();
    this.currency = employee.currency;
  }

  masterAccountName: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  companyName: string;
  currency: Currency
  payFrequency: string;
  amountAccrued: number;
  isSourcedFromIntegration: boolean;
  accrualStart: string;
  accrualEnd: string;
  approvedWages: WeeklyWages;
  unapprovedWages: WeeklyWages;
  hoursWithNoRate: WeeklyHours;
  cancelledHours: WeeklyHours;

  static createFromEmployeeTimesheets(employeeTimesheetDtos: EmployeeTimesheetDto[], fromDate: string) {
    return employeeTimesheetDtos.map(employeeTimesheetDto => {
      const row = new TimesheetTableRow(employeeTimesheetDto);
      employeeTimesheetDto.timesheets.forEach( timesheet => {
        row.setFromTimesheet(timesheet, fromDate )
      })
      return row;
    });
  }

  private mapDateStringToKeyString(dateString: string, fromDate: string): Day {
    const date = parseDateYYYYMMDD(dateString);
    const fromDateDate = parseDateYYYYMMDD(fromDate);
    const diff = (date.getTime() - fromDateDate.getTime()) / (1000 * 60 * 60 * 24);
    if (diff > 6 || diff < 0) {
      throw new Error(`Cannot place date in table: ${dateString}, diff=${diff}`);
    }
    return days[diff];
  }

  setFromTimesheet(timesheetDto: AggregatedTimesheetDto, fromDate: string) {
    const key = this.mapDateStringToKeyString(timesheetDto.date, fromDate);
    this.approvedWages.setAmount(timesheetDto.wages, key);
    this.unapprovedWages.setAmount(timesheetDto.unapprovedWages, key);
    this.hoursWithNoRate.setAmount(timesheetDto.hoursWithNoRate, key);
    this.cancelledHours.setAmount(timesheetDto.cancelledOrDeletedHours, key);
  }
}

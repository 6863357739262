<div class="time-container">
  <table mat-table [dataSource]="dataSource" class="time-table">

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element"> {{element.date | date}} </td>
    </ng-container>

    <ng-container matColumnDef="unitsWorked">
      <th mat-header-cell *matHeaderCellDef>Hours</th>
      <td mat-cell *matCellDef="let element">{{element.hoursWorked || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="ratePerUnit">
      <th mat-header-cell *matHeaderCellDef>Rate</th>
      <td mat-cell *matCellDef="let element">
        <div class="hourly-rate">
          {{element.hourlyRate ? (element.hourlyRate | pennyToPound: element.currency ) : '-' }}
        </div>
        <div class="pay-code" *ngIf="element.payCode">{{ element.payCode }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="accrued">
      <th mat-header-cell *matHeaderCellDef>Wages</th>
      <td mat-cell *matCellDef="let element"> {{element.wages ? (element.wages | pennyToPound: element.currency ) : '0' }} </td>
    </ng-container>

    <ng-container matColumnDef="approved">
      <th mat-header-cell *matHeaderCellDef>Approved</th>
      <td mat-cell *matCellDef="let element"> <lib-tick-cross [value]="!element.isUnapproved"></lib-tick-cross></td>
    </ng-container>

    <ng-container matColumnDef="cancelled">
      <th mat-header-cell *matHeaderCellDef>Cancelled</th>
      <td mat-cell *matCellDef="let element"> <lib-tick-cross [value]="element.isCancelledOrDeleted"></lib-tick-cross></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell none-found" colspan="6">
        {{ isLoading ? 'Loading...' : 'No timesheets in this period' }}
      </td>
    </tr>
  </table>
</div>


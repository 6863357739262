import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ContactSupportDialogComponent } from 'common-ui/contact-support-dialog/contact-support-dialog.component';
import {
  ToolbarDataService,
  ToolbarData,
  BURGER_MENU_ICON_URL,
  LEFT_ARROW_ICON_URL
} from 'common-ui/services/toolbar-data.service';
import { Subscription } from 'rxjs';
import { UserService } from 'common-ui/services/user.service';
import { MasterAccountService } from 'common-ui/services';
import { ApiSource } from 'common-ui/open-api';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, OnDestroy {
  @ViewChild('sideNav', {static: true}) sideNav: MatSidenav;
  @ViewChild('router_outlet', {
    read: ViewContainerRef,
    static: true
  }) viewContainerRef: ViewContainerRef;
  mobileQuery: MediaQueryList;
  toolBarIconImgSrc = '/assets/menu_icon.png';
  isMasterAccountAdmin = false;
  showEmployeePortalLink = false;
  showIntegrationLink = false;
  showDiscountCardsPage = false;
  showPayCodesPage = false;

  public toolbarData: ToolbarData = {
    title: 'FlexEarn',
    action: 'toggle'
  };

  get selected() {
    return this.toolbarData?.title.toLowerCase();
  }

  private readonly mobileQueryListener: () => void;

  private toolbarDataSubscription: Subscription;

  constructor(
    media: MediaMatcher,
    private router: Router,
    public dialog: MatDialog,
    private toolbarDataService: ToolbarDataService,
    private changeDetector: ChangeDetectorRef,
    private userService: UserService,
    private masterAccountService: MasterAccountService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 1024px)');

    this.mobileQueryListener = async () => {
      if (this.mobileQuery.matches) {
        await this.sideNav.close();
      } else {
        await this.sideNav.open();
      }
    };
    this.mobileQuery.addEventListener('change', this.mobileQueryListener);

    this.toolbarDataSubscription = this.toolbarDataService.get().subscribe(tb => {
      this.toolbarData = tb;
      this.changeDetector.detectChanges();
    });

  }

  async toolbarAction() {
    switch (this.toolbarData.action) {
      case 'toggle':
        this.toolBarIconImgSrc = BURGER_MENU_ICON_URL;
        await this.sideNav.toggle();
        break;
      case 'route':
        this.toolBarIconImgSrc = LEFT_ARROW_ICON_URL;
        await this.router.navigateByUrl(this.toolbarData.args.url);
        break;
      default:
        return;
    }
  }

  async ngOnInit(): Promise<void> {
    this.isMasterAccountAdmin = await this.userService.isMasterAccountUser();
    if (this.mobileQuery.matches) {
      await this.sideNav.close();
    } else {
      await this.sideNav.open();
    }

    this.showEmployeePortalLink = await this.userService.isEmployee();
    const masterAccount = await this.masterAccountService.getCoreMasterAccount(this.userService.currentUser.masterAccountId);
    this.showIntegrationLink = masterAccount.integrationSource !== null &&
      masterAccount.integrationSource !== ApiSource.NONE;
    this.showDiscountCardsPage = masterAccount.discountCardsEnabled;
    this.showPayCodesPage = masterAccount.payCodesEnabled
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeEventListener('change', this.mobileQueryListener);
    this.toolbarDataSubscription.unsubscribe();
  }

  openContactSupportDialog() {
    this.dialog.open(ContactSupportDialogComponent);
  }

  async gotoReferralsPage() {
    await this.router.navigateByUrl('/referral')
  }
}

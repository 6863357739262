import { Component, OnInit } from '@angular/core';
import { ReportingService } from 'common-ui/services/reporting.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivityRecordDto, DateGroupByType } from 'common-ui/open-api';
import { ToolbarDataService } from 'common-ui/services';

@Component({
  selector: 'lib-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.css']
})
export class ReportingPageComponent implements OnInit {

  isLoading = true;
  errorMessage: string;
  dataSource = new MatTableDataSource<ActivityRecordDto>();
  dateGroupByType: DateGroupByType
  displayedColumns = [
    'weekOrMonth',
    'startDate',
    'withdrawalCount',
    'withdrawalAmount',
    'withdrawalFees',
    'employeeCount',
    'signUps',
    'activeEmployees',
    'percentActiveEmployees'
  ];

  constructor(
    private reportingService: ReportingService,
    private toolbarDataService: ToolbarDataService
  ) {
  }

  async dateGroupByChanged(newValue: DateGroupByType) {
    this.isLoading = true;
    this.dataSource.data = [];
    this.dateGroupByType = newValue
    const report = await this.reportingService.getMasterAccountActivityReportData(newValue);
    this.dataSource.data = report.records;
    this.isLoading = false;
  }

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle', title: 'Reporting'
    });
  }

  async downloadDetailReport() {
    try {
      this.isLoading = true;
      await this.reportingService.downloadActivityReportXls(this.dateGroupByType);
    } catch ( e) {
      this.errorMessage = e.message;
    }
    this.isLoading = false;
  }

  protected readonly DateGroupByType = DateGroupByType;
}

import { Component } from '@angular/core';
import { TimesheetType } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';

@Component({
  selector: 'lib-timesheet-type-selector',
  templateUrl: './timesheet-type-selector.component.html',
  styleUrls: ['./timesheet-type-selector.component.css']
})
export class TimesheetTypeSelectorComponent {
  values = Object.values(TimesheetType).map(pt => ({
    value: pt.toString(),
    description: pt.toString()
  }));
  FilterType = FilterType;
}

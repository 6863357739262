import { Routes } from '@angular/router';
import { LoginComponent } from 'common-ui/login/login.component';
import { FourOFourComponent } from 'common-ui/four-o-four/four-o-four.component';
import { MainComponent } from './main/main.component';
import { PeriodHistoryPageComponent } from 'common-ui/invoice-history-page/period-history-page.component';
import { LoginPasswordResetComponent } from 'common-ui/login-password-reset/login-password-reset.component';
import { AccountSettingsPageComponent } from './account-settings-page/account-settings-page.component';
import { RequestAccountContainerComponent } from './request-account-container/request-account-container.component';
import {
  IntegrationOauth2RedirectComponent
} from './integration-oauth2-redirect/integration-oauth2-redirect.component';
import { AccountRequestReceivedComponent } from './account-request-received/account-request-received.component';
import { SystemDownComponent } from 'common-ui/system-down/system-down.component';
import { ImpersonateUserComponent } from './impersonate-user/impersonate-user.component';
import { IsSysadminGuard } from './guards/is-sysadmin.guard';
import { ErrorPageComponent } from 'common-ui/error-page/error-page.component';
import { PortalGuard } from './guards/portal.guard';
import { employeePortalRoutes } from 'employee-portal/employee-portal-routes';
import { PayrollPageComponent } from 'common-ui/payroll-page/payroll-page.component';
import { TimesheetPageRouterComponent } from 'common-ui/timesheet-page-router/timesheet-page-router.component';
import { ReportingPageComponent } from 'common-ui/reporting-page/reporting-page.component';
import { WithdrawalsPageComponent } from 'common-ui/withdrawals-page/withdrawals-page.component';
import { AppLinkComponent } from './app-link/app-link.component';
import { CommsLogPageComponent } from 'common-ui/comms-log-page/comms-log-page.component';
import { CsvImportPageComponent } from './csv-import-page/csv-import-page.component';
import { CompanyPageComponent } from 'common-ui/company-page/company-page.component';
import { EmployeePageComponent } from 'common-ui/employee-page/employee-page.component';
import { IntegrationReportsPageComponent } from 'common-ui/integration-reports-page/integration-reports-page.component';
import { EmailChangedComponent } from 'common-ui/email-changed/email-changed.component';
import { DeleteAccountWithOtpComponent } from './delete-account-with-otp/delete-account-with-otp.component';
import { DiscountCardPageComponent } from 'common-ui/discount-card-page/discount-card-page.component';
import { PayCodePageComponent } from 'common-ui/pay-code-page/pay-code-page.component';
import { ReferralPageComponent } from 'common-ui/referral-page/referral-page.component';

export const portalRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivateChild: [PortalGuard],
    children: [
      {path: '', redirectTo: 'employees', pathMatch: 'full'},
      {path: 'employees', component: EmployeePageComponent},
      {path: 'history', component: PeriodHistoryPageComponent},
      {path: 'payrolls', component: PayrollPageComponent},
      {path: 'timesheets', component: TimesheetPageRouterComponent},
      {path: 'companies', component: CompanyPageComponent},
      {path: 'import', component: CsvImportPageComponent},
      {path: 'integration', component: IntegrationReportsPageComponent},
      {path: 'account', component: AccountSettingsPageComponent},
      {path: 'connect', component: IntegrationOauth2RedirectComponent},
      {path: 'reporting', component: ReportingPageComponent},
      {path: 'withdrawals', component: WithdrawalsPageComponent},
      {path: 'discount-cards', component: DiscountCardPageComponent},
      {path: 'emails', component: CommsLogPageComponent},
      {path: 'pay-codes', component: PayCodePageComponent},
      {path: 'referral', component: ReferralPageComponent}
    ]
  },
  employeePortalRoutes,
  {path: '404', component: FourOFourComponent},
  {path: 'app-link/:action', component: AppLinkComponent},
  {path: 'reset-password', component: LoginPasswordResetComponent},
  {path: 'verify-email', component: LoginPasswordResetComponent},
  {path: 'login', component: LoginComponent},
  {path: 'delete-account', component: DeleteAccountWithOtpComponent},
  {path: 'account-email-changed', component: EmailChangedComponent},
  {path: 'request-account', component: RequestAccountContainerComponent},
  {path: 'request-demo', component: RequestAccountContainerComponent},
  {path: 'thank-you', component: AccountRequestReceivedComponent},
  {path: 'system-down', component: SystemDownComponent},
  {path: 'account-impersonator', component: ImpersonateUserComponent, canActivate: [IsSysadminGuard]},
  {path: 'error', component: ErrorPageComponent},
  {path: '**', component: FourOFourComponent}
];

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterService, FilterType } from 'common-ui/services';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'lib-payment-reference-selector',
  templateUrl: './payment-reference-selector.component.html',
  styleUrls: ['./payment-reference-selector.component.css']
})
export class PaymentReferenceSelectorComponent implements OnInit, OnDestroy {

  paymentReferenceFilter: FormControl;
  private ngUnsubscribe = new Subject();

  constructor(
    private filterService: FilterService
  ) {
    this.paymentReferenceFilter = new FormControl('')
    this.paymentReferenceFilter.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe), debounceTime(300))
      .subscribe(
      value =>  this.filterService.setFilter(FilterType.PAYMENT_REFERENCE, value)
    )
  }

  ngOnInit(): void {
    this.paymentReferenceFilter.setValue(this.filterService.getFilter(FilterType.PAYMENT_REFERENCE))
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }
}

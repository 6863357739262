import { Component, Input, OnInit, Inject } from '@angular/core';
import { MasterAccountDto, DateGroupByType } from 'common-ui/open-api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterAccountService } from 'common-ui/services';
import { MessageDialogComponent } from 'common-ui/message-dialog/message-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'lib-master-account-report-export-settings',
  templateUrl: './master-account-report-export-settings.component.html',
  styleUrls: ['./master-account-report-export-settings.component.css']
})
export class MasterAccountReportExportSettingsComponent implements OnInit {

  @Input() masterAccount: MasterAccountDto;
  form: FormGroup;
  errorMessage: string;
  isWorking: boolean;
  DateGroupByType = DateGroupByType;
  dateGroupBys = Object.values(DateGroupByType);

  constructor(
    fb: FormBuilder,
    private masterAccountService: MasterAccountService,
    private dialog: MatDialog,
    @Inject('env') private environment
  ) {
    this.form = fb.group({
      isEnabled: false,
      dateGroupByType: DateGroupByType.WEEK,
      sftpHost: ['', Validators.required],
      sftpDirectory: ['', Validators.required],
      sftpUsername: ['', Validators.required]
    });

    if ( !this.isConsole ) {
      this.form.disable()
    }
  }

  get isConsole() {
    return this.environment.adminConsole
  }

  ngOnInit(): void {
    if (this.masterAccount.reportExportSettings) {
      this.form.patchValue(this.masterAccount.reportExportSettings);
    } else {
      this.form.patchValue({
        isEnabled: false,
        dateGroupByType: DateGroupByType.WEEK,
        sftpHost: '',
        sftpDirectory: '',
        sftpUsername: ''
      });
    }
  }

  async save() {
    try {
      this.isWorking = true;
      await this.masterAccountService.update(this.masterAccount._id, {
        reportExportSettings: this.form.value
      });
      this.masterAccount = await this.masterAccountService.get(this.masterAccount._id);
      this.form.reset(this.masterAccount.reportExportSettings);
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }

  async runExport() {
    this.isWorking = true;
    this.errorMessage = '';
    let header: string;
    let subHeader: string;
    try {
      await this.masterAccountService.runReportExport(this.masterAccount._id);
      header = 'Export Success';
      subHeader = 'Reports generated and exported to ' + this.masterAccount.reportExportSettings.sftpHost;
    } catch (err) {
      header = 'Export Failed';
      subHeader = err.message;
    }
    this.isWorking = false;
    this.dialog.open(MessageDialogComponent, {
      data: {
        header: header,
        subHeader: subHeader
      }
    });
  }

  public formControlIsRequired(formControlName: string): boolean {
    const formControl = this.form.get(formControlName);
    return formControl.invalid && formControl.dirty && formControl.touched && formControl.errors.required;
  }

  async testConnection() {
    this.isWorking = true;
    this.errorMessage = '';
    let header: string;
    let subHeader: string;
    try {
      await this.masterAccountService.testReportExportConnection(this.masterAccount._id);
      header = 'Connection Success';
      subHeader = 'Connection established with ' + this.masterAccount.reportExportSettings.sftpHost + '.';
    } catch (err) {
      header = 'Connection Failed';
      subHeader = err.message;
    }
    this.isWorking = false;
    this.dialog.open(MessageDialogComponent, {
      data: {
        header: header,
        subHeader: subHeader
      }
    });
  }

  async downloadSftpPublicKey() {
    await this.masterAccountService.downloadSftpPublicKey(this.masterAccount._id);
  }
}

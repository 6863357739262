import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface AreYouSureDialogData {
  message: string;
}

@Component({
  selector: 'lib-are-you-sure-dialog',
  templateUrl: './are-you-sure-dialog.component.html',
  styleUrls: ['./are-you-sure-dialog.component.css']
})
export class AreYouSureDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AreYouSureDialogData,
    private dialogRef: MatDialogRef<AreYouSureDialogComponent>
  ) {
  }

  yes() {
    this.dialogRef.close(true);
  }

  no() {
    this.dialogRef.close(false);
  }

}

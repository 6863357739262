import { Router, UrlTree } from '@angular/router';
import { AuthService } from 'common-ui/services';
import { UserService } from 'common-ui/services/user.service';
import { Injectable } from '@angular/core';

@Injectable(
  {providedIn: 'root'}
)
export class IsSysadminGuard {
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService
  ) {
  }

  async canActivate(): Promise<boolean | UrlTree> {
    if (!await this.authService.isSessionValid()) {
      return this.router.createUrlTree(['login']);
    }

    try {
      if (await this.userService.isSysAdminUser()) {
        return true;
      } else if (await this.userService.isEmployerPortalUser()) {
        return this.router.createUrlTree(['']);
      } else {
        await this.authService.logout('You do not have permissions to use this feature');
      }
    } catch (err) {
      if (err.status !== 503) {
        await this.authService.logout('Failed to retrieve account details');
      } else {
        return false;
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export const LEFT_ARROW_ICON_URL = '/assets/arrow_left_icon@2x.png';
export const BURGER_MENU_ICON_URL = '/assets/menu_icon.png';

export interface RouteArgs {
  url: string;
}

export interface ToolbarData {
  title: string;
  action?: 'toggle' | 'route';
  args?: RouteArgs;
}


@Injectable({
  providedIn: 'root'
})
export class ToolbarDataService {

  private subject = new Subject<ToolbarData>();

  get() {
    return this.subject.asObservable();
  }

  setupToolbar(title: ToolbarData) {
    this.subject.next(title);
  }

}

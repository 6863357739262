import { Component, OnInit, ViewChild, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CommsLogDataSource } from 'common-ui/comms-log-page/comms-log-data-source';
import { MatSort } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ToolbarDataService, FilterService, FilterType } from 'common-ui/services';
import { CommsLogService } from 'common-ui/services/comms-log.service';
import { CommsLogQueryDto, CommsLogDto, SortDirection } from 'common-ui/open-api';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { JsonDialogComponent } from 'common-ui/json-dialog/json-dialog.component';
import { Pages } from 'common-ui/services/filter.service';
import { Environment } from 'common-ui/models/environment.type';
import { ReportingService } from 'common-ui/services/reporting.service';

@Component({
  selector: 'lib-comms-log-page',
  templateUrl: './comms-log-page.component.html',
  styleUrls: ['./comms-log-page.component.css']
})
export class CommsLogPageComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns = [
    'email',
    'templateType',
    'createdDate',
    'status',
    'opened',
    'clicked',
    'spamReport'
  ];

  dataSource: CommsLogDataSource;
  private ngUnsubscribe = new Subject();
  FilterType = FilterType;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isWorking: boolean;
  errorMessage: string;

  constructor(
    private commsLogService: CommsLogService,
    private toolbarDataService: ToolbarDataService,
    private filterService: FilterService,
    private reportingService: ReportingService,
    private dialog: MatDialog,
    @Inject('env') public environment: Environment
  ) {
    this.dataSource = new CommsLogDataSource(commsLogService);

    if (this.isConsole) {
      this.displayedColumns.push('unsubscribed');
      this.displayedColumns.push('menu');
    }
  }

  ngOnInit(): void {
    this.toolbarDataService.setupToolbar({
      action: 'toggle', title: 'Email Log'
    });

  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => await this.loadData());

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      await this.loadData();
      this.paginator.firstPage();
    });

    const initialSort = this.filterService.getPageSort(Pages.COMMS_LOG);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.COMMS_LOG, newSort.active, newSort.direction as SortDirection);
      });

    await this.loadData();
  }

  async loadData() {
    const query: CommsLogQueryDto = {
      offset: this.paginator.pageIndex * this.pageSize,
      limit: this.pageSize
    };

    const currentFilter = this.filterService.currentFilter;
    query.employeeName = currentFilter.employeeSearch;
    query.masterAccountIds = currentFilter.masterAccounts;
    query.templateTypes = currentFilter.emailTemplateTypes;
    query.statuses = currentFilter.commsLogStatuses;

    if (this.sort.active) {
      const sort = {};
      sort[this.sort.active] = this.sort.direction;
      query.sort = sort;
    }

    this.dataSource.load(query);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  openJsonDialog(commsLog: CommsLogDto) {
    this.dialog.open(JsonDialogComponent, {
      data: {
        header: 'Comms Log Detail',
        data: commsLog
      }
    });
  }

  async resendComms(commsLogId: string) {
    await this.commsLogService.resendComms(commsLogId);
    await this.loadData();
  }

  async downloadEmailIssueReport() {
    try {
      this.isWorking = true;
      await this.reportingService.downloadEmailIssuesReport();
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }
}

<lib-page-container>
  <lib-filter-bar [textSearchType]="FilterType.EMPLOYEE_SEARCH" header>
    <div control-options>

      <button (click)="deleteSelected()"
              mat-button
              *ngIf="isConsole"
              [disabled]="selection.isEmpty()">
        Delete
      </button>

      <button (click)="openEmployeeCreateDialog()"
              mat-button
              *ngIf="!isConsole">
        Create
      </button>

      <button (click)="openEmployeeInviteDialog()"
              *ngIf="isPortal"
              mat-button>
        Invite Manager
      </button>

    </div>

    <lib-email-event-selector label="Invite Status">
    </lib-email-event-selector>

    <lib-employee-usage-rating-selector *ngIf="isConsole">
    </lib-employee-usage-rating-selector>

    <lib-boolean-selector
      *ngIf="isConsole"
      [filterType]="FilterType.EMPLOYEE_VULNERABLE"
      description="Vulnerable">
    </lib-boolean-selector>

    <lib-pay-frequency-selector>
    </lib-pay-frequency-selector>

    <lib-pay-type-selector>
    </lib-pay-type-selector>

    <lib-employment-status-selector>
    </lib-employment-status-selector>

    <lib-company-selector>
    </lib-company-selector>

    <lib-master-account-selector *ngIf="isConsole">
    </lib-master-account-selector>

  </lib-filter-bar>

  <table [dataSource]="dataSource"
         mat-table
         matSort>

    <ng-container matColumnDef="select">
      <th *matHeaderCellDef mat-header-cell>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td *matCellDef="let row" mat-cell>
        <mat-checkbox class="employee-checkbox"
                      (change)="$event ? selection.toggle(row) : null"
                      (click)="$event.stopPropagation()"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>First Name</th>
      <td *matCellDef="let element" mat-cell>
        <div>
          <div>{{ element.firstName }}</div>
          <div class="subtext vulnerable" *ngIf="element.isVulnerable && isConsole">vulnerable</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Last Name</th>
      <td *matCellDef="let element" mat-cell>{{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="masterAccountName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Master Account</th>
      <td *matCellDef="let element" mat-cell>{{ element.masterAccountName }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Company</th>
      <td *matCellDef="let element" mat-cell>
        <div>{{ element.companyName }}</div>
        <div>
          <span class="subtext">{{ element.employerPaysFee ? 'Employer Pays Fee' : '' }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="payFrequency">
      <th *matHeaderCellDef mat-header-cell>Payroll</th>
      <td *matCellDef="let element" mat-cell>
        <p class="payroll-value-main">{{ element.payFrequency | payFrequency }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="currency">
      <th *matHeaderCellDef mat-header-cell>Currency</th>
      <td *matCellDef="let element" mat-cell>
        <p class="payroll-value-main">{{ element.currency }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="payType">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Pay Type</th>
      <td *matCellDef="let element" mat-cell>
        <div>
          <div>{{ element.payType | titlecase }}</div>
          <div *ngIf="element.isZeroHoursWorker" class="subtext">Zero Hours</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="usageRating">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Usage</th>
      <td *matCellDef="let element" mat-cell>{{ element.usageRating | titlecase }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Email</th>
      <td *matCellDef="let element" mat-cell>{{ element.email }}</td>
    </ng-container>

    <ng-container matColumnDef="employmentStatus">
      <th *matHeaderCellDef mat-header-cell>Employment</th>
      <td *matCellDef="let element" mat-cell>{{ element.employmentStatus | titlecase }}</td>
    </ng-container>

    <ng-container matColumnDef="inviteStatus">
      <th *matHeaderCellDef mat-header-cell>Invite</th>
      <td *matCellDef="let element" mat-cell>
        <div class="two-line-field">
          <span>{{ element.inviteStatus | hyphenatedToRegular }}</span>
          <span *ngIf="!element.email" class="col-value-subtext-error">no email</span>
        </div>
      </td>

    </ng-container>

    <ng-container matColumnDef="amountAccrued">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Accrued
      </th>
      <td *matCellDef="let element" mat-cell>
        {{ element.amountAccrued | pennyToPound: element.currency: false }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amountAvailable">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Available
      </th>
      <td *matCellDef="let element" mat-cell>
        {{ element.amountAvailable | pennyToPound: element.currency: false }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amountWithdrawn">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>
        Withdrawn
      </th>
      <td *matCellDef="let element" mat-cell>
        <div>{{
            element.amountWithdrawn
              | pennyToPound: element.currency: false
          }}
        </div>
        <ng-container *ngIf="element.advancePayrollAmountWithdrawn > 0">
          <span class="sub-text">{{ element.advancePayrollAmountWithdrawn| pennyToPound: element.currency: true }}
            already settled</span>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="blocked">
      <th *matHeaderCellDef mat-header-cell>
      </th>
      <td *matCellDef="let element" mat-cell>
        <div class="blocked-icon">
          <mat-icon
            *ngIf="isEmployeeBlocked(element)"
            matTooltip="{{getBlockedTooltip(element)}}"
            [matTooltipPosition]="'above'">
            block
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="menu">
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <!--      Clicking this column does not select the employee     -->
      <td *matCellDef="let element" mat-cell (click)="$event.stopPropagation()">
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button (click)="openEmployeeEditDialog(element)" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit Employee</span>
          </button>

          <button [disabled]="element.payType!==PayType.TIMESHEET"
                  (click)="openEmployeeTimesheetDialog(element)" mat-menu-item>
            <mat-icon>today</mat-icon>
            <span>View Timesheets</span>
          </button>

          <button (click)="openEmployeeWithdrawalsDialog(element)" mat-menu-item>
            <mat-icon>payments</mat-icon>
            <span>View Withdrawals</span>
          </button>

          <button (click)="openAvailabilityExplainerDialog(element)" mat-menu-item>
            <mat-icon>calculate</mat-icon>
            <span>Amount Available Breakdown</span>
          </button>

          <button (click)="sendEmployeeInvite(element)" mat-menu-item>
            <mat-icon>mail</mat-icon>
            <span>{{ element.inviteStatus === EmailEventType.NOT_SENT ? 'S' : 'Res' }}end Invitation</span>
          </button>

          <button mat-menu-item
                  data-e2e="block-menu-item"
                  *ngIf="!isEmployeeBlocked(element)"
                  (click)="openEmployeeStatusDialog(element, true)">
            <mat-icon>block</mat-icon>
            <span>Block Employee</span>
          </button>

          <button mat-menu-item
                  *ngIf="isConsole"
                  (click)="openEmployeeVulnerabilityDialog(element)">
            <mat-icon>person</mat-icon>
            <span>Edit Vulnerability</span>
          </button>

          <button mat-menu-item
                  data-e2e="unblock-menu-item"
                  *ngIf="isEmployeeBlocked(element)"
                  (click)="openEmployeeStatusDialog(element, false)"
                  [disabled]="element.isCompanyOrMasterAccountBlocked">
            <mat-icon>refresh</mat-icon>
            <span>{{
                element.isCompanyOrMasterAccountBlocked ?
                  'Company is blocked' :
                  'Unblock Employee'
              }}</span>
          </button>

          <button
            mat-menu-item
            *ngIf="isConsole"
            (click)="updateAccrual(element)">
            <mat-icon>calculate</mat-icon>
            <span>Calculate Balances</span>
          </button>

          <button
            mat-menu-item
            *ngIf="isConsole"
            (click)="openAuditLogDialog(element)">
            <mat-icon>receipt_long</mat-icon>
            <span>Audit Log</span>
          </button>

          <button mat-menu-item
                  (click)="openDeleteEmployeeDialog(element)">
            <mat-icon>delete</mat-icon>
            <span>Delete Employee</span>
          </button>

          <button mat-menu-item
                  *ngIf="!!element.timesheetIntegrationId"
                  (click)="deIntegrateEmployee(element)">
            <mat-icon>delete</mat-icon>
            <span>De-Integrate Employee</span>
          </button>

          <button
            mat-menu-item
            (click)="openJsonDialog(element)"
            *ngIf="isConsole">
            <mat-icon>description</mat-icon>
            <span>View JSON</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr (click)="selection.toggle(row)"
        *matRowDef="let row; columns: displayedColumns;"
        class="clickable"
        mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="16">{{ (this.dataSource.loading$ | async) ? "Loading..." : "No data" }}</td>
    </tr>
  </table>

  <div footer>
    <mat-paginator [length]="dataSource.total$ | async"
                   [pageSize]="pageSize"
                   [showFirstLastButtons]=true>
    </mat-paginator>
  </div>
</lib-page-container>

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Master Account status
 */
export type EntityStatus = 'onboarding' | 'blocked' | 'active' | 'inactive';

export const EntityStatus = {
    ONBOARDING: 'onboarding' as EntityStatus,
    BLOCKED: 'blocked' as EntityStatus,
    ACTIVE: 'active' as EntityStatus,
    INACTIVE: 'inactive' as EntityStatus
};


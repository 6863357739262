<lib-dialog-container (exit)="close(false)"
                      [header]="(data.create ? 'Create' : 'Edit') + ' Employee'">

  <lib-employee-edit
    (operationCancelled)="close(false)"
    (operationComplete)="close(true)"
    [masterAccountId]="data.masterAccountId"
    [employee]="data.employee"
    [companyId]="data.createInCompanyId"
    [create]="data.create">
  </lib-employee-edit>
</lib-dialog-container>

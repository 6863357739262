import { Component, forwardRef, OnDestroy, Input } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormBuilder,
  ValidationErrors,
  ControlValueAccessor,
  Validators
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PayrollDay } from 'common-ui/open-api';

@Component({
  selector: 'lib-payroll-day-input',
  templateUrl: './payroll-day-input.component.html',
  styleUrls: ['./payroll-day-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PayrollDayInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PayrollDayInputComponent),
      multi: true
    }
  ]
})
export class PayrollDayInputComponent implements OnDestroy, ControlValueAccessor {

  @Input() label: string;
  @Input() derivedDate = 'tbd';

  form: FormGroup;
  private ngUnsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      day: [31, Validators.required],
      avoidHolidaysAndWeekends: true
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(payrollDay: PayrollDay): void {
    if (payrollDay) {
      // this.form.setValue(payrollDay, {emitEvent: false});
      this.form.setValue(payrollDay);
    }
  }

  validate(): ValidationErrors | null {
    const error: ValidationErrors = {};
    error.label = this.label;
    return this.form.valid ? null : error;
  }

}

import { CsvInputFile } from './csv-input-file';
import { CsvInputError, CsvImportTemplateField } from 'common-ui/open-api';
import { emailRegexp } from 'common-ui/util/email-validator';
import { parseIntStrict } from 'common-ui/util/parse-int-strict';
import { CsvPayFrequencyValidator } from './csv-pay-frequency-validator';
import { CsvInputCell } from './csv-input-cell';
import { parse, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { CsvCompanyNameValidator } from './csv-company-name-validator';
import { CsvCurrencyValidator } from './csv-currency-validator';

export const sortCodeRegex = new RegExp(/[0-9]{6}/);
export const dateRegex = new RegExp(/[0-9]{4}-[0-9]{2}-[0-9]{2}/);

export enum CsvFieldMappingStatus {
  UNMAPPED_REQUIRED = 'Unmapped required',
}

export class CsvCellMappingError {
  constructor(public badCell: CsvInputCell,
              public row: number) {
  }

  toString() {
    return `${this.badCell.cellErrors} (row ${this.row}) `;
  }
}

export class CsvColumnMapping {
  cellValidationErrors: CsvCellMappingError[] = [];
  status: CsvFieldMappingStatus;
  csvColumnName: string;
  firstRowValue: string;
  isKey: boolean;
  private readonly isRequiredField: boolean;

  constructor(
    public targetField: CsvImportTemplateField,
    public companyNameValidator: CsvCompanyNameValidator
  ) {
    if (targetField.validations.includes(CsvInputError.REQUIRED)) {
      this.isRequiredField = true;
      this.status = CsvFieldMappingStatus.UNMAPPED_REQUIRED;
    }
    if (targetField.validations.includes(CsvInputError.KEY)) {
      this.isKey = true;
    }
  }

  get label() {
    return this.targetField?.label || this.targetField.name;
  }

  get isRequired() {
    return this.isRequiredField;
  }

  get isValid(): boolean {
    return !this.status;
  }

  setMapping(csvColumnName: string) {
    this.csvColumnName = csvColumnName;
  }

  loadColumnValues(csvInputFile: CsvInputFile) {
    if (!this.csvColumnName) {
      if (this.targetField.validations.includes(CsvInputError.REQUIRED)) {
        this.status = CsvFieldMappingStatus.UNMAPPED_REQUIRED;
      }
      return;
    }
    const csvColumnCells = csvInputFile.getColumnValues(this.csvColumnName);
    if (!csvColumnCells) {
      if (this.targetField.validations.includes(CsvInputError.REQUIRED)) {
        this.status = CsvFieldMappingStatus.UNMAPPED_REQUIRED;
      }
      return;
    }
    this.status = null;
    this.firstRowValue = csvColumnCells[0].value;
    this.cellValidationErrors = [];

    for (let row = 0; row < csvColumnCells.length; row++) {
      const cell = csvColumnCells[row];

      if (cell) {
        cell.clearErrors();

        this.targetField.validations.forEach(validator => {
          if (this.isCellValid(validator, row, this.targetField, csvColumnCells)) {
            this.cellValidationErrors.push(new CsvCellMappingError(cell, row + 1));
          }
        });
      }
    }
  }

  removeMapping() {
    this.csvColumnName = '';
    this.cellValidationErrors.forEach(mapping => {
      mapping.badCell.cellErrors = [];
    });
    this.cellValidationErrors = [];
    this.firstRowValue = '';
  }

  private isCellValid(errorType: CsvInputError,
                      row: number,
                      targetField: CsvImportTemplateField,
                      csvColumnCells: CsvInputCell[]
  ): boolean {
    let columnValue = csvColumnCells[row].value;
    if (columnValue && targetField.isCaseInsensitive) {
      columnValue = columnValue.toLowerCase();
    }
    let fail = false;
    switch (errorType) {
      case CsvInputError.INVALID_EMAIL:
        fail = columnValue && !emailRegexp.test(columnValue);
        break;

      case CsvInputError.REQUIRED:
        fail = !columnValue;
        break;

      case CsvInputError.NOT_UNIQUE:
        if (columnValue) {
          const csvColumnValues = csvColumnCells.map(c => {
            return c.value;
          });
          fail = csvColumnValues.filter(v => v === columnValue).length > 1;
        }
        break;

      case CsvInputError.INVALID_DATE:
        if (columnValue) {

          const regexPattern = /^\d{2}\/\d{2}\/\d{4}$/;

          // Check if the date string matches the format
          if (!regexPattern.test(columnValue)) {
            fail = true;
          } else {
            const parsedDate = parse(columnValue, 'dd/MM/yyyy', new Date(), {locale: enGB});
            fail = !isValid(parsedDate);
          }
        }
        break;

      case CsvInputError.INVALID_INT:
        if (columnValue) {
          const n = parseIntStrict(columnValue);
          fail = isNaN(n);
        }
        break;

      case CsvInputError.INVALID_START_DATE:
        if (columnValue) {
          // eslint-disable-next-line
          const val = columnValue.replace(/[\/]/g, '-');
          if (!dateRegex.test(val)) {
            if (val.length === 10) {
              const newVal = val.slice(6) + '-' + val.slice(3, 5) + '-' + val.slice(0, 2);
              fail = !dateRegex.test(newVal);
            } else {
              fail = true;
            }
          }
        }
        break;

      case CsvInputError.INVALID_LEAVING_DATE:
        if (columnValue) {
          // eslint-disable-next-line
          const val = columnValue.replace(/[\/]/g, '-');
          if (!dateRegex.test(val)) {
            if (val.length === 10) {
              const newVal = val.slice(6) + '-' + val.slice(3, 5) + '-' + val.slice(0, 2);
              fail = !dateRegex.test(newVal);
            } else {
              fail = true;
            }
          }
        }
        break;

      case CsvInputError.INVALID_ENUM:
        if (columnValue) {
          fail = targetField.exampleValues
            .map(v => v.toLowerCase())
            .indexOf(columnValue) === -1;
        }
        break;
      case CsvInputError.INVALID_FREQUENCY: {
        const validator = new CsvPayFrequencyValidator();
        fail = !validator.isValid(columnValue);
        break;
      }
      case CsvInputError.INVALID_CURRENCY: {
        const currentValidator = new CsvCurrencyValidator();
        fail = !currentValidator.isValid(columnValue);
        break;
      }
      case CsvInputError.COMPANY_NAME:
        fail = !this.companyNameValidator.isValid(columnValue);
        break;
    }
    if (fail) {
      csvColumnCells[row].cellErrors.push(errorType);
    }
    return fail;
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PayrollService } from 'common-ui/services/payroll.service';
import { PayrollDto } from 'common-ui/open-api';

export interface ConfirmDeletePayrollData {
  payroll: PayrollDto;
}

@Component({
  selector: 'lib-confirm-delete-payroll-dialog',
  templateUrl: './confirm-delete-payroll-dialog.component.html',
  styleUrls: ['./confirm-delete-payroll-dialog.component.css']
})
export class ConfirmDeletePayrollDialogComponent {

  errorMessage: string;
  isLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeletePayrollData,
    private dialogRef: MatDialogRef<ConfirmDeletePayrollDialogComponent>,
    private payrollService: PayrollService
  ) {
  }

  get subHeader() {
    return `Confirm delete of ${this.data.payroll.frequency} payroll for ${this.data.payroll.companyName}.`;
  }

  public async deletePayroll() {
    try {
      await this.payrollService.deletePayroll(this.data.payroll._id);
      this.close(true);
    } catch (err) {
      this.errorMessage = err;
    }
  }

  public close(reload: boolean) {
    this.dialogRef.close(reload);
  }

}


import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-icon-error',
  templateUrl: './icon-error.component.html',
  styleUrls: ['./icon-error.component.css']
})
export class IconErrorComponent {

  @Input() control: AbstractControl;
  @Input() errorName: string;
  @Input() errorText: string;

  constructor() {
  }
}

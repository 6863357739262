import { Component, Input, OnDestroy, OnInit, OnChanges } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  Validator,
  ValidationErrors,
  NG_VALIDATORS,
  FormControl
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formatDateYYYYMMDD, parseDateYYYYMMDD } from 'common-ui/util';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import { DATE_FNS_DATE_FORMAT } from 'common-ui/util/date-formats';

@Component({
  selector: 'lib-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatepickerComponent,
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: DatepickerComponent,
    multi: true
  }]
})
export class DatepickerComponent
  implements ControlValueAccessor, OnDestroy, Validator, OnChanges, OnInit {

  @Input() label = 'Date';
  @Input() max: string;
  @Input() min: string;
  @Input() required = false;
  @Input() errorMessageOverrides: Record<string, string> = {};

  minDate: Date;


  errorMessages = {
    dateMax: 'dateMax',
    dateMin: 'dateMin',
    required: 'required'
  };

  datePickerControl = new FormControl();

  onTouched: () => void;

  private ngUnsubscribe$ = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.datePickerControl.setValidators(this.dateValidator.bind(this));
  }

  ngOnChanges(): void {
    if (this.min) {
      if (typeof this.min === 'string') {
        this.minDate = parseDateYYYYMMDD(this.min);
      } else {
        this.minDate = this.min;
      }
      this.errorMessages.dateMin = this.label + ' must be after ' + format(parse(this.min, DATE_FNS_DATE_FORMAT, new Date()), 'dd/MM/yyyy');
    }
    if (this.max) {
      this.errorMessages.dateMax = this.label + ' must be before ' + format(parse(this.max, DATE_FNS_DATE_FORMAT, new Date()), 'dd/MM/yyyy');
    }
    if (this.required) {
      this.errorMessages.required = this.label + ' is required';
    }
    this.datePickerControl.updateValueAndValidity();
  }

  dateValidator(control: FormControl): ValidationErrors {
    if (control.value) {
      const m = control.value;

      if (!isValid(m)) {
        return {invalidDate: true};
      }

      if (isBefore(m, new Date(1900, 1, 1))) {
        return {invalidDate: true};
      }

      const stringDate = format(m, DATE_FNS_DATE_FORMAT);
      if (this.max && stringDate > this.max) {
        return {dateMax: true};
      }
      if (this.min && stringDate < this.min) {
        return {dateMin: true};
      }
    } else if (this.required) {
      return {required: true};
    }
  }

  formatError(key: string): string {
    const opts = {
      ...this.errorMessages,
      ...this.errorMessageOverrides
    };
    return opts[key];
  }

  writeValue(value: any) {
    if (value) {
      const date = parse(value, DATE_FNS_DATE_FORMAT, new Date());
      this.datePickerControl.setValue(date);
    } else {
      this.datePickerControl.setValue(null);
    }
  }

  registerOnChange(fn: any) {
    this.datePickerControl.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$)
      ).subscribe((v: any) => {
      let newValue = '';
      if (typeof v === 'string') {
        newValue = v;
      } else if (v) {
        newValue = formatDateYYYYMMDD(v);
      }
      fn(newValue);
    });
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate() {
    return this.datePickerControl.errors;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.datePickerControl.disable();
    } else {
      this.datePickerControl.enable();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }
}

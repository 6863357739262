import { Component } from '@angular/core';
import { EmailTemplateType } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';
import { HyphenatedToRegularPipe } from 'common-ui/pipes/hyphenated-to-regular.pipe';

@Component({
  selector: 'lib-email-template-type-selector',
  templateUrl: './email-template-type-selector.component.html',
  styleUrls: ['./email-template-type-selector.component.css']
})
export class EmailTemplateTypeSelectorComponent {
  templateType = Object.values(EmailTemplateType).map(value => ({
    value: value.toString(),
    description: new HyphenatedToRegularPipe().transform(value.toString())
  })).sort(((item1, item2) => item1.description > item2.description ? 1 : -1 ));
  FilterType = FilterType;
}

import { Injectable } from '@angular/core';
import { CreateSupportTicketDto } from 'common-ui/open-api';
import { ApiService } from 'common-ui/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactSupportService {

  constructor(private apiService: ApiService) {
  }

  async create(ticket: CreateSupportTicketDto) {
    await this.apiService.post('/api/support-ticket/', ticket);
  }
}

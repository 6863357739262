<div class="container">
  <form [formGroup]="form"
        class="scrolling-section">

    <table mat-table
           [dataSource]="dataSource"
           class="schedule-table">

      <ng-container matColumnDef="periodStart">
        <th mat-header-cell *matHeaderCellDef>Period Start</th>
        <td mat-cell *matCellDef="let row;">
          {{ row.periodStart | formatDateTimeString: false }}
        </td>
      </ng-container>

      <ng-container matColumnDef="periodEnd">
        <th mat-header-cell *matHeaderCellDef>Period End</th>
        <td mat-cell *matCellDef="let row;">
          {{ row.periodEnd | formatDateTimeString: false }}
        </td>
      </ng-container>

      <ng-container matColumnDef="payDate">
        <th mat-header-cell *matHeaderCellDef>Pay Date</th>
        <td mat-cell *matCellDef="let row;">
          {{ row.payDate | formatDateTimeString: false }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button
                  [disabled]="i < customSchedule.length -1"
                  class="delete-button"
                  (click)="deleteCustomPeriod(i)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Header and Row Definitions -->
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>

      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell" colspan="16">{{ isWorking ? 'Loading...' : "Please add at least one custom period" }}</td>
      </tr>
    </table>
  </form>

  <form [formGroup]="newPeriodForm"
        class="new-period-form"
        (ngSubmit)="addCustomPeriod()">
    <div class="form-fields">

      <lib-datepicker
        formControlName="periodStart"
        label="Period Start">
      </lib-datepicker>

      <lib-datepicker
        *ngIf="!newPeriodStart"
        formControlName="periodEnd"
        label="Period End">
      </lib-datepicker>

      <lib-datepicker
        *ngIf="newPeriodStart"
        formControlName="periodEnd"
        [min]="newPeriodStart"
        label="Period End">
      </lib-datepicker>

      <lib-datepicker
        *ngIf="includePayDate"
        formControlName="payDate"
        label="Pay Date">
      </lib-datepicker>

      <button mat-button
              [disabled]="newPeriodForm.invalid"
              type="submit">
        Add
      </button>
    </div>

  </form>
</div>

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MasterAccountType = 'Internal' | 'Trial' | 'Demo' | 'Customer';

export const MasterAccountType = {
    INTERNAL: 'Internal' as MasterAccountType,
    TRIAL: 'Trial' as MasterAccountType,
    DEMO: 'Demo' as MasterAccountType,
    CUSTOMER: 'Customer' as MasterAccountType
};


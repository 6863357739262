import { Component } from '@angular/core';
import { environment } from '../../../../portal/src/environments/environment';

@Component({
  selector: 'lib-404',
  templateUrl: './four-o-four.component.html',
  styleUrls: ['./four-o-four.component.css']
})
export class FourOFourComponent {
  imageSource: string;

  constructor() {
    this.imageSource = environment.locations.images;
  }

}

import { Component } from '@angular/core';
import { FilterType } from 'common-ui/services';
import { WithdrawalType } from 'common-ui/open-api';
import { HyphenatedToRegularPipe } from 'common-ui/pipes/hyphenated-to-regular.pipe';

@Component({
  selector: 'lib-withdrawal-type-selector',
  templateUrl: './withdrawal-type-selector.component.html',
  styleUrls: ['./withdrawal-type-selector.component.css']
})
export class WithdrawalTypeSelectorComponent {
  enumValues = Object.values(WithdrawalType).map(value => ({
    value: value.toString(),
    description: new HyphenatedToRegularPipe().transform(value)
  }));
  FilterType = FilterType;
}

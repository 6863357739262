<mat-form-field>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    matInput
    [min]="minDate"
    [matDatepicker]="picker"
    [formControl]="datePickerControl">

  <mat-datepicker-toggle
    matSuffix
    [for]="picker">
  </mat-datepicker-toggle>

  <mat-datepicker #picker></mat-datepicker>
  <mat-error
    *ngFor="let error of datePickerControl.errors | validationErrorsKeyValue">
    {{ formatError(error.key) }}
  </mat-error>
</mat-form-field>

<lib-spinner-container
  [isWorking]="isWorking">

  <lib-dialog-container
    (exit)="dialogRef.close(!!latestImport)"
    [showCross]="true"
    [header]="header"
    [subHeader]="subHeader">

    <lib-error-message [message]="errorMessage">
    </lib-error-message>

    <lib-integration-result
      *ngIf="latestImport"
      [importResult]="latestImport">
    </lib-integration-result>

    <lib-button-panel>
      <button
        mat-flat-button
        color="primary"
        (click)="runImport()"
        [disabled]="isWorking || masterAccountHasNoIntegrations || masterAccountHasNoIntegrations">
        Run
      </button>
    </lib-button-panel>

  </lib-dialog-container>
</lib-spinner-container>

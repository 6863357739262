<div *ngIf="profile">

  <div class="box">
    FlexEarn is closed until {{ profile.periodEnd | date: 'dd MMM' }}.
  </div>

  <lib-employee-portal-time-left
    text="Opens in"
    [periodEnd]="profile.periodEnd"
    [systemTime]="profile.systemTime">
  </lib-employee-portal-time-left>

  <lib-employee-portal-info-panel
    text="Withdrawals Carried Forward"
    [accent]=true
    [currency]="profile.currency"
    [pennyAmount]="profile.amountWithdrawn - profile.advancePayrollAmountWithdrawn">
  </lib-employee-portal-info-panel>

</div>

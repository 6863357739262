import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CarePlannerService } from '../services/care-planner.service';
import { CarePlannerPaymentGroupDto } from 'common-ui/open-api';
import { FormControl } from '@angular/forms';

export class PaymentGroupsDialogData {
  masterAccountId: string;
  paymentGroup: CarePlannerPaymentGroupDto | null;
}

@Component({
  selector: 'lib-care-planner-payment-groups-dialog',
  templateUrl: './care-planner-payment-groups-dialog.component.html',
  styleUrls: ['./care-planner-payment-groups-dialog.component.css']
})
export class CarePlannerPaymentGroupsDialogComponent implements OnInit {

  paymentGroups: CarePlannerPaymentGroupDto[];
  formControl = new FormControl();
  isWorking = true;

  constructor(
    private dialogRef: MatDialogRef<CarePlannerPaymentGroupsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: PaymentGroupsDialogData,
    private carePlannerService: CarePlannerService
  ) {
  }

  async ngOnInit() {
    try {
      this.paymentGroups = await this.carePlannerService.getPaymentGroups(this.data.masterAccountId);
    } catch (err) {
      console.log(err.message);
    }
    if ( this.data.paymentGroup ) {
      const value = this.paymentGroups.find(pg => pg.identifier === this.data.paymentGroup.identifier);
      this.formControl.setValue(value)
    }
    this.isWorking = false;
  }

  async save() {
    this.dialogRef.close(this.formControl.value);
  }

  cancel() {
    this.dialogRef.close();
  }
}

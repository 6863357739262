import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import {
  UpsertSimpleTimesheetDto,
  TimesheetDto,
  TimesheetQuery,
  TimesheetQueryResultDto,
  AggregatedTimesheetQueryResultDto, CreateManualTimesheetDto, UpdateManualTimesheetDto
} from 'common-ui/open-api';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  constructor(
    private apiService: ApiService
  ) {
  }

  async getTimesheetsForEmployeeByDate(employeeId: string, range: JustDateRange): Promise<TimesheetDto[]> {
    return await this.apiService.get(`/api/timesheet?employeeId=${employeeId}&&fromDate=${range.fromDate}&toDate=${range.toDate}`);
  }

  async queryAggregatedTimesheets(
    timesheetQuery: TimesheetQuery
  ): Promise<AggregatedTimesheetQueryResultDto> {
    return await this.apiService.post(`/api/timesheet/query-aggregated`, timesheetQuery);
  }

  async queryTimesheets(timesheetQuery: TimesheetQuery): Promise<TimesheetQueryResultDto> {
    return await this.apiService.post(`/api/timesheet/query`, timesheetQuery);
  }

  async upsertTimesheet(entry: UpsertSimpleTimesheetDto) {
    return await this.apiService.post(`/api/timesheet/simple`, entry);
  }

  async createManualTimesheet(timesheet: CreateManualTimesheetDto) {
    return await this.apiService.post(`/api/timesheet/`, timesheet);
  }

  async deleteTimesheet(timesheetId: string) {
    await this.apiService.delete(`/api/timesheet/${timesheetId}`);
  }

  async updateManualTimesheet(timesheetId: string, updateDto: UpdateManualTimesheetDto) {
    await this.apiService.patch(`/api/timesheet/${timesheetId}`, updateDto)

  }
}

import { Component, Inject } from '@angular/core';
import { EmployeeDto } from 'common-ui/open-api';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

export interface EmployeeEditData {
  masterAccountId: string;
  createInCompanyId?: string;
  employee?: EmployeeDto;
  create: boolean;
}

@Component({
  selector: 'lib-employee-edit-dialog',
  templateUrl: './employee-edit-dialog.component.html',
  styleUrls: ['./employee-edit-dialog.component.css']
})
export class EmployeeEditDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmployeeEditData,
    private dialogRef: MatDialogRef<EmployeeEditDialogComponent>
  ) {
  }

  close(reload: boolean) {
    this.dialogRef.close(reload);
  }

}

import { Component, Input } from '@angular/core';
import { ApiSource } from 'common-ui/open-api';

@Component({
  selector: 'lib-integration-source',
  templateUrl: './integration-source.component.html',
  styleUrls: ['./integration-source.component.scss']
})
export class IntegrationSourceComponent {

  @Input() timesheetIntegrationSource: ApiSource;
  @Input() target: string;

  constructor() {
  }
}

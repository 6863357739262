<div
  class="dialog-container">

  <div class="header-container-line mat-typography">
    <lib-dialog-header
      class="container-dialog-header"
      [maxWidth]="maxHeaderWidth"
      *ngIf="header"
      [subHeader]="subHeader">
      {{header}}
    </lib-dialog-header>

    <button
      *ngIf="showCross"
      mat-icon-button
      tabindex="-1"
      (click)="exit.emit()"
      class="close-icon">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="dialog-content-container">
    <ng-content></ng-content>
  </div>

  <div>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>

import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FilterService, FilterType, SystemService } from 'common-ui/services';
import { Subject } from 'rxjs';
import { DateRangeType, NewDateRange, getDateRangeSummary, getJustDateRange } from './date-range.type';
import { takeUntil } from 'rxjs/operators';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';


@Component({
  selector: 'lib-custom-dates-range-selector',
  templateUrl: './custom-dates-range-selector.component.html',
  styleUrls: ['./custom-dates-range-selector.component.css']
})
export class CustomDatesRangeSelectorComponent implements OnInit, OnDestroy {

  form: FormGroup;
  dateRangeHeading: string;
  DateRangeType = DateRangeType;
  ngUnSubscribe = new Subject();
  @ViewChildren('menuTrigger')
  menuTriggerQuery: QueryList<MatMenuTrigger>;

  constructor(
    private filterService: FilterService,
    private systemService: SystemService,
    formBuilder: FormBuilder
  ) {
    this.form = formBuilder.group({
      dateRangeType: DateRangeType.MONTHS,
      dateRangeAmount: [ 1, [Validators.required, Validators.min(1)]],
      fromDate: [''],
      toDate: ['']
    });
  }

  get pluralSuffix() {
    return this.form.value.dateRangeAmount > 1 ? 's' : '';
  }

  ngOnInit(): void {

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnSubscribe))
      .subscribe(value => {
        if (value.dateRangeType === DateRangeType.CUSTOM) {
          if (this.form.controls['fromDate'].disabled) {
            this.form.controls['fromDate'].enable({emitEvent: false});
            this.form.controls['toDate'].enable({emitEvent: false});
          }
          if (this.form.controls['dateRangeAmount'].enabled) {
            this.form.controls['dateRangeAmount'].disable({emitEvent: false});
          }
        } else {

          if (this.form.controls['fromDate'].enabled) {
            this.form.controls['fromDate'].disable({emitEvent: false});
            this.form.controls['toDate'].disable({emitEvent: false});
          }
          if (this.form.controls['dateRangeAmount'].disabled) {
            this.form.controls['dateRangeAmount'].enable({emitEvent: false});
          }
        }
      });

    const dateRange: NewDateRange = this.filterService.getFilter(FilterType.DATE_RANGE);
    const justDateRange = getJustDateRange(dateRange, this.systemService.getSystemTime());

    if (dateRange) {
      this.form.setValue({
        dateRangeType: dateRange.dateRangeType,
        dateRangeAmount: dateRange.dateRangeAmount || 1,
        fromDate: justDateRange.fromDate,
        toDate: justDateRange.toDate
      });

    } else {
      const defaultDateRange: NewDateRange = {
        dateRangeType: DateRangeType.MONTHS,
        dateRangeAmount: 1
      };
      const justDateRange = getJustDateRange(dateRange, this.systemService.getSystemTime());

      this.form.setValue({
        ...defaultDateRange,
        ...justDateRange
      });
    }


    this.updateHeading();
  }

  updateFilterService() {
    if (!this.form.valid) {
      return;
    }
    this.filterService.setFilter(FilterType.DATE_RANGE, this.form.value);
    this.updateHeading();
  }

  closeMenu() {
    if (!this.form.valid) {
      return;
    }
    const menuTrigger = this.menuTriggerQuery.first;
    menuTrigger.closeMenu();
  }

  updateHeading() {
    this.dateRangeHeading = getDateRangeSummary(this.form.value);
  }

  ngOnDestroy() {
    this.ngUnSubscribe.next(undefined);
    this.ngUnSubscribe.complete();
  }
}

<div class="wrapper mat-typography">
  <mat-toolbar class="toolbar" color="primary">
    <span>{{title}}</span>
  </mat-toolbar>

  <div class="box">
    <div class="logo-container">
      <img class="logo" src="{{ imageSource }}/logo_purpletext.svg">
    </div>

    <div class="mat-typography centre-text">
      <div class="mat-title">Uh oh, something went wrong!</div>
      <div class="sub-message" *ngIf="authService.loginError">{{ authService.loginError }}</div>
      <div class="sub-message">Please try again later.</div>
    </div>

    <button
      mat-flat-button
      color="primary"
      (click)="tryAgain()">
      Try Again
    </button>

  </div>
</div>


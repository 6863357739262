import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PayrollDto, CompanyDto } from 'common-ui/open-api';

export interface PayrollEditData {
  payrollId?: string;
  companyId?: string;
  existingPayrolls: PayrollDto[];
  create: boolean;
  availableCompanies?: CompanyDto[];
}

@Component({
  selector: 'lib-payroll-edit-dialog',
  templateUrl: './payroll-edit-dialog.component.html',
  styleUrls: ['./payroll-edit-dialog.component.css']
})
export class PayrollEditDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PayrollEditData,
    private dialogRef: MatDialogRef<PayrollEditDialogComponent>
  ) {
  }

  close(reload: boolean) {
    this.dialogRef.close(reload);
  }

}

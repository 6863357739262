<div class="week-selector" *ngIf="displayedWeek">
  <button
    mat-button
    [disabled]="isLoading"
    (click)="prevWeek()">
    <<
  </button>

  <h3>{{ (this.displayedWeek.fromDate | date: "dd-MMM") +
  "  to  " + (this.displayedWeek.toDate | date: "dd-MMM")}}
  </h3>

  <button
    mat-button
    [disabled]="isLoading"
    (click)="nextWeek()">
    >>
  </button>
</div>

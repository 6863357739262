import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable({providedIn: 'root'})
export class FileDownloadService {

  getBlobFileName(res: HttpResponse<any>) {
    const contentDispositionHeader = res.headers.get('content-disposition');
    if (contentDispositionHeader) {
      const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
      return result.replace(/"/g, '');
    }
    return null;
  }

  async downloadTextFile(responseObservable: Observable<HttpResponse<string>>) {
    const res = await firstValueFrom(responseObservable);
    const link = document.createElement('a');
    const blob = new Blob([res.body], { type: 'text/plain' });
    link.href = window.URL.createObjectURL(blob);
    link.download = this.getBlobFileName(res);
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  async downloadFile(blobObservable: Observable<HttpResponse<Blob>>) {
    const res = await firstValueFrom(blobObservable);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(res.body);
    link.download = this.getBlobFileName(res);
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}

import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { ImpersonationService } from 'common-ui/services/impersonation.service';
import { UserService } from 'common-ui/services/user.service';
import { LogoutTimerService } from 'common-ui/services/logout-timer.service';
import { PasswordResetType, SignInDto, CheckTokenResultDto, SignInFromType } from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router,
    private apiService: ApiService,
    private impersonationService: ImpersonationService,
    private userService: UserService,
    @Inject('env') private environment: any,
    private logoutTimer: LogoutTimerService,
  ) {
    this.logoutTimer.logout$.subscribe(async () => {
      await this.logout('Logged out due to inactivity');
    });
  }

  get loginError() {
    return this.apiService.loginError;
  }

  set loginError(loginError: string) {
    this.apiService.loginError = loginError;
  }

  async logout(message?: string) {
    await this.apiService.logout(message);
  }

  async checkTokenAndSignIn(token: string, password: string): Promise<void> {
    const signInDto = await this.apiService.post(`/api/user/check-token-and-sign-in`, {
      token, password,
      signInFrom: this.environment.adminConsole ? SignInFromType.CONSOLE : SignInFromType.PORTAL
    });

    if (!signInDto.authErrorCode) {
      this.apiService.setIdToken(signInDto.idToken);
    } else {
      throw new Error(signInDto.authErrorCode);
    }
  }

  async checkToken(token: string): Promise<CheckTokenResultDto> {
    return await this.apiService.post(`/api/user/check-token`, {token});
  }

  async sendPasswordReset(email: string, type: PasswordResetType) {
    const routeToConsole = this.environment.adminConsole === true;
    await this.apiService.post('/api/user/password-reset',
      {email, type, routeToConsole});
  }

  async sendOTPEmail(email: string) {
    await this.apiService.post('/api/user/send-otp-email',
      {email});
  }

  async signInWithEmailAndPassword(email: string, password: string, redirectUrl: string) {
    const signInDto = await this.apiService.post(`/api/user/sign-in-with-email-and-password`, {
      email,
      password,
      signInFrom: this.environment.adminConsole ? SignInFromType.CONSOLE : SignInFromType.PORTAL
    });
    await this.processSignIn(signInDto, redirectUrl);
  }

  private async processSignIn(signInDto: SignInDto, redirectUrl: string) {
    if (!signInDto.authErrorCode) {
      this.impersonationService.clear();
      this.apiService.setIdToken(signInDto.idToken);
      if (await this.userService.isSysAdminUser() && !this.environment.adminConsole) {
        await this.router.navigateByUrl('account-impersonator');
      } else {
        await this.router.navigateByUrl(redirectUrl);
      }
    } else {
      throw new Error(signInDto.authErrorCode);
    }
  }

  async signInWithGoogle(idToken: string, redirectUrl: string) {
    const signInDto = await this.apiService.post('/api/user/login-with-google', {
      idToken,
      signInFrom: this.environment.adminConsole ? SignInFromType.CONSOLE : SignInFromType.PORTAL
    });
    await this.processSignIn(signInDto, redirectUrl);
  }

  async isSessionValid() {
    this.loginError = '';
    if (this.apiService.isAuthenticated()) {
      if (this.apiService.isIdTokenExpired && this.apiService.isRefreshTokenExpired()) {
        await this.logout('Session Expired');
        return false;
      }
      return true;
    } else {
      this.apiService.currentUser = null;
      return false;
    }
  }

  async deleteAccountWithOtp(email: string, otp: string) {
    await this.apiService.post('/api/user/delete-account-with-otp', {
      email, otp
    });
  }

  async undeleteAccount(email: string) {
    await this.apiService.post('/api/user/undelete-account', {
      email
    });
  }
}

<div *ngIf="masterAccount">
  <mat-tab-group [selectedIndex]="selectedTab">
    <mat-tab label="General">
      <div class="gap"></div>
      <lib-master-account-general-settings
        (masterAccountChange)="reloadMasterAccount()"
        [masterAccount]="masterAccount">
      </lib-master-account-general-settings>
    </mat-tab>
    <mat-tab label="Users">
      <lib-master-account-user-settings
        [masterAccount]="masterAccount">
      </lib-master-account-user-settings>
    </mat-tab>
    <mat-tab label="Integration">
      <div class="gap"></div>
      <lib-master-account-integration-settings
        (masterAccountChanges)="reloadMasterAccount()"
        [masterAccount]="masterAccount">
      </lib-master-account-integration-settings>
    </mat-tab>
    <mat-tab label="Limits">
      <div class="gap"></div>
      <lib-master-account-limit-settings
        [masterAccount]="masterAccount">
      </lib-master-account-limit-settings>
    </mat-tab>
    <mat-tab label="Customisation">
      <div class="gap"></div>
      <lib-master-account-customisation-settings
        [masterAccount]="masterAccount">
      </lib-master-account-customisation-settings>
    </mat-tab>
    <mat-tab label="Report Exports">
      <div class="gap"></div>
      <lib-master-account-report-export-settings
        [masterAccount]="masterAccount">
      </lib-master-account-report-export-settings>
    </mat-tab>
    <mat-tab label="CSV Import" *ngIf="isConsole">
      <lib-csv-import
        [masterAccountId]="masterAccount._id">
      </lib-csv-import>
    </mat-tab>
  </mat-tab-group>
</div>

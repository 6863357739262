import { Injectable, Inject } from '@angular/core';
import { Environment } from 'common-ui/models/environment.type';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationService {

  private USER_KEY = 'user-to-impersonate';

  constructor(
    @Inject('env') private environment: Environment
  ) {
  }

  clear() {
    localStorage.removeItem(this.USER_KEY);
  }

  get userToImpersonate() {
    if (this.environment.adminConsole) {
      return undefined;
    } else {
      return localStorage.getItem(this.USER_KEY);
    }
  }

  set userToImpersonate(userId: string) {
    if (userId) {
      localStorage.setItem(this.USER_KEY, userId);
    } else {
      localStorage.removeItem(this.USER_KEY);
    }
  }

}

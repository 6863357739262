import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CompanyDto, Address, EntityStatus, Currency } from 'common-ui/open-api';
import {
  CompanyService,
  PayrollService,
  ToolbarDataService,
  FilterService,
  FilterType,
  FilterStatus
} from 'common-ui/services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyEditData, CompanyEditDialogComponent } from '../company-edit-dialog/company-edit-dialog.component';
import { PayrollEditDialogComponent, PayrollEditData } from '../payroll-edit-dialog/payroll-edit-dialog.component';
import { EmployeeEditDialogComponent, EmployeeEditData } from '../employee-edit-dialog/employee-edit-dialog.component';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { AreYouSureDialogComponent } from 'common-ui/are-you-sure-dialog/are-you-sure-dialog.component';
import { ErrorDialogComponent } from 'common-ui/error-dialog/error-dialog.component';
import { CompanyPayCodesDialogComponent } from 'common-ui/company-pay-codes-dialog/company-pay-codes-dialog.component';
import { UserService } from 'common-ui/services/user.service';
import { Environment } from 'common-ui/models/environment.type';

@Component({
  selector: 'lib-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.css']
})
export class CompanyPageComponent implements OnInit, AfterViewInit, OnDestroy {

  dataSource: MatTableDataSource<CompanyDto> = new MatTableDataSource<CompanyDto>();
  isLoading = true;
  displayedColumns = [
    'legalEntity',
    'name',
    'mandateId',
    'contractSigned',
    'unsettledGbpAmount',
    'unsettledEurAmount',
    'unsettledCount',
    'status',
    'menu'
  ];

  @ViewChild(MatSort) sort: MatSort;

  @Output() openCompanySetupDialog = new EventEmitter<string | undefined>();

  filterSub: Subscription;
  FilterType = FilterType;

  constructor(
    private companyService: CompanyService,
    private dialog: MatDialog,
    private toolbarDataService: ToolbarDataService,
    private payrollService: PayrollService,
    private filterService: FilterService,
    private userService: UserService,
    @Inject('env') private environment: Environment
  ) {
    if (this.environment.adminConsole) {
      this.displayedColumns = ['masterAccountName', ...this.displayedColumns];
    }
  }

  async ngOnInit(): Promise<void> {

    this.toolbarDataService.setupToolbar({
      action: 'toggle',
      title: 'Companies'
    });

    await this.getData();

    this.dataSource.filterPredicate = (data: CompanyDto, filterString: string) => {
      const filter: FilterStatus = JSON.parse(filterString) as FilterStatus;

      if (this.isConsole) {
        const search = !filter.companySearch ||
          data.name.toLowerCase().indexOf(filter.companySearch.toLowerCase()) > -1
          || data.masterAccountName.toLowerCase().indexOf(filter.companySearch.toLowerCase()) > -1
          || data.legalEntityName?.toLowerCase().indexOf(filter.companySearch.toLowerCase()) > -1;

        const general = this.filterService.checkGeneralFilters([
          {value: data.masterAccountId, type: FilterType.MASTER_ACCOUNT}
        ]);

        const checkStatus = !filter.masterAccountStatus
          || filter.masterAccountStatus.length === 0
          || filter.masterAccountStatus.includes(data.status);

        return general && search && checkStatus;
      } else {
        return !filter.companySearch ||
          data.name.toLowerCase().indexOf(filter.companySearch.toLowerCase()) > -1
          || data.legalEntityName?.toLowerCase().indexOf(filter.companySearch.toLowerCase()) > -1;
      }
    };
    this.dataSource.filter = JSON.stringify(this.filterService.currentFilter);
    this.filterSub = this.filterService.filter$.subscribe(updatedFilter => {
      this.dataSource.filter = JSON.stringify(updatedFilter);
    });
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (
      data: CompanyDto,
      sortHeaderId: string) => {
      if (sortHeaderId === 'address') {
        return this.formatAddress(data.address).toUpperCase();
      } else if (sortHeaderId === 'name' || sortHeaderId === 'deductionId') {
        return data[sortHeaderId].toUpperCase();
      } else {
        return data[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;
  }

  async getData() {
    const companies = await this.companyService.getCompaniesForUser();

    this.dataSource.data = companies.map(c => ({
      ...c,
      fullAddress: this.formatAddress(c.address)
    }));

    this.isLoading = false;
  }

  openEditCompanyDialog(create: boolean, companyId?: string) {
    const data: CompanyEditData = {
      create, companyId,
      masterAccountId: this.userService.currentUser.masterAccountId
    };
    const ref = this.dialog.open(CompanyEditDialogComponent, {data});
    ref.afterClosed().subscribe(async success => {
      if (success) {
        await this.ngOnInit();
      }
    });
  }

  openContractSignedDialog(company: CompanyDto) {
    const setContractSigned = !company.contractSignedEmail;
    console.log('setContractSigned', setContractSigned);
    const message = setContractSigned ? 'Confirm Contract Signed?' : 'Confirm Contract Revoked?';

    const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
      data: {
        message: message
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (setContractSigned) {
          await this.companyService.contractSigned(company._id);
        } else {
          await this.companyService.contractRevoked(company._id);
        }
        await this.ngOnInit();
      }
    });
  }

  async openCreatePayrollDialog(company?: CompanyDto) {
    const inputCompanyId = company._id || this.dataSource.data[0]._id;
    const data: PayrollEditData = {
      create: true,
      existingPayrolls: await this.payrollService.getPayrollsForCompany(inputCompanyId),
      companyId: inputCompanyId
    };
    const ref = this.dialog.open(PayrollEditDialogComponent, {data});
    ref.afterClosed().subscribe(async reload => {
      if (reload) {
        await this.ngOnInit();
      }
    });
  }

  openCreateEmployeeDialog(company?: CompanyDto) {
    const data: EmployeeEditData = {
      masterAccountId: company.masterAccountId,
      createInCompanyId: company._id,
      create: true
    };

    const dialogRef = this.dialog.open(EmployeeEditDialogComponent, {data});
    dialogRef.afterClosed().subscribe(async reload => {
      if (reload) {
        await this.ngOnInit();
      }
    });
  }

  openDeleteCompanyDialog(company?: CompanyDto) {
    const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
      data: {
        message: `Are you sure you want to delete ${company.name}`
      }
    });
    dialogRef.afterClosed().subscribe(async confirm => {
      if (confirm) {
        try {
          await this.companyService.deleteCompany(company._id);
        } catch (err) {
          this.dialog.open(ErrorDialogComponent, {
            data: {
              message: err.message,
              operationName: 'Delete Company'
            }
          });
        }
        await this.ngOnInit();
      }
    });
  }

  openCompanyPayCodesDialog(company: CompanyDto) {
    this.dialog.open(CompanyPayCodesDialogComponent, {
      data: {
        companyId: company._id,
        companyName: company.name
      }
    });
  }


  isContractSigned(company: CompanyDto) {
    return !!company.contractSignedEmail;
  }

  formatAddress(addressObj: Address) {
    if (addressObj) {
      return `${addressObj.firstLine}, ${addressObj.town}`;
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }
  }

  getBlockButtonText(company: CompanyDto): string {
    if (company.status === EntityStatus.BLOCKED) {
      return 'Unblock';
    } else {
      return 'Block';
    }
  }

  async blockCompany(company: CompanyDto) {
    if (company.status === EntityStatus.BLOCKED) {
      await this.companyService.unblockCompany(company._id);
    } else {
      await this.companyService.blockCompany(company._id);
    }

    await this.getData();
  }

  protected readonly Currency = Currency;
}

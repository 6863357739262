import { Component } from '@angular/core';
import { FilterItem, FilterType } from 'common-ui/services';

@Component({
  selector: 'lib-timesheet-status-selector',
  templateUrl: './timesheet-status-selector.component.html',
  styleUrls: ['./timesheet-status-selector.component.css']
})
export class TimesheetStatusSelectorComponent {

  statuses: FilterItem[] = [{
    value: 'isApproved',
    description: 'Approved'
  }, {
    value: 'isCancelled',
    description: 'Cancelled'
  }];

  FilterType = FilterType;
}

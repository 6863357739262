import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-tick-cross',
  templateUrl: './tick-cross.component.html',
  styleUrls: ['./tick-cross.component.css']
})
export class TickCrossComponent {

  @Input() value: boolean;
  @Input() blackAndWhite = false;

  constructor() {
  }
}

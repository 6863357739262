import { Component, Inject, OnInit } from '@angular/core';
import { PayCodeDto } from 'common-ui/open-api';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PayCodeService } from 'common-ui/services/pay-code.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

export interface CompanyDialogData {
  companyId: string;
  companyName: string;
}

@Component({
  selector: 'lib-company-pay-codes-dialog',
  templateUrl: './company-pay-codes-dialog.component.html',
  styleUrls: ['./company-pay-codes-dialog.component.css']
})
export class CompanyPayCodesDialogComponent implements OnInit {

  subHeader: string;
  dataSource = new MatTableDataSource<PayCodeDto>();
  isLoading = true;
  error: string;

  displayedColumns: string[] = [
    'payCode',
    'rate'
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CompanyDialogData,
    private dialogRef: MatDialogRef<CompanyPayCodesDialogComponent>,
    private payCodeService: PayCodeService
  ) {
  }

  async ngOnInit() {
    this.subHeader = `Pay Codes for ${this.data.companyName}`;
    this.isLoading = true;
    try {
      this.dataSource.data = await this.payCodeService.getPayCodesForCompany(this.data.companyId);
    } catch (err) {
      this.error = err.message;
    }
    this.isLoading = false;
  }

  close() {
    this.dialogRef.close();
  }

}

import { Component, OnInit } from '@angular/core';
import { FilterType, FilterService, CompanyService } from 'common-ui/services';
import { BehaviorSubject } from 'rxjs';
import { FilterItem } from 'common-ui/services/filter.service';

@Component({
  selector: 'lib-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.css']
})
export class CompanySelectorComponent implements OnInit {

  FilterType = FilterType;
  companies$ = new BehaviorSubject<FilterItem[]>([]);

  constructor(
    private filterService: FilterService,
    private companyService: CompanyService
  ) {
    this.filterService.companyFilterItems$.subscribe(this.companies$);
  }

  async ngOnInit(): Promise<void> {
    const masterAccountFilter = this.filterService.getFilter(FilterType.MASTER_ACCOUNT);
    const companiesForUser = await this.companyService.getCompaniesForMasterAccounts(masterAccountFilter);
    this.filterService.setCompanyFilterItems(companiesForUser.map(c => ({
      value: c._id,
      description: c.name
    })));
  }
}

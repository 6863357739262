import { Pipe, PipeTransform } from '@angular/core';
import { convertCamelToRegular } from './convert-camel-to-regular';

@Pipe({
  name: 'camelToRegular'
})
export class CamelToRegularPipe implements PipeTransform {
  transform(value: string): string {
    return convertCamelToRegular(value);
  }
}

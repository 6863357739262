import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { style, state, trigger, transition, animate } from '@angular/animations';

@Component({
  selector: 'lib-custom-snack-bar',
  templateUrl: './custom-snack-bar.component.html',
  styleUrls: ['./custom-snack-bar.component.css'],
  animations: [
    trigger('snackBarState', [
      state('void', style({
        transform: 'translateY(100%)'
      })),
      state('visible', style({
        transform: 'translateY(0)'
      })),
      transition('void => visible', animate('300ms ease-in')),
      transition('visible => void', animate('300ms ease-out'))
    ])
  ]
})
export class CustomSnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<CustomSnackBarComponent>
  ) {
  }
}

import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ExportFileDto, FinanceExportsOpenApiService } from 'common-ui/open-api';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { FileDownloadService } from 'common-ui/services/file-download.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-finance-exports-dialog',
  templateUrl: './finance-exports-dialog.component.html',
  styleUrls: ['./finance-exports-dialog.component.css']
})
export class FinanceExportsDialogComponent implements OnInit {

  dataSource = new MatTableDataSource<ExportFileDto>();
  displayedColumns = [
    'select',
    'fileName',
    'exportedDate',
    'exportedTime'
  ];
  isLoading = true;
  selection = new SelectionModel<ExportFileDto>(true, []);

  constructor(
    private ref: MatDialogRef<FinanceExportsDialogComponent>,
    private financeExportsService: FinanceExportsOpenApiService,
    private fileDownloadService: FileDownloadService
  ) {
  }

  async ngOnInit() {
    this.dataSource.data = await firstValueFrom(this.financeExportsService.listFinanceExportFiles());
    this.isLoading = false;
  }

  close() {
    this.ref.close();
  }

  async downloadFile() {
    const selectedFiles = this.selection.selected;
    for (const selectedFile of selectedFiles) {
      await this.fileDownloadService.downloadTextFile(this.financeExportsService.downloadFinanceExportFile({
        fileName: selectedFile.fileName
      }, 'response'));
    }
  }
}

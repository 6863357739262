import { Component, OnInit, OnDestroy } from '@angular/core';
import { ImpersonationService } from 'common-ui/services/impersonation.service';
import { UserEmailAndId } from 'common-ui/open-api';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'common-ui/services/user.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-impersonate-user',
  templateUrl: './impersonate-user.component.html',
  styleUrls: ['./impersonate-user.component.scss']
})
export class ImpersonateUserComponent implements OnInit, OnDestroy {

  filteredEmails: UserEmailAndId[] = [];
  form: FormGroup;
  loading = true;
  ngUnsubscribe = new Subject<void>()

  constructor(
    private impersonationService: ImpersonationService,
    private userService: UserService,
    private router: Router,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      email: ['', [Validators.required] ]
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  async ngOnInit(): Promise<void> {
    this.filteredEmails = await this.userService.searchEmails('');
    this.impersonationService.userToImpersonate = undefined;
    this.form.controls['email'].valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async filter => {
      this.filteredEmails = await this.userService.searchEmails(filter);
    });

    this.loading = false;
  }

  clearUser() {
    this.form.controls['email'].setValue(undefined);
  }

  displayUser(inputValue: UserEmailAndId) {
    if (!inputValue) {
      return '';
    } else {
      return inputValue.email;
    }
  }

  async selectUserAndGo() {
    if (this.form.value) {
      this.impersonationService.userToImpersonate = this.form.value.email.userId;
      this.userService.clearCurrentUser();
    }
    await this.router.navigateByUrl('');
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { CsvFileChooserService } from '../csv-services/csv-file-chooser.service';

@Component({
  selector: 'lib-csv-file-chooser',
  templateUrl: './csv-file-chooser.component.html',
  styleUrls: ['./csv-file-chooser.component.css']
})
export class CsvFileChooserComponent implements AfterViewInit {

  @Input() truncateChars = 100;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() fileSelected = new EventEmitter<void>();
  @Output() fileToLarge = new EventEmitter<void>();

  constructor(private fileService: CsvFileChooserService) {
  }

  get selectedFile() {
    return this.fileService.currentFile;
  }

  ngAfterViewInit(): void {
    this.fileInput.nativeElement.onchange = async () => {
      const files = this.fileInput.nativeElement.files;
      if (files.length > 0) {
        const file = this.fileInput.nativeElement.files[0];
        if (file.size > 50 * 1024 * 1024) {
          this.fileToLarge.emit();
        } else {
          await this.loadFile(file);
          this.fileSelected.emit();
        }
      }
    };
  }

  onClick() {
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  async loadFile(file: File): Promise<string> {
    return new Promise<string>(resolve => {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const content = fileReader.result.toString();
        this.fileService.setCurrentFile(file.name, fileReader.result.toString());
        resolve(content);
      };
      fileReader.readAsText(file);
    });
  }

}

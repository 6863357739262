<mat-form-field
  class="form-field"
  *ngIf="!isLoading">
  <mat-label>Company</mat-label>
  <mat-autocomplete
    #autocomplete="matAutocomplete"
    (optionSelected)="selectCompany($event)"
    [displayWith]="displayWith">
    <mat-option
      *ngFor="let company of this.filteredCompanies"
      [value]="company">
      {{ company.name }}
    </mat-option>
  </mat-autocomplete>

  <input
    type="text"
    placeholder="Company Name"
    matInput
    [formControl]="companyControl"
    [matAutocomplete]="autocomplete">

  <button mat-button
          *ngIf="companyControl.value"
          matSuffix mat-icon-button
          aria-label="Clear"
          (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MasterAccountDto, IntegrationReportDto, JobStatus } from 'common-ui/open-api';
import { IntegrationService } from 'common-ui/services/integration.service';
import { formatRelative } from 'date-fns';
import { hyphenatedToRegular } from 'common-ui/pipes/hyphenated-to-regular';

@Component({
  selector: 'lib-integration-run-dialog',
  templateUrl: './integration-run-dialog.component.html',
  styleUrls: ['./integration-run-dialog.component.css']
})
export class IntegrationRunDialogComponent implements OnInit {

  latestImport: IntegrationReportDto;
  isWorking = true;
  errorMessage: string;
  header = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public masterAccount: MasterAccountDto,
    public dialogRef: MatDialogRef<IntegrationRunDialogComponent>,
    private integrationService: IntegrationService
  ) {
    this.header = 'Run Integration';
  }

  async ngOnInit() {
    try {
      this.errorMessage = null;
      this.latestImport = await this.integrationService.getLatestImport(this.masterAccount._id);
    } catch {
      this.errorMessage = "Failed to load previous report"
    }
    this.isWorking = false;
  }

  get subHeader() {
    let subHeader: string;
    if (!this.latestImport) {
      subHeader = 'Import data from ';
      if (this.masterAccount.timesheetIntegration) {
        subHeader += hyphenatedToRegular(this.masterAccount.timesheetIntegration.apiSource);
      }
    } else {
      const startTime = new Date(this.latestImport.startTime);
      subHeader = `Last import was run ${formatRelative(startTime, new Date())}`;

      if ( this.latestImport.status === JobStatus.RUNNING ) {
        subHeader += `, and has not completed`;
      } else if ( this.latestImport.duration !== '-') {
        subHeader += `, and completed in ${this.latestImport.duration}.`;
      }
    }
    return subHeader;
  };

  public async runImport(): Promise<void> {
    this.isWorking = true;
    this.errorMessage = '';
    try {
      this.latestImport = await this.integrationService.runImport(this.masterAccount._id);
      this.isWorking = false;
    } catch (err) {
      this.latestImport = null;
      this.errorMessage = err.message;
      this.isWorking = false;
    }
  }

  get masterAccountHasNoIntegrations(): boolean {
    return !this.masterAccount.timesheetIntegration;
  }

  get isOAuth2ConnectionRequired(): boolean {
    return this.masterAccount.timesheetIntegration.isOAuth2ConnectionRequired;
  }
}

import { Component } from '@angular/core';
import { EmployeeUsageRating } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';
import { HyphenatedToRegularPipe } from 'common-ui/pipes/hyphenated-to-regular.pipe';

@Component({
  selector: 'lib-employee-usage-rating-selector',
  templateUrl: './employee-usage-rating-selector.component.html',
  styleUrls: ['./employee-usage-rating-selector.component.css']
})
export class EmployeeUsageRatingSelectorComponent {
  employeeUsageRatings = Object.values(EmployeeUsageRating).map(value => ({
    value: value.toString(),
    description: new HyphenatedToRegularPipe().transform(value.toString())
  }));
  FilterType = FilterType;
}

import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { EmployeeDto, EmployeeAuditLogDto, EmployeeStatusType, EmployeeAuditEvent } from 'common-ui/open-api';
import { EmployeeService } from 'common-ui/services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'lib-employee-vulnerability-dialog',
  templateUrl: './employee-vulnerability-dialog.component.html',
  styleUrls: ['./employee-vulnerability-dialog.component.css']
})
export class EmployeeVulnerabilityDialogComponent implements OnInit {

  errorMessage: string;
  isWorking = true;
  form: FormGroup;
  auditLog = new MatTableDataSource<EmployeeAuditLogDto>();
  displayedColumns: string[] = [
    'isVulnerable',
    'createdDate',
    'createdBy',
    'note'
  ];
  EmployeeAuditEvent = EmployeeAuditEvent;
  note = new FormControl('', {
    validators: [Validators.required, Validators.maxLength(100)]
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public employee: EmployeeDto,
    private dialogRef: MatDialogRef<EmployeeVulnerabilityDialogComponent>,
    private employeeService: EmployeeService
  ) {
    this.form = new FormGroup({
      note: this.note
    });
  }

  get subHeader() {
    return `Enter a new Vulnerability report for ${this.employeeName}.`;
  }

  close(confirmed: boolean) {
    this.dialogRef.close(confirmed);
  }

  get employeeName() {
    return this.employee.firstName + ' ' + this.employee.lastName;
  }

  async updateStatus(isVulnerable: boolean) {
    this.errorMessage = '';
    this.isWorking = true;
    try {
      await this.employeeService.updateStatus(this.employee._id, {
        note: this.form.value.note,
        type: EmployeeStatusType.VULNERABLE,
        value: isVulnerable
      });
      await this.ngOnInit();
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }

  async ngOnInit() {
    this.auditLog.data = await this.employeeService.getAuditLog(this.employee._id, EmployeeStatusType.VULNERABLE);
    this.isWorking = false;
  }

  getNoteErrorMessage() {
    if (this.note.hasError('required')) {
      return 'You must enter a note';
    }
    if (this.note.hasError('maxlength')) {
      return `Max length ${this.note.errors.maxlength.requiredLength}`;
    }
    return '';
  }
}

import { Injectable } from '@angular/core';
import {
  CsvImportTemplate,
  CsvDataType,
  CsvConfigDto,
  ImportResultDto,
  CsvTemplateType,
  CsvFieldMapping,
  ConfigType,
  CompanyDto,
  PayCodesOpenApiService
} from 'common-ui/open-api';
import { CsvConfig } from './csv-config';
import { ApiService } from 'common-ui/services/api.service';
import { getUniqueValues } from 'common-ui/util/get-unique-values';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CsvConfigService {

  targets = new Map<CsvTemplateType, CsvImportTemplate>();
  configs: CsvConfigDto[];

  constructor(
    private apiService: ApiService,
    private payCodeService: PayCodesOpenApiService
  ) {
  }

  getCsvConfig(
    csvConfigDto: CsvConfigDto,
    companyDtos: CompanyDto[]
  ) {
    const targetDefinition = this.targets.get(csvConfigDto.templateType);
    return new CsvConfig(csvConfigDto.name, targetDefinition,
      csvConfigDto.noHeaderRow, csvConfigDto.type, companyDtos, csvConfigDto.mappings);
  }

  async copyConfig(
    csvConfig: CsvConfig,
    configName: string,
    clearMappings: boolean
  ): Promise<string> {
    const targetDefinition = this.targets.get(csvConfig.templateType);
    const fieldMappings: CsvFieldMapping[] = csvConfig.mappings.map(m => {
      const fieldMapping: CsvFieldMapping = {
        targetFieldName: m.targetField.name,
        csvColumnName: clearMappings ? undefined : m.csvColumnName
      };
      return fieldMapping;
    });
    const newCsvConfig = new CsvConfig(configName, targetDefinition, csvConfig.noHeaderRow,
      ConfigType.USER, csvConfig.companyNameValidator.companyDtos, fieldMappings);
    const result = await this.apiService.post('/api/csv-config', newCsvConfig.toCreateDto());
    return result.id;
  }

  async updateConfig(configId: string, config: CsvConfig): Promise<void> {
    await this.apiService.patch(`/api/csv-config/${configId}`, config.toUpdateDto());
  }

  async deleteConfig(configId: string): Promise<void> {
    await this.apiService.delete(`/api/csv-config/${configId}`);
  }

  async runImport(
    masterAccountId: string,
    csvConfig: CsvConfig,
    companyId?: string
  ): Promise<ImportResultDto> {
    if (csvConfig.dataType === CsvDataType.PAY_CODE) {

      return await firstValueFrom(this.payCodeService.csvImport({
        masterAccountId,
        data: csvConfig.getOutput()
      }));

    } else {

      const results = await this.apiService.post(csvConfig.postUrl, {
        masterAccountId,
        importType: csvConfig.definition.employeeImportId,
        data: csvConfig.getOutput(companyId)
      });

      const hasClientErrorCount = csvConfig.csvInputFile.hasClientErrorRowCount;
      results.invalid += hasClientErrorCount;

      return results;
    }
  }

  async loadConfigForUser(): Promise<CsvConfigDto[]> {
    const templates: CsvImportTemplate[] = await this.apiService.get('/api/csv-config/templates');
    templates.forEach(template => {
      this.targets.set(template.templateType, template);
    });
    this.configs = await this.apiService.get('/api/csv-config');
    return this.configs;
  }

  get templates(): CsvImportTemplate[] {
    return Array.from(this.targets.values());
  }

  get templateTypes(): CsvTemplateType[] {
    return this.templates.map(t => t.templateType);
  }

  get dataTypes(): CsvDataType[] {
    return getUniqueValues('dataType', this.templates);
  }

  downloadExampleFile(csvConfig: CsvConfig) {
    const file = new Blob([csvConfig.getExample()]);
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = csvConfig.name + ' example.csv';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }


}

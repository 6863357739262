import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'common-ui/services/customer.service';
import { Router } from '@angular/router';
import { UserService } from 'common-ui/services/user.service';
import { ApiService } from 'common-ui/services';

@Component({
  selector: 'lib-employee-portal-accept-terms',
  templateUrl: './employee-portal-accept-terms.component.html',
  styleUrls: ['./employee-portal-accept-terms.component.css']
})
export class EmployeePortalAcceptTermsComponent implements OnInit {

  isWorking = false;
  termsOfService: string;
  privacyPolicy: string;

  constructor(
    private customerService: CustomerService,
    private userService: UserService,
    private router: Router,
    private apiService: ApiService
  ) {
  }

  async ngOnInit() {
    this.isWorking = true;
    const legalDocs = await this.apiService.get('/api/customer/legal-docs');

    this.termsOfService = legalDocs.termsOfService;
    this.privacyPolicy = legalDocs.privacyPolicy;
    this.isWorking = false;
  }

  async acceptTerms() {
    try {
      this.isWorking = true;
      const user = await this.userService.getCurrentUser();
      await this.customerService.acceptTos(user._id);
      await this.router.navigateByUrl('/employee-portal/withdraw');
    } catch (err) {
      console.error(err);
    }
    this.isWorking = false;
  }

}

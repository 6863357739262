import { Component, Inject } from '@angular/core';
import { Environment } from 'common-ui/models/environment.type';

@Component({
  selector: 'lib-referral-page',
  templateUrl: './referral-page.component.html',
  styleUrls: ['./referral-page.component.css']
})
export class ReferralPageComponent {
  assetsUrl: string;
  referralComplete = false;

  constructor(
    @Inject('env') environment: Environment
  ) {
    this.assetsUrl = environment.locations.images;
  }

  referralCompleted() {
    this.referralComplete = true;
  }
}

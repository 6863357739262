import { Component, Input } from '@angular/core';
import { TrafficLights } from 'common-ui/models/traffic-lights.model';

@Component({
  selector: 'lib-traffic-lights',
  templateUrl: './traffic-lights.component.html',
  styleUrls: ['./traffic-lights.component.css']
})
export class TrafficLightsComponent {
  @Input() value: TrafficLights;
  TrafficLights = TrafficLights;
}

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AdminUserRole = 'system admin' | 'full-access read-only' | 'company admin' | 'master account admin' | 'partner';

export const AdminUserRole = {
    SYSTEM_ADMIN: 'system admin' as AdminUserRole,
    FULL_ACCESS_READ_ONLY: 'full-access read-only' as AdminUserRole,
    COMPANY_ADMIN: 'company admin' as AdminUserRole,
    MASTER_ACCOUNT_ADMIN: 'master account admin' as AdminUserRole,
    PARTNER: 'partner' as AdminUserRole
};


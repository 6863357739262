import { Component, forwardRef, OnDestroy, Input, OnChanges } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormBuilder,
  ValidationErrors,
  ControlValueAccessor,
  Validators,
  FormArray,
  FormControl
} from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatLegacyTableDataSource } from '@angular/material/legacy-table';
import { parseDateYYYYMMDD, formatDateYYYYMMDD } from 'common-ui/util';
import { addDays } from 'date-fns';
import { CustomPeriod } from 'common-ui/open-api';

@Component({
  selector: 'lib-payroll-custom-schedule-input',
  templateUrl: './payroll-custom-schedule-input.component.html',
  styleUrls: ['./payroll-custom-schedule-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PayrollCustomScheduleInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PayrollCustomScheduleInputComponent),
      multi: true
    }
  ]
})
export class PayrollCustomScheduleInputComponent
  implements OnDestroy, ControlValueAccessor, OnChanges {

  @Input() isWorking = true;
  @Input() includePayDate: boolean;
  form: FormGroup;
  newPeriodForm: FormGroup;
  dataSource: MatLegacyTableDataSource<CustomPeriod>;
  newPeriodStart: string;

  private ngUnsubscribe$ = new Subject();

  columns: string[];

  constructor(
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      customSchedule: this.fb.array([])
    });

    this.dataSource = new MatLegacyTableDataSource();

    this.newPeriodForm = this.fb.group({
      periodStart: ['', Validators.required],
      periodEnd: ['', Validators.required]
    });
  }

  ngOnChanges() {
    if (this.includePayDate) {
      this.columns = ['periodStart', 'periodEnd', 'payDate', 'actions'];
      this.newPeriodForm.addControl('payDate', new FormControl('', Validators.required));
    } else {
      this.columns = ['periodStart', 'periodEnd', 'actions'];
      this.newPeriodForm.removeControl('payDate');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
      fn(this.form.value.customSchedule);
    });
  }

  registerOnTouched(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(customPeriods: CustomPeriod[]): void {
    if (customPeriods) {
      customPeriods.forEach(period => {
        const controls = {
          periodStart: [period.periodStart, Validators.required],
          periodEnd: [period.periodEnd, Validators.required]
        };
        if (this.includePayDate) {
          controls['payDate'] = [period.payDate, Validators.required];
        }
        const customPeriodFormGroup = this.fb.group(controls);
        this.customSchedule.push(customPeriodFormGroup);
        this.dataSource.data = this.customSchedule.value;
        this.newPeriodForm.controls.periodStart.disable();
        this.setNewPeriodStart(period.periodEnd);
      });
    }
  }

  validate(): ValidationErrors | null {
    const error: ValidationErrors = {};
    return this.form.valid ? null : error;
  }

  get customSchedule() {
    return this.form.controls['customSchedule'] as FormArray;
  }

  addCustomPeriod() {
    const periodStart = this.customSchedule.length > 0 ? this.newPeriodStart : this.newPeriodForm.value.periodStart;

    const controls = {
      periodStart: [periodStart, Validators.required],
      periodEnd: [this.newPeriodForm.value.periodEnd, Validators.required]
    };

    if (this.includePayDate) {
      controls['payDate'] = [this.newPeriodForm.value.payDate, Validators.required];
    }

    const customPeriod = this.fb.group(controls);

    this.customSchedule.push(customPeriod);
    this.dataSource.data = this.customSchedule.value;
    this.setNewPeriodStart(customPeriod.value.periodEnd);
  }

  private setNewPeriodStart(previousPeriodEnd: string) {
    const periodEnd = parseDateYYYYMMDD(previousPeriodEnd);
    this.newPeriodStart = formatDateYYYYMMDD(addDays(periodEnd, 1));

    this.newPeriodForm.reset({
      periodStart: this.newPeriodStart
    });
    this.newPeriodForm.updateValueAndValidity();

    this.newPeriodForm.controls.periodStart.disable();
  }

  deleteCustomPeriod(index: number) {
    this.customSchedule.removeAt(index);
    this.dataSource.data = this.customSchedule.value;

    if (this.customSchedule.length === 0) {
      this.newPeriodForm.controls.periodStart.enable();

      const resetValue = {
        periodStart: '',
        periodEnd: ''
      };

      if (this.includePayDate) {
        resetValue['payDate'] = '';
      }

      this.newPeriodForm.reset();

      this.newPeriodForm.updateValueAndValidity();
      this.newPeriodStart = null;

    } else {
      const lastPeriod = this.customSchedule.value[this.customSchedule.length - 1];
      this.setNewPeriodStart(lastPeriod.periodEnd);
    }
  }
}

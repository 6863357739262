<lib-page-container>

  <lib-filter-bar [textSearchType]="isConsole ? FilterType.COMPANY_SEARCH : undefined">
    <div control-options>

      <div *ngIf="isConsole">

        <button mat-button
                [matMenuTriggerFor]="instructionsMenu">
          GC Payments
          <mat-icon>expand_more</mat-icon>
        </button>

        <mat-menu #instructionsMenu="matMenu">
          <button (click)="generatePaymentInstructions()"
                  mat-menu-item>
            Export Pending Invoices
          </button>

          <button (click)="openImportGcPaymentsExportDialog()"
                  mat-menu-item>
            Import GC Payments...
          </button>

          <button mat-menu-item
                  (click)="openPaymentInstructionsHistoryDialog()">
            View History...
          </button>
        </mat-menu>

        <button mat-button
                [matMenuTriggerFor]="exportsMenu">
          Finance Exports
          <mat-icon>expand_more</mat-icon>
        </button>

        <mat-menu #exportsMenu="matMenu">
          <button mat-menu-item
                  (click)="openFinanceExportsDialog()">
            View History...
          </button>

          <button mat-menu-item
                  (click)="openRegenerateExportsDialog()">
            File Generator...
          </button>
        </mat-menu>
      </div>

    </div>
    <lib-payment-reference-selector *ngIf="isConsole">
    </lib-payment-reference-selector>
    <lib-invoice-status-selector>
    </lib-invoice-status-selector>
    <lib-company-selector>
    </lib-company-selector>
    <lib-master-account-selector *ngIf="isConsole">
    </lib-master-account-selector>
  </lib-filter-bar>

  <table [dataSource]="dataSource"
         class="invoice-table"
         matSort
         mat-table>

    <ng-container matColumnDef="masterAccountName">
      <th *matHeaderCellDef mat-header-cell>Master Account</th>
      <td *matCellDef="let element" mat-cell> {{ element.masterAccountName }}</td>
    </ng-container>

    <ng-container matColumnDef="legalEntityName">
      <th *matHeaderCellDef mat-header-cell>Legal Entity</th>
      <td *matCellDef="let element" mat-cell> {{ element.legalEntityName }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th *matHeaderCellDef mat-header-cell>Company</th>
      <td *matCellDef="let element" mat-cell> {{ element.companyName }}</td>
    </ng-container>

    <ng-container matColumnDef="frequency">
      <th *matHeaderCellDef mat-header-cell>Payroll</th>
      <td *matCellDef="let element" mat-cell>
        <span *ngIf="element.payType">{{ element.frequency | payFrequency }}
          /{{ element.payType | hyphenatedToRegular }}</span>
        <span *ngIf="!element.payType">{{ element.frequency | payFrequency }}</span>
      </td>

    </ng-container>

    <ng-container matColumnDef="invoiceNumber">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Invoice</th>
      <td *matCellDef="let element" mat-cell> {{ element.invoiceNumber ?? '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="periodStart">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Start Date</th>
      <td *matCellDef="let element" mat-cell> {{ element.periodStart | date: "dd/MM/yyyy" }}</td>
    </ng-container>

    <ng-container matColumnDef="periodEnd">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>End Date</th>
      <td *matCellDef="let element" mat-cell> {{ element.periodEnd | date: "dd/MM/yyyy" }}</td>
    </ng-container>

    <ng-container matColumnDef="ddChargeDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Charge Date</th>
      <td *matCellDef="let element" mat-cell>
        <div>
          <div>{{ element.ddChargeDate ?? element.ddTargetChargeDate | date: "dd/MM/yyyy" }}</div>
          <div *ngIf="element.ddCreateDeferred" class="sub-text">To be
            raised {{ element.ddTargetCreationDate | date: "dd/MM/yyyy" }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="ddPayoutDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Payout Date</th>
      <td *matCellDef="let element" mat-cell> {{ element.ddPayoutDate | date: "dd/MM/yyyy" }}</td>
    </ng-container>

    <ng-container matColumnDef="invoiceAmount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Invoice Amount</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.invoiceAmount | pennyToPound: element.currency:false }}
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentReference">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Payment Reference</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.paymentReference ?? '-' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.status | hyphenatedToRegular }}
      </td>
    </ng-container>

    <ng-container matColumnDef="menu" stickyEnd>
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" mat-cell>

        <div class="row-progress-spinner"
             *ngIf="getIsRowWorking(element._id); else showMenu">
          <mat-spinner diameter="30"></mat-spinner>
        </div>

        <ng-template #showMenu>
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">

            <button
              *ngIf="isConsole"
              (click)="openPeriodHistoryEditDialog(element)"
              mat-menu-item>
              <mat-icon>edit</mat-icon>
              <span>Edit Status</span>
            </button>

            <button
              [disabled]="element.status === InvoiceStatus.NO_WITHDRAWALS"
              (click)="downloadFile(element._id, PeriodExportType.CUSTOMER_INVOICE)"
              mat-menu-item>
              <mat-icon>download</mat-icon>
              <span>Download Invoice</span>
            </button>

            <button
              [disabled]="element.status === InvoiceStatus.NO_WITHDRAWALS"
              (click)="downloadFile(element._id, PeriodExportType.DEDUCTIONS_EXPORT)"
              mat-menu-item>
              <mat-icon>download</mat-icon>
              <span>Download Deductions</span>
            </button>

            <button
              [disabled]="element.status === InvoiceStatus.NO_WITHDRAWALS"
              (click)="downloadFile(element._id, PeriodExportType.ACCOUNTING_BILLS)"
              mat-menu-item
              *ngIf="isConsole && element.xeroBillsFileName">
              <mat-icon>download</mat-icon>
              <span>Download Xero Bills</span>
            </button>

            <button
              [disabled]="element.status === InvoiceStatus.NO_WITHDRAWALS"
              (click)="downloadFile(element._id, PeriodExportType.ACCOUNTING_INVOICE)"
              mat-menu-item
              *ngIf="isConsole && element.xeroInvoiceFileName">
              <mat-icon>download</mat-icon>
              <span>Download Xero Invoice</span>
            </button>

            <button
              [disabled]="element.status === InvoiceStatus.NO_WITHDRAWALS || !element.paymentInstructionsFile"
              (click)="downloadFile(element._id, PeriodExportType.PAYMENT_INSTRUCTIONS)"
              mat-menu-item
              *ngIf="isConsole">
              <mat-icon>download</mat-icon>
              <span>Download Payment Instructions</span>
            </button>

            <button
              [disabled]="element.status === InvoiceStatus.NO_WITHDRAWALS"
              (click)="regenerateFiles(element._id)"
              mat-menu-item
              *ngIf="isConsole">
              <mat-icon>build</mat-icon>
              <span>Regenerate Files</span>
            </button>

          </mat-menu>
        </ng-template>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="13">{{ isLoading ? "Loading..." : "No data" }}</td>
    </tr>

  </table>

  <div footer>
    <mat-paginator [length]="dataSource.total$ | async"
                   [pageSize]="pageSize"
                   [showFirstLastButtons]=true>
    </mat-paginator>
  </div>

</lib-page-container>

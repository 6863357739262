import { getCurrencySymbol } from './get-currency-symbol';
import { Currency } from 'common-ui/open-api';

export const formatCcy = (
  amount: number,
  currency: Currency
) => {
  const amountInPound = (amount / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return `${getCurrencySymbol(currency)}${amountInPound}`;
};

import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ImportResultDto } from 'common-ui/open-api';
import { CsvConfig, CsvConfigService } from '../csv-services';

export interface CsvRunImportDialogData {
  masterAccountId: string;
  companyId: string;
  csvConfig: CsvConfig;
}

@Component({
  selector: 'lib-run-csv-import-dialog',
  templateUrl: './csv-run-import-dialog.component.html',
  styleUrls: ['./csv-run-import-dialog.component.css']
})
export class CsvRunImportDialogComponent implements OnInit {

  @Output() closing = new EventEmitter<ImportResultDto>();
  errorMessage: string;
  isLoading = true;
  importResults: ImportResultDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CsvRunImportDialogData,
    private dialogRef: MatDialogRef<CsvRunImportDialogComponent>,
    private csvService: CsvConfigService
  ) {
  }

  get header() {
    if (this.isLoading) {
      return 'Running ' + this.data.csvConfig.dataType + ' Import';
    } else if (this.errorMessage) {
      return this.data.csvConfig.dataType + ' Import Failed';
    } else {
      return this.data.csvConfig.dataType + ' Import Result';
    }
  }

  get subHeading() {
    if (this.isLoading) {
      const ending = this.data.csvConfig.csvInputFileRows.length > 0 ? 's.' : '.';
      return `Loading ${this.data.csvConfig.csvInputFileRows.length} ${this.data.csvConfig.dataType}${ending}`;
    } else if (this.errorMessage) {
      return 'Something unexpected occurred. Check your files or call support';
    } else {
      if (this.importResults) {
        if (this.importResults.rejected > 0) {
          return 'Some of your records failed to import.';
        } else if ( this.importResults.warned > 0 ) {
          return 'Some of your records were imported with warnings.';
        } else {
          return 'All of your records were processed without errors.';
        }
      }
    }
  }

  async ngOnInit() {
    this.errorMessage = '';
    this.isLoading = true;
    try {
      const importResults = await this.csvService.runImport(
        this.data.masterAccountId,
        this.data.csvConfig,
        this.data.companyId,
      );
      this.importResults = {
        ...importResults,
        rejected: importResults.rejected + this.data.csvConfig.clientErrorCount
      }
      this.data.csvConfig.loadServerImportErrors(importResults.errors);
      this.isLoading = false;
    } catch (err) {
      this.errorMessage = err;
      this.isLoading = false;
    }
  }

  close() {
    this.dialogRef.close(this.importResults);
  }
}

import { Injectable } from '@angular/core';
import { SystemService } from 'common-ui/services/system.service';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';

@Injectable({
  providedIn: 'root'
})
export class DateRangeService {

  private dateRange: JustDateRange;

  constructor(private systemService: SystemService) {
    this.dateRange = this.systemService.getCurrentWeek();
  }

  getDateRange() {
    return this.dateRange;
  }

  setDateRange(dateRange: JustDateRange) {
    this.dateRange = dateRange;
  }
}

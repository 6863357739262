import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { formatDateYYYYMMDD } from 'common-ui/util';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { TimesheetService } from 'common-ui/services';
import { TimesheetType, TimesheetDto } from 'common-ui/open-api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-timesheet-dialog',
  templateUrl: './timesheet-dialog.component.html',
  styleUrls: ['./timesheet-dialog.component.css']
})
export class TimesheetDialogComponent implements OnInit, OnDestroy {

  isWorking = false;
  errorMessage: string;
  types = Object.values(TimesheetType);
  ngUnsubscribe$ = new Subject();

  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<TimesheetDialogComponent>,
    private fb: FormBuilder,
    private timesheetService: TimesheetService,
    @Inject(MAT_DIALOG_DATA) private timesheetDto: TimesheetDto
  ) {
    this.form = this.fb.group({
      hourlyRate: null as number,
      hoursWorked: null as number,
      startTime: ['00:00', Validators.required],
      endTime: ['23:59', Validators.required],
      date: ['', Validators.required],
      employeeId: ['', Validators.required],
      type: TimesheetType.STANDARD,
      wages: null as number
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  get header() {
    return this.timesheetDto ? 'Edit Timesheet' : 'Create Timesheet';
  }

  get isEdit() {
    return !!this.timesheetDto;
  }

  async ngOnInit() {
    if (this.timesheetDto) {
      this.form.controls.employeeId.disable();
      this.form.setValue({
        employeeId: this.timesheetDto.employeeId,
        startTime: this.timesheetDto.startTime,
        endTime: this.timesheetDto.endTime,
        date: this.timesheetDto.date,
        hourlyRate: this.timesheetDto.hourlyRate ?? null,
        hoursWorked: this.timesheetDto.hoursWorked ?? null,
        type: this.timesheetDto.type,
        wages: this.timesheetDto.wages
      });
    } else {
      this.form.patchValue({
        date: formatDateYYYYMMDD(new Date()),
        startTime: '09:00',
        endTime: '17:00',
        type: TimesheetType.STANDARD
      });
    }

    this.form.controls.hourlyRate.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(value => {
        this.form.patchValue({
          wages: Math.floor(value * this.form.controls.hoursWorked.value)
        });
      });

    this.form.controls.hoursWorked.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(value => {
        this.form.patchValue({
          wages: Math.floor(value * this.form.controls.hourlyRate.value)
        });
      });
  }

  async save() {
    try {
      if (this.isEdit) {
        await this.timesheetService.updateManualTimesheet(this.timesheetDto._id, {
          hoursWorked: this.form.value.hoursWorked,
          hourlyRate: this.form.value.hourlyRate,
          date: this.form.value.date,
          startTime: this.form.value.startTime ?? null,
          endTime: this.form.value.endTime ?? null,
          type: this.form.value.type,
          wages: this.form.value.wages
        });
      } else {
        await this.timesheetService.createManualTimesheet({
          hoursWorked: this.form.value.hoursWorked,
          hourlyRate: this.form.value.hourlyRate,
          employeeId: this.form.value.employeeId,
          date: this.form.value.date,
          startTime: this.form.value.startTime ?? null,
          endTime: this.form.value.endTime ?? null,
          type: this.form.value.type,
          wages: this.form.value.wages
        });
      }
      this.dialogRef.close(true);
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}

<lib-dialog-container
  header="Integration Report"
  (exit)="close()"
  [showCross]="true">

  <div class="results-container">
    <lib-integration-result
      [importResult]="data">
    </lib-integration-result>
  </div>

  <div footer>
    <lib-button-panel>
      <button
        mat-flat-button
        color="primary"
        (click)="dialogRef.close()">
        Close
      </button>
    </lib-button-panel>
  </div>
</lib-dialog-container>


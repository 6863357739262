import { Component, Input } from '@angular/core';
import { ImportResultDto } from 'common-ui/open-api';

@Component({
  selector: 'lib-csv-import-results',
  templateUrl: './csv-import-results.component.html',
  styleUrls: ['./csv-import-results.component.css']
})
export class CsvImportResultsComponent {
  @Input() importResult: ImportResultDto;
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-timesheet-page-router',
  templateUrl: './timesheet-page-router.component.html',
  styleUrls: ['./timesheet-page-router.component.css']
})
export class TimesheetPageRouterComponent implements OnInit {

  showListView: boolean;

  constructor() {
  }

  ngOnInit(): void {
    let view = localStorage.getItem('timesheet-view');
    if (!view) {
      view = 'list';
    }
    this.switchView(view);
  }

  switchView(view: string) {
    localStorage.setItem('timesheet-view', view);
    this.showListView = view === 'list';
  }

}

import { Injectable } from '@angular/core';
import { ProfileDto, UpdateBankDetailsDto, PaymentStatusEnum, CustomerOpenApiService } from 'common-ui/open-api';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { UserService } from 'common-ui/services/user.service';
import * as Sentry from '@sentry/angular';

export interface WithdrawalStatus {
  status?: PaymentStatusEnum;
  withdrawalClientId?: string | null;
  requireAck: boolean;
  errorMessage?: string;
  updatedProfile?: ProfileDto;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  withdrawalInProgress$ = new BehaviorSubject<WithdrawalStatus>({
    status: PaymentStatusEnum.PENDING,
    requireAck: false
  });
  isMultiCompany: boolean;
  isPolling = false;

  constructor(
    private customerOpenApiService: CustomerOpenApiService,
    private userService: UserService
  ) {
  }

  async acknowledgeWithdrawal() {
    try {
      await firstValueFrom(this.customerOpenApiService.userAcknowledgesWithdrawal({
        id: this.withdrawalInProgress$.value.withdrawalClientId
      }));

      this.withdrawalInProgress$.next({
        withdrawalClientId: null,
        status: null,
        requireAck: false
      });
    } catch (err) {

      Sentry.captureException(err);
      this.withdrawalInProgress$.next({
        withdrawalClientId: null,
        status: null,
        requireAck: false
      });
    }
  }

  get withdrawalStatus() {
    return this.withdrawalInProgress$.value.status;
  }

  async getProfiles(publishStatus = true): Promise<ProfileDto[]> {
    const profiles: ProfileDto[] = await firstValueFrom(this.customerOpenApiService.getProfiles());
    this.isMultiCompany = profiles.length > 0;

    if (publishStatus) {
      const pendingProfile = profiles.find(p => !!p.pendingWithdrawalClientId || !!p.unacknowledgedWithdrawalClientId);
      if (pendingProfile) {
        const withdrawalClientId = pendingProfile.pendingWithdrawalClientId ?? pendingProfile.unacknowledgedWithdrawalClientId;
        this.withdrawalInProgress$.next({
          status: PaymentStatusEnum.PENDING,
          withdrawalClientId: withdrawalClientId,
          requireAck: true
        });
        if (!this.isPolling) {
          this.pollWithdrawalStatus(pendingProfile.employeeId, withdrawalClientId);
        }
      } else {
        this.withdrawalInProgress$.next({
          status: null,
          withdrawalClientId: null,
          requireAck: false
        });
      }
    }

    return profiles;
  }

  public pollWithdrawalStatus(
    employeeId: string,
    withdrawalClientId: string
  ) {
    this.isPolling = true;

    this.customerOpenApiService.getWithdrawalStatusByClientId(withdrawalClientId)
      .subscribe({
        next: async pollData => {
          if (pollData.status === PaymentStatusEnum.SUCCESS) {
            this.isPolling = false;
            const profiles = await this.getProfiles(false);
            const selectedProfile = profiles.find(p => p.employeeId === employeeId);
            this.withdrawalInProgress$.next({
              status: PaymentStatusEnum.SUCCESS,
              updatedProfile: selectedProfile,
              withdrawalClientId: withdrawalClientId,
              requireAck: true
            });

          } else if (pollData.status === PaymentStatusEnum.PENDING) {
            setTimeout(() => {
              this.pollWithdrawalStatus(employeeId, withdrawalClientId);
            }, 5000);

          } else {
            // Note that failed payments causes an exception, so this condition should never happen.
            this.isPolling = false;
            this.withdrawalInProgress$.next({
              withdrawalClientId: withdrawalClientId,
              status: PaymentStatusEnum.FAILED,
              errorMessage: pollData.failureReason,
              requireAck: true
            });

          }
        }, error: err => {
          // todo - consider if we should continue polling.
          if (err.error.status === 400) {
            this.isPolling = false;
            this.withdrawalInProgress$.next({
              withdrawalClientId: withdrawalClientId,
              status: PaymentStatusEnum.FAILED,
              errorMessage: err.message,
              requireAck: true
            });
          } else {
            setTimeout(() => {
              this.pollWithdrawalStatus(employeeId, withdrawalClientId);
            }, 5000);
          }
        }
      });
  }

  async initiateWithdrawal(
    employeeId: string,
    amountInMinor: number
  ): Promise<void> {
    const clientId = crypto.randomUUID();
    try {

      this.withdrawalInProgress$.next({
        withdrawalClientId: clientId,
        status: PaymentStatusEnum.PENDING,
        requireAck: false
      });

      const withdrawalResult = await firstValueFrom(this.customerOpenApiService.initiateWithdrawal({
        employeeId, amount: amountInMinor, clientId
      }));

      if (withdrawalResult.status === PaymentStatusEnum.FAILED) {
        // These are validation failures, so we don't need to poll, and no ack is required.
        // If we do poll, the clientId will not exist.
        this.withdrawalInProgress$.next({
          withdrawalClientId: clientId,
          status: PaymentStatusEnum.FAILED,
          errorMessage: withdrawalResult.failureReason,
          requireAck: false
        });

        return;

      } else {

        setTimeout(async () => {
          this.pollWithdrawalStatus(employeeId, clientId);
        }, 2000);

      }

    } catch (err ) {

      // todo - migrate to returning validation errors
      if (err.error && err.error.status === 400) {
        this.withdrawalInProgress$.next({
          withdrawalClientId: clientId,
          status: PaymentStatusEnum.FAILED,
          errorMessage: err.message,
          requireAck: true
        });

      } else {
        setTimeout(async () => {
          this.pollWithdrawalStatus(employeeId, clientId);
        }, 2000);
      }
    }
  }

  async acceptTos(userId: string): Promise<void> {
    await firstValueFrom(this.customerOpenApiService.acceptTos({userId}));
    this.userService.clearCurrentUser();
  }

  updateBankDetails(body: UpdateBankDetailsDto): Promise<void> {
    return firstValueFrom(this.customerOpenApiService.updateBankDetails(body));
  }
}

<div class="mat-typography">

  <div class="mat-title">Bank Details</div>
  <div>Enter your new bank details.</div>

  <form
    (ngSubmit)="save()"
    [formGroup]="form"
    class="form-area">

    <div class="sub-form">

      <lib-account-number-input
        *ngIf="currency=== Currency.GBP"
        formControlName="accountNumber"
        class="input-field"
        appFirstFocus
        [placeholder]="data.accountNumber"
        [clearable]="false"
        [required]="true">
      </lib-account-number-input>

      <lib-sort-code-input
        *ngIf="currency=== Currency.GBP"
        formControlName="accountSortCode"
        class="input-field"
        [placeholder]="data.sortCode"
        [clearable]="false"
        [required]="true">
      </lib-sort-code-input>

      <mat-form-field
        class="iban"
        *ngIf="currency=== Currency.EUR">
        <input
          class="iban"
          matInput
          formControlName="iban"
          placeholder="IBAN" />
      </mat-form-field>

      <p data-e2e="otp-message"
         *ngIf="isOtpEmailSent">
        We have sent you an email containing a One-Time-Passcode. Enter the passcode below to complete this operation.
      </p>

      <div class="otp-input"
           data-e2e="otp-input"
           *ngIf="isOtpEmailSent">
        <mat-form-field appearance="standard">
          <mat-label>One Time Passcode</mat-label>
          <input
            matInput
            formControlName="otp"
            placeholder="otp"
            type="text">
          <mat-error *ngIf="form.get('otp').hasError('required') && form.get('otp').touched">
            You must enter the OPT to update bank details
          </mat-error>
        </mat-form-field>
        <lib-error-message [message]="errorMessage"></lib-error-message>
      </div>
    </div>

    <div class="button-panel">

      <button
        type="button"
        (click)="close()"
        class="employee-portal-button"
        mat-flat-button>
        Cancel
      </button>

      <button
        [disabled]="form.invalid"
        color="primary"
        type="submit"
        class="employee-portal-button"
        mat-flat-button>
        Save
      </button>

    </div>

  </form>
</div>

import { TimesheetDto, TimesheetQuery, TimesheetOpenApiService } from 'common-ui/open-api';
import { Observable } from 'rxjs';
import { AbstractQueryDataSource } from 'common-ui/abstract-query-data-source/abstract-query-data-source';

export class TimesheetListDataSource extends AbstractQueryDataSource<TimesheetDto, TimesheetQuery> {

  constructor(
    private timesheetService: TimesheetOpenApiService
  ) {
    super();
  }

  executeQuery(query: TimesheetQuery): Observable<{
    data: TimesheetDto[];
    total: number;
    tooManyRecords: boolean
  }> {
    return this.timesheetService.queryTimesheets(query);
  }
}

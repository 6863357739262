import { Injectable } from '@angular/core';
import { UserService } from 'common-ui/services/user.service';
import { FileDownloadService } from 'common-ui/services/file-download.service';
import { firstValueFrom } from 'rxjs';
import {
  ActivityReportDto,
  DateGroupByType,
  ReportingOpenApiService,
  ReportOptionsDto, AssetReport
} from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private userService: UserService,
    private fileDownloadService: FileDownloadService,
    private api: ReportingOpenApiService
  ) {
  }

  async getMasterAccountActivityReportData(
    dateGroupByType: DateGroupByType
  ): Promise<ActivityReportDto> {
    const currentUser = await this.userService.getCurrentUser();
    return await firstValueFrom(this.api.getMasterAccountActivityReportData(currentUser.masterAccountId, {
      dateGroupByType
    }));
  }

  async downloadActivityReportXls(
    dateGroupByType: DateGroupByType,
    masterAccountId?: string
  ) {
    let id = masterAccountId;
    if (!id) {
      const currentUser = await this.userService.getCurrentUser();
      id = currentUser.masterAccountId;
    }
    return await this.fileDownloadService.downloadFile(this.api.getMasterAccountActivityReport(id, {
      dateGroupByType
    }, 'response'));
  }

  async downloadConsolidatedMisReport(numberOfMonths: number) {
    await this.fileDownloadService.downloadFile(this.api.getConsolidatedMisReport({value: numberOfMonths}, 'response'));
  }

  async downloadConsoleEmployeeReport() {
    await this.fileDownloadService.downloadFile(this.api.getConsoleEmployeeReport('response'));
  }

  async getAssetReport(): Promise<AssetReport> {
    return await firstValueFrom(this.api.getAssetReport());
  }

  async downloadBalancePredictionsReport() {
    await this.fileDownloadService.downloadFile(this.api.getBalancePredictionReport('response'));
  }

  async downloadInBoundCashFlowsReport() {
    await this.fileDownloadService.downloadFile(this.api.downloadInBoundCashFlowsReport('response'));
  }

  async downloadEmailIssuesReport() {
    await this.fileDownloadService.downloadFile(this.api.getEmailIssuesReport('response'));
  }

  async downloadWithdrawalsExport(options: ReportOptionsDto) {
    await this.fileDownloadService.downloadFile(this.api.getWithdrawalsExport(options, 'response'));
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { EmployeeDto, EmployeeAuditLogDto, EmployeeStatusType } from 'common-ui/open-api';
import { EmployeeService } from 'common-ui/services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

export interface EmployeeStatusData {
  employee: EmployeeDto;
  block: boolean;
}

@Component({
  selector: 'lib-employee-status-dialog',
  templateUrl: './employee-status-dialog.component.html',
  styleUrls: ['./employee-status-dialog.component.css']
})
export class EmployeeStatusDialogComponent implements OnInit {

  errorMessage: string;
  isWorking = true;
  form: FormGroup;
  auditLog = new MatTableDataSource<EmployeeAuditLogDto>();
  displayedColumns: string[] = [
    'event',
    'createdDate',
    'createdBy',
    'note'
  ];
  note = new FormControl('', {
    validators: [Validators.required, Validators.maxLength(100)
]})

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmployeeStatusData,
    private dialogRef: MatDialogRef<EmployeeStatusDialogComponent>,
    private employeeService: EmployeeService
  ) {
    this.form = new FormGroup({
      note: this.note
    });
  }

  get header()  {
    return `${ this.data.block ? 'Block' : 'Unblock' } Employee`;
  }

  get subHeader() {
    return `Are you sure you want to ${ this.data.block ? 'block' : 'un-block'} ${this.employeeName}?`
  }

  close(confirmed: boolean) {
    this.dialogRef.close(confirmed);
  }

  get employeeName() {
    return this.data.employee.firstName + ' ' + this.data.employee.lastName;
  }

  get block() {
    return this.data.block;
  }

  async updateStatus() {
    this.errorMessage = '';
    this.isWorking = true;
    try {
      await this.employeeService.updateStatus(this.data.employee._id, {
        note: this.form.value.note,
        type: EmployeeStatusType.BLOCKED,
        value: !this.data.employee.isBlocked
      });
      this.close(true);
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = true;
  }

  async ngOnInit() {
    this.auditLog.data = await this.employeeService.getAuditLog(this.data.employee._id, EmployeeStatusType.BLOCKED);
    this.isWorking = false;
  }

  getNoteErrorMessage() {
    if (this.note.hasError('required')) {
      return 'You must enter a note';
    }
    if (this.note.hasError('maxlength')) {
      return `Max length ${this.note.errors.maxlength.requiredLength}`
    }
    return ''
  }
}

<lib-dialog-container [showCross]=true
                      (exit)="close()"
                      [header]="data.create ? 'Create User' : 'Edit User'">
  <lib-spinner-container [isWorking]="isWorking">

    <div class="container mat-typography" *ngIf="companies">

      <form [formGroup]="form">

        <mat-form-field
          appearance="standard">
          <mat-label>Email</mat-label>
          <input
            appFirstFocus
            matInput
            formControlName="email"
            placeholder="Email">
          <mat-error *ngIf="form.get('email').hasError('required') && form.get('email').touched">
            You must enter your email address
          </mat-error>
          <mat-error *ngIf="form.get('email').hasError('invalidEmail') && form.get('email').touched">
            Please enter a valid email address
          </mat-error>
        </mat-form-field>

        <mat-checkbox formControlName="isMasterAccountAdmin">
          Account Owner (has all permissions)
        </mat-checkbox>

        <mat-checkbox
          *ngIf="data.create"
          formControlName="sendInvite">
          Send an invitation email
        </mat-checkbox>

        <div *ngIf="!form.controls.isMasterAccountAdmin.value && companies.length > 0" class="message-body">
          Select the companies this user can access (at least one)
        </div>

        <div *ngIf="form.controls.isMasterAccountAdmin.value" class="message-body">
          Account Owner has access to all companies.
        </div>

        <div class="list">
          <mat-checkbox [formControlName]="company._id"
                        *ngFor="let company of companies">
            {{ company.name }}
          </mat-checkbox>
        </div>

      </form>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <lib-button-panel>
        <button
          [disabled]="form.invalid"
          data-e2e="create-admin-user-button"
          color="primary"
          (click)="createOrUpdateUser()"
          mat-flat-button>
          {{ data.create ? 'Create' : 'Update' }}
        </button>
      </lib-button-panel>
    </div>

  </lib-spinner-container>
</lib-dialog-container>

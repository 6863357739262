<div class="table-container mat-typography">

  <table class="results-table">
    <tr>
      <td>Created:</td>
      <td>{{ importResult.created}}</td>
    </tr>
    <tr>
      <td>Updated:</td>
      <td>{{ importResult.updated}}</td>
    </tr>
    <tr>
      <td>Rejected:</td>
      <td>{{ importResult.rejected   }}</td>
    </tr>
    <tr>
      <td>Warnings:</td>
      <td>{{ importResult.warned  }}</td>
    </tr>
  </table>
</div>


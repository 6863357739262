import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor() {
  }

  get dataLayer(): any {
    const w: any = window;
    return w.dataLayer;
  }

  public accountRequestSuccessEvent() {
    this.publishEvent('account-request-success');
  }

  private publishEvent(name: string) {
    if (this.dataLayer) {
      const event = {event: name};
      this.dataLayer.push(event);
    }
  }

  private publishEventWithData(name: string, data: any = null) {
    if (this.dataLayer) {
      const event = {event: name};

      if (data) {
        Object.assign(event, data);
      }

      this.dataLayer.push(event);
    }
  }
}

import { Component, Input } from '@angular/core';
import { IntegrationReportDto } from 'common-ui/open-api';

@Component({
  selector: 'lib-integration-result',
  templateUrl: './integration-result.component.html',
  styleUrls: ['./integration-result.component.scss']
})
export class IntegrationResultComponent {

  @Input() importResult: IntegrationReportDto;
  searchText: string;

  constructor() {
  }

  reportHasErrors() {
    return this.importResult.counts.reduce((acc, cur) => acc + cur.errors + cur.warnings, 0) > 0;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { CsvConfig, CsvConfigService, CsvInputFile } from '../csv-services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CompanyDto, ConfigType, CsvConfigDto, CsvDataType, PayCodesOpenApiService } from 'common-ui/open-api';
import { CompanyService } from 'common-ui/services/company.service';
import { CsvRunImportDialogComponent } from '../csv-run-import-dialog/csv-run-import-dialog.component';
import { TrafficLights } from 'common-ui/models/traffic-lights.model';
import { CsvFileChooserService } from '../csv-services/csv-file-chooser.service';
import { WarningDialogComponent } from 'common-ui/warning-dialog/warning-dialog.component';
import {
  CompanyEditDialogComponent,
  CompanyEditData
} from 'common-ui/company-edit-dialog/company-edit-dialog.component';
import { FileDownloadService } from 'common-ui/services/file-download.service';

@Component({
  selector: 'lib-csv-import',
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.css']
})
export class CsvImportComponent implements OnInit {

  @Input() masterAccountId: string;
  csvConfig: CsvConfig;
  currentConfig: CsvConfigDto;
  output: any;
  companies: CompanyDto[];
  configs: CsvConfigDto[];
  filteredConfigs: CsvConfigDto[];
  errorMessage: string;
  ConfigType = ConfigType;
  TrafficLights = TrafficLights;
  filterErrors = false;
  CsvDataType = CsvDataType;

  get csvDataTypes() {
    return this.csvService.dataTypes;
  }

  constructor(private csvService: CsvConfigService,
              private companyService: CompanyService,
              private dialog: MatDialog,
              private fileChooserService: CsvFileChooserService,
              private payCodesService: PayCodesOpenApiService,
              private fileDownloadService: FileDownloadService
  ) {
  }

  get currentCompanyId() {
    return localStorage.getItem('csv-company-id');
  }

  set currentCompanyId(id: string) {
    if (id) {
      localStorage.setItem('csv-company-id', id);
    } else {
      localStorage.removeItem('csv-company-id');
    }
  }

  get currentDataType(): CsvDataType {
    return localStorage.getItem('csv-current-data-type') as CsvDataType;
  }

  set currentDataType(name: CsvDataType) {
    if (name) {
      localStorage.setItem('csv-current-data-type', name);
    } else {
      localStorage.removeItem('csv-current-data-type');
    }
  }

  get currentConfigId() {
    return localStorage.getItem('csv-config-id');
  }

  set currentConfigId(name: string) {
    if (name) {
      localStorage.setItem('csv-config-id', name);
    } else {
      localStorage.removeItem('csv-config-id');
    }
  }

  async ngOnInit() {
    this.companies = await this.companyService.getCompaniesForMasterAccount(this.masterAccountId);
    this.configs = await this.csvService.loadConfigForUser();

    if (history.state?.data) {
      this.currentDataType = history.state.data.dataType; // todo
      this.currentCompanyId = history.state.data.companyId;
    } else {
      if (!this.currentDataType) {
        this.currentDataType = CsvDataType.EMPLOYEE;
      }
    }

    if (!this.currentCompanyId || !this.companies.find(c => c._id === this.currentCompanyId)) {
      if (this.companies.length > 0) {
        this.currentCompanyId = this.companies[0]._id;
      } else {
        this.currentCompanyId = undefined;
      }
    }

    this.configSelected(this.currentConfigId);
  }

  openCompanyEditDialog() {
    const data: CompanyEditData = {
      create: true,
      masterAccountId: this.masterAccountId,
      subHeader: 'Please create a company to get started.'
    };
    const dialogRef = this.dialog.open(CompanyEditDialogComponent, {data});
    dialogRef.afterClosed().subscribe(async (success) => {
      if (success) {
        await this.ngOnInit();
      }
    });
  }

  toggleErrorFilter() {
    this.filterErrors = !this.filterErrors;
  }

  async mappingUpdated() {
    await this.saveConfiguration();
  }

  openWarningDialog() {
    this.dialog.open(WarningDialogComponent, {
      data: {
        warning: 'Sorry, this file is too large.  Our file size limit is 50Mb'
      }
    });
  }

  loadCsvFile() {
    this.filterErrors = false;
    this.errorMessage = '';
    const csv = this.fileChooserService.currentContent;
    if (csv) {
      const csvFile = CsvInputFile.fromCsv(csv, this.csvConfig.noHeaderRow);
      this.csvConfig.loadFile(csvFile);
    }
  }

  clearChosenFile() {
    this.filterErrors = false;
    this.fileChooserService.clearCurrent();
    this.csvConfig.clearFile();
  }

  async runImport() {
    this.dialog.open(CsvRunImportDialogComponent, {
      autoFocus: false,
      disableClose: true,
      data: {
        masterAccountId: this.masterAccountId,
        csvConfig: this.csvConfig,
        companyId: this.currentCompanyId
      }
    });
  }

  async saveConfiguration() {
    if (this.currentConfig.type === ConfigType.STANDARD) {
      this.errorMessage = 'You cannot modify a Standard Mapping';
      return;
    }

    try {
      this.errorMessage = '';
      await this.csvService.updateConfig(this.currentConfigId, this.csvConfig);
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.loadCsvFile();
  }

  get filteredStandardConfig() {
    return this.filteredConfigs.filter(config => config.type === ConfigType.STANDARD);
  }

  async deleteConfiguration() {
    try {
      this.errorMessage = '';
      await this.csvService.deleteConfig(this.currentConfigId);
      this.configs = this.configs.filter(c => c._id !== this.currentConfigId);
      this.configSelected(undefined);
      this.configSelected('');
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  downloadExampleFile() {
    this.csvService.downloadExampleFile(this.csvConfig);
  }

  configSelected(configId: string) {
    this.errorMessage = '';
    this.filteredConfigs = this.configs.filter(config =>
      config.dataType === this.currentDataType
    );
    if (this.filteredConfigs.length === 0) {
      this.currentDataType = CsvDataType.EMPLOYEE;
      this.filteredConfigs = this.configs.filter(config =>
        config.dataType === this.currentDataType
      );
    }
    let dto = this.filteredConfigs.find(config => config._id === configId);
    if (!dto && this.filteredConfigs.length > 0) {
      dto = this.filteredConfigs[0];
    }
    if (dto) {
      this.csvConfig = this.csvService.getCsvConfig(dto, this.companies);
      this.currentConfigId = dto._id;
      this.currentConfig = dto;
      this.loadCsvFile();
    } else {
      this.csvConfig = null;
      this.currentConfigId = '';
      this.currentConfig = null;
    }
  }

  async dataTypeSelected(dataType: CsvDataType) {
    this.fileChooserService.clearCurrent();
    this.currentDataType = dataType;
    this.configSelected('');
  }

  async companySelected(companyId: string) {
    this.currentCompanyId = companyId;
    this.configSelected('');
  }

  get showCompanySelector() {
    return this.csvConfig && !this.csvConfig.isMultiCompanyImport;
  }

  async downloadPayCodesFile() {
    try {
      const downloadObservable = this.payCodesService.downloadPayCodesFile(this.masterAccountId, 'response');
      await this.fileDownloadService.downloadFile(downloadObservable);
    } catch (err) {
      this.errorMessage = err.message;
    }

  }
}

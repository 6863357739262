<div class="table-container">
  <table [dataSource]="dataSource" class="withdrawals-table" mat-table>

    <ng-container matColumnDef="withdrawalDate">
      <th *matHeaderCellDef mat-header-cell>Date</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.timestamp | date: "dd MMM yyyy" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="withdrawalTime">
      <th *matHeaderCellDef mat-header-cell>Time</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.timestamp | date: "HH:mm" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th *matHeaderCellDef mat-header-cell>Amount</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.amount  | pennyToPound: element.currency }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fee">
      <th *matHeaderCellDef mat-header-cell>Fee</th>
      <td *matCellDef="let element" mat-cell>
        <div>
          {{ element.fee | pennyToPound: element.currency }}
        </div>
        <div>
          <span class="subtext">{{ element.employerPaysFee ? 'Employer Pays' : ''}}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell>Status</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.paymentStatus | titlecase }}
        <div class="subtext" *ngIf="element.failureReason">{{ element.failureReason | truncate: 50 }}</div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell no-withdrawals-found" colspan="5">
        {{ isLoading ? 'Loading...' : 'No withdrawals in this period' }}
      </td>
    </tr>

  </table>
</div>

import { Component } from '@angular/core';
import { AuthService } from 'common-ui/services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { emailValidator } from 'common-ui/util';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AreYouSureDialogComponent } from 'common-ui/are-you-sure-dialog/are-you-sure-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-account-with-otp',
  templateUrl: './delete-account-with-otp.component.html',
  styleUrls: ['./delete-account-with-otp.component.css']
})
export class DeleteAccountWithOtpComponent {
  isWorking: boolean;
  form: FormGroup;
  errorMessage: string;
  isDeleted = false;

  constructor(
    private authService: AuthService,
    formBuilder: FormBuilder,
    private matDialog: MatDialog,
    private router: Router
  ) {
    this.form = formBuilder.group({
      email: ['', [emailValidator, Validators.required]]
    });
  }

  requestOtp() {
    console.log(this.form.value);
    const ref = this.matDialog.open(AreYouSureDialogComponent, {
      data: {
        message: 'This operation is not reversible.  Are you sure you want to delete your account?'
      }
    });

    ref.afterClosed().subscribe(async result => {
      if (result) {
        try {
          this.isWorking = true;
          await this.authService.sendOTPEmail(this.form.value.email);
          this.form.addControl('otp', new FormControl());
        } catch (err) {
          this.errorMessage = err;
        }
        this.isWorking = false;
      } else {
        await this.router.navigateByUrl('login');
      }
    });
  }

  async confirmOtp() {
    try {
      this.isWorking = true;
      await this.authService.deleteAccountWithOtp(this.form.value.email, this.form.value.otp);
      this.isDeleted = true;
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }
}

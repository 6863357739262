import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from 'common-ui/services/user.service';
import { AuthService } from 'common-ui/services';
import { LogoutTimerService } from 'common-ui/services/logout-timer.service';
import { CustomerService } from 'common-ui/services/customer.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeePortalGuard {
  constructor(
    private router: Router,
    private userService: UserService,
    private customerService: CustomerService,
    private authService: AuthService,
    private logoutTimer: LogoutTimerService
  ) {
  }

  async canActivateChild(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    if (!await this.authService.isSessionValid()) {
      return this.router.createUrlTree(['login']);
    }

    try {
      const user = await this.userService.getCurrentUser();
      if (!this.logoutTimer.allowLogin()) {
        await this.authService.logout('You have been logged out due to inactivity');
      } else if (user.isEmployee) {
        this.logoutTimer.init();
        const profile = (await this.customerService.getProfiles())[0];
        if (profile.emailChanged) {
          return this.router.createUrlTree(['account-email-changed'], {
            queryParams: {
              updatedEmail: profile.email
            }
          });
        } else if (!profile.isTermsAccepted) {
          if (state.url !== '/employee-portal/accept-terms') {
            return this.router.createUrlTree(['employee-portal/accept-terms']);
          }
        } else if (!profile.isBankDetailsSet) {
          if (state.url !== '/employee-portal/bank-details') {
            return this.router.createUrlTree(['employee-portal/bank-details']);
          }
        } else if (state.url === '/employee-portal/bank-details' && !!profile.sortCode) {
          return this.router.createUrlTree(['employee-portal/withdraw']);
        } else if (state.url === '/employee-portal/accept-terms' && profile.isTermsAccepted) {
          if (profile.sortCode) {
            return this.router.createUrlTree(['employee-portal/withdraw']);
          } else {
            return this.router.createUrlTree(['employee-portal/bank-details']);
          }
        }
        return true;
      } else {
        await this.authService.logout('Employee account not recognised');
      }

    } catch {
      await this.authService.logout('Failed to retrieve account details');
    }
  }
}


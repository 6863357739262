import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'common-ui/services/user.service';
import { UserDto, PushNotificationPref, UpdateNotificationPrefsDto } from 'common-ui/open-api';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'lib-notification-preferences-edit',
  templateUrl: './notification-preferences-edit.component.html',
  styleUrls: ['./notification-preferences-edit.component.scss']
})
export class NotificationPreferencesEditComponent implements OnInit {

  @Input() userId: string
  @Output() operationComplete = new EventEmitter()

  form: FormGroup;
  isWorking = true;
  errorMessage: string;
  user: UserDto;
  PushNotificationPrefs = Object.values(PushNotificationPref);

  constructor(
    private userService: UserService,
  ) {
    this.form = new FormGroup({});
  }

  async ngOnInit() {
    try {
      this.user = await this.userService.getUser(this.userId);
      const prefs = this.user.notificationPreferences;

      if (this.user.isSystemAdmin) {
        this.form.addControl('cashBalanceAlerts', new FormControl(prefs.cashBalanceAlerts || false));
        this.form.addControl(`trueLayerCashBalanceAlertThreshold`, new FormControl(prefs.trueLayerCashBalanceAlertThreshold / 100 || 5000));
        this.form.addControl(`modulrCashBalanceAlertThreshold`, new FormControl(prefs.modulrCashBalanceAlertThreshold / 100 || 5000));
        this.form.addControl('eurCashBalanceAlerts', new FormControl(prefs.eurCashBalanceAlerts || false));
        this.form.addControl(`trueLayerEurCashBalanceAlertThreshold`, new FormControl(prefs.trueLayerEurCashBalanceAlertThreshold / 100 || 5000));
        this.form.addControl(`modulrEurCashBalanceAlertThreshold`, new FormControl(prefs.modulrEurCashBalanceAlertThreshold / 100 || 5000));

        this.form.controls.cashBalanceAlerts.valueChanges.subscribe(active => {
          this.setCashBalanceAlertThresholdEnabledState(active);
        });
        this.setCashBalanceAlertThresholdEnabledState(this.form.controls.cashBalanceAlerts.value);

        this.form.controls.eurCashBalanceAlerts.valueChanges.subscribe(active => {
          this.setEurCashBalanceAlertThresholdEnabledState(active);
        });
        this.setEurCashBalanceAlertThresholdEnabledState(this.form.controls.eurCashBalanceAlerts.value);
      }

      if (this.user.isSystemAdmin || this.user.isMasterAccount || this.user.isCompanyAdmin) {
        this.form.addControl('invoicePeriodEnd', new FormControl(prefs.invoicePeriodEnd || false));
      }

      if (this.user.isEmployee) {
        this.form.addControl('successfulWithdrawal', new FormControl(prefs.successfulWithdrawal || false));
        this.form.addControl('failedWithdrawal', new FormControl(prefs.failedWithdrawal || false));
        this.form.addControl('wellbeingCampaign', new FormControl(prefs.wellbeingCampaign || false));
        this.form.addControl('amountAvailablePushNotificationPref', new FormControl(prefs.amountAvailablePushNotificationPref || PushNotificationPref.NEVER));
      }

    } catch (err) {
      this.errorMessage = err.message;

    }
    this.isWorking = false;
  }

  private setCashBalanceAlertThresholdEnabledState(active: boolean) {
    if (active) {
      this.form.controls.trueLayerCashBalanceAlertThreshold.enable();
      this.form.controls.modulrCashBalanceAlertThreshold.enable();
    } else {
      this.form.controls.trueLayerCashBalanceAlertThreshold.disable();
      this.form.controls.modulrCashBalanceAlertThreshold.disable();
    }
  }

  private setEurCashBalanceAlertThresholdEnabledState(active: boolean) {
    if (active) {
      this.form.controls.trueLayerEurCashBalanceAlertThreshold.enable();
      this.form.controls.modulrEurCashBalanceAlertThreshold.enable();
    } else {
      this.form.controls.trueLayerEurCashBalanceAlertThreshold.disable();
      this.form.controls.modulrEurCashBalanceAlertThreshold.disable();
    }
  }

  async save() {
    this.isWorking = true;
    try {
      this.isWorking = true;
      const prefs: UpdateNotificationPrefsDto = {
        userId: this.userId,
        ...this.form.value
      };

      if (this.form.value.cashBalanceAlerts) {
        prefs.trueLayerCashBalanceAlertThreshold = prefs.trueLayerCashBalanceAlertThreshold * 100;
        prefs.modulrCashBalanceAlertThreshold = prefs.modulrCashBalanceAlertThreshold * 100;
      }

      if ( this.form.value.eurCashBalanceAlerts) {
        prefs.trueLayerEurCashBalanceAlertThreshold = prefs.trueLayerEurCashBalanceAlertThreshold * 100;
        prefs.modulrEurCashBalanceAlertThreshold = prefs.modulrEurCashBalanceAlertThreshold * 100;
      }

      await this.userService.updateUserNotificationPreferences(prefs);
      this.operationComplete.emit();
      this.form.markAsPristine()
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }

  get isSysAdmin() {
    return this.user && this.user.isSystemAdmin;
  }

  get isEmployee() {
    return this.user && this.user.isEmployee;
  }

  get isAdmin() {
    return this.user && (this.user.isCompanyAdmin || this.user.isMasterAccount);
  }
}

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TokenStatus = 'ok' | 'unknown-token' | 'token-expired';

export const TokenStatus = {
    OK: 'ok' as TokenStatus,
    UNKNOWN_TOKEN: 'unknown-token' as TokenStatus,
    TOKEN_EXPIRED: 'token-expired' as TokenStatus
};


import { Component, OnInit, Input, Inject } from '@angular/core';
import { MasterAccountDto, UpdateMasterAccountDto, Currency } from 'common-ui/open-api';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MasterAccountService } from 'common-ui/services';
import { Environment } from 'common-ui/models/environment.type';
import { getCurrencySymbol } from 'common-ui/util';

@Component({
  selector: 'lib-master-account-limit-settings',
  templateUrl: './master-account-limit-settings.component.html',
  styleUrls: ['./master-account-limit-settings.component.css']
})
export class MasterAccountLimitSettingsComponent implements OnInit {

  @Input() masterAccount: MasterAccountDto;
  isWorking: boolean;
  public form: FormGroup;
  errorMessage: string;
  currencySymbol: string;

  constructor(
    private formBuilder: FormBuilder,
    private masterAccountService: MasterAccountService,
    @Inject('env') private environment: Environment
  ) {
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  ngOnInit(): void {
    this.currencySymbol = getCurrencySymbol(Currency.GBP);

    this.form = this.formBuilder.group({
      dailyWithdrawalLimitActive: this.masterAccount.dailyWithdrawalLimitActive,
      periodWithdrawalLimitActive: this.masterAccount.periodWithdrawalLimitActive,
      creditLimitActive: this.masterAccount.creditLimitActive
    });
    this.rebuildMaxWithdrawalsField('maxWithdrawalsPerDay', this.masterAccount.dailyWithdrawalLimitActive, this.masterAccount.maxWithdrawalsPerDay);
    this.rebuildMaxWithdrawalsField('maxWithdrawalsPerPeriod', this.masterAccount.periodWithdrawalLimitActive, this.masterAccount.maxWithdrawalsPerPeriod);
    this.rebuildCreditLimit(this.masterAccount.creditLimitActive, this.masterAccount.creditLimit)

    this.form.get('dailyWithdrawalLimitActive')
      .valueChanges.subscribe(active => {
      this.rebuildMaxWithdrawalsField('maxWithdrawalsPerDay', active, this.masterAccount.maxWithdrawalsPerDay);
    });

    this.form.get('periodWithdrawalLimitActive')
      .valueChanges.subscribe(active => {
      this.rebuildMaxWithdrawalsField('maxWithdrawalsPerPeriod', active, this.masterAccount.maxWithdrawalsPerPeriod);
    });

    this.form.get('creditLimitActive')
      .valueChanges.subscribe(active => {
      this.rebuildCreditLimit(active, this.masterAccount.creditLimit);
    });

    if (!this.isConsole) {
      this.form.disable();
    }
  }

  rebuildMaxWithdrawalsField(fieldName: string, active: boolean, value: number) {
    if (active) {
      this.form.addControl(fieldName, new FormControl(value, [
        Validators.required, Validators.min(1)]));
    } else {
      this.form.removeControl(fieldName);
    }

  }

  rebuildCreditLimit(active: boolean, value : number ) {
    if (active) {
      this.form.addControl('creditLimit', new FormControl(value /100, [Validators.required, Validators.min(1)]));
    } else {
      this.form.removeControl('creditLimit');
    }
  }

  get showMaxWithdrawalsPerDay() {
    return this.form.get('dailyWithdrawalLimitActive').value === true;
  }

  get showMaxWithdrawalsPerPeriod() {
    return this.form.get('periodWithdrawalLimitActive').value === true;
  }

  get showCreditLimit() {
    return this.form.get('creditLimitActive').value === true
  }

  public showError(formControlName: string, error: string): boolean {
    const formControl = this.form.get(formControlName);
    return formControl.invalid && formControl.dirty && formControl.errors[error];
  }

  async saveForm() {
    this.isWorking = true;
    try {
      this.errorMessage = '';
      let update: UpdateMasterAccountDto = {
        dailyWithdrawalLimitActive: this.form.value.dailyWithdrawalLimitActive,
        periodWithdrawalLimitActive: this.form.value.periodWithdrawalLimitActive,
        creditLimitActive: this.form.value.creditLimitActive
      };

      if (this.form.value.dailyWithdrawalLimitActive) {
        update = {
          ...update,
          maxWithdrawalsPerDay: Number.parseInt(this.form.value.maxWithdrawalsPerDay)
        };
      }

      if (this.form.value.periodWithdrawalLimitActive) {
        update = {
          ...update,
          maxWithdrawalsPerPeriod: Number.parseInt(this.form.value.maxWithdrawalsPerPeriod)
        };
      }

      if ( this.form.value.creditLimitActive ) {
        update= {
          ...update,
          creditLimit: Number.parseInt(this.form.value.creditLimit) * 100
        }
      }

      this.masterAccount = await this.masterAccountService.update(
        this.masterAccount._id, update
      );

      this.form.markAsPristine();

    } catch (e) {
      this.errorMessage = e.message;
    }
    this.isWorking = false;
  }

  // protected readonly getCurrencySymbol = getCurrencySymbol;
}

import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  AfterViewInit,
  OnDestroy,
  Inject,
  Output,
  EventEmitter
} from '@angular/core';
import {
  EmployeeService,
  FilterService,
  FilterType,
  TimesheetService,
  ToolbarDataService,
  SystemService
} from 'common-ui/services';
import { TimesheetQuery, SortDirection, TimesheetType } from 'common-ui/open-api';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Subject } from 'rxjs';
import { WeekSelectorComponent } from 'common-ui/week-selector/week-selector.component';
import { formatDateYYYYMMDD, getWeekEnd, parseDateYYYYMMDD } from 'common-ui/util';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { getShiftedDate } from 'common-ui/util/get-shifted-date';
import { DateRangeService } from 'common-ui/services/date-range.service';
import { TimesheetTableRow } from 'common-ui/timesheets-table-page/timesheet-table-row';
import { days } from 'common-ui/timesheets-table-page/days';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';
import { AggregatedTimesheetDataSource } from 'common-ui/timesheets-table-page/aggregated-timesheet-data-source';
import format from 'date-fns/format';
import { Pages } from 'common-ui/services/filter.service';
import { Environment } from 'common-ui/models/environment.type';

@Component({
  selector: 'lib-timesheet-table-page',
  templateUrl: './timesheet-table-page.component.html',
  styleUrls: ['./timesheet-table-page.component.css']
})
export class TimesheetTablePageComponent implements OnInit, AfterViewInit, OnDestroy {

  FilterType = FilterType;
  initialDateRange: JustDateRange;
  editField = false;
  dayKeys = days;
  dataSource: AggregatedTimesheetDataSource;
  displayedColumns = [
    'firstName',
    'lastName',
    'masterAccountName',
    'companyName',
    'payFrequency',
    'amountAccrued',
    ...days
  ];
  focusedCellId: string;
  buildingTableData = false;
  today: string;
  public showMasterAccountFilter = false;
  private ngUnsubscribe = new Subject();

  @Output() switchView = new EventEmitter<string>();
  @ViewChild('weekSelector') weekSelector: WeekSelectorComponent;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private employeeService: EmployeeService,
    private filterService: FilterService,
    private dateRangeService: DateRangeService,
    private systemService: SystemService,
    private timesheetService: TimesheetService,
    private toolbarDataService: ToolbarDataService,
    @Inject('env') private environment: Environment
  ) {
    this.initialDateRange = this.dateRangeService.getDateRange();
    this.dataSource = new AggregatedTimesheetDataSource(timesheetService);
    this.showMasterAccountFilter = this.environment.adminConsole;
    if (!this.environment.adminConsole) {
      this.displayedColumns = this.displayedColumns.filter(
        column => column !== 'masterAccountName');
    }
  }

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle',
      title: 'Timesheets'
    });
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => await this.loadData());

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      await this.loadData();
      this.paginator.firstPage();
    });

    const initialSort = this.filterService.getPageSort(Pages.TIMESHEET_TABLE);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.TIMESHEET_TABLE, newSort.active, newSort.direction as SortDirection);
      });

    await this.loadData();
  }

  async loadData() {
    const query: TimesheetQuery = {
      fromDate: this.dateRangeService.getDateRange().fromDate,
      toDate: this.dateRangeService.getDateRange().toDate,
      offset: this.paginator.pageIndex * this.pageSize,
      limit: this.pageSize
    };

    const sort = this.filterService.getPageSort(Pages.TIMESHEET_TABLE);
    if (sort && this.sort.sortables.has(sort.name)) {
      query.sort = FilterService.getSortObj(sort);
    }

    const currentFilter = this.filterService.currentFilter;
    query.employeeName = currentFilter.employeeSearch;
    query.masterAccountIds = currentFilter.masterAccounts;
    query.companyIds = currentFilter.companies;
    query.payFrequencies = currentFilter.payFrequencies;
    query.employmentStatuses = currentFilter.employmentStatuses;

    this.buildingTableData = true;
    await this.dataSource.load(query);
    this.buildingTableData = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  @HostListener('document:keydown', ['$event'])
  async handleKeyboardEvent(event: KeyboardEvent) {
    if (this.focusedCellId && !event.repeat && !this.buildingTableData) {
      const {employeeId, key} = this.parseCellId(this.focusedCellId);
      const visibleCellList = this.dataSource.currentValue;
      let employeeIndex = 0;
      for (let i = 0; i < visibleCellList.length; i++) {
        if (visibleCellList[i].employeeId === employeeId) {
          employeeIndex = i;
          break;
        }
      }
      let keyIndex = this.dayKeys.indexOf(key);

      let updateFocusCell = true;

      // Don't await this.updateList calls to prevent the ui being delayed
      switch (event.key) {
        case 'Enter': {
          this.updateList(employeeId, key).then().catch(() => {
          });
          break;
        }
        case 'ArrowUp': {
          this.updateList(employeeId, key).then().catch(() => {
          });
          employeeIndex = employeeIndex > 0 ? employeeIndex - 1 : 0;
          break;
        }
        case 'ArrowDown': {
          this.updateList(employeeId, key).then().catch(() => {
          });
          employeeIndex = employeeIndex < visibleCellList.length - 1 ? employeeIndex + 1 : employeeIndex;
          break;
        }
        case 'ArrowLeft': {
          this.updateList(employeeId, key).then().catch(() => {
          });
          if (keyIndex === 0) {
            await this.shiftDays(-7);
            keyIndex = this.dayKeys.length - 1;
          } else {
            keyIndex--;
          }
          break;
        }
        case 'Tab':
          this.updateList(employeeId, key).then().catch(() => {
          });
          updateFocusCell = false;
          break;
        case 'ArrowRight': {
          this.updateList(employeeId, key).then().catch(() => {
          });
          if (keyIndex === this.dayKeys.length - 1) {
            await this.shiftDays(7);
            keyIndex = 0;
          } else {
            keyIndex++;
          }
          break;
        }
        default: {
          return;
        }
      }

      const cellId = this.buildCellId(visibleCellList[employeeIndex].employeeId, this.dayKeys[keyIndex]);
      const cellToFocus = document.getElementById(cellId);
      if (cellToFocus && updateFocusCell) {
        cellToFocus.focus();
      }
    }
  }

  async setDates(dateRange: JustDateRange) {
    this.dateRangeService.setDateRange(dateRange);
    await this.loadData();
  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  changeValue(event: KeyboardEvent) {

    const {employeeId, key} = this.parseCellId(this.focusedCellId);
    const row = this.dataSource.getRow(employeeId);

    if (this.isDisabled(row, key)) {
      event.preventDefault();
      this.editField = false;
    }

    if (event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === '.' ||
      !isNaN(parseInt(event.key, 10))
    ) {
      // Sets flag to say we've changed data in a cell
      this.editField = true;
    } else if (event.key === 'Tab') {
      return;
    } else {
      event.preventDefault();
    }
  }

  async updateList(employeeId: string, key: string) {
    // If we've entered a number in a cell in changeValue(), this will be true so we need to update
    // the table and save changes
    if (this.editField) {
      const row = this.dataSource.getRow(employeeId);
      const amount = row.approvedWages.getAmount(key);
      row.approvedWages.setAmount(amount, key);
      this.editField = false;
      await this.timesheetService.upsertTimesheet({
        wages: amount,
        employeeId,
        date: this.mapKeyStringToDateString(key),
        isUnapproved: false,
        isMissingHourlyRate: false,
        type: TimesheetType.STANDARD
      });

      const employee = await this.employeeService.getEmployee(row.employeeId);
      row.amountAccrued = employee.amountAccrued;
    }
  }

  mapKeyStringToDateString(key: string): string {
    const index = this.dayKeys.indexOf(key);
    const fromDate = this.dateRangeService.getDateRange().fromDate;
    return getShiftedDate(fromDate, index);
  }

  isDisabled(tableRow: TimesheetTableRow, key: string): boolean {
    const date = this.mapKeyStringToDateString(key);
    return date < tableRow.accrualStart || tableRow.isSourcedFromIntegration;
  }

  isAccrualStart(tableRow: TimesheetTableRow, key: string): boolean {
    const date = this.mapKeyStringToDateString(key);
    return date === tableRow.accrualStart;
  }

  isAccrualEnd(tableRow: TimesheetTableRow, key: string): boolean {
    const date = this.mapKeyStringToDateString(key);
    const today = formatDateYYYYMMDD(this.systemService.getSystemTime());
    return date === tableRow.accrualEnd || date === today;
  }

  getKeyHeaderString(key: string): string {
    const keyString = key.charAt(0).toUpperCase() + key.slice(1);
    const date = parseDateYYYYMMDD(this.mapKeyStringToDateString(key));
    const dateString = format(date, 'do');
    return `${keyString} ${dateString}`;
  }

  focusCell(employeeId: string, key: string) {
    this.focusedCellId = this.buildCellId(employeeId, key);
  }

  async shiftDays(days: number) {
    const fromDate = getShiftedDate(this.dateRangeService.getDateRange().fromDate, days);
    const dateRange: JustDateRange = {
      fromDate: fromDate,
      toDate: getWeekEnd(fromDate)
    };
    this.dateRangeService.setDateRange(dateRange);
    this.weekSelector.setDateRange(dateRange);
    await this.loadData();
  }

  buildCellId(employeeId: string, key: string) {
    return `${employeeId}.${key}`;
  }

  parseCellId(cellId: string) {
    const parts = cellId.split('.');
    return {
      employeeId: parts[0],
      key: parts[1]
    };
  }

  async switchToListView() {
    this.switchView.emit('list');
  }
}

<lib-dialog-container header="Payroll Schedule"
                      [showCross]="true"
                      (exit)="close()"
                      [subHeader]="payroll.companyName + ', ' + frequency + (payroll.currentDatesOverrideActive ? ', override is active' : '')">

  <lib-spinner-container [isWorking]="isWorking">

    <lib-payroll-schedule-table
      [payroll]="payroll"
      [schedule]="schedule">
    </lib-payroll-schedule-table>

  </lib-spinner-container>
</lib-dialog-container>

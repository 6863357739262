<lib-dialog-container (exit)="close()"
                      [showCross]="true"
                      [subHeader]="subHeader"
                      header="Employee Audit Log">

  <lib-spinner-container [isWorking]="isWorking">

    <div class="log-table-container">
      <table
        class="log-table"
        mat-table [dataSource]="auditLog">

        <ng-container matColumnDef="createdDate">
          <th *matHeaderCellDef mat-header-cell>Date/Time</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.createdDate | date: 'dd/MM/yy HH:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="event">
          <th *matHeaderCellDef mat-header-cell>Event</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.event | hyphenatedToRegular }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th *matHeaderCellDef mat-header-cell>User</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.createdBy }}
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th *matHeaderCellDef mat-header-cell>Note</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.note }}
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

        <tr *matNoDataRow class="mat-row">
          <td class="mat-cell" colspan="4">No events</td>
        </tr>
      </table>
    </div>

    <lib-error-message [message]="errorMessage"></lib-error-message>

  </lib-spinner-container>

</lib-dialog-container>

import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import {
  MasterAccountDto,
  MasterAccountCoreDto,
  UpdateMasterAccountDto,
  TestConnectionResult,
  ApiSource,
  EntityStatus,
  CarePlannerPaymentGroupDto
} from 'common-ui/open-api';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { MasterAccountOpenApiService } from 'common-ui/open-api';
import { FileDownloadService } from 'common-ui/services/file-download.service';

@Injectable({
  providedIn: 'root'
})
export class MasterAccountService {

  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private masterAccountOpenApiService: MasterAccountOpenApiService,
    private fileDownloadService: FileDownloadService
  ) {
  }

  async search(nameContains: string): Promise<MasterAccountCoreDto[]> {
    return await this.apiService.get(`/api/master-account/search?nameContains=${nameContains}`);
  }

  async get(masterAccountId: string): Promise<MasterAccountDto> {
    return await this.apiService.get(`/api/master-account/full/${masterAccountId}`);
  }

  async getCoreMasterAccount(masterAccountId: string): Promise<MasterAccountCoreDto> {
    return await this.apiService.get(`/api/master-account/core/${masterAccountId}`);
  }

  async findAll(): Promise<MasterAccountDto[]> {
    return firstValueFrom(this.masterAccountOpenApiService.getAll());
  }

  async getOAuthRedirectUrl(apiSource: ApiSource, masterAccountId: string): Promise<string> {
    const response = await this.apiService.post(
      `/api/integration/oauth-redirect-url/${masterAccountId}`,
      {apiSource}
    );
    return response.url;
  }

  async exchangeOAuthCode(apiSource: ApiSource, code: string, state: string) {
    await this.apiService.post('/api/integration/oauth-exchange', {apiSource, code, state});
  }

  async runReportExport(masterAccountId: string): Promise<void> {
    return await this.apiService.post(`/api/master-account/run-report-export/${masterAccountId}`, {});
  }

  async testReportExportConnection(masterAccountId: string): Promise<void> {
    return await this.apiService.post(`/api/master-account/test-report-export-connection/${masterAccountId}`, {});
  }

  async testConnection(masterAccountId: string): Promise<TestConnectionResult> {
    return await this.apiService.get(`/api/integration/test-connection/${masterAccountId}`);
  }

  async update(masterAccountId: string, updateMasterAccountDto: UpdateMasterAccountDto): Promise<MasterAccountDto> {
    return await this.apiService.patch(`/api/master-account/${masterAccountId}`, updateMasterAccountDto);
  }

  async delete(masterAccountId: string): Promise<void> {
    return await this.apiService.delete(`/api/master-account/${masterAccountId}`);
  }

  async updateStatus(masterAccountId: string, status: EntityStatus) {
    await this.apiService.patch(`/api/master-account/${masterAccountId}/update-status`, {status});
  }

  async downloadSftpPublicKey(masterAccountId: string) {
    const res = this.masterAccountOpenApiService.downloadSftpPublicKey(masterAccountId, 'response');
    await this.fileDownloadService.downloadTextFile(res);
  }

  async updateCustomisation(
    masterAccountId: string,
    useCustomisedEmailLogo: boolean,
    customisedEmailLogoFile?: File): Promise<MasterAccountDto> {
    const formData = new FormData();
    if (useCustomisedEmailLogo) {
      formData.append('file', customisedEmailLogoFile);
    }
    formData.append('useCustomisedEmailLogo', useCustomisedEmailLogo ? 'true' : 'false');
    let headers = new HttpHeaders();
    headers = headers.append('Authorization', `Bearer ${this.apiService.idToken}`);
    try {
      const res: HttpResponse<any> = await firstValueFrom(this.http.patch(`/api/master-account/update-customisation/${masterAccountId}`, formData, {
        headers: headers,
        observe: 'response'
      }));
      return res.body;
    } catch (err) {
      console.log(err);
    }
  }

  async createMasterAccount(
    masterAccountName: string
  ) {
    await this.apiService.post('/api/master-account/', {
      masterAccountName
    });
  }

  async calcEmployeeBalances(masterAccountId: string) {
    await this.apiService.post(`/api/master-account/calc-employee-balances/${masterAccountId}`, {});
  }

  async updateCarePlannerSalariedPaymentGroup(masterAccountId: string, group: CarePlannerPaymentGroupDto) {
    await this.apiService.patch(`/api/master-account/care-planner-salaried-payment-group/${masterAccountId}`, group);
  }
}

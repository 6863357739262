import { CsvInputRow } from './csv-input-row';
import { CsvInputCell } from './csv-input-cell';

export interface ColumnHeading {
  columnName: string;
  columnDescription: string;
  exampleValue: string;
}

export interface ServerImportError {
  errorColumn: string;
  message: string;
  rowId: string;
  isWarning: boolean
}

export class CsvInputFile {
  rows: CsvInputRow[];
  fileErrors: string[] = [];
  allColumnNames: string[];

  constructor(columnNames: string[],
              cells: string[][]) {
    this.allColumnNames = columnNames;
    let rowNumber = 0;
    this.rows = cells.map(row => {
      rowNumber++;
      return new CsvInputRow(String(rowNumber), row.map(cell => new CsvInputCell(cell)));
    });
  }

  static copyFirstRow(input: CsvInputFile) {
    if (input.rows.length === 0) {
      return new CsvInputFile(input.columnNames, []);
    }
    const cells = input.rows[0].cells.map(cell => cell.value);
    return new CsvInputFile(input.columnNames, [cells]);
  }

  static fromCsv(csv: string, noHeaderRow: boolean) {
    const lines = csv.split('\n');
    const textGrid = lines
      .filter(line => line.length > 1)
      .map(line => {
        return line.split(',').map(cell => {
          let ret = cell.trim();
          if (ret[0] === '"') {
            ret = ret.substr(1, ret.length);
          }
          if (ret[ret.length - 1] === '"') {
            ret = ret.substr(0, ret.length - 1);
          }
          return ret;
        });
      });

    let columns: string[];
    if (noHeaderRow) {
      columns = textGrid[0].map((_, i) =>
        'Column ' + String.fromCharCode('A'.charCodeAt(0) + i));
      if (textGrid[0][columns.length - 1] === '') {
        columns = columns.splice(0, columns.length - 1);
      }
    } else {
      columns = textGrid[0];
      if (columns[columns.length - 1] === '') {
        columns = columns.splice(0, columns.length - 1);
      }
    }
    let rows = textGrid.splice(noHeaderRow ? 0 : 1, textGrid.length);
    rows = rows.map(row => {
      return row.splice(0, columns.length);
    });
    return new CsvInputFile(columns, rows);
  }

  get columnNames() {
    return this.allColumnNames.filter(x => x !== '');
  }

  getRowIndex(row: CsvInputRow) {
    this.rows.indexOf(row);
  }

  getColumnValues(column: string): CsvInputCell[] {
    const columnIndex = this.getColumnIndex(column);
    if (columnIndex === -1) {
      return null;
    }
    return this.rows.map(row => row.cells[columnIndex]);
  }

  getCandidateMapping(targetFieldName: string) {
    return this.columnNames.find(name => name.toLowerCase() === targetFieldName.toLowerCase());
  }

  addServerErrors(errors: ServerImportError[]) {
    this.clearServerErrors();
    errors.forEach(error => {
      let errorCol = this.getColumnIndex(error.errorColumn);
      if (errorCol === -1) {
        errorCol = 0;
      }
      const errorRows = this.rows.filter(row => row.id === error.rowId);
      errorRows.forEach(errorRow => errorRow.addServerError(errorCol, error));
    });
  }

  clearFileErrors() {
    this.fileErrors = [];
  }

  clearServerErrors() {
    this.rows.forEach(row => {
      row.clearServerErrors();
    });
  }

  get hasClientErrorRowCount() {
    return this.rows.filter(row => row.hasClientError).length;
  }

  addFileError(error: string) {
    this.fileErrors.push(error);
  }

  getColumnIndex(columnName: string) {
    return this.allColumnNames.map(name => name.toLowerCase()).indexOf(columnName.toLowerCase());
  }

  createHeadings(noHeaderRow: boolean): ColumnHeading[] {
    const row = this.rows[0];
    if (noHeaderRow && this.rows.length > 0) {

      const getLetterFromName = name => {
        return name.substring(7, name.length);
      };

      return this.columnNames.map(columnName => {
        const n = this.getColumnIndex(columnName);
        return {
          columnName,
          columnDescription: getLetterFromName(columnName) + ' - ' + row.getValue(n).value,
          exampleValue: row.getValue(n).value
        };
      });

    } else {
      return this.columnNames.map(columnName => ({
        columnName,
        columnDescription: columnName,
        exampleValue: row.getValue(this.getColumnIndex(columnName)).value
      }));
    }
  }
}

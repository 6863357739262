import { Component, forwardRef } from '@angular/core';
import { FormGroup, ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PermissionEnum } from 'common-ui/open-api';

export const excludePermissions = [
  PermissionEnum.COMPANY_READ,
  PermissionEnum.EMPLOYEE_READ,
  PermissionEnum.MASTER_ACCOUNT_READ,
  PermissionEnum.MASTER_ACCOUNT_CORE_READ
];

@Component({
  selector: 'lib-permissions-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PermissionsListComponent),
      multi: true
    }
  ]
})
export class PermissionsListComponent  implements ControlValueAccessor {
  form: FormGroup;
  permissions = Object.values(PermissionEnum)
    .filter(p => !excludePermissions.includes(p));

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({});

    this.permissions.forEach(permission => {
      this.form.addControl(permission, this.fb.control(false));
    });

    this.form.valueChanges.subscribe(values => {
      const selectedPermissions = Object.entries(values)
        .filter(([, isSelected]) => isSelected)
        .map(([permission]) => permission as PermissionEnum);
      this.onChange(selectedPermissions);
      this.onTouched();
    });
  }

  writeValue(permissions: PermissionEnum[]): void {
    this.permissions.forEach(permission => {
      this.form.get(permission)?.setValue(false, { emitEvent: false });
    });

    if (permissions) {
      permissions.forEach(permission => {
        this.form.get(permission)?.setValue(true, { emitEvent: false });
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Inject, NgZone } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ImpersonationService } from 'common-ui/services/impersonation.service';
import { SignInDto, UserDto } from 'common-ui/open-api';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { firstValueFrom, Subject, BehaviorSubject } from 'rxjs';
import { Environment } from 'common-ui/models/environment.type';
import { jwtDecode } from 'jwt-decode';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LogoutTimerService } from 'common-ui/services/logout-timer.service';
import { CustomSnackBarComponent } from 'common-ui/custom-snack-bar/custom-snack-bar.component';

export class ApiError extends Error {
  constructor(
    message: string,

    // This is here so that customer service can examine the error and determine if it is a 400
    public error?: HttpErrorResponse

  ) {
    super(message);
  }
}

export type ApiStatus = 'running' | 'success' | 'error';

export interface ApiEvent {
  status: ApiStatus,
  responseTime?: number
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  systemTimeOffset$ = new BehaviorSubject<number>(0);
  apiEvent$ = new Subject<ApiEvent>();
  idToken: string;
  currentUser: UserDto;
  loginError: string;
  snackBarErrors = new Set<string>();

  constructor(
    private http: HttpClient,
    @Inject('env') private environment: Environment,
    public snackBar: MatSnackBar,
    private impersonationService: ImpersonationService,
    private router: Router,
    private matDialog: MatDialog,
    private logoutTimer: LogoutTimerService
  ) {
    // this.snackBarErrors.add('payment-instructions/export-pending');
  }

  async get(path: string) {
    return this.sendApiRequest('GET', path, {});
  }

  async delete(path: string) {
    return this.sendApiRequest('DELETE', path, {});
  }

  async post(path: string, data: object) {
    return this.sendApiRequest('POST', path, data);
  }

  async patch(path: string, data: object) {
    return this.sendApiRequest('PATCH', path, data);
  }

  async put(path: string, data: object) {
    return this.sendApiRequest('PUT', path, data);
  }

  isAuthenticated(): boolean {
    try {
      const isAuth = this.getCookie('is-authenticated');
      return isAuth === 'true';
    } catch (err) {
      Sentry.captureException(err);
      return false;
    }
  }

  isIdTokenExpired() {
    try {
      if (!this.idToken) {
        return true;
      }
      const decoded = jwtDecode(this.idToken);
      const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
      const expirationTime = decoded.exp; // expiration time in seconds
      return expirationTime - currentTime < 30;
    } catch (err) {
      Sentry.captureMessage('Cannot decode idToken');
      Sentry.captureException(err);
      return true;
    }
  }

  isRefreshTokenExpired(): boolean {
    try {
      const refreshTokenExpiry = this.getCookie('refresh-token-expiry');
      return !!refreshTokenExpiry && new Date(refreshTokenExpiry).toISOString() < new Date().toISOString();
    } catch (err) {
      Sentry.captureException(err);
      return true;
    }
  }

  setIdToken(idToken: string) {
    this.idToken = idToken;
  }

  private getCookie(name: string) {
    const ca: string[] = document.cookie.split(';');
    const caLen: number = ca.length;
    const cookieName = this.getCookieNameForEnvironment(name);
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        const value = c.substring(cookieName.length, c.length);
        return decodeURIComponent(value);
      }
    }
    return '';
  }

  private getCookieNameForEnvironment(name: string) {
    return this.isConsole ? `console-${name}=` : `${name}=`;
  }

  checkAngularZone() {
    if (!NgZone.isInAngularZone()) {
      throw new Error('Lost Angular Zone!!');
    }
  }

  get isConsole(): boolean {
    return this.environment.adminConsole;
  }

  async logout(message?: string) {
    this.loginError = message ?? '';
    this.currentUser = null;
    try {
      await this.post('/api/user/sign-out', {
        isConsole: this.environment.adminConsole
      });
    } catch {
      Sentry.captureMessage('Sign-Out Failed');
    }
    this.matDialog.closeAll();
    this.idToken = null;
    this.logoutTimer.cleanup();
    this.impersonationService.clear();
    await this.router.navigateByUrl('login');
  }

  async refreshTokenIfNeeded(url?: string) {
    if (url && (url.includes('sign-in')
      || url.includes('login')
      || url === 'password-reset'
      || url === 'check-token')) {
      return;
    }

    if (this.isAuthenticated() && this.isIdTokenExpired()) {
      if (this.isRefreshTokenExpired()) {
        await this.logout('Session expired. Please login again');
        throw new ApiError('Session expired. Please login again');
      }
      let signInDto: SignInDto;
      const startTime = new Date().getTime();

      try {
        Sentry.captureMessage(`refresh token: isConsole: ${this.isConsole}`);
        const response = await firstValueFrom(this.http.post('/api/user/refresh-token', {
          isConsole: this.isConsole
        }, {
          headers: this.getHttpHeaders(),
          observe: 'body'
        }));

        this.checkAngularZone();

        signInDto = response as SignInDto;

      } catch (err) {
        await this.handleError(err, startTime);
        return;
      }

      if (signInDto.idToken) {
        this.idToken = signInDto.idToken;
      } else {
        throw new Error(signInDto.authErrorCode);
      }
    }
  }

  logRequest(method: string, uri: string, data?: any) {
    if (this.environment.debug) {
      if (method != 'GET') {
        console.log(`=> ${method}:${uri}`, data);
      } else {
        console.log(`=> ${method}:${uri}`);
      }
    }
  }

  private async sendApiRequest(
    method: string,
    uri: string,
    data: object
  ): Promise<any> {
    const response: HttpResponse<any> = await firstValueFrom(this.http.request(method, uri, {
      body: data,
      reportProgress: true,
      observe: 'response'
    }));
    return response.body;
  }

  public getHttpHeaders(): HttpHeaders {
    let httpHeaders = new HttpHeaders({
      'x-request-id': crypto.randomUUID(),
      'x-client-timestamp': new Date().toISOString(),
      'x-client-name': this.isConsole ? 'console' : 'portal'
    });

    if (this.isConsole) {
      httpHeaders = httpHeaders.set('console', 'true');
    }

    if (this.idToken) {
      httpHeaders = httpHeaders.set('Authorization', `Bearer ${this.idToken}`);

      if (this.impersonationService.userToImpersonate) {
        httpHeaders = httpHeaders.set('impersonate-user', this.impersonationService.userToImpersonate);
      }
    }

    if (this.systemTimeOffset$) {
      httpHeaders = httpHeaders.set('system-time-offset', this.systemTimeOffset$.toString());
    }

    return httpHeaders;
  }

  async postFile(
    file: File,
    fileName: string,
    uri: string
  ): Promise<any> {
    const startTime = new Date().getTime();
    this.statusEvent({
      status: 'running'
    });

    try {
      const formData = new FormData();
      formData.append(fileName, file, file.name);
      await this.refreshTokenIfNeeded();
      const result = await firstValueFrom(
        this.http.post(uri, formData, {
          headers: this.getHttpHeaders()
        }));
      this.statusEvent({
        status: 'success',
        responseTime: new Date().getTime() - startTime
      });
      return result;
    } catch (err) {
      await this.handleError(err, startTime);
    }
  }

  async handleError(err: HttpErrorResponse, startTime: number) {
    console.error(err);
    this.statusEvent({
      status: 'error',
      responseTime: new Date().getTime() - startTime
    });

    let errorMessage: string;

    // todo - document where each of these comes from
    if (err.error && typeof err.error == 'string' && err.error.startsWith('{"')) {
      // File downloads that throw BadRequestException.
      const parsedError = JSON.parse(err.error);
      errorMessage = parsedError['message'];
    } else if (err.error && err.error.message) {
      if (Array.isArray(err.error.message)) {
        errorMessage = err.error.message[0];
      } else {
        errorMessage = err.error.message;
      }
    } else if (err.message) {
      errorMessage = err.message;
    } else {
      errorMessage = err.toString();
    }

    let showSnackBar = false;

    if (err.status === 503 && !this.isConsole) {
      await this.router.navigateByUrl('system-down');
      return;
    }

    if (err.status === 403) {
      showSnackBar = true;
    } else if (err.status === 504) {
      errorMessage = 'Connection failed!';
      showSnackBar = true;
    } else if (err.status === 400 && this.snackBarErrors.has(this.getApiPath(err.url))) {
      showSnackBar = true;
    } else if (err.status === 500) {
      errorMessage = 'Operation failed. Please try again.';
    }

    if (showSnackBar) {
      this.snackBar.openFromComponent(CustomSnackBarComponent, {
        verticalPosition: 'top',
        data: {
          message: err.status === 403 ? 'You have insufficient permissions to perform this action' : errorMessage,
          buttonText: 'Dismiss',
          icon: err.status === 403 ? 'block' : 'error'
        }
      });
    }
    throw new ApiError(errorMessage, err);
  }

  getApiPath(url: string): string {
    const match = url.match(/\/api\/(.+)/);
    return match ? match[1] : '';
  }

  statusEvent(status: ApiEvent) {
    this.apiEvent$.next(status);
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PeriodDates, PayrollDto, PayrollType, PayrollScheduleDto } from 'common-ui/open-api';

@Component({
  selector: 'lib-payroll-schedule-table',
  templateUrl: './payroll-schedule-table.component.html',
  styleUrls: ['./payroll-schedule-table.component.css']
})
export class PayrollScheduleTableComponent implements OnChanges {
  dataSource = new MatTableDataSource<PeriodDates>;
  isOverrideActive: boolean;
  skipNextPeriodEnd: boolean;
  @Input() payroll: PayrollDto;
  @Input() schedule: PayrollScheduleDto;
  @Input() periodsToShow: number | null = null;

  displayedColumns = [
    'periodStart',
    'periodEnd'
  ];

  ngOnChanges() {
    if (!this.payroll || !this.schedule) {
      return;
    }

    if (this.payroll.type === PayrollType.ADVANCED) {
      this.displayedColumns = [
        'periodStart',
        'deductionsExportDate',
        'payDate',
        'periodEnd'
      ];
    } else {
      if (this.payroll.delayChargeDateToPayDate) {
        this.displayedColumns = [
          'periodStart',
          'periodEnd',
          'payDate'
        ];
      } else {
        this.displayedColumns = [
          'periodStart',
          'periodEnd'
        ];
      }
    }

    if (this.periodsToShow) {
      this.dataSource.data = this.schedule.periodDates.slice(0, this.periodsToShow);
    } else {
      this.dataSource.data = this.schedule.periodDates;
    }

    this.isOverrideActive = this.payroll.currentDatesOverrideActive;
    this.skipNextPeriodEnd = this.payroll.skipNextPeriodEnd;
  }
}

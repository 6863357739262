import { Injectable } from '@angular/core';
import {
  PayFrequency,
  PayType,
  AdminUserRole,
  EmploymentStatus,
  JobStatus,
  EntityStatus,
  EmailTemplateType,
  CommsLogStatus,
  SortDirection,
  ContentType,
  InvoiceStatus,
  PaymentStatusEnum,
  TimesheetType,
  EmployeeUsageRating,
  EmailEventType,
  BankTransactionType,
  PaymentApiType,
  WithdrawalType,
  CompanyDto
} from 'common-ui/open-api';
import { Observable, Subject } from 'rxjs';
import { NewDateRange, DateRangeType } from 'common-ui/custom-dates-range-selector/date-range.type';
import { CompanyService } from 'common-ui/services/company.service';

export enum Pages {
  EMPLOYEE = 'employee',
  PERIOD_HISTORY = 'history',
  MASTER_ACCOUNT = 'master-account',
  COMPANY = 'company',
  PAYROLL = 'payroll',
  JOB_SCHEDULE = 'job-schedule',
  BANK_TRANSACTIONS = 'bank-transaction',
  WITHDRAWAL = 'withdrawal',
  DISCOUNT_CARDS = 'discount-cards',
  TIMESHEET_LIST = 'timesheet-list',
  PAY_CODES = 'pay-codes',
  TIMESHEET_TABLE = 'timesheet-table',
  COMMS_LOG = 'comms-log',
  INTEGRATION_REPORTS = 'integration-reports',
  USERS = 'users',
  SAVINGS_ACCOUNT = 'savings-account'
}

export enum WithdrawalsSettledFilter {
  SETTLED = 'Settled',
  UNSETTLED = 'Unsettled'
}

export interface TimesheetStatus {
  isApproved: boolean | null;
  isCancelled: boolean | null;
}

export enum FilterType {
  COMPANY = 'Company',
  PAY_FREQUENCY = 'Pay Frequency',
  EMPLOYMENT_STATUS = 'Employment Status',
  USER_SEARCH = 'User Search',
  BANK_TRANSACTION_SEARCH = 'Bank Transaction Search',
  EMPLOYEE_SEARCH = 'Employee Search',
  COMPANY_SEARCH = 'Company Search',
  MASTER_ACCOUNT_NAME_SEARCH = 'Master Account Search',
  API_KEY_SEARCH = 'Api Keys Search',
  PAY_TYPE = 'Pay Type',
  USER_ROLE = 'User Role',
  UNAPPROVED = 'Unapproved',
  FOLLOWING = 'Following',
  SCOPE = 'Scope',
  MASTER_ACCOUNT = 'Master Account',
  JOB_STATUS = 'Job Status',
  WITHDRAWALS_SETTLED = 'Withdrawals Settled',
  FROM_DATE = 'From Date',
  TO_DATE = 'To Date',
  DATE_RANGE = 'Date Range',
  INVOICE_NUMBER = 'Invoice Number',
  ENTITY_STATUS = 'Entity Status',
  EMAIL_TEMPLATE_TYPE = 'Template Type',
  COMMS_LOG_STATUS = 'comms-log-status',
  EMAIL_EVENT = 'email-event',
  INVOICE_STATUS = 'invoice-status',
  CUSTOM_CONTENT_TYPE = 'custom-content-type',
  TIMESHEET_STATUS = 'timesheet-status',
  PAYMENT_REFERENCE = 'payment-reference',
  PAYMENT_STATUS = 'payment-status',
  TIMESHEET_TYPES = 'Timesheet Types',
  EMPLOYEE_USAGE_RATING = 'employee-usage-rating',
  EMPLOYEE_VULNERABLE = 'employee-vulnerable',
  BANK_TRANSACTION_TYPE = 'bank-transaction-type',
  PAYMENT_API_TYPE = 'payment-api-types',
  WITHDRAWAL_TYPE = 'Withdrawal Type',
  PAY_CODE_SEARCH = 'Pay Code'
}

const filterStatusMap = new Map<FilterType, string>([
  [FilterType.USER_SEARCH, 'userSearch'],
  [FilterType.BANK_TRANSACTION_SEARCH, 'bankTransactionSearch'],
  [FilterType.EMPLOYEE_SEARCH, 'employeeSearch'],
  [FilterType.COMPANY_SEARCH, 'companySearch'],
  [FilterType.MASTER_ACCOUNT_NAME_SEARCH, 'masterAccountNameSearch'],
  [FilterType.API_KEY_SEARCH, 'apiKeySearch'],
  [FilterType.COMPANY, 'companies'],
  [FilterType.PAY_FREQUENCY, 'payFrequencies'],
  [FilterType.PAY_TYPE, 'payTypes'],
  [FilterType.USER_ROLE, 'userRoles'],
  [FilterType.UNAPPROVED, 'unapproved'],
  [FilterType.FOLLOWING, 'following'],
  [FilterType.SCOPE, 'scope'],
  [FilterType.MASTER_ACCOUNT, 'masterAccounts'],
  [FilterType.EMPLOYMENT_STATUS, 'employmentStatuses'],
  [FilterType.JOB_STATUS, 'jobStatuses'],
  [FilterType.WITHDRAWALS_SETTLED, 'withdrawalsSettled'],
  [FilterType.FROM_DATE, 'fromDate'],
  [FilterType.TO_DATE, 'toDate'],
  [FilterType.DATE_RANGE, 'dateRange'],
  [FilterType.INVOICE_NUMBER, 'invoiceNumber'],
  [FilterType.ENTITY_STATUS, 'masterAccountStatus'],
  [FilterType.EMAIL_TEMPLATE_TYPE, 'emailTemplateTypes'],
  [FilterType.COMMS_LOG_STATUS, 'commsLogStatuses'],
  [FilterType.CUSTOM_CONTENT_TYPE, 'customContentTypes'],
  [FilterType.TIMESHEET_STATUS, 'timesheetStatus'],
  [FilterType.INVOICE_STATUS, 'invoiceStatuses'],
  [FilterType.PAYMENT_REFERENCE, 'paymentReference'],
  [FilterType.PAYMENT_STATUS, 'paymentStatuses'],
  [FilterType.TIMESHEET_TYPES, 'timesheetTypes'],
  [FilterType.EMPLOYEE_USAGE_RATING, 'employeeUsageRating'],
  [FilterType.EMPLOYEE_VULNERABLE, 'employeeVulnerable'],
  [FilterType.EMAIL_EVENT, 'emailEvents'],
  [FilterType.BANK_TRANSACTION_TYPE, 'bankTransactionTypes'],
  [FilterType.PAYMENT_API_TYPE, 'paymentApiTypes'],
  [FilterType.WITHDRAWAL_TYPE, 'withdrawalTypes'],
  [FilterType.PAY_CODE_SEARCH, 'payCodeSearch']
]);

export interface FilterStatus {
  userSearch: string;
  employeeSearch: string;
  companySearch: string;
  notificationSearch: string;
  masterAccountNameSearch: string;
  apiKeySearch: string;
  bankTransactionSearch: string;
  companies: string[];
  payFrequencies: PayFrequency[];
  payTypes: PayType[];
  userRoles: AdminUserRole[];
  unapproved: boolean;
  masterAccounts: string[];
  employmentStatuses: EmploymentStatus[];
  jobStatuses: JobStatus[];
  withdrawalsSettled: WithdrawalsSettledFilter;
  fromDate: string;
  toDate: string;
  dateRange: NewDateRange;
  invoiceNumber: number;
  masterAccountStatus: EntityStatus[];
  emailTemplateTypes: EmailTemplateType[];
  commsLogStatuses: CommsLogStatus[];
  customContentTypes: ContentType[];
  sort?: Sort;
  pageSort?: {
    employees?: Sort
  };
  timesheetStatus: TimesheetStatus;
  invoiceStatuses: InvoiceStatus[];
  paymentReference: string;
  paymentStatuses: PaymentStatusEnum[];
  timesheetTypes: TimesheetType[];
  employeeUsageRating: EmployeeUsageRating[];
  employeeVulnerable: string[];
  emailEvents: EmailEventType[];
  bankTransactionTypes: BankTransactionType[],
  paymentApiTypes: PaymentApiType[],
  withdrawalTypes: WithdrawalType[],
  payCodeSearch: string;
}

const INITIAL_STATUS: FilterStatus = {
  userSearch: '',
  employeeSearch: '',
  companySearch: '',
  notificationSearch: '',
  masterAccountNameSearch: '',
  apiKeySearch: '',
  bankTransactionSearch: '',
  companies: [],
  payFrequencies: [],
  payTypes: [],
  userRoles: [],
  unapproved: false,
  masterAccounts: [],
  employmentStatuses: [
    EmploymentStatus.JOINING,
    EmploymentStatus.CURRENT
  ],
  jobStatuses: [],
  withdrawalsSettled: undefined,
  fromDate: undefined,
  toDate: undefined,
  invoiceNumber: undefined,
  masterAccountStatus: [EntityStatus.BLOCKED, EntityStatus.ACTIVE],
  emailTemplateTypes: [],
  commsLogStatuses: [],
  customContentTypes: [],
  dateRange: {
    dateRangeAmount: 1,
    dateRangeType: DateRangeType.MONTHS
  },
  timesheetStatus: {
    isApproved: null,
    isCancelled: null
  },
  invoiceStatuses: [],
  paymentReference: null,
  paymentStatuses: [],
  timesheetTypes: [],
  employeeUsageRating: [],
  employeeVulnerable: [],
  bankTransactionTypes: [],
  paymentApiTypes: [],
  emailEvents: [],
  withdrawalTypes: [],
  payCodeSearch: ''
};

const FILTER_STATUS_KEY = 'filter-status';

export interface FilterItem {
  value: string;
  description: string;
}

export interface Sort {
  name: string;
  direction: SortDirection;
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private filterStatus: Subject<FilterStatus>;
  filter$: Observable<FilterStatus>;
  companyFilterItems$: Subject<FilterItem[]>;
  companies: FilterItem[] = [];

  constructor(
    private companyService: CompanyService
  ) {
    this.filterStatus = new Subject<FilterStatus>();
    this.filter$ = this.filterStatus.asObservable();
    this.companyFilterItems$ = new Subject<FilterItem[]>();
  }

  setCompanyFilterItems(filterItems: FilterItem[]) {
    this.companies = filterItems;
    this.validateEnumeratedFilter(FilterType.COMPANY, filterItems);
    this.companyFilterItems$.next(filterItems);
  }

  setFilter(type: FilterType, value: any, options?: { emitEvent: boolean }) {
    const newFilter = this.filterInStorage;
    const key = filterStatusMap.get(type);

    if (!key) {
      throw new Error(`Invalid filter type: ${type}`);
    }

    if (type === FilterType.MASTER_ACCOUNT) {
      this.companyService.getCompaniesForMasterAccounts(value).then(
        (companiesForMasterAccounts: CompanyDto[]) => {

          this.setCompanyFilterItems(companiesForMasterAccounts.map(c => ({
            value: c._id,
            description: c.name
          })));
        });
    }

    newFilter[key] = value;
    this.filterInStorage = newFilter;

    if (options?.emitEvent !== false) {
      this.filterStatus.next(newFilter);
    }
  }

  get currentFilter(): FilterStatus {
    const filter = this.filterInStorage;

    if (!filter.dateRange) {
      filter.dateRange = this.getFilter(FilterType.DATE_RANGE);
      this.setFilter(FilterType.DATE_RANGE, filter.dateRange, {emitEvent: false});
    }

    if (!filter.timesheetStatus) {
      filter.timesheetStatus = {
        isApproved: false,
        isCancelled: false
      };
      this.setFilter(FilterType.TIMESHEET_STATUS, filter.timesheetStatus, {emitEvent: false});
    }

    return filter;
  }

  setPageSort(page: Pages, name: string, direction: SortDirection) {
    const newFilter = this.filterInStorage;

    let pageSort = newFilter.pageSort as any;
    if (!pageSort) {
      pageSort = {};
    }

    if (!pageSort[page]) {
      pageSort[page] = {};
    }

    pageSort[page] = {name, direction};
    newFilter.pageSort = pageSort;
    this.filterInStorage = newFilter;
    this.filterStatus.next(newFilter);
  }

  getPageSort(page: Pages): Sort | null {
    const newFilter = this.filterInStorage;
    if (!newFilter.pageSort) {
      return null;
    }
    if (!newFilter.pageSort[page]) {
      return null;
    }
    return newFilter.pageSort[page];
  }

  static getSortObj(sort: Sort) {
    const clause = {};
    clause[sort.name] = sort.direction;
    return clause;
  }

  private get filterInStorage(): FilterStatus {
    const local = localStorage.getItem(FILTER_STATUS_KEY);
    try {
      return local ? JSON.parse(local) : INITIAL_STATUS;
    } catch {
      return INITIAL_STATUS;
    }
  }

  private set filterInStorage(filterStatus: FilterStatus) {
    localStorage.setItem(FILTER_STATUS_KEY, JSON.stringify(filterStatus));
  }

  getFilter(type: FilterType) {
    const current = this.filterInStorage;
    const key = filterStatusMap.get(type);
    if (!key) {
      throw new Error(`Invalid filter type: ${type}`);
    }
    if (!current[key]) {
      return INITIAL_STATUS[key];
    }
    return current[key];
  }

  public validateEnumeratedFilter(filterType: FilterType, validFilterItems: FilterItem[]): string[] {
    const validFilterValues = validFilterItems.map(item => item.value);
    const existingFilterValues = this.getFilter(filterType) as string[] || [];
    const inValidExistingValues = existingFilterValues.filter(name => {
      return !validFilterValues.includes(name);
    });

    if (inValidExistingValues.length > 0) {
      const validExistingValues = existingFilterValues.filter(name => {
        return validFilterValues.includes(name);
      });
      this.setFilter(filterType, validExistingValues);
      return validExistingValues;
    }
    return existingFilterValues;
  }

  /*
    A generic function to check if a list of value, type pairs
    passes the filter. If the key in the current filter is a
    list, check if the value appears in the list. Otherwise, check
    if the value is equal to the key in current filter.
   */
  checkGeneralFilters(valuesToCheck: {
                        value: any,
                        type: FilterType
                      }[]
  ) {
    const currentFilter = this.currentFilter;
    for (const valueToCheck of valuesToCheck) {
      const key = filterStatusMap.get(valueToCheck.type);
      const filterValue = currentFilter[key];
      if (filterValue) {
        if (Array.isArray(filterValue)) {
          if (filterValue.length > 0 && !filterValue.includes(valueToCheck.value)) {
            return false;
          }
        } else if (valueToCheck.type === FilterType.PAYMENT_REFERENCE) {
          return valueToCheck.value && valueToCheck.value.includes(filterValue);
        } else {
          if (filterValue !== valueToCheck.value) {
            return false;
          }
        }
      }
    }
    return true;
  }
}

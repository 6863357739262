import { Component, forwardRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { ThemePalette } from '@angular/material/core';

export const ACCOUNT_NUMBER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AccountNumberInputComponent),
  multi: true
};

const ACCOUNT_NUMBER_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => AccountNumberInputComponent),
  multi: true
};

@Component({
  selector: 'lib-account-number-input',
  templateUrl: './account-number-input.component.html',
  styleUrls: ['./account-number-input.component.css'],
  providers: [ACCOUNT_NUMBER_VALUE_ACCESSOR, ACCOUNT_NUMBER_VALIDATOR]
})
export class AccountNumberInputComponent implements ControlValueAccessor, Validator, OnDestroy {

  // eslint-disable-next-line
  customPatterns = {0: {pattern: new RegExp('\[0-9*\]')}};
  accountNumberControl = new UntypedFormControl();
  onTouched: () => void;
  onChange: (val) => void;
  private accountNumberPattern = /([0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9])|(\*\*\*\*\*[0-9][0-9][0-9])/;
  private ngUnsubscribe$ = new Subject<void>();
  @Input() required = false;
  @Input() clearable = true;
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() color: ThemePalette = 'primary';
  @Output() clear = new EventEmitter<void>();
  @Input() placeholder = '00000000';

  constructor() {
    this.accountNumberControl.valueChanges
      .pipe(first())
      .subscribe(() => {
        const validators = [Validators.pattern(this.accountNumberPattern)];
        if (this.required) {
          validators.push(Validators.required);
        }
        this.accountNumberControl.setValidators(validators);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  validate() {
    return this.accountNumberControl.errors;
  }

  onBlur() {
    this.onTouched();
  }

  onInputBlur() {
    this.onTouched();
  }

  writeValue(v: any) {
    this.accountNumberControl.patchValue(v, {emitEvent: false});
    if (typeof v === 'string' && v.length === 8 && v.includes('*')) {
      this.accountNumberControl.disable({emitEvent: false});
    } else {
      this.accountNumberControl.enable({emitEvent: false});
    }
  }

  registerOnChange(fn: any) {
    this.accountNumberControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  clearInput() {
    this.accountNumberControl.setValue(undefined);
    this.accountNumberControl.enable();
    this.clear.emit();
  }
}

import { AbstractControl, ValidationErrors } from '@angular/forms';

// The Regular Expression of a postcode!!
const postcodeRegex = /^(GIR ?0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]([0-9ABEHMNPRV-Y])?)|[0-9][A-HJKPS-UW]) ?[0-9][ABD-HJLNP-UW-Z]{2})$/;

export function PostcodeFormatValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value) {
    const postcode = control.value
      .toString()
      .toUpperCase()
      .replace(/ /g, '');
    const valid = postcode.search(postcodeRegex) === 0;
    return valid ? null : {invalidPostcode: true};
  } else {
    return null;
  }
}

import { Injectable } from '@angular/core';
import { CreateAccountRequestDto } from 'common-ui/open-api';
import { ApiService } from 'common-ui/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class RequestAccountService {

  constructor(
    private apiService: ApiService
  ) {
  }

  async sendAccountRequest(accountRequest: CreateAccountRequestDto) {
    await this.apiService.post('/api/account-request', accountRequest);
  }
}

import { Component, Input, OnChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { WithdrawalDto } from 'common-ui/open-api';
import { WithdrawalService } from 'common-ui/services';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';

@Component({
  selector: 'lib-employee-withdrawals',
  templateUrl: './employee-withdrawals.component.html',
  styleUrls: ['./employee-withdrawals.component.css']
})
export class EmployeeWithdrawalsComponent implements OnChanges {
  @Input() employeeId: string;
  @Input() dateRange: JustDateRange;

  displayedColumns = ['withdrawalDate', 'withdrawalTime', 'amount', 'fee', 'status'];
  dataSource = new MatTableDataSource<WithdrawalDto>([]);
  isLoading = true;

  constructor(
    private withdrawalService: WithdrawalService
  ) {
  }

  async ngOnChanges() {
    await this.selectPeriod(this.dateRange);
  }

  async selectPeriod(dateRange: JustDateRange) {
    if (!dateRange || !this.employeeId) return;
    this.isLoading = true;
    this.dataSource.data = await this.withdrawalService.getEmployeeWithdrawalsFromDateToDate(
      this.employeeId,
      dateRange.fromDate,
      dateRange.toDate);
    this.isLoading = false;
  }
}

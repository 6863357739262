import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { PayrollDto, PayrollOpenApiService, PayrollScheduleDto } from 'common-ui/open-api';
import { firstValueFrom } from 'rxjs';
import { HyphenatedToRegularPipe } from 'common-ui/pipes/hyphenated-to-regular.pipe';

@Component({
  selector: 'lib-payroll-schedule',
  templateUrl: './payroll-schedule.component.html',
  styleUrls: ['./payroll-schedule.component.css']
})
export class PayrollScheduleComponent implements OnInit {

  isWorking = true;
  frequency: string;
  schedule: PayrollScheduleDto;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected payroll: PayrollDto,
    private payrollService: PayrollOpenApiService,
    private dialogRef: MatDialogRef<PayrollScheduleComponent>,
    private capsPipe: HyphenatedToRegularPipe,
  ) {
  }

  async ngOnInit() {
    this.schedule = await firstValueFrom(this.payrollService.getSchedule(this.payroll._id));
    this.frequency = this.capsPipe.transform(this.payroll.frequency);
    this.isWorking = false;
  }

  close() {
    this.dialogRef.close();
  }
}

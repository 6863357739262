<input type="file" class="file-input"
       (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload">

  <button mat-button
          color="primary"
          type="button"
          (click)="fileUpload.click()">
    <mat-icon>file_upload</mat-icon>
    Choose File
  </button>

  {{fileName || "No file selected."}}

</div>

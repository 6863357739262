<lib-employee-portal-page
  title="Withdrawal History"
  *ngIf="history">

  <div *ngIf="noWithdrawalsYet"
       class="no-withdrawals-yet">
    <div class="pound-image">
      <img src="{{ assetsUrl }}/pound_note.png">
    </div>
    <div class="message">
      You have not made any withdrawals yet.
    </div>
  </div>

  <div *ngIf="!noWithdrawalsYet" class="withdrawals-table">
    <ng-container *ngFor="let period of activeHistory">
      <div *ngIf="isMultiCompany">
        <div class="company-name">{{ period.companyName }}</div>
        <div class="mce-period">{{ getWithdrawalPeriodHeader(period) }}</div>
      </div>

      <div *ngIf="!isMultiCompany" class="period">{{ getWithdrawalPeriodHeader(period) }}</div>

      <mat-divider></mat-divider>
      <mat-list>
        <mat-list-item class="list-item"
                       *ngFor="let withdrawal of period.withdrawals">
          <div class="list-item-line">
            <div class="status-col" *ngIf="withdrawal.paymentStatus !== PaymentStatus.PENDING">
              <lib-tick-cross [value]="withdrawal.paymentStatus === PaymentStatus.SUCCESS"></lib-tick-cross>
            </div>
            <div class="status-col" *ngIf="withdrawal.paymentStatus === PaymentStatus.PENDING">
              <mat-spinner color="warn" class="pending-spinner" diameter="28"></mat-spinner>
            </div>
            <div class="timestamp">
              <div>{{ withdrawal.timestamp | date: "dd MMM yyyy" }} at {{ withdrawal.timestamp | date: "HH:mm" }}</div>
              <div class="subtext" *ngIf="withdrawal.paymentStatus === PaymentStatus.FAILED">{{ withdrawal.failureReason | truncate: 18 }}</div>
            </div>
            <div class="amounts">
              <div class="amount">{{ withdrawal.amount | pennyToPound: withdrawal.currency }}</div>
              <div *ngIf="!withdrawal.employerPaysFee" class="subtext">
                +{{ withdrawal.fee | pennyToPound: withdrawal.currency }}
              </div>
              <div *ngIf="withdrawal.employerPaysFee" class="subtext">No Fee</div>
            </div>
          </div>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </ng-container>
  </div>
</lib-employee-portal-page>


<lib-spinner-container [isWorking]="isWorking">
  <lib-dialog-container header="Import GC Payments">

    <div class="container" *ngIf="!isComplete">
      <lib-file-upload (fileSelected)="fileSelected($event)"></lib-file-upload>
      <div *ngIf="errorMessage" class="error">
        <lib-error-message [message]="errorMessage"></lib-error-message>
      </div>
    </div>

    <div class="container" *ngIf="isComplete && recBreaks.length === 0">
      {{ file.name }} loaded successfully.
    </div>

    <div class="container" *ngIf="isComplete && recBreaks.length > 0">
      <p>{{ file.name }} loaded with breaks.</p>
      <table class="breaks-table">
        <tr>
        <th>Reference</th>
        <th>Break Amount</th>
        </tr>
        <tr *ngFor="let recBreak of recBreaks">
          <td>{{ recBreak.reference}}</td>
          <td>{{ recBreak.amount | pennyToPound: recBreak.currency }}</td>
        </tr>
      </table>
    </div>

    <lib-button-panel>

      <button
        mat-stroked-button
        color="primary"
        (click)="cancel()"
        type="button">{{ isComplete ? 'Close' : 'Cancel' }}
      </button>

      <button mat-flat-button
              color="primary"
              type="button"
              [disabled]="!file || isComplete"
              (click)="importFile()">
        Upload
      </button>

    </lib-button-panel>

  </lib-dialog-container>
</lib-spinner-container>

import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { portalRoutes } from './portal-routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './main/main.component';
import { environment } from '../environments/environment';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { BrowserModule, Title } from '@angular/platform-browser';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatBadgeModule } from '@angular/material/badge';
import { FooterComponent } from './footer/footer.component';
import { DatePipe, NgOptimizedImage } from '@angular/common';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { AccountSettingsPageComponent } from './account-settings-page/account-settings-page.component';
import { CommonUiModule } from 'common-ui/common-ui.module';
import * as Sentry from '@sentry/angular';
import { RequestAccountContainerComponent } from './request-account-container/request-account-container.component';
import {
  IntegrationOauth2RedirectComponent
} from './integration-oauth2-redirect/integration-oauth2-redirect.component';
import { AccountRequestReceivedComponent } from './account-request-received/account-request-received.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ImpersonateUserComponent } from './impersonate-user/impersonate-user.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { LogoutTimerService } from 'common-ui/services/logout-timer.service';
import { ApiKeysDialogComponent } from './api-keys-dialog/api-keys-dialog.component';
import { EmployeePortalModule } from 'employee-portal/employee-portal.module';
import { AppLinkComponent } from './app-link/app-link.component';
import { CsvImportPageComponent } from './csv-import-page/csv-import-page.component';
import { DeleteAccountWithOtpComponent } from './delete-account-with-otp/delete-account-with-otp.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    FooterComponent,
    AccountSettingsPageComponent,
    IntegrationOauth2RedirectComponent,
    RequestAccountContainerComponent,
    AccountRequestReceivedComponent,
    ImpersonateUserComponent,
    ApiKeysDialogComponent,
    AppLinkComponent,
    CsvImportPageComponent,
    DeleteAccountWithOtpComponent
  ],
  bootstrap: [AppComponent],
  exports: [], imports: [MatStepperModule,
    MatBottomSheetModule,
    MatTooltipModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(portalRoutes),
    BrowserAnimationsModule,
    MatInputModule,
    MatGridListModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    MatMenuModule,
    MatBadgeModule,
    MatTabsModule,
    MatRadioModule,
    CommonUiModule.forRoot(environment),
    EmployeePortalModule.forRoot(environment),
    OverlayModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatCardModule,
    NgOptimizedImage], providers: [
    Title,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (logoutTimer: LogoutTimerService) => {
        return () => {
          logoutTimer.checkOnStartup();
        };
      },
      multi: true,
      deps: [LogoutTimerService, Sentry.TraceService]
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})


export class AppModule {
  // eslint-disable-next-line
  constructor(trace: Sentry.TraceService) {
  }
}

import { CsvInputError } from 'common-ui/open-api';
import { ServerImportError } from './csv-input-file';
import { TrafficLights } from 'common-ui/models/traffic-lights.model';

export class CsvInputCell {
  cellErrors: CsvInputError[] = [];
  serverErrors: ServerImportError[] = [];

  constructor(public value: string) {
  }

  get status(): TrafficLights {
    if (this.errors.length > 0) {
      return TrafficLights.STOP
    } else if (this.warnings.length > 0) {
      return TrafficLights.WARN
    } else {
      return TrafficLights.GO
    }
  }
  get hasClientError() {
    return this.cellErrors.length > 0;
  }

  addServerError(error: ServerImportError) {
    this.serverErrors.push(error);
  }

  clearServerErrors() {
    this.serverErrors = [];
  }

  clearErrors() {
    this.serverErrors = [];
    this.cellErrors = [];
  }

  get errors(): string[] {
    const cellErrors = this.cellErrors.map(e => e.toString());
    return cellErrors.concat(this.serverErrors.filter(e => !e.isWarning).map(e => e.message));
  }

  get warnings(): string[] {
    return this.serverErrors.filter(e => !!e.isWarning).map(e => e.message);
  }

}

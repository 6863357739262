<lib-dialog-container [header]="data.title" (exit)="close()" [showCross]=true>
  <form (ngSubmit)="saveString()" [formGroup]="form">
    <mat-form-field class="string-value">
      <input matInput  formControlName="stringValue" />
    </mat-form-field>

    <lib-button-panel>
      <button (click)="close()"
              mat-stroked-button
              color="primary"
              type="button">
        Cancel
      </button>
      <button type="submit"
              color="primary"
              [disabled]="form.invalid"
              mat-flat-button>
        {{ data.buttonText }}
      </button>
    </lib-button-panel>

  </form>
</lib-dialog-container>

import { Component, Inject, OnInit } from '@angular/core';
import { PayCodeDto, PayCodesOpenApiService, Currency, CreatePayCodeDto } from 'common-ui/open-api';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'common-ui/services/user.service';
import { Environment } from 'common-ui/models/environment.type';

export interface PayCodesEditData {
  payCode: PayCodeDto;
  isCreate: boolean;
}

@Component({
  selector: 'lib-pay-codes-edit',
  templateUrl: './pay-codes-edit.component.html',
  styleUrls: ['./pay-codes-edit.component.css']
})
export class PayCodesEditComponent implements OnInit {

  isWorking = false;
  errorMessage: string;
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<PayCodesEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PayCodesEditData,
    private fb: FormBuilder,
    private payCodesService: PayCodesOpenApiService,
    private userService: UserService,
    @Inject('env') private environment: Environment
  ) {
    this.form = this.fb.group({
      payCode: new FormControl('', Validators.required),
      rate: new FormControl('', Validators.required),
      companyId: new FormControl('', Validators.required)
    });

    if (this.isConsole) {
      this.form.addControl('masterAccountId', new FormControl('', Validators.required));
    }
  }

  get masterAccountId() {
    if (this.isConsole) {
      return this.form.controls['masterAccountId']?.value;
    } else {
      return this.userService.currentUser.masterAccountId;
    }
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit() {
    if (!this.data.isCreate) {
      if (this.isConsole) {
        this.form.controls.masterAccountId.disable();
      }
      ;
      this.form.controls.companyId.disable();
      this.form.controls.payCode.disable();

      const formValue: any = {
        payCode: this.data.payCode.payCode,
        rate: this.data.payCode.rate / 100,
        companyId: this.data.payCode.companyId
      };

      if (this.isConsole) {
        formValue.masterAccountId = this.data.payCode.masterAccountId;
      }

      this.form.setValue(formValue);

    } else {
      const formValue: any = {
        payCode: '',
        rate: '',
        companyId: ''
      };

      if (this.isConsole) {
        formValue.masterAccountId = '';
      }

      this.form.setValue(formValue);
    }
  }

  async save() {
    try {
      if (this.data.isCreate) {

        const createDto: CreatePayCodeDto = {
          masterAccountId: this.userService.currentUser.masterAccountId,
          companyId: this.form.value.companyId,
          currency: Currency.GBP,
          rate: this.form.value.rate * 100,
          payCode: this.form.value.payCode
        };

        if (this.isConsole) {
          createDto.masterAccountId = this.form.value.masterAccountId;
        }

        await firstValueFrom(this.payCodesService.createPayCode(createDto));
      } else {
        await firstValueFrom(this.payCodesService.updatePayCode(this.data.payCode._id, {
          rate: this.form.value.rate * 100
        }));
      }
      this.dialogRef.close(true);
    } catch (err) {
      this.errorMessage = err.message;
    }
  }
}

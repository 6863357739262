import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { FilterService, FilterType, FilterItem } from 'common-ui/services';

@Component({
  selector: 'lib-list-selector-component',
  templateUrl: './list-selector.component.html',
  styleUrls: ['./list-selector.component.css']
})
export class ListSelectorComponent implements OnInit, OnChanges {
  @Input() list: FilterItem[];
  @Input() filterType: FilterType;
  @Input() sortItems = false;
  @Input() singleSelect = false;
  @Input() label: string;

  formControl = new FormControl();
  allFilter = false;

  constructor(
    private filterService: FilterService
  ) {
  }

  ngOnChanges() {
    if (this.list && this.list.length > 0) {
      if (this.sortItems) {
        this.list = this.list.sort((a: FilterItem, b: FilterItem) => {
          return a.description > b.description ? 1 : -1;
        });
      }
      if (!this.singleSelect) {
        const validValues = this.filterService.validateEnumeratedFilter(this.filterType, this.list);
        const currentSelected = this.formControl.value
        if (currentSelected && !currentSelected.every ( v => validValues.includes(v))) {
          this.formControl.setValue(validValues);
        }
      }
    }
  }

  ngOnInit() {
    const filterValues = this.filterService.getFilter(this.filterType);
    this.formControl.setValue(filterValues);
  }

  emitSelectorValue(event: MatSelectChange) {
    if (this.singleSelect) {
      this.filterService.setFilter(this.filterType, event.value);
    } else {
      if (event.value.indexOf('all') > -1) {
        if (this.allFilter) {
          const remainingItems = event.value.filter(v => v !== 'all');
          this.formControl.setValue(remainingItems);
          this.filterService.setFilter(this.filterType, remainingItems);
          this.allFilter = false;
        } else {
          const allItems: string[] = [...this.list.map(i => i.value), 'all'];
          this.formControl.setValue(allItems);
          this.filterService.setFilter(this.filterType, []);
          this.allFilter = true;
        }
      } else {
        if (this.allFilter) {
          this.allFilter = false;
          this.formControl.setValue([]);
          this.filterService.setFilter(this.filterType, []);
        } else {
          const selectorValue: string[] = event.value;
          this.filterService.setFilter(this.filterType, selectorValue);
        }
      }
    }
  }
}

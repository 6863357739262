import { ValidationErrors, AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators {

  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): Record<string, any> => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl): ValidatorFn {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({noPasswordMatch: true});
    }
    return null;
  }

  static passwordValidator = [
    Validators.required,
    CustomValidators.patternValidator(/\d/, {hasNumber: true}),
    CustomValidators.patternValidator(/[A-Z]/, {hasUpperCase: true}),
    CustomValidators.patternValidator(/[a-z]/, {hasLowerCase: true}),
    CustomValidators.patternValidator(/[!@#$%^&*()_+-=[\]{};':"|,.<>/?]/, {hasSpecialCharacters: true}),
    Validators.minLength(8)
  ];
}

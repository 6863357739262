<lib-spinner-container [isWorking]="false">
  <lib-dialog-container
    (exit)="close()"
    [header]="data.employee.firstName + ' ' + data.employee.lastName + ' Withdrawals'"
    [showCross]="true">

    <lib-employee-withdrawals
      [employeeId]="data.employee._id"
      [dateRange]="dateRange">
    </lib-employee-withdrawals>

    <div class="period-selector">
      <lib-week-selector
        [dateRange]="dateRange"
        [isLoading]="isLoading"
        (emitDates)="selectPeriod($event)">
      </lib-week-selector>
    </div>

  </lib-dialog-container>
</lib-spinner-container>
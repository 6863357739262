/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PermissionEnum = 'adhoc-sys-admin' | 'create-sys-admin' | 'master-account-core-read' | 'master-account-create' | 'master-account-read' | 'master-account-delete' | 'master-account-customer-update' | 'master-account-restricted-update' | 'employee-create' | 'employee-read' | 'company-create' | 'company-read' | 'company-update' | 'company-restricted-update' | 'run-import' | 'batch-runner' | 'partner-read' | 'manual-payment' | 'update-withdrawal-status' | 'messenger-read' | 'messenger-update' | 'messenger-send' | 'direct-debit-import-export' | 'finance-exports' | 'manage-custom-content' | 'manage-pull-notifications' | 'manage-support-tickets' | 'user-read';

export const PermissionEnum = {
    ADHOC_SYS_ADMIN: 'adhoc-sys-admin' as PermissionEnum,
    CREATE_SYS_ADMIN: 'create-sys-admin' as PermissionEnum,
    MASTER_ACCOUNT_CORE_READ: 'master-account-core-read' as PermissionEnum,
    MASTER_ACCOUNT_CREATE: 'master-account-create' as PermissionEnum,
    MASTER_ACCOUNT_READ: 'master-account-read' as PermissionEnum,
    MASTER_ACCOUNT_DELETE: 'master-account-delete' as PermissionEnum,
    MASTER_ACCOUNT_CUSTOMER_UPDATE: 'master-account-customer-update' as PermissionEnum,
    MASTER_ACCOUNT_RESTRICTED_UPDATE: 'master-account-restricted-update' as PermissionEnum,
    EMPLOYEE_CREATE: 'employee-create' as PermissionEnum,
    EMPLOYEE_READ: 'employee-read' as PermissionEnum,
    COMPANY_CREATE: 'company-create' as PermissionEnum,
    COMPANY_READ: 'company-read' as PermissionEnum,
    COMPANY_UPDATE: 'company-update' as PermissionEnum,
    COMPANY_RESTRICTED_UPDATE: 'company-restricted-update' as PermissionEnum,
    RUN_IMPORT: 'run-import' as PermissionEnum,
    BATCH_RUNNER: 'batch-runner' as PermissionEnum,
    PARTNER_READ: 'partner-read' as PermissionEnum,
    MANUAL_PAYMENT: 'manual-payment' as PermissionEnum,
    UPDATE_WITHDRAWAL_STATUS: 'update-withdrawal-status' as PermissionEnum,
    MESSENGER_READ: 'messenger-read' as PermissionEnum,
    MESSENGER_UPDATE: 'messenger-update' as PermissionEnum,
    MESSENGER_SEND: 'messenger-send' as PermissionEnum,
    DIRECT_DEBIT_IMPORT_EXPORT: 'direct-debit-import-export' as PermissionEnum,
    FINANCE_EXPORTS: 'finance-exports' as PermissionEnum,
    MANAGE_CUSTOM_CONTENT: 'manage-custom-content' as PermissionEnum,
    MANAGE_PULL_NOTIFICATIONS: 'manage-pull-notifications' as PermissionEnum,
    MANAGE_SUPPORT_TICKETS: 'manage-support-tickets' as PermissionEnum,
    USER_READ: 'user-read' as PermissionEnum
};


import { Component } from '@angular/core';

@Component({
  selector: 'lib-button-panel',
  templateUrl: './button-panel.component.html',
  styleUrls: ['./button-panel.component.css']
})
export class ButtonPanelComponent {

  constructor() {
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-spinner-overlay',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.css']
})
export class SpinnerOverlayComponent {

  @Input() showSpinner = false;

  constructor() {
  }
}

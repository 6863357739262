import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import {
  CreateEmployeeDto,
  EmployeeDto,
  UpdateEmployeeDto,
  EmployeeQuery,
  EmployeeQueryDto,
  InviteEmployeesDto,
  UpdateEmployeeStatusDto,
  EmployeeAuditLogDto,
  EmployeeStatusType
} from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(
    private apiService: ApiService
  ) {
  }

  async queryEmployees(query?: EmployeeQuery): Promise<EmployeeQueryDto> {
    return await this.apiService.post('/api/employee/query', query);
  }

  async getEmployee(employeeId: string): Promise<EmployeeDto> {
    return await this.apiService.get(`/api/employee/${employeeId}`);
  }

  async updateStatus(employeeId: string, update: UpdateEmployeeStatusDto) {
    return await this.apiService.patch(`/api/employee/update-status/${employeeId}`, update);
  }

  async inviteEmployees(employeeInviteDto: InviteEmployeesDto) {
    return await this.apiService.post('/api/employee/invite', employeeInviteDto);
  }

  updateEmployee(update: UpdateEmployeeDto) {
    return this.apiService.patch('/api/employee', update);
  }

  deleteEmployee(employeeId: string) {
    return this.apiService.delete(`/api/employee/${employeeId}`);
  }

  async createEmployee(createEmployeeDto: CreateEmployeeDto) {
    await this.apiService.post(`/api/employee`, createEmployeeDto);
  }

  async getAuditLog(employeeId: string, type?: EmployeeStatusType): Promise<EmployeeAuditLogDto[]> {
    return await this.apiService.get(`/api/employee/audit-log/${employeeId}${type ? '?type=' + type : ''}`);
  }

  async getAmountAvailableBreakdown(employeeId: string) {
    return this.apiService.get(`/api/employee/${employeeId}/amount-available-breakdown`);
  }
}

import { PayType } from '../open-api';

export const formatPayType = (payType: PayType) => {
  switch (payType) {
    case PayType.SALARY:
      return 'Salary';
    case PayType.TIMESHEET:
      return 'Timesheet';
    case PayType.UNDEFINED:
      return "Undefined"

  }
};

import { Component } from '@angular/core';
import { FilterType } from 'common-ui/services';
import { EmploymentStatus } from 'common-ui/open-api';
import { HyphenatedToRegularPipe } from 'common-ui/pipes/hyphenated-to-regular.pipe';

@Component({
  selector: 'lib-employment-status-selector',
  templateUrl: './employment-status-selector.component.html',
  styleUrls: ['./employment-status-selector.component.css']
})
export class EmploymentStatusSelectorComponent {
  employmentStatus = Object.values(EmploymentStatus).map(value => ({
    value: value.toString(),
    description: new HyphenatedToRegularPipe().transform(value)
  }));
  FilterType = FilterType;
}

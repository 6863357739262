import { Component, OnInit, OnDestroy, forwardRef } from '@angular/core';
import { EmployeeDto } from 'common-ui/open-api';
import {
  FormControl,
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ValidationErrors
} from '@angular/forms';
import { EmployeeService } from 'common-ui/services';
import { Subject } from 'rxjs';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent
} from '@angular/material/legacy-autocomplete';
import { takeUntil, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'lib-employee-input',
  templateUrl: './employee-input.component.html',
  styleUrls: ['./employee-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmployeeInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EmployeeInputComponent),
    multi: true
  }]
})
export class EmployeeInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  employee: EmployeeDto;
  isLoading = true;
  filteredEmployees: EmployeeDto[];
  control = new FormControl();
  employees: EmployeeDto[];
  ngUnsubscribe$ = new Subject<void>();
  disableAutoComplete = false;

  displayWith(employee: EmployeeDto) {
    return employee ? employee.firstName + ' ' + employee.lastName : '';
  }

  constructor(
    private employeeService: EmployeeService
  ) {
  }

  // eslint-disable-next-line
  onChange = (_: any) => {
  };

  onTouched = () => {
  };

  async writeValue(employeeId: string) {
    if (employeeId) {
      this.disableAutoComplete = true;
      try {
        this.employee = await this.employeeService.getEmployee(employeeId);
        this.filteredEmployees = [this.employee];
        this.control.patchValue(this.employee, {
          emitEvent: false
        });
      } catch (err) {
        console.log(err);
        this.employee = null;
      }
      setTimeout(() => {
        this.disableAutoComplete = false;
      }, 500);
    }
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {

      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    return this.employee?._id === null ? {required: true} : null;
  }

  selectEmployee(event: MatAutocompleteSelectedEvent) {
    this.employee = event.option.value;
    this.onChange(event.option.value._id);
  }

  async ngOnInit() {
    this.control.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe$),
      debounceTime(700)
    ).subscribe(async employeeNameFilter => {
      if (typeof employeeNameFilter === 'string') {
        this.employee = null;
        this.onChange(null);
        const result = await this.employeeService.queryEmployees({
          employeeName: employeeNameFilter,
          limit: 20
        });
        this.filteredEmployees = result.data;
      }
    });
    this.isLoading = false;
  }

  async ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}

<div class="login-container">
  <div *ngIf="showSpinner" class="spinner uploader-status">
    <mat-spinner class="spinner-itself"></mat-spinner>
  </div>
  <div *ngIf="!showSpinner" class="login-dialog-container">
    <lib-login-dialog
      [errorMessage]="errorMessage"
      [redirectUrl]="redirectUrl">
    </lib-login-dialog>
  </div>
</div>

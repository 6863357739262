<lib-dialog-container
  [showCross]="true"
  (exit)="close()"
  header="Notification Preferences">

  <lib-notification-preferences-edit
    (operationComplete)="close()"
    [userId]="userId">
  </lib-notification-preferences-edit>

</lib-dialog-container>

<lib-dialog-container (exit)="close()"
                      [header]="header"
                      [subHeader]="subHeading"
                      width="450px">

  <div class="csv-run-import-container">
    <div *ngIf="!isLoading && importResults" class="import-dialog-content">
      <lib-csv-import-results [importResult]="importResults">
      </lib-csv-import-results>
    </div>

    <div *ngIf="!isLoading && errorMessage" class="import-dialog-content">
      <lib-error-message [message]="errorMessage"></lib-error-message>
    </div>

    <lib-button-panel *ngIf="!isLoading">
      <button mat-flat-button
              class="cancel-button"
              color="primary"
              (click)="close()">
        Close
      </button>
    </lib-button-panel>

    <div *ngIf="isLoading" class="import-dialog-content">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
</lib-dialog-container>

import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import {
  UpdateBankTransactionDto,
  BankTransactionDto,
  BankTransactionQuery,
  RecBreakDto,
  BankTransactionOpenApiService
} from 'common-ui/open-api';
import { FileDownloadService } from 'common-ui/services/file-download.service';

@Injectable({
  providedIn: 'root'
})
export class BankTransactionService {

  constructor(
    private apiService: ApiService,
    private bankTransactionOpenApiService: BankTransactionOpenApiService,
    private fileDownloadService: FileDownloadService
  ) {
  }

  async queryTransactions(
    query: BankTransactionQuery
  ) {
    return await this.apiService.post('/api/bank-transaction/query', query);
  }

  async getByReference(reference: string): Promise<BankTransactionDto> {
    return await this.apiService.get(`/api/bank-transaction/${reference}`);
  }

  async updateInvoiceMapping(id: string, updateBankTransactionDto: UpdateBankTransactionDto) {
    await this.apiService.patch(`/api/bank-transaction/${id}`, updateBankTransactionDto);
  }

  async importGcPaymentsExport(file: File): Promise<RecBreakDto[]> {
    return await this.apiService.postFile(file, 'gcPaymentsExport', '/api/bank-transaction/import-gc-payments-export');
  }

  async downloadInvoiceRec(id: string) {
    return await this.fileDownloadService.downloadFile(this.bankTransactionOpenApiService.exportReconciliation(id, 'response'));
  }

}

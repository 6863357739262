<div class="account-button-container">
  <button
    mat-icon-button
    data-e2e="account-menu-button"
    (click)="openPanel()"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin">
    <mat-icon>person</mat-icon>
  </button>

  <mat-icon
    *ngIf="impersonationService.userToImpersonate"
    class="impersonating-icon">
    person
  </mat-icon>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'">

  <div class="panel-container mat-elevation-z4 mat-typography">

    <div class="header">
      <h3 class="master-account-name" *ngIf="masterAccount">{{masterAccount.name | truncate: 25 }}</h3>
      <div class="email"> {{user.email}} </div>
    </div>

    <button mat-menu-item
            (click)="openNotificationDialog()">
      Notifications
    </button>

    <button mat-menu-item
            (click)="showAboutDialog()">
      About
    </button>

    <button mat-menu-item
            data-e2e="sign-out-button"
            (click)="logOut()">Sign Out
    </button>
  </div>

</ng-template>

import { Component, OnInit, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


const hoursToHHMM = (totalHours: number) => {
  const hours = Math.floor(totalHours);
  const minutes = Math.round((totalHours - hours) * 60);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

function HHMMToHours(timeString: string) {
  const [hoursStr, minutesStr] = timeString.split(':');
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr, 10) / 60;
  return hours + minutes;
}

@Component({
  selector: 'lib-hours-input',
  templateUrl: './hours-input.component.html',
  styleUrls: ['./hours-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => HoursInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => HoursInputComponent),
    multi: true
  }]
})
export class HoursInputComponent implements OnInit, OnDestroy {
  control: FormControl;
  ngUnsubscribe$ = new Subject();

  constructor() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  ngOnInit(): void {
    this.control = new FormControl('', [Validators.required]);

    this.control.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(time => {
        this.onChange(HHMMToHours(time));
      });
  }

  // eslint-disable-next-line
  onChange = (_: any) => {
  };

  onTouched = () => {
  };

  writeValue(hours: number): void {
    this.control.setValue(hoursToHHMM(hours));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    return this.control.errors;
  }
}

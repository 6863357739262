import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {
  CompanyDto,
  CreateEmployeeDto,
  EmployeeDto,
  PayrollDto,
  PayType,
  UpdateEmployeeDto,
  ApiSource,
  MasterAccountCoreDto,
  Currency
} from 'common-ui/open-api';
import {
  EmployeeService,
  PayrollService,
  CompanyService,
  MasterAccountService,
  SystemService
} from 'common-ui/services';
import { emailValidator, setFormEnabled, getCurrencySymbol } from 'common-ui/util';
import { Subject } from 'rxjs';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import { DATE_FNS_DATE_FORMAT } from 'common-ui/util/date-formats';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.css']
})
export class EmployeeEditComponent implements OnInit, OnDestroy {
  @Input() employee: EmployeeDto;
  @Input() masterAccountId: string;
  @Input() companyId: string;
  @Input() create: boolean;
  availableCompanies: CompanyDto[];
  @Output() operationComplete = new EventEmitter();
  @Output() operationCancelled = new EventEmitter();
  errorMessage: string;
  employeeForm: FormGroup;
  isLoading = false;
  availablePayrolls: PayrollDto[];
  PayType = PayType;
  payTypes = [PayType.SALARY, PayType.TIMESHEET];
  ApiSource = ApiSource;
  masterAccount: MasterAccountCoreDto;
  isPayAmountEnabled: boolean;
  isPayAmountLockDisabled: boolean;
  isPayAmountInputVisible: boolean;
  tomorrow: string;
  private ngSub = new Subject();
  useEmployeeAllowanceOverride: boolean;
  isAllowanceSourcedFromIntegration: boolean;
  currencySymbol = '';

  constructor(
    private employeeService: EmployeeService,
    private companyService: CompanyService,
    private payrollService: PayrollService,
    private formBuilder: FormBuilder,
    private masterAccountService: MasterAccountService,
    private systemService: SystemService
  ) {
    this.employeeForm = this.formBuilder.group({
      email: ['', [Validators.required, emailValidator]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      payAmount: ['', this.validatePayAmount.bind(this)], // Validators.min(1)],
      allowance: '',
      payrollId: ['', Validators.required],
      companyId: ['', Validators.required],
      payType: ['', Validators.required],
      payrollExternalId: '',
      leavingDate: '',
      startDate: ''
    }, {
      validators: [this.leavingDateAfterStartDateValidator]
    });
  }

  validatePayAmount(c: FormControl) {
    if (!c.value && this.employeeForm && this.employeeForm.get('payType').value === PayType.SALARY) {
      return {
        required: true
      };
    }
    return null;
  }

  leavingDateAfterStartDateValidator(group: FormGroup) {
    if (group.controls.leavingDate.value && group.controls.leavingDate.value !== '' &&
      group.controls.startDate.value && group.controls.startDate.value !== '' &&
      group.controls.leavingDate.value <= group.controls.startDate.value) {
      return {invalidDates: true};
    }
    return null;
  }

  readonly allowanceRangeError = `Allowance must be between 1% and 100%`;

  async ngOnInit() {
    this.isLoading = true;
    this.masterAccount = await this.masterAccountService.getCoreMasterAccount(this.masterAccountId);
    this.tomorrow = format(addDays(this.systemService.getSystemTime(), 1), DATE_FNS_DATE_FORMAT);

    this.employeeForm.controls.companyId.valueChanges
      .pipe(takeUntil(this.ngSub))
      .subscribe(async companyId => {
        if (companyId) {
          this.availablePayrolls = await this.payrollService.getPayrollsForCompany(companyId);
          if (this.availablePayrolls && this.availablePayrolls.length > 0) {
            this.employeeForm.patchValue({
              payrollId: this.availablePayrolls[0]._id
            }, {emitEvent: false});
            this.employeeForm.controls.payrollId.markAsDirty();
            this.currencySymbol = getCurrencySymbol(this.availablePayrolls[0].currency);
          } else {
            this.employeeForm.patchValue({payrollId: null});
            this.currencySymbol = getCurrencySymbol(Currency.GBP);
          }
        }
      });

    this.employeeForm.controls.payrollId.valueChanges
      .pipe(takeUntil(this.ngSub))
      .subscribe(payrollId => {
        const payroll = this.availablePayrolls.find(p => p._id === payrollId);
        this.currencySymbol = !payroll ? '£' : getCurrencySymbol(payroll.currency);
      });

    if (!this.create) {
      await this.getEmployeeData();
    }

    const sortCompaniesFn = (a: CompanyDto, b: CompanyDto) => {
      return a.name < b.name ? -1 : 1;
    };

    if (this.companyId) {
      this.availableCompanies = [await this.companyService.getCompany(this.companyId)];
    } else {
      this.availableCompanies = (await this.companyService
        .getCompaniesForMasterAccount(this.masterAccountId))
        .sort(sortCompaniesFn);
    }

    if (this.create) {
      this.loadDefaults();
    }

    this.employeeForm.controls.payType.valueChanges.subscribe(() => this.payTypeChanged());
    this.isLoading = false;
  }

  private updateAllowanceFieldState(
    override: boolean,
    sourcedFromIntegration: boolean
  ) {
    if (override) {
      this.employeeForm.controls['allowance'].enable();
      this.employeeForm.controls['allowance'].setValidators([
        Validators.required,
        Validators.min(1),
        Validators.max(100)
      ]);
    } else if (sourcedFromIntegration) {
      this.employeeForm.controls['allowance'].disable();
      this.employeeForm.controls['allowance'].clearValidators();

    } else {

      this.employeeForm.patchValue({
        allowance: this.masterAccount.defaultAllowance * 100
      });
      this.employeeForm.controls['allowance'].disable();
      this.employeeForm.controls['allowance'].clearValidators();
    }
    this.employeeForm.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.ngSub.next('');
    this.ngSub.complete();
  }

  loadDefaults() {
    this.employee = {} as EmployeeDto;
    let companyId: string;
    if (this.availableCompanies && this.availableCompanies.length > 0) {
      const company = this.availableCompanies.find(c => c._id === this.companyId);
      if (company) {
        companyId = this.companyId;
      } else {
        companyId = this.availableCompanies[0]._id;
      }
    }

    this.useEmployeeAllowanceOverride = false;
    this.isAllowanceSourcedFromIntegration = false;

    this.employeeForm.patchValue({
      allowance: this.masterAccount.defaultAllowance * 100,
      companyId,
      payType: PayType.SALARY
    });
    this.updateAllowanceFieldState(false, false);
    this.isPayAmountEnabled = true;
    this.payTypeChanged();
  }

  async getEmployeeData() {
    this.availablePayrolls = await this.payrollService.getPayrollsForCompany(this.employee.companyId);
    this.currencySymbol = getCurrencySymbol(this.employee.currency);
    if (!this.create) {
      let enabledFields = this.employee.enabledProperties;
      if (this.employee.unlockedFields) {
        enabledFields = enabledFields.concat(this.employee.unlockedFields);
      }
      this.isPayAmountInputVisible = this.employee.payType === PayType.SALARY;
      this.isPayAmountEnabled = enabledFields.includes('payAmount');
      this.isPayAmountLockDisabled = this.employee.payType !== PayType.SALARY;
      this.setPayAmountEnabled(this.isPayAmountEnabled);
      setFormEnabled(this.employeeForm, enabledFields.filter(e => e !== 'payAmount'));
    }

    this.useEmployeeAllowanceOverride = this.employee.useEmployeeAllowanceOverride ?? false;
    this.isAllowanceSourcedFromIntegration = this.employee.isAllowanceSourcedFromIntegration ?? false;

    this.employeeForm.patchValue({
      email: this.employee.email,
      firstName: this.employee.firstName,
      lastName: this.employee.lastName,
      payAmount: this.employee.payAmount / 100,
      payType: this.employee.payType,
      payrollId: this.employee.payrollId,
      companyId: this.employee.companyId,
      payrollExternalId: this.employee.payrollExternalId,
      leavingDate: this.employee.leavingDate ? this.employee.leavingDate : null,
      startDate: this.employee.startDate ? this.employee.startDate : null,
      allowance: this.employee.allowance * 100
    }, {emitEvent: false});

    this.updateAllowanceFieldState(this.useEmployeeAllowanceOverride, this.isAllowanceSourcedFromIntegration);
    this.payTypeChanged();
    this.employeeForm.markAsPristine();
  }

  setPayAmountEnabled(enabled: boolean) {
    if (enabled) {
      this.employeeForm.get('payAmount').enable();
    } else {
      this.employeeForm.get('payAmount').disable();
    }
  }

  getFormValues(cg: FormGroup) {
    const data: any = {
      useEmployeeAllowanceOverride: this.useEmployeeAllowanceOverride,
      allowance: this.useEmployeeAllowanceOverride ? this.employeeForm.get('allowance').value / 100 : this.masterAccount.defaultAllowance
    };

    const unlockedFields: string[] = [];
    Object.keys(cg.controls).forEach((fieldName) => {
      const currentControl = cg.controls[fieldName];
      if (currentControl.enabled && fieldName !== 'allowance') {

        unlockedFields.push(fieldName);

        if (fieldName === 'payAmount') {
          if (this.employeeForm.get('payType').value === PayType.SALARY) {
            data[fieldName] = Math.floor(currentControl.value * 100);
          }
        } else if (fieldName === 'leavingDate' || fieldName === 'startDate') {
          if (this.create) {
            data[fieldName] = currentControl.value;
          } else {
            const formLeavingDateIsNull = currentControl.value === '' || currentControl.value === null;
            const originalLeavingDateIsNull = !this.employee[fieldName] || this.employee[fieldName] === '';
            if (formLeavingDateIsNull && originalLeavingDateIsNull) {
              // do nothing
            } else if (currentControl.value === this.employee[fieldName]) {
              // do nothing
            } else {
              data[fieldName] = currentControl.value;
            }
          }
        } else {
          data[fieldName] = currentControl.value;
        }
      }
    });

    if (this.employee?.timesheetIntegrationId) {
      data['unlockedFields'] = unlockedFields;
    }
    return data;
  }

  getCreateDto(): CreateEmployeeDto {
    return this.getFormValues(this.employeeForm) as CreateEmployeeDto;
  }

  async onSubmit() {
    try {
      this.isLoading = true;

      if (this.create) {
        await this.employeeService.createEmployee(this.getCreateDto());
      } else {

        const employeeUpdateDto: UpdateEmployeeDto = {
          employeeId: this.employee._id,
          ...this.getFormValues(this.employeeForm)
        };

        if (Object.keys(employeeUpdateDto).length > 1) {
          await this.employeeService.updateEmployee(employeeUpdateDto);
        } else {
          this.operationCancelled.emit();
          return;
        }
      }
      this.operationComplete.emit();

    } catch (err) {
      this.isLoading = false;
      this.errorMessage = err.message;
    }
  }

  cancelled() {
    this.operationCancelled.emit();
  }

  toggleAllowance() {
    this.useEmployeeAllowanceOverride = !this.useEmployeeAllowanceOverride;
    this.updateAllowanceFieldState(this.useEmployeeAllowanceOverride, this.isAllowanceSourcedFromIntegration);
  }

  toggleEnabled(formControlName: string) {
    if (this.employeeForm.get(formControlName).enabled) {
      const patch = {};
      patch[formControlName] = this.employee[formControlName];
      this.employeeForm.patchValue(patch);
      this.employeeForm.get(formControlName).disable();
      this.employeeForm.get(formControlName).markAsPristine();
      if (formControlName === 'companyId') {
        this.employeeForm.patchValue({payrollId: this.employee.payrollId});
        this.employeeForm.get('payrollId').disable();
        this.employeeForm.get(formControlName).markAsPristine();
      }
    } else {
      this.employeeForm.get(formControlName).enable();
      this.employeeForm.get(formControlName).markAsDirty();
      if (formControlName === 'payType' && !this.isPayAmountEnabled) {
        this.togglePayAmountEnabled();
      }
      if (formControlName === 'companyId') {
        this.employeeForm.get('payrollId').enable();
      }
    }
  }

  togglePayAmountEnabled() {
    if (this.isPayAmountEnabled) {
      const patch = {};
      patch['payAmount'] = this.employee.payAmount / 100;
      this.employeeForm.patchValue(patch);
      this.employeeForm.get('payAmount').disable();
      this.employeeForm.get('payAmount').markAsPristine();
      this.isPayAmountEnabled = false;
    } else {
      this.isPayAmountEnabled = true;
      this.employeeForm.get('payAmount').enable();
      this.employeeForm.get('payAmount').markAsDirty();
    }
  }

  payTypeChanged() {
    this.isPayAmountInputVisible = this.employeeForm.get('payType').value === PayType.SALARY;
    this.isPayAmountLockDisabled = this.employeeForm.get('payType').value !== PayType.SALARY;
    this.setPayAmountEnabled(this.isPayAmountEnabled);
    if (!this.isPayAmountInputVisible) {
      this.employeeForm.get('payAmount').patchValue(10000);
    }
  }

  isFieldDirty(fieldName: string) {
    const currentControl = this.employeeForm.get(fieldName);
    if (this.create) {
      return currentControl.dirty;
    } else if (fieldName === 'startDate' || fieldName === 'leavingDate') {
      const formLeavingDateIsNull = currentControl.value === '' || currentControl.value === null;
      const originalLeavingDateIsNull = !this.employee[fieldName] || this.employee[fieldName] === '';
      if (formLeavingDateIsNull && originalLeavingDateIsNull) {
        return false;
      } else if (currentControl.value === this.employee[fieldName]) {
        return false;
      } else {
        return true;
      }
    } else {
      return currentControl.dirty;
    }
  }
}

import { Component } from '@angular/core';
import { ContactSupportService } from '../services/contact-support.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'lib-contact-support-dialog',
  templateUrl: './contact-support-dialog.component.html',
  styleUrls: ['./contact-support-dialog.component.css']
})
export class ContactSupportDialogComponent {

  newTicket = true;
  isWorking = false;
  form: UntypedFormGroup;
  errorMessage: string;

  constructor(
    private contactSupportService: ContactSupportService,
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ContactSupportDialogComponent>
  ) {
    this.form = fb.group({
      subject: ['', [Validators.required]],
      problem: ['', [Validators.required]]
    });
  }

  async sendNewTicket() {
    this.isWorking = true;
    this.errorMessage = '';
    try {
      await this.contactSupportService.create({
        subject: this.form.value.subject,
        description: this.form.value.problem
      });
      this.isWorking = false;
      this.newTicket = false;
    } catch (err) {
      this.isWorking = false;
      this.errorMessage = err.message;
    }
  }
}

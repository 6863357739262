<button
  class="date-range-button"
  mat-stroked-button
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="menu">
  <div class="date-selector-button-title">
    {{dateRangeHeading}}
    <div class="mat-select-arrow ng-tns-c154-10"></div>
  </div>
</button>

<mat-menu
  #menu="matMenu"
  xPosition="before"
  (closed)="updateFilterService()">

  <form [formGroup]="form"
        (click)="$event.stopPropagation()"
        (ngSubmit)="closeMenu()">

    <div class="range-container">

      <mat-radio-group class="date-range-radio"
                       formControlName="dateRangeType">
        <mat-radio-button value="days">Day{{pluralSuffix}}</mat-radio-button>
        <mat-radio-button value="weeks">Week{{pluralSuffix}}</mat-radio-button>
        <mat-radio-button value="months">Month{{pluralSuffix}}</mat-radio-button>
        <mat-radio-button value="custom">Custom</mat-radio-button>
      </mat-radio-group>

      <mat-form-field *ngIf="form.controls.dateRangeType.value !== DateRangeType.CUSTOM">
        <input formControlName="dateRangeAmount"
               placeholder="Number of {{ form.controls.dateRangeType.value }}"
               data-lpignore="true"
               matInput />
      </mat-form-field>

      <lib-datepicker
        *ngIf="form.controls.dateRangeType.value === DateRangeType.CUSTOM"
        formControlName="fromDate"
        label="From Date"
        [required]="true">
      </lib-datepicker>

      <lib-datepicker
        *ngIf="form.controls.dateRangeType.value === DateRangeType.CUSTOM"
        formControlName="toDate"
        label="To Date"
        [required]="true">
      </lib-datepicker>

      <div class="apply-button-container">
        <button
          mat-stroked-button
          color="primary"
          [disabled]="!form.valid"
          (click)="closeMenu()">
          Apply
        </button>
      </div>
    </div>

  </form>
</mat-menu>

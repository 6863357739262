import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'common-ui/services';
import { ProfileDto, UserDto, AdminUserRole } from 'common-ui/open-api';
import { CustomerService } from 'common-ui/services/customer.service';
import { Router } from '@angular/router';
import { AboutDialogComponent } from 'common-ui/about-dialog/about-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserService } from 'common-ui/services/user.service';
import { ContactSupportDialogComponent } from 'common-ui/contact-support-dialog/contact-support-dialog.component';
import { MediaMatcher } from '@angular/cdk/layout';
import {
  NotificationPreferencesDialogComponent
} from 'common-ui/notification-preferences-dialog/notification-preferences-dialog.component';

@Component({
  selector: 'lib-employee-portal',
  templateUrl: './employee-portal.component.html',
  styleUrls: ['./employee-portal.component.css']
})
export class EmployeePortalComponent implements OnDestroy, OnInit {

  private readonly mobileQueryListener: () => void;
  isMobileMediaQuery: MediaQueryList;
  sideNavOpenMediaQuery: MediaQueryList;
  profiles: ProfileDto[];
  user: UserDto;

  constructor(
    private authService: AuthService,
    private customerService: CustomerService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.profiles = [{
      firstName: 'Loading...',
      lastName: ''
    } as ProfileDto];

    this.isMobileMediaQuery = media.matchMedia('(max-width: 750px)');
    this.sideNavOpenMediaQuery = media.matchMedia('(min-width: 1024px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.isMobileMediaQuery.addEventListener('change', this.mobileQueryListener);
    this.sideNavOpenMediaQuery.addEventListener('change', this.mobileQueryListener);
  }

  async ngOnInit() {
    this.user = await this.userService.getCurrentUser();
    this.profiles = await this.customerService.getProfiles();
  }

  ngOnDestroy() {
    this.isMobileMediaQuery.removeEventListener('change', this.mobileQueryListener);
    this.sideNavOpenMediaQuery.removeEventListener('change', this.mobileQueryListener);
  }

  async logOut() {
    await this.authService.logout();
  }

  openContactSupportDialog() {
    this.dialog.open(ContactSupportDialogComponent);
  }

  openNotificationPreferencesDialog() {
    this.dialog.open(NotificationPreferencesDialogComponent, {
      width: '450px',
      data: this.userService.currentUser._id
    });
  }

  openAboutDialog() {
    this.dialog.open(AboutDialogComponent);
  }

  get profile() {
    return this.profiles ? this.profiles[0] : null;
  }

  get showEmployerPortalLink() {
    return this.user?.role && (this.user.role === AdminUserRole.COMPANY_ADMIN || AdminUserRole.MASTER_ACCOUNT_ADMIN);
  }
}



<div class="dates-form-container">
  <lib-spinner-container
    class="mat-typography"
    [isWorking]="isWorking">

    <lib-dialog-container
      (exit)="close()"
      header="Period Dates Override"
      subHeader="Dates will overridden for the current period only, and will revert automatically">

      <div class="message">
        Use the Skip Next Period End, if you want to bring the period forward and ignore the mini-period.
      </div>

      <form [formGroup]="form"
            class="dates-form"
            (ngSubmit)="setPayrollDates()">

        <mat-checkbox formControlName="isOverrideActive">
          Override Active
        </mat-checkbox>

        <div class="dates-container">

          <lib-datepicker formControlName="periodStart"
                          *ngIf="form.contains('periodStart')"
                          label="Period Start"
                          [required]="true">
          </lib-datepicker>

          <lib-datepicker formControlName="deductionsExportDate"
                          *ngIf="form.contains('deductionsExportDate')"
                          label="Deductions Export"
                          [required]="true">
          </lib-datepicker>

          <lib-datepicker formControlName="payDate"
                          *ngIf="form.contains('payDate')"
                          label="Pay Date"
                          [required]="true">
          </lib-datepicker>

          <lib-datepicker formControlName="periodEnd"
                          *ngIf="form.contains('periodEnd')"
                          label="Period End"
                          [required]="true">
          </lib-datepicker>

        </div>

        <mat-checkbox
          *ngIf="form.controls['isOverrideActive'].value"
          formControlName="skipNextPeriodEnd">
          Skip Next Period End
        </mat-checkbox>

        <div *ngIf="payroll && schedule" class="schedule-table">
          <p>These settings will result in the following two periods;</p>
          <lib-payroll-schedule-table
            class="schedule-table"
            [payroll]="payroll" [schedule]="schedule" [periodsToShow]="2">
          </lib-payroll-schedule-table>
        </div>

        <lib-error-message [message]="errorMessage"></lib-error-message>

        <lib-button-panel>
          <button mat-stroked-button
                  type="button"
                  (click)="close()">
            Close
          </button>

          <button color="primary"
                  type="submit"
                  mat-flat-button
                  [disabled]="!form.valid || form.pristine">
            Save
          </button>
        </lib-button-panel>
      </form>


    </lib-dialog-container>
  </lib-spinner-container>
</div>

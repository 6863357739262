<lib-dialog-container [showCross]="true"
                      (exit)="dialogRef.close()">
  <lib-spinner-container [isWorking]="isWorking">

    <div *ngIf="newTicket">

      <form [formGroup]="form"
            (ngSubmit)="sendNewTicket()"
            class="container">

        <lib-dialog-header
          [subHeader]="'Create a ticket and FlexEarn Customer Service will contact you shortly.'">
          Contact Support
        </lib-dialog-header>

        <mat-form-field>
          <mat-label>Subject</mat-label>
          <input matInput
               appFirstFocus
                 formControlName="subject"
                 placeholder="Subject">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Problem</mat-label>
          <textarea matInput
                    formControlName="problem"
                    class="textarea-input"
                    placeholder="Enter your problem"></textarea>
        </mat-form-field>

        <lib-error-message [message]="errorMessage"></lib-error-message>

        <lib-button-panel>
          <button
            mat-stroked-button
            type="button"
            (click)="dialogRef.close()"
            color="primary">
            Close
          </button>

          <button
            type="submit"
            color="primary"
            [disabled]="form.invalid"
            mat-flat-button>Send
          </button>
        </lib-button-panel>
      </form>
    </div>

    <div class="sent-container" *ngIf="!newTicket">
      <lib-dialog-header [subHeader]="'Customer Services will contact you shortly.'">
        Message Sent
      </lib-dialog-header>

      <div class="dialog-body">
        <button mat-flat-button
                color="primary"
                (click)="dialogRef.close()">
          Close
        </button>
      </div>

    </div>

  </lib-spinner-container>
</lib-dialog-container>

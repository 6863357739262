import { Injectable, Inject } from '@angular/core';
import { fromEvent, merge, Subscription, interval, Subject } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root'
})
export class LogoutTimerService {

  events$: Subscription;
  interval$: Subscription;
  logout$ = new Subject<void>();

  timeoutKey = 'autoLogoutTimeout';
  timerLengthKey = 'autoLogoutTimerLength';
  timerIntervalKey = 'autoLogoutTimerInterval';
  bailOnStartup = false;

  constructor(
    @Inject('env') private environment: any,
    private dialog: MatDialog
  ) {
  }

  set timeout(value: number) {
    if (!value) {
      localStorage.removeItem(this.timeoutKey);
    } else {
      localStorage.setItem(this.timeoutKey, value.toString());
    }
  }

  get timeout(): number {
    return parseInt(localStorage.getItem(this.timeoutKey), 10);
  }

  get timerInterval(): number {
    const storageValue = localStorage.getItem(this.timerIntervalKey);
    if (storageValue) {
      return parseInt(storageValue, 10);
    } else {
      return this.environment.logoutTimerInterval;
    }
  }

  get timerLength(): number {
    const storageValue = localStorage.getItem(this.timerLengthKey);
    if (storageValue) {
      return parseInt(storageValue, 10);
    } else {
      return this.environment.logoutTimer;
    }
  }

  init() {
    if (this.timerLength) {
      this.setTimeout();

      this.events$ = merge(
        fromEvent(window, 'mousemove'),
        fromEvent(document, 'click'),
        fromEvent(document, 'keypress')
      ).subscribe(() => {
        this.setTimeout();
      });

      this.interval$ = interval(this.timerInterval * 1000).subscribe(() => {
        if (this.checkTime()) {
          this.logout();
        }
      });
    }
  }

  checkOnStartup() {
    if (this.timerLength && this.checkTime()) {
      this.bailOnStartup = true;
    }
  }

  clearBailOnStartup() {
    this.bailOnStartup = false;
  }

  /*
    If the page has been closed for more than 10 minutes, the user should be logged out. This tells
    the authentication guard that it needs to reject the user and set an error before logging in.

    This is controlled by the bailOnStartup flag, which is checked when the app is initialised.

    Only does this the first time, after that the user is allowed to log in so we reset the flag
    so the user can proceed as normal after logging in.
   */
  allowLogin() {
    if (this.bailOnStartup) {
      this.bailOnStartup = false;
      this.timeout = undefined;
      return false;
    } else {
      return true;
    }
  }

  checkTime() {
    return this.timeout && this.timeout < new Date().getTime();
  }

  setTimeout() {
    this.timeout = new Date().getTime() + this.timerLength * 1000;
  }

  logout() {
    this.dialog.closeAll();
    this.logout$.next();
    this.cleanup();
  }

  cleanup() {
    this.timeout = undefined;
    this.events$?.unsubscribe();
    this.interval$?.unsubscribe();
  }


}

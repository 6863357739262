import { DataSource } from '@angular/cdk/collections';
import { TimesheetTableRow } from 'common-ui/timesheets-table-page/timesheet-table-row';
import { BehaviorSubject, Observable } from 'rxjs';
import { TimesheetService } from 'common-ui/services';
import { TimesheetQuery } from 'common-ui/open-api';

export class AggregatedTimesheetDataSource extends DataSource<TimesheetTableRow> {

  private dataSubject = new BehaviorSubject<TimesheetTableRow[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  public total$ = new BehaviorSubject<number>(0);

  constructor(
    private timesheetService: TimesheetService
  ) {
    super();
  }

  get currentValue() {
    return this.dataSubject.value;
  }

  getRow(employeeId: string) {
    return this.currentValue.find(tr => tr.employeeId === employeeId);
  }

  connect(): Observable<TimesheetTableRow[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.total$.complete();
  }

  async load(query: TimesheetQuery) {
    this.loadingSubject.next(true);
    const result = await this.timesheetService.queryAggregatedTimesheets(query);
    this.total$.next(result.total);
    const table = TimesheetTableRow.createFromEmployeeTimesheets(result.data, query.fromDate);
    this.dataSubject.next(table);
    this.loadingSubject.next(false);
  }
}

import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ToolbarDataService, FilterType, FilterService } from 'common-ui/services';
import { SortDirection, PayCodeDto, PayCodesOpenApiService, PayCodeQuery } from 'common-ui/open-api';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Subject, firstValueFrom } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Pages } from 'common-ui/services/filter.service';
import { Environment } from 'common-ui/models/environment.type';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AreYouSureDialogComponent } from 'common-ui/are-you-sure-dialog/are-you-sure-dialog.component';
import { JsonDialogComponent } from 'common-ui/json-dialog/json-dialog.component';
import { PayCodeDataSource } from 'common-ui/pay-code-page/pay-code-data-source';
import { PayCodesEditComponent } from 'common-ui/pay-codes-edit/pay-codes-edit.component';


@Component({
  selector: 'lib-pay-codes-page',
  templateUrl: './pay-code-page.component.html',
  styleUrls: ['./pay-code-page.component.css']
})
export class PayCodePageComponent implements OnInit, AfterViewInit, OnDestroy {

  public masterAccountIds: string[];
  @Output() switchView = new EventEmitter<string>();
  public dataSource: PayCodeDataSource;
  public FilterType = FilterType;
  public displayedColumns = [
    'masterAccountName',
    'companyName',
    'payCode',
    'rate',
    'actions'
  ];

  private ngUnsubscribe = new Subject();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private toolbarDataService: ToolbarDataService,
    private filterService: FilterService,
    private payCodeService: PayCodesOpenApiService,
    @Inject('env') private environment: Environment,
    private dialog: MatDialog
  ) {
    this.dataSource = new PayCodeDataSource(this.payCodeService);
    if (!this.environment.adminConsole) {
      this.displayedColumns = this.displayedColumns.filter(
        column => column !== 'masterAccountName');
    }
  }

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle', title: 'Pay Codes'
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => await this.loadData());

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      await this.loadData();
      this.paginator.firstPage();
    });

    const initialSort = this.filterService.getPageSort(Pages.PAY_CODES);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.PAY_CODES, newSort.active, newSort.direction as SortDirection);
      });

    await this.loadData();
  }

  async loadData() {
    const currentFilter = this.filterService.currentFilter;

    const query: PayCodeQuery = {
      offset: this.paginator.pageIndex * this.environment.defaultPageSize,
      limit: this.environment.defaultPageSize,
      payCode: currentFilter.payCodeSearch,
      masterAccountIds: currentFilter.masterAccounts,
      companyIds: currentFilter.companies
    };

    const sort = this.filterService.getPageSort(Pages.PAY_CODES);
    if (sort && this.sort.sortables.has(sort.name)) {
      const sortClause = {};
      sortClause[sort.name] = sort.direction;
      query.sort = sortClause;
    }

    await this.dataSource.load(query);
  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  openJsonDialog(payCodeDto: PayCodeDto) {
    this.dialog.open(JsonDialogComponent, {
      data: {
        header: 'Pay Code',
        subHeader: payCodeDto._id,
        data: payCodeDto
      }
    });
  }

  openDeleteDialog(payCodeDto: PayCodeDto) {
    const ref = this.dialog.open(AreYouSureDialogComponent, {
      data: {message: `Are you sure you want to delete Pay Code ${payCodeDto.payCode}?`}
    });
    ref.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async () => {
        await firstValueFrom(this.payCodeService.deletePayCode(payCodeDto._id));
        await this.loadData();
      });
  }

  openEditDialog(payCodeDto: PayCodeDto) {
    const ref = this.dialog.open(PayCodesEditComponent, {
      data: {
        payCode: payCodeDto,
        isCreate: false
      }
    });
    ref.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async () => {
        await this.loadData();
      });

  }

  openCreateDialog() {
    const ref = this.dialog.open(PayCodesEditComponent, {
      data: {
        isCreate: true
      }
    });
    ref.afterClosed()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async () => {
        await this.loadData();
      });
  }
}

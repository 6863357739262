<lib-page-container>
  <lib-spinner-container [isWorking]="isLoading">

    <lib-filter-bar header>
      <div control-options>
        <button mat-button
                [disabled]="isLoading"
                (click)="downloadDetailReport()">
          Download Full Report
        </button>
      </div>

      <lib-week-or-month-selector
        (dateGroupByTypeChange)="dateGroupByChanged($event)">
      </lib-week-or-month-selector>

    </lib-filter-bar>

    <table [dataSource]="dataSource"
           mat-table
           matSort>

      <ng-container matColumnDef="year">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Year</th>
        <td *matCellDef="let element" mat-cell>{{ element.year }}</td>
      </ng-container>

      <ng-container matColumnDef="weekOrMonth">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ dateGroupByType | titlecase  }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.weekOrMonth }}</td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Period From</th>
        <td *matCellDef="let element" mat-cell>{{ element.startDate | date: "dd MMM yyyy" }}</td>
      </ng-container>

      <ng-container matColumnDef="employeeCount">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Total Employees</th>
        <td *matCellDef="let element" mat-cell>{{ element.employeeCount }}</td>
      </ng-container>

      <ng-container matColumnDef="activeEmployees">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Active Employees</th>
        <td *matCellDef="let element" mat-cell>{{ element.activeEmployeeCount }}</td>
      </ng-container>

      <ng-container matColumnDef="signUps">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Sign Ups</th>
        <td *matCellDef="let element" mat-cell>{{ element.signUpCount }}</td>
      </ng-container>

      <ng-container matColumnDef="percentActiveEmployees">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>% Active</th>
        <td *matCellDef="let element" mat-cell>{{ element.percentActiveEmployees | percent: '1.2-2' }}</td>
      </ng-container>

      <ng-container matColumnDef="withdrawalCount">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Withdrawals</th>
        <td *matCellDef="let element" mat-cell>{{ element.withdrawalCount }}</td>
      </ng-container>

      <ng-container matColumnDef="withdrawalAmount">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Withdrawal Amount</th>
        <td *matCellDef="let element" mat-cell>{{ element.withdrawalAmount | currency: 'GBP':'symbol':'1.0-0' }}</td>
      </ng-container>

      <ng-container matColumnDef="withdrawalFees">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Withdrawal Fees</th>
        <td *matCellDef="let element" mat-cell>{{ element.withdrawalFees | currency: 'GBP':'symbol':'1.2-2' }}</td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;"
          class="clickable"
          mat-row></tr>

      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell" colspan="12">{{ (isLoading) ? "Loading..." : "No data" }}</td>
      </tr>
    </table>
  </lib-spinner-container>

</lib-page-container>

import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SystemService } from 'common-ui/services';
import { Environment } from 'common-ui/models/environment.type';

@Component({
  selector: 'lib-system-down',
  templateUrl: './system-down.component.html',
  styleUrls: ['./system-down.component.scss']
})
export class SystemDownComponent {

  public imageSource: string;

  constructor(
    private router: Router,
    @Inject('env') private environment: Environment,
    private systemService: SystemService
  ) {
    this.imageSource = environment.locations.images;
  }

  get navBarTitle() {
    return this.environment.adminConsole ? 'Admin Console' : 'Portal';
  }

  async tryNow() {
    try {
      await this.systemService.test();
      await this.router.navigateByUrl('/');
    } catch {
      // ignore
    }
  }

}

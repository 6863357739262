import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-spinner-container',
  templateUrl: './spinner-container.component.html',
  styleUrls: ['./spinner-container.component.css']
})
export class SpinnerContainerComponent {

  @Input() isWorking: boolean;

  constructor() {
  }
}

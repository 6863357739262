/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CsvDataType = 'Employee' | 'Timesheet' | 'Pay Code';

export const CsvDataType = {
    EMPLOYEE: 'Employee' as CsvDataType,
    TIMESHEET: 'Timesheet' as CsvDataType,
    PAY_CODE: 'Pay Code' as CsvDataType
};


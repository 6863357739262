import { Component, forwardRef, Input } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  AbstractControl,
  ValidationErrors,
  Validator,
  NG_VALIDATORS,
  FormControl,
  ValidatorFn
} from '@angular/forms';

function timeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValidTime = /^([0-1]?[0-9]|2[0-9]):[0-9][0-9]$/.test(control.value);
    if (!isValidTime) return {'invalidTime': true};

    const [hoursStr, minutesStr] = control.value.split(':');
    const hours = parseInt(hoursStr, 10);
    if (hours > 24 || hours < 0) {
      return {invalidHours: true};
    }

    const minutes = parseInt(minutesStr, 10);
    if (minutes > 60 || minutes < 0) {
      return {invalidMinutes: true};
    }
    return null;
  };
}

@Component({
  selector: 'lib-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => TimeInputComponent),
    multi: true
  }]
})
export class TimeInputComponent implements ControlValueAccessor, Validator {
  control = new FormControl('', timeValidator());

  @Input() label: string;

  // eslint-disable-next-line
  onChange = (_: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.onChange(value);
  }

  validate(): ValidationErrors | null {
    return this.control.errors;
  }
}

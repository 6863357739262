import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-account-request-received',
  templateUrl: './account-request-received.component.html',
  styleUrls: ['./account-request-received.component.scss']
})
export class AccountRequestReceivedComponent {

  imageSource: string;
  message: string;

  constructor() {
    this.imageSource = environment.locations.images;
    this.message = history.state.message;
  }

  goHome() {
    window.location.href = 'https://www.flexearn.com';
  }
}

<lib-spinner-container [isWorking]="isWorking">

  <div class="custom-form">

    <div class="custom-form-col">


      <form
        [formGroup]="form"
        (ngSubmit)="saveCustomisations()">

        <mat-checkbox
          formControlName="useCustomisedEmailLogo">
          Customise Email Logo
        </mat-checkbox>

        <input #fileInput
               formControlName="fileInput"
               hidden
               accept="image/*"
               type="file">

        <div
          class="co-branded-logo-panel"
          *ngIf="form.value['useCustomisedEmailLogo']">

          <p class="co-branded-logo"
             *ngIf="!customisedEmailLogoUrl">Please select a file.</p>

          <img class="co-branded-logo"
               *ngIf="customisedEmailLogoUrl"
               [src]="customisedEmailLogoUrl" />

          <button
            mat-button
            type="button"
            color="primary"
            (click)="onClick()">
            <mat-icon>file_upload</mat-icon>
            Choose File
          </button>
        </div>

        <div class="button-panel">
          <button mat-flat-button
                  color="primary"
                  [disabled]="form.invalid || form.pristine"
                  type="submit">
            Save
          </button>
        </div>
      </form>
    </div>

    <div class="custom-html-col">
      <lib-email-render
        [updated]="lastUpdate"
        [masterAccountId]="masterAccount._id">
      </lib-email-render>
    </div>

  </div>

</lib-spinner-container>

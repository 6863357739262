import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'lib-employee-portal-withdrawal-limit-panel',
  templateUrl: './employee-portal-withdrawal-limit-panel.component.html',
  styleUrls: ['./employee-portal-withdrawal-limit-panel.component.css']
})
export class EmployeePortalWithdrawalLimitPanelComponent implements OnChanges {

  @Input() allowed: number;
  @Input() used: number;
  subMessage: string;

  ngOnChanges(): void {
    this.subMessage = this.allowed >= this.used ? `You have reached the withdrawal limit of ${this.allowed} transactions.` : `You have ${this.allowed - this.used} remaining.`;
  }

}

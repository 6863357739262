import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
  name: 'validationErrorsKeyValue'
})
export class ValidationErrorsKeyValuePipe implements PipeTransform {

  transform(value: ValidationErrors): { key: string, value: any }[] {
    const ret = [];
    if (value) {
      for (const key of Object.keys(value)) {
        ret.push({key, value: value[key]});
      }
    }
    return ret;
  }

}

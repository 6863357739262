<lib-dialog-container [header]="header">
  <lib-spinner-container [isWorking]="isWorking">

    <form (ngSubmit)="save()" [formGroup]="form">

      <lib-employee-input
        autofocus
        formControlName="employeeId">
      </lib-employee-input>

      <div class="row">
        <lib-datepicker
          class="datepicker"
          formControlName="date">
        </lib-datepicker>

        <mat-form-field >
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option [value]="type"
                        *ngFor="let type of types">
              {{ type | hyphenatedToRegular }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="row">
        <lib-currency-input
          label="Hourly Rate"
          formControlName="hourlyRate">
        </lib-currency-input>

        <lib-hours-input formControlName="hoursWorked">
        </lib-hours-input>
      </div>

      <div class="row">
        <lib-time-input
          formControlName="startTime"
          label="Start Time">
        </lib-time-input>

        <lib-time-input
          formControlName="endTime"
          label="End Time">
        </lib-time-input>
      </div>

      <lib-currency-input
        [isRequired]="true"
        label="Wages"
        formControlName="wages">
      </lib-currency-input>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <lib-button-panel>
        <button
          type="button"
          (click)="cancel()"
          mat-stroked-button
          color="primary">
          Cancel
        </button>

        <button
          [disabled]="!form.valid || isWorking"
          mat-flat-button
          color="primary"
          type="submit">
          Save
        </button>
      </lib-button-panel>

    </form>
  </lib-spinner-container>
</lib-dialog-container>

<lib-dialog-container (exit)="close(false)"
                      [showCross]="true"
                      [subHeader]="subHeader"
                      [header]="header">

  <lib-spinner-container [isWorking]="isWorking">

    <div class="log-table-container">
      <table
        class="log-table"
        mat-table [dataSource]="auditLog">

        <ng-container matColumnDef="createdDate">
          <th *matHeaderCellDef mat-header-cell>Date/Time</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.createdDate | date: 'dd/MM/yy HH:mm' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="event">
          <th *matHeaderCellDef mat-header-cell>Event</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.event | camelToRegular }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th *matHeaderCellDef mat-header-cell>User</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.createdBy }}
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th *matHeaderCellDef mat-header-cell>Note</th>
          <td *matCellDef="let row" mat-cell>
            {{ row.note }}
          </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

        <tr *matNoDataRow class="mat-row">
          <td class="mat-cell" colspan="4">No previous events</td>
        </tr>
      </table>
    </div>

    <p class="mat-typography msg" *ngIf="!data.employee.isBlocked">
      Leave an note for the employee explaining why they are blocked.  This message will be shown in the App.
    </p>

    <p class="mat-typography msg" *ngIf="data.employee.isBlocked">
      Leave an note explaining why the employee is unblocked.
    </p>

    <form (ngSubmit)="updateStatus()"
          [formGroup]="form">

      <mat-form-field class="note-field">
        <mat-label>Note</mat-label>
        <textarea matInput
                  id="note-field"
                  #noteField
                  placeholder="Reason for change of status"
                  formControlName="note">
        </textarea>
        <mat-hint align="end">{{noteField.value.length}}/100</mat-hint>
        <mat-error *ngIf="note.invalid">{{getNoteErrorMessage()}}</mat-error>
      </mat-form-field>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <lib-button-panel>
        <button (click)="close(false)"
                mat-stroked-button
                color="primary"
                type="button">
          Cancel
        </button>
        <button type="submit"
                color="primary"
                [disabled]="form.invalid"
                mat-flat-button>
          {{ data.employee.isBlocked ? 'Unblock' : 'Block' }}
        </button>
      </lib-button-panel>
    </form>

  </lib-spinner-container>

</lib-dialog-container>

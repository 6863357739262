import { Component, forwardRef, Input, OnInit, OnDestroy } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  AbstractControl,
  ValidationErrors,
  Validator,
  NG_VALIDATORS,
  FormControl,
  ValidatorFn
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

function maxValidator(maxValue: number, required: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value && required) return {required: true};
    if (value > maxValue) return {exceededMax: {max: maxValue, actual: value}};
    return null;
  };
}

@Component({
  selector: 'lib-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CurrencyInputComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CurrencyInputComponent),
    multi: true
  }]
})
export class CurrencyInputComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
  value = '';
  formControl: FormControl;
  ngUnsubscribe$ = new Subject();

  constructor() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  ngOnInit() {
    this.formControl = new FormControl('', [maxValidator(this.max, this.isRequired)]);

    this.formControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(value => {
        if (value === '') {
          this.onChange(null);
        } else {
          this.onChange(Math.round(parseFloat(value) * 100));
        }
      });
  }

  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() max: number;

  // eslint-disable-next-line
  onChange = (_: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    if (value === null || value === 0) {
      this.formControl.setValue('');
    } else {
      this.formControl.setValue(parseFloat((value / 100).toFixed(2)));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(): ValidationErrors | null {
    return this.formControl.errors;
  }
}

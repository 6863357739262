import { PayCodesOpenApiService, PayCodeQuery, PayCodeDto } from 'common-ui/open-api';
import { Observable } from 'rxjs';
import { AbstractQueryDataSource } from 'common-ui/abstract-query-data-source/abstract-query-data-source';

export class PayCodeDataSource extends AbstractQueryDataSource<PayCodeDto, PayCodeQuery> {

  constructor(
    private payCodeService: PayCodesOpenApiService
  ) {
    super();
  }

  executeQuery(query: PayCodeQuery): Observable<{
    data: PayCodeDto[];
    total: number;
    tooManyRecords: boolean
  }> {
    return this.payCodeService.queryPayCodes(query);
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'common-ui/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showSpinner = false;
  errorMessage: string;
  redirectUrl = '/employees';

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  async ngOnInit() {
    this.activatedRoute.data.pipe(first()).subscribe(data => {
      this.redirectUrl = data.redirectUrl || '/employees';
    });
    this.errorMessage = this.authService.loginError;
  }
}

import {
  ApiSource,
  MasterAccountDto,
  PayFrequency,
  Currency,
  UpdateMasterAccountDto,
  PayrollType,
  IntegrationOpenApiService
} from 'common-ui/open-api';
import { MasterAccountService } from 'common-ui/services';
import { Component, Input, OnDestroy, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MessageDialogComponent } from 'common-ui/message-dialog/message-dialog.component';
import { IntegrationRunDialogComponent } from '../integration-run-dialog/integration-run-dialog.component';
import { Environment } from 'common-ui/models/environment.type';
import {
  CarePlannerPaymentGroupsDialogComponent
} from 'common-ui/care-planner-payment-groups-dialog/care-planner-payment-groups-dialog.component';
import { EnterStringDialogComponent } from 'common-ui/enter-string-dialog/enter-string-dialog.component';
import { FileDownloadService } from 'common-ui/services/file-download.service';
import { hyphenatedToRegular } from 'common-ui/pipes/hyphenated-to-regular';

const starredApiKey = '**********************************************************************';
const starredPassword = '***********';

@Component({
  selector: 'lib-master-account-integration-settings',
  templateUrl: './master-account-integration-settings.component.html',
  styleUrls: ['./master-account-integration-settings.component.css']
})
export class MasterAccountIntegrationSettingsComponent implements OnInit, OnDestroy {
  @Input() masterAccount: MasterAccountDto;

  form: FormGroup;
  errorMessage: string;
  integrations: string[];
  isWorking: boolean;
  isAdminConsole: boolean;
  isOauth2IntegrationDisconnected: boolean;
  @Output() masterAccountChanges = new EventEmitter();

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private masterAccountService: MasterAccountService,
    private integrationOpenApiService: IntegrationOpenApiService,
    private dialog: MatDialog,
    @Inject('env') env: Environment,
    private fileDownloadService: FileDownloadService
  ) {
    this.isAdminConsole = env.adminConsole;
    this.integrations = [
      ...Object.values(ApiSource)
    ];
    this.integrations = this.integrations.sort(
      (a, b) => a.localeCompare(b)
    );

    this.form = new FormGroup({
      apiSource: new FormControl(ApiSource.NONE),
      multiCompany: new FormControl(false),
      useDefaultCompanyForTimesheets: new FormControl(false),
      useTimePunching: new FormControl(false)
    });
  }

  async ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {

    if (this.isAdminConsole) {
      this.form.get('apiSource').valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.rebuildForm();
        });
    }

    this.form.patchValue({
      apiSource: this.masterAccount.timesheetIntegration?.apiSource || ApiSource.NONE,
      multiCompany: this.masterAccount?.timesheetIntegration?.multiCompany || false,
      useDefaultCompanyForTimesheets: this.masterAccount?.timesheetIntegration?.useDefaultCompanyForTimesheets || false,
      useTimePunching: this.masterAccount.timesheetIntegration?.useTimePunching || false
    });

    this.rebuildForm();

    if (!this.isAdminConsole) {
      this.form.disable();
    }

    this.isOauth2IntegrationDisconnected = this.masterAccount.timesheetIntegration?.isOAuth2ConnectionRequired ?? false;
  }

  rebuildDefaultTimesheetRateFields(useDefaultRate: boolean, rate = 10) {
    if (useDefaultRate) {
      this.form.addControl('defaultTimesheetRate', new FormControl(rate, [Validators.required]));
      this.showDefaultRateInput = true;
    } else {
      this.showDefaultRateInput = false;
      this.form.removeControl('defaultTimesheetRate');
    }
  }

  private rebuildForm() {
    const form = this.form;

    if (this.isOAuth2ConnectionRequired) {
      this.isOauth2IntegrationDisconnected = true;
    } else {
      this.isOauth2IntegrationDisconnected = false;
    }

    if (this.isLocalFile || this.isSftp) {
      this.form.addControl('sftpDirectory', new FormControl(this.masterAccount.timesheetIntegration?.sftpDirectory || '', Validators.required));
    } else {
      this.form.removeControl('sftpDirectory');
    }

    if (this.isGcpBucket) {
      this.form.addControl('bucketName', new FormControl(this.masterAccount.timesheetIntegration?.bucketName || '', Validators.required));
      this.form.addControl('projectId', new FormControl(this.masterAccount.timesheetIntegration?.projectId || '', Validators.required));
      this.form.addControl('serviceAccount', new FormControl(this.masterAccount.timesheetIntegration?.serviceAccount || '', Validators.required));
    } else {
      this.form.removeControl('bucketName');
      this.form.removeControl('projectId');
      this.form.removeControl('gcpServiceAccount');
    }

    this.showDefaultRateInput = false;
    if (this.showUseDefaultRateCheckBox) {
      const useDefaultTimesheetRate = this.masterAccount.timesheetIntegration?.useDefaultTimesheetRate || false;
      this.form.addControl('useDefaultTimesheetRate', new FormControl(useDefaultTimesheetRate));

      if (this.isAdminConsole) {
        this.form.get('useDefaultTimesheetRate').valueChanges
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(useDefaultRate => {
            this.rebuildDefaultTimesheetRateFields(useDefaultRate);
          });
      }

      this.rebuildDefaultTimesheetRateFields(useDefaultTimesheetRate, this.masterAccount.timesheetIntegration?.defaultTimesheetRate || 10);
    } else {
      this.form.removeControl('useDefaultTimesheetRate');
      this.rebuildDefaultTimesheetRateFields(false);
    }

    if (this.showOnePayrollPerPayType) {
      form.addControl('onePayrollPerPayType', new FormControl(
        this.masterAccount.timesheetIntegration?.onePayrollPerPayType));
    } else {
      form.removeControl('onePayrollPerPayType');
    }

    if (!this.isMultiCompanyRequired && this.masterAccount.timesheetIntegration) {
      this.masterAccount.timesheetIntegration.multiCompany = false;
    }

    if (this.isKelio) {
      form.addControl('username', new FormControl(
        this.masterAccount.timesheetIntegration?.username || ''
      ));
      form.addControl('password', new FormControl(
        this.masterAccount.timesheetIntegration ? starredPassword : ''
      ));
      form.addControl('populationFilter', new FormControl(
        this.masterAccount.timesheetIntegration?.populationFilter || ''
      ));
      const exclusions = this.masterAccount.timesheetIntegration?.populationExclusions ?? [];
      form.addControl('populationExclusions', new FormArray(exclusions.map(item => new FormControl(item))));
    } else {
      form.removeControl('username');
      form.removeControl('password');
      form.removeControl('populationFilter');
      form.removeControl('populationExclusions');
    }

    if (this.isSftp) {
      form.addControl('sftpHost', new FormControl(
        this.masterAccount.timesheetIntegration?.sftpHost || '',
        Validators.required
      ));
      form.addControl('sftpUsername', new FormControl(
        this.masterAccount.timesheetIntegration?.sftpUsername || '',
        Validators.required
      ));
      // form.addControl('sftpDirectory', new FormControl(
      //   this.masterAccount.timesheetIntegration?.sftpDirectory || '',
      //   Validators.required
      // ));
    } else {
      form.removeControl('sftpHost');
      form.removeControl('sftpUsername');
      // form.removeControl('sftpDirectory');
    }

    if (this.isHourlyTimesheetsRequired) {
      form.addControl('hourlyTimesheets', new FormControl(
        this.masterAccount.timesheetIntegration?.hourlyTimesheets
      ));
    } else {
      form.removeControl('hourlyTimesheets');
    }

    if (this.isPayCodeRequired) {
      form.addControl('usePayCodes', new FormControl(
        this.masterAccount.timesheetIntegration?.usePayCodes
      ));
    } else {
      form.removeControl('usePayCodes');
    }

    if (this.isTimesheetTypesRequired) {
      form.addControl('includeHolidayPay', new FormControl(
        this.masterAccount.timesheetIntegration?.includeHolidayPay
      ));
      form.addControl('includeExtraPay', new FormControl(
        this.masterAccount.timesheetIntegration?.includeExtraPay
      ));
      form.addControl('excludeStandardHours', new FormControl(
        this.masterAccount.timesheetIntegration?.excludeStandardHours
      ));
    } else {
      form.removeControl('includeHolidayPay');
      form.removeControl('includeExtraPay');
      form.removeControl('excludeStandardHours');
    }

    if (this.isPositionForPayCodeRequired) {
      form.addControl('usePositionForPayCode', new FormControl(
        this.masterAccount.timesheetIntegration?.usePositionForPayCode
      ));
    } else {
      form.removeControl('usePositionForPayCode');
    }

    if (this.isClientIdRequired) {
      form.addControl('clientId', new FormControl(
        this.masterAccount.timesheetIntegration?.clientId || '',
        Validators.required
      ));
    } else {
      form.removeControl('clientId');
    }

    if (this.isClientSecretRequired) {
      form.addControl('clientSecret', new FormControl(
        this.masterAccount.timesheetIntegration?.isClientSecretSet ? starredApiKey : '',
        Validators.required
      ));
    } else {
      form.removeControl('clientSecret');
    }

    if (this.isOrganisationNameRequired) {
      form.addControl('accountId', new FormControl(
        this.masterAccount.timesheetIntegration?.accountId || '',
        Validators.required
      ));
    } else {
      form.removeControl('accountId');
    }

    if (this.isAccountUrlIsRequired) {
      form.addControl('accountUrl', new FormControl(
        this.masterAccount.timesheetIntegration?.accountUrl || '',
        Validators.required
      ));
    } else {
      form.removeControl('accountUrl');
    }

    if (this.isApiKeyRequired) {
      form.addControl('apiKey', new FormControl(
        this.masterAccount.timesheetIntegration?.isApiKeySet ? starredApiKey : '',
        Validators.required
      ));
    } else {
      form.removeControl('apiKey');
    }

    if (this.showDefaultPayroll) {
      form.addControl('defaultPayroll', new FormControl(
        this.masterAccount.timesheetIntegration?.defaultPayroll || {
          currency: Currency.GBP,
          frequency: PayFrequency.MONTHLY,
          type: PayrollType.STANDARD,
          periodEndDay: {
            day: 31,
            avoidHolidaysAndWeekends: true
          }
        },
        Validators.required
      ));
    } else {
      form.removeControl('defaultPayroll');
    }

    if (this.showLoadTimesheetEmployeesOnlyCheckBox) {
      form.addControl('loadTimesheetEmployeesOnly', new FormControl(
        this.masterAccount.timesheetIntegration?.loadTimesheetEmployeesOnly || false,
        Validators.required
      ));
    } else {
      form.removeControl('loadTimesheetEmployeesOnly');
    }

    if (this.showExcludeBankStaff) {
      this.form.addControl('excludeBankStaff', new FormControl(false));
    } else {
      this.form.removeControl('excludeBankStaff');
    }
  }

  get populationExclusions() {
    return this.form.get('populationExclusions') as FormArray;
  }

  removePopulationExclusion(index: number) {
    this.populationExclusions.removeAt(index);
    this.form.markAsDirty();
  }

  addPopulationExclusion() {
    const ref = this.dialog.open(EnterStringDialogComponent, {
      data: {
        title: 'Enter Population Exclusion',
        placeholder: 'Exclusion',
        buttonText: 'Add'
      },
      width: '450'
    });
    ref.afterClosed().subscribe(value => {
      if (value) {
        this.populationExclusions.push(new FormControl(value));
        this.form.markAsDirty();
      }
    });
  }

  get showOnePayrollPerPayType() {
    return this.isIntegrationActive && !this.form.get('multiCompany').value
      && this.source !== ApiSource.CARE_PLANNER && this.source !== ApiSource.XERO;
  }

  get isUseDefaultCompanyForTimesheetsRequired() {
    return this.source === ApiSource.DEPUTY && this.form.get('multiCompany').value === true;
  }

  get isIntegrationActive() {
    return this.form.get('apiSource').value !== 'None';
  }

  get isFileSource() {
    return this.source === ApiSource.LOCAL_FILE || this.source === ApiSource.SFTP || this.source === ApiSource.GCP_BUCKET;
  }

  get showExcludeBankStaff() {
    return this.source === ApiSource.COOL_CARE;
  }

  get isPayCodeRequired() {
    return this.source === ApiSource.COOL_CARE || this.source === ApiSource.FAKE;
  }

  get isTimesheetTypesRequired() {
    return this.source === ApiSource.COOL_CARE;
  }

  get isPositionForPayCodeRequired() {
    return this.source === ApiSource.COOL_CARE;
  }

  get isHourlyTimesheetsRequired() {
    return this.source === ApiSource.SFTP;
  }

  get isClientIdRequired(): boolean {
    return [
      ApiSource.CARE_PLANNER,
      ApiSource.EMPLOYMENT_HERO
    ].includes(this.form.get('apiSource').value);
  }

  get isUseTimePunchingRequired(): boolean {
    return [
      ApiSource._7_SHIFTS
    ].includes(this.form.get('apiSource').value);
  }

  get isClientSecretRequired(): boolean {
    return [
      ApiSource.CARE_PLANNER,
      ApiSource.EMPLOYMENT_HERO
    ].includes(this.form.get('apiSource').value);
  }

  get showDefaultPayroll() {
    return this.source !== ApiSource.XERO && this.source !== ApiSource.NONE;
  }

  public async updateMasterAccount() {
    this.isWorking = true;
    try {
      this.errorMessage = '';

      const integrationUpdates = {
        ...this.form.value
      };

      if (integrationUpdates.apiKey === starredApiKey) {
        delete integrationUpdates['apiKey'];
      }

      const updateDto: UpdateMasterAccountDto = {
        timesheetIntegration: integrationUpdates
      };

      this.masterAccount = await this.masterAccountService.update(
        this.masterAccount._id, updateDto
      );

      this.form.markAsPristine();
      this.masterAccountChanges.emit();

    } catch (e) {
      this.errorMessage = e.message;
    }
    this.isWorking = false;
  }

  get source(): ApiSource {
    return this.form.controls['apiSource'].value;
  }

  get multiCompanyOptionText() {
    if (this.source === ApiSource.PLANDAY) {
      return 'One Company Per Department';
    } else if (this.source === ApiSource.DEPUTY || this.source === ApiSource._7_SHIFTS) {
      return 'One Company Per Location';
    } else if (this.source === ApiSource.COOL_CARE) {
      return 'One Company Per Home';
    } else if (this.source === ApiSource.CARE_PLANNER) {
      return 'One Company Per Payment Group';
    } else if (this.source === ApiSource.SFTP || this.source === ApiSource.GCP_BUCKET) {
      return 'Specify company name the employee file';
    } else if (this.source === ApiSource.FAKE) {
      return 'Multi-Company';
    } else if (this.source === ApiSource.PEOPLE_HR) {
      return 'Multiple Companies per Account';
    } else if (this.source === ApiSource.EMPLOYMENT_HERO) {
      return 'One Company per Location';
    } else {
      return 'Unsupported';
    }
  }

  public async testConnection(): Promise<void> {
    this.isWorking = true;
    this.errorMessage = '';
    let header: string;
    let subHeader: string;
    try {
      const testConnectionResult = await this.masterAccountService.testConnection(this.masterAccount._id);
      header = testConnectionResult.isConnected ? 'Connection Success' : 'Connection Failed';
      if (testConnectionResult.message) {
        subHeader = testConnectionResult.message;
      } else {
        subHeader = testConnectionResult.isConnected ?
          'Connection established with ' + hyphenatedToRegular(this.masterAccount.timesheetIntegration.apiSource) + '.' :
          'Connection failed';
      }

    } catch (err) {
      header = 'Connection Failed';
      subHeader = err.message;
    }

    this.isWorking = false;

    this.dialog.open(MessageDialogComponent, {
      data: {
        header: header,
        subHeader: subHeader
      }
    });
  }

  public async openRunIntegrationDialog(): Promise<void> {
    this.errorMessage = '';
    this.dialog.closeAll();
    this.dialog.open(IntegrationRunDialogComponent, {
      data: this.masterAccount,
      width: '1000px'
    });
  }

  public async navigateToConnectionPage(): Promise<void> {
    try {
      this.errorMessage = '';
      location.href = await this.masterAccountService.getOAuthRedirectUrl(
        this.form.value.apiSource,
        this.masterAccount._id
      );
    } catch (err) {
      this.errorMessage = err.message;
      console.log(err);
    }
  }

  get isCarePlanner() {
    return this.form.get('apiSource').value === ApiSource.CARE_PLANNER;
  }

  get isOrganisationNameRequired(): boolean {
    return [
      ApiSource.CARE_PLANNER,
      ApiSource.EMPLOYMENT_HERO
    ].includes(this.form.get('apiSource').value);
  }

  get isSftp() {
    return this.form.get('apiSource').value === ApiSource.SFTP;
  }

  get isLocalFile() {
    return this.source === ApiSource.LOCAL_FILE;
  }

  get isGcpBucket() {
    return this.source === ApiSource.GCP_BUCKET;
  }

  get isKelio() {
    return this.form.get('apiSource').value === ApiSource.KELIO;
  }

  get isApiKeyRequired(): boolean {
    return [
      ApiSource.EMPLOYMENT_HERO,
      ApiSource._7_SHIFTS,
      ApiSource.COOL_CARE,
      ApiSource.ROTA_CLOUD,
      ApiSource.PEOPLE_HR
    ].includes(this.form.get('apiSource').value);
  }

  get isMultiCompanyRequired(): boolean {
    return ![
      ApiSource.XERO,
      ApiSource.NONE,
      ApiSource.ROTA_CLOUD,
      ApiSource.KELIO
    ].includes(this.form.get('apiSource').value);
  }

  get isMultiTenant(): boolean {
    return this.source === ApiSource.XERO;
  }

  get isOAuth2ConnectionRequired(): boolean {
    return [
      ApiSource.DEPUTY,
      ApiSource.PLANDAY,
      ApiSource.XERO,
      ApiSource.EMPLOYMENT_HERO
    ].includes(this.form.get('apiSource').value);
  }

  get isAccountUrlIsRequired(): boolean {
    return this.form.get('apiSource').value === ApiSource.DEPUTY
      || this.form.get('apiSource').value === ApiSource.KELIO;
  }

  public formControlIsRequired(formControlName: string): boolean {
    const formControl = this.form.get(formControlName);
    return formControl.invalid && formControl.dirty && formControl.touched && formControl.errors.required;
  }

  get showUseDefaultRateCheckBox() {
    return this.source === ApiSource.ROTA_CLOUD || this.source === ApiSource.KELIO;
  }

  showDefaultRateInput = false;

  get showLoadTimesheetEmployeesOnlyCheckBox() {
    return this.source === ApiSource.ROTA_CLOUD;
  }

  async downloadSftpPublicKey() {
    await this.masterAccountService.downloadSftpPublicKey(this.masterAccount._id);
  }

  openSalariedPaymentGroupDialog() {
    if (!this.masterAccount.timesheetIntegration?.isClientSecretSet) {
      return;
    }
    const ref = this.dialog.open(CarePlannerPaymentGroupsDialogComponent, {
      data: {
        masterAccountId: this.masterAccount._id,
        paymentGroup: this.masterAccount.timesheetIntegration.carePlannerSalariedPaymentGroup
      }
    });
    ref.afterClosed().subscribe(async value => {
      if (value) {
        try {
          await this.masterAccountService.updateCarePlannerSalariedPaymentGroup(this.masterAccount._id, value);
          this.masterAccount.timesheetIntegration.carePlannerSalariedPaymentGroup = value;
        } catch (err) {
          this.errorMessage = err.message;
        }
      }
    });
  }

  getCarePlannerSalariedPaymentGroup() {
    if (!this.masterAccount.timesheetIntegration?.isClientSecretSet) {
      return 'Set when integration is active.';
    }
    return this.masterAccount.timesheetIntegration?.carePlannerSalariedPaymentGroup?.organisation ?? 'Not Set';
  }

  async disconnectOauth2() {
    try {
      await firstValueFrom(this.integrationOpenApiService.disconnectOauth2Integration({
        masterAccountId: this.masterAccount._id
      }));
      if (this.masterAccount.timesheetIntegration) {
        this.isOauth2IntegrationDisconnected = true;
      }
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  serviceAccountSelected(serviceAccount: string[]) {
    this.form.patchValue({
      serviceAccount: serviceAccount.join('\n')
    });
    this.form.markAsDirty();
  }

  async downloadFileSourceFiles() {
    console.log('id', this.masterAccount._id);
    await this.fileDownloadService.downloadFile(this.integrationOpenApiService.downloadFiles(this.masterAccount._id, 'response'));
  }
}

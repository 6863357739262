<form [formGroup]="form"
      class="payroll-form">

  <div class="payroll-core-fields">

    <mat-form-field class="input-field">
      <mat-label>Currency</mat-label>
      <mat-select formControlName="currency">
        <mat-option
          *ngFor="let currency of currencies"
          [value]="currency">
          {{ currency | uppercase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-field">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option
          *ngFor="let type of payrollTypes"
          [value]="type">
          {{ type | hyphenatedToRegular }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-field">
      <mat-label>Frequency</mat-label>
      <mat-select formControlName="frequency">
        <mat-option
          *ngFor="let frequency of frequencies"
          [attr.data-e2e]="frequency + '-option'"
          [value]="frequency">
          {{ frequency | payFrequency }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <mat-form-field
    *ngIf="form.contains('periodEndWeekDay')"
    class="input-field">
    <mat-label>Period End</mat-label>
    <mat-select formControlName="periodEndWeekDay">
      <mat-option
        *ngFor="let weekDay of weekDays"
        [value]="weekDay">
        {{ weekDay | hyphenatedToRegular }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <lib-datepicker
    *ngIf="form.contains('twoOrFourWeeklyPeriodEnd')"
    formControlName="twoOrFourWeeklyPeriodEnd"
    [required]="true">
  </lib-datepicker>

  <lib-payroll-day-input
    *ngIf="form.contains('periodEndDay')"
    [derivedDate]="(periodDates$ | async).periodEnd ?? ''"
    label="Period End"
    formControlName="periodEndDay">
  </lib-payroll-day-input>

  <lib-payroll-day-input
    *ngIf="form.contains('deductionsExportDay')"
    [derivedDate]="(periodDates$ | async).deductionsExportDate ?? ''"
    label="Deductions Export"
    formControlName="deductionsExportDay">
  </lib-payroll-day-input>

  <lib-payroll-day-input
    *ngIf="form.contains('payDateDay')"
    [derivedDate]="(periodDates$ | async)?.payDate ?? ''"
    label="Pay Date"
    formControlName="payDateDay">
  </lib-payroll-day-input>

  <lib-payroll-custom-schedule-input
    *ngIf="form.contains('customSchedule')"
    [isWorking]=false
    [includePayDate]="form.controls.delayChargeDateToPayDate.value"
    formControlName="customSchedule">
  </lib-payroll-custom-schedule-input>

  <div class="checkbox-column">

    <mat-checkbox
      *ngIf="form.contains('delayChargeDateToPayDate')"
      formControlName="delayChargeDateToPayDate">
      Delay Charge to Pay Date
    </mat-checkbox>

    <mat-checkbox
      *ngIf="form.contains('paidInArrears')"
      formControlName="paidInArrears">
      Paid In Arrears
    </mat-checkbox>
  </div>
</form>

import { DataSource } from '@angular/cdk/collections';
import { PeriodHistoryDto, PeriodHistoryQuery } from 'common-ui/open-api';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { PeriodHistoryService } from 'common-ui/services';
import { finalize } from 'rxjs/operators';

export class PeriodHistoryDataSource extends DataSource<PeriodHistoryDto> {

  private dataSubject = new BehaviorSubject<PeriodHistoryDto[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  public total$ = new BehaviorSubject<number>(0);

  constructor(
    private periodHistoryService: PeriodHistoryService
  ) {
    super();
  }

  get currentValue() {
    return this.dataSubject.value;
  }

  connect(): Observable<PeriodHistoryDto[] | readonly PeriodHistoryDto[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.total$.complete();
  }

  load(query: PeriodHistoryQuery) {
    this.loadingSubject.next(true);
    from(this.periodHistoryService.query(query))
      .pipe(
        finalize(() => this.loadingSubject.next(false)))
      .subscribe(async result => {
        this.total$.next(result.total);
        this.dataSubject.next(result.data);
      });
  }
}

<div class="page mat-typography">

  <div class="container">

    <lib-dialog-header>
      FlexEarn Portal
    </lib-dialog-header>

    <div class="text-box">
      <p>You have clicked a link that was meant for your phone.</p>
      <p>Either open the link on your phone or complete your task with the
        <button
          class="link-button"
          (click)="navigateToWebLink()">FlexEarn Portal
        </button>.
      </p>
    </div>

    <div *ngIf="action==='install'"
         class="store-buttons">
      <button (click)="navigateToPlayStore()"
              class="play-store-button"
              mat-button>
        <img class="play-store-image"
             src="assets/google-play-badge.png">
      </button>

      <button (click)="navigateToAppStore()"
              class="app-store-button"
              mat-button>
        <img class="app-store-image"
             src="assets/apple-app-store-batch.svg">
      </button>
    </div>

  </div>
</div>

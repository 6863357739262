<div class="panel">

  <div *ngIf="isLoading" class="connecting-panel">
    <h1>Connecting to {{ apiSource | hyphenatedToRegular }}...</h1>
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="errorMessage" class="connecting-panel">
    <div class="error-message">Connection Failed</div>
    <lib-error-message [message]="errorMessage"></lib-error-message>
    <div class="icon-panel">
      <mat-icon>error</mat-icon>
    </div>
    <div class="error-message">Please try again or contact support</div>
    <button
      class="back-button"
      (click)="gotoIntegrationPage()"
      mat-stroked-button>
      Back to Integration Settings
    </button>
  </div>
</div>

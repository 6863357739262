<div class="permissions-scroll-container mat-elevation-z1">
  <form [formGroup]="form">
    <div class="permissions-list">
      <mat-checkbox
        *ngFor="let permission of permissions"
        [formControlName]="permission"
        color="primary"
        class="permission-checkbox"
      >
        {{ permission | hyphenatedToRegular }}
      </mat-checkbox>
    </div>
  </form>
</div>
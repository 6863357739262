<div class="page-container">
  <div class="page-header">
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="page-content">
    <ng-content></ng-content>
  </div>
  <div class="page-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>

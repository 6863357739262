import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { MasterAccountDto } from 'common-ui/open-api';
import { MasterAccountService } from 'common-ui/services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'lib-master-account-customisation-settings',
  templateUrl: './master-account-customisation-settings.component.html',
  styleUrls: ['./master-account-customisation-settings.component.css']
})
export class MasterAccountCustomisationSettingsComponent
  implements OnInit, AfterViewInit {

  @Input()
  masterAccount: MasterAccountDto;

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;

  customisedEmailLogoUrl: any;
  form: FormGroup;
  private file: File;
  isWorking = true;
  error = '';
  lastUpdate: Date;

  constructor(
    private masterAccountService: MasterAccountService,
    formBuilder: FormBuilder
  ) {
    this.form = formBuilder.group({
      useCustomisedEmailLogo: false,
      fileInput: ''
    });

    this.form.controls.useCustomisedEmailLogo.valueChanges.subscribe(v => {
      if (v) {
        this.customisedEmailLogoUrl = '';
        this.form.controls.fileInput.addValidators(Validators.required);
        this.form.patchValue({fileInput: ''});
      } else {
        this.form.controls.fileInput.setValidators(null);
      }
      this.form.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.customisedEmailLogoUrl = this.masterAccount.customisedEmailLogoUrl;
    this.form.patchValue({
      useCustomisedEmailLogo: this.masterAccount.useCustomisedEmailLogo
    }, {
      emitEvent: false
    });
    this.isWorking = false;
  }

  ngAfterViewInit(): void {
    this.fileInput.nativeElement.onchange = async () => {
      const files = this.fileInput.nativeElement.files;
      if (files.length > 0) {
        this.file = this.fileInput.nativeElement.files[0];
        await this.loadFile();
      }
    };
  }

  private async loadFile(): Promise<string> {
    return new Promise<string>(resolve => {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const content = fileReader.result.toString();
        this.customisedEmailLogoUrl = fileReader.result as string;
        resolve(content);
      };
      fileReader.readAsDataURL(this.file);
    });
  };

  onClick() {
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  async saveCustomisations() {
    try {
      this.isWorking = true;
      this.error = '';
      this.masterAccount = await this.masterAccountService.updateCustomisation(
        this.masterAccount._id,
        this.form.value.useCustomisedEmailLogo,
        this.file
      );
      this.lastUpdate = new Date();
      this.form.markAsPristine();
    } catch (err) {
      this.error = err.message;
    }
    this.isWorking = false;
  }

  get logoUrl() {
    if (this.masterAccount?.useCustomisedEmailLogo) {
      return this.masterAccount.customisedEmailLogoUrl;
    } else {
      return null;
    }
  }
}

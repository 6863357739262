<table [dataSource]="dataSource"
       class="counts-table"
       mat-table>

  <ng-container matColumnDef="source">
    <th mat-header-cell *matHeaderCellDef>Source</th>
    <td mat-cell *matCellDef="let element">{{ element.source }}</td>
  </ng-container>

  <ng-container matColumnDef="target">
    <th mat-header-cell *matHeaderCellDef>Data Loaded</th>
    <td mat-cell *matCellDef="let element">{{ element.target }}</td>
  </ng-container>

  <ng-container matColumnDef="processed">
    <th mat-header-cell *matHeaderCellDef>Records</th>
    <td mat-cell *matCellDef="let element">{{ element.recordsProcessed }}</td>
  </ng-container>

  <ng-container matColumnDef="created">
    <th mat-header-cell *matHeaderCellDef>Created</th>
    <td mat-cell *matCellDef="let element">{{ element.created }}</td>
  </ng-container>

  <ng-container matColumnDef="updated">
    <th mat-header-cell *matHeaderCellDef>Updated</th>
    <td mat-cell *matCellDef="let element">{{ element.updated }}</td>
  </ng-container>

  <ng-container matColumnDef="deleted">
    <th mat-header-cell *matHeaderCellDef>Deleted</th>
    <td mat-cell *matCellDef="let element">{{ element.deleted }}</td>
  </ng-container>

  <ng-container matColumnDef="errors">
    <th mat-header-cell *matHeaderCellDef>Errors</th>
    <td mat-cell *matCellDef="let element">{{ element.errors }}</td>
  </ng-container>

  <ng-container matColumnDef="warnings">
    <th mat-header-cell *matHeaderCellDef>Warnings</th>
    <td mat-cell *matCellDef="let element">{{ element.warnings }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
  </tr>

</table>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.css']
})
export class DialogHeaderComponent {

  @Input() subHeader;
  @Input() maxWidth: string;

  constructor() {
  }
}

<lib-spinner-container
  *ngIf="user"
  class="mat-typography"
  [isWorking]="isWorking">
  <form [formGroup]="form"
        (ngSubmit)="save()">

    <div class="push-panel" *ngIf="user.isEmployee">
      <h3>Push Notifications</h3>
      <mat-form-field>
        <mat-label>Amount Available Notification Frequency</mat-label>
        <mat-select
          formControlName="amountAvailablePushNotificationPref"
          *ngIf="isEmployee">
          <mat-option *ngFor="let pref of PushNotificationPrefs" [value]="pref">{{ pref | hyphenatedToRegular }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="preferences">

      <h3>Email Notifications</h3>
      <mat-checkbox
        *ngIf="isAdmin || isSysAdmin"
        formControlName="invoicePeriodEnd">Invoice Period End
      </mat-checkbox>

      <mat-checkbox *ngIf="isEmployee"
                    formControlName="successfulWithdrawal">
        Successful Withdrawal
      </mat-checkbox>

      <mat-checkbox *ngIf="isEmployee"
                    formControlName="failedWithdrawal">
        Failed Withdrawal
      </mat-checkbox>

      <mat-checkbox
        *ngIf="isEmployee"
        formControlName="wellbeingCampaign">
        Wellbeing Campaigns
      </mat-checkbox>

      <div *ngIf="user.isSystemAdmin"
           class="balance-prefs-row">

        <div class="left">
          <mat-checkbox
            *ngIf="isSysAdmin"
            formControlName="cashBalanceAlerts">
            GBP Balance Alerts
          </mat-checkbox>

          <mat-form-field
            class="gap"
            *ngIf="isSysAdmin">
            <input matInput
                   placeholder="True Layer GBP Threshold"
                   type="number"
                   formControlName="trueLayerCashBalanceAlertThreshold">
          </mat-form-field>

          <mat-form-field
            *ngIf="isSysAdmin">
            <input matInput
                   placeholder="Modulr GBP Threshold"
                   type="number"
                   formControlName="modulrCashBalanceAlertThreshold">
          </mat-form-field>
        </div>
        <div>

          <mat-checkbox
            *ngIf="isSysAdmin"
            formControlName="eurCashBalanceAlerts">
            EUR Balance Alerts
          </mat-checkbox>

          <mat-form-field
            class="gap"
            *ngIf="isSysAdmin">
            <input matInput
                   placeholder="True Layer EUR Threshold"
                   type="number"
                   formControlName="trueLayerEurCashBalanceAlertThreshold">
          </mat-form-field>

          <mat-form-field
            *ngIf="isSysAdmin">
            <input matInput
                   placeholder="Modulr EUR Threshold"
                   type="number"
                   formControlName="modulrEurCashBalanceAlertThreshold">
          </mat-form-field>
        </div>
      </div>
    </div>

    <lib-error-message [message]="errorMessage"></lib-error-message>

    <div class="button-panel">
      <button type="submit"
              [disabled]="isWorking || !form.dirty"
              color="primary"
              mat-flat-button>
        Save
      </button>
    </div>
  </form>
</lib-spinner-container>

<lib-login-container>
  <div class="container">

    <lib-dialog-header>
      {{ 'Email Updated' }}
    </lib-dialog-header>

    <div class="text">
      <p>Your email has been updated by your employer.</p>
      <p>You must login again as {{ newEmail }}</p>
    </div>

    <div class="email-box">
      <span class="new-email-title">Your new email</span>
      <span data-e2e="updated-email" class="new-email">{{ newEmail }}</span>
    </div>

    <button mat-flat-button
            data-e2e="goto-sign-in"
            (click)="gotoSignIn()"
            color="primary">
      Goto Sign-In
    </button>
  </div>

</lib-login-container>

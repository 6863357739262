import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ExportFileDto } from 'common-ui/open-api';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { PaymentInstructionService } from 'common-ui/services/payment-instruction.service';

@Component({
  selector: 'lib-payment-instruction-history-dialog',
  templateUrl: './payment-instruction-history-dialog.component.html',
  styleUrls: ['./payment-instruction-history-dialog.component.css']
})
export class PaymentInstructionHistoryDialogComponent implements OnInit {

  dataSource = new MatTableDataSource<ExportFileDto>();
  displayedColumns= [
    'select',
    'fileName',
    'exportedDate',
    'exportedTime'
  ];
  isLoading = true;
  selection = new SelectionModel<ExportFileDto>(false, []);

  constructor(
    private ref: MatDialogRef<PaymentInstructionHistoryDialogComponent>,
    private paymentInstructionService: PaymentInstructionService
  ) { }

  async ngOnInit() {
    this.dataSource.data =  await this.paymentInstructionService.getHistory()
    this.isLoading = false
  }

  close() {
    this.ref.close();
  }

  async downloadFile() {
    const selected = this.selection.selected;
    console.log(selected);
    if ( selected.length > 0 ) {
      await this.paymentInstructionService.download(selected[0].fileName)
    }
  }
}

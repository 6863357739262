import { Component, Input, OnChanges } from '@angular/core';
import { differenceInDays, differenceInHours } from 'date-fns';

@Component({
  selector: 'lib-employee-portal-time-left',
  templateUrl: './employee-portal-time-left.component.html',
  styleUrls: ['./employee-portal-time-left.component.css']
})
export class EmployeePortalTimeLeftComponent implements OnChanges {

  @Input() text: string;
  @Input() systemTime: string;
  @Input() periodEnd: string;
  amount: number;
  units: string;

  constructor() {
  }

  ngOnChanges(): void {
    const {amount, units} = this.calcAmountAndUnits(new Date(this.periodEnd), new Date(this.systemTime));
    this.amount = amount;
    this.units = units;
  }

  calcAmountAndUnits(
    periodEnd: Date, systemTime: Date
  ): { amount: number, units: string } {
    const diffInDays = differenceInDays(periodEnd, systemTime);
    if (diffInDays >= 1) {
      return {amount: diffInDays, units: 'days'};
    } else {
      const diffInHours = differenceInHours(periodEnd, systemTime);

      if (diffInHours >= 1) {
        return {amount: diffInHours, units: 'hours'};
      } else {
        return {amount: 0, units: 'hours'};
      }
    }
  }
}

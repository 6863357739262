<div class="form-container">
  <lib-dialog-container
    header="FlexEarn Demo"
    subHeader="Complete the form to request a demo.">

    <lib-spinner-container [isWorking]="isLoading">

      <form (ngSubmit)="onSubmit()" [formGroup]="form">

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Your Name</mat-label>
          <input formControlName="requesterName"
                 matInput
                 appFirstFocus
                 placeholder="Your Name"
                 required>

          <mat-error *ngIf=" hasError(form.controls.requesterName, 'required')">
            Name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Email</mat-label>
          <input formControlName="email"
                 matInput
                 placeholder="Your Email"
                 required>

          <mat-error *ngIf=" hasError(form.controls.email, 'required')">
            Email is required
          </mat-error>

          <mat-error *ngIf=" hasError(form.controls.email, 'invalidEmail')">
            Invalid email
          </mat-error>

        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Phone</mat-label>
          <input formControlName="phone"
                 matInput
                 placeholder="Your Phone"
                 required>

          <mat-error *ngIf=" hasError(form.controls.phone, 'required')">
            Email is required
          </mat-error>

        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Company Name</mat-label>
          <input formControlName="companyName"
                 matInput
                 placeholder="Company Name"
                 required>

          <mat-error *ngIf=" hasError(form.controls.companyName, 'required')">
            Company name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Job Title</mat-label>
          <input formControlName="jobTitle"
                 matInput
                 placeholder="Job Title"
                 required>

          <mat-error *ngIf=" hasError(form.controls.jobTitle, 'required')">
            Job Title is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Number of Employees</mat-label>
          <mat-select formControlName="numberOfEmployees">
            <mat-option *ngFor="let val of numEmployeeValues" [value]="val">{{ val }}</mat-option>
          </mat-select>

          <mat-error *ngIf=" hasError(form.controls.numberOfEmployees, 'required')">
            Number of employees is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Which of these best describes your enquiry?</mat-label>
          <mat-select formControlName="requesterRole">
            <mat-option [value]="AccountRequestRole.EMPLOYER">I want to provide this service to my employees
            </mat-option>
            <mat-option [value]="AccountRequestRole.EMPLOYEE">I want to use this service</mat-option>
          </mat-select>

          <mat-error *ngIf=" hasError(form.controls.requesterRole, 'required')">
            Please select one of the options.
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="form.contains('hrContactEmail')" appearance="outline" class="input-field">
          <mat-label>HR Contact</mat-label>
          <input formControlName="hrContactEmail"
                 matInput
                 placeholder="HR Contact Email"
                 required>

          <mat-error *ngIf=" hasError(form.controls.hrContactEmail, 'required')">
            Please provide the email of a contact in your HR Department
          </mat-error>

          <mat-error *ngIf=" hasError(form.controls.hrContactEmail, 'invalidEmail')">
            Invalid email
          </mat-error>

        </mat-form-field>

        <lib-error-message [message]="errorMessage"></lib-error-message>

        <lib-button-panel>
          <button
            class="email-me-button"
            color="primary"
            mat-flat-button
            [disabled]="!form.valid || !form.dirty || isLoading"
            type="submit">
            Email Me
          </button>
        </lib-button-panel>

      </form>

    </lib-spinner-container>
  </lib-dialog-container>
</div>

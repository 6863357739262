/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type WeekDay = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

export const WeekDay = {
    MONDAY: 'Monday' as WeekDay,
    TUESDAY: 'Tuesday' as WeekDay,
    WEDNESDAY: 'Wednesday' as WeekDay,
    THURSDAY: 'Thursday' as WeekDay,
    FRIDAY: 'Friday' as WeekDay,
    SATURDAY: 'Saturday' as WeekDay,
    SUNDAY: 'Sunday' as WeekDay
};


import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'lib-available-explainer-dialog',
  templateUrl: './available-explainer-dialog.component.html',
  styleUrls: ['./available-explainer-dialog.component.css']
})
export class AvailableExplainerDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      employeeId: string
    }
  ) {
  }

}

import { Component, Input, OnChanges } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'common-ui/services';
import { EmailTemplateDto, TestEmailDto, EmailTemplateType } from 'common-ui/open-api';

@Component({
  selector: 'lib-email-render',
  templateUrl: './email-render.component.html',
  styleUrls: ['./email-render.component.css']
})
export class EmailRenderComponent implements OnChanges {

  @Input() masterAccountId: string;
  @Input() updated: Date;
  emailHtml: SafeHtml;
  templateDto: TestEmailDto;

  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer
  ) {
  }

  async ngOnChanges() {
    if (!this.templateDto) {
      const employeeInvite: EmailTemplateDto = await this.apiService.get(`/api/email/email-template/${EmailTemplateType.EMPLOYEE_INVITE}`);
      this.templateDto = {
        type: employeeInvite.type,
        recipients: [],
        templateData: employeeInvite.exampleData
      };
    }
    this.templateDto.masterAccountId = this.masterAccountId;
    const {html} = await this.apiService.post('/api/email/render-test-email', this.templateDto);
    this.emailHtml = this.sanitizer.bypassSecurityTrustHtml(html);
  }

}

import { Component, forwardRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { ThemePalette } from '@angular/material/core';

export const SORT_CODE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SortCodeInputComponent),
  multi: true
};

const SORT_CODE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SortCodeInputComponent),
  multi: true
};

@Component({
  selector: 'lib-sort-code-input',
  templateUrl: './sort-code-input.component.html',
  styleUrls: ['./sort-code-input.component.css'],
  providers: [SORT_CODE_VALUE_ACCESSOR, SORT_CODE_VALIDATOR]
})
export class SortCodeInputComponent implements ControlValueAccessor, Validator, OnDestroy {

  customPatterns = {0: {pattern: new RegExp('[0-9*]')}};
  sortCodeControl = new UntypedFormControl();
  onTouched: () => void;
  onChange: (val) => void;
  private sortCodePattern = /([0-9][0-9][0-9][0-9][0-9][0-9])|(\*\*\*\*[0-9][0-9])/;
  private ngUnsubscribe$ = new Subject<void>();
  @Input() required = false;
  @Input() clearable = true;
  @Input() appearance: MatFormFieldAppearance = 'standard';
  @Input() color: ThemePalette = 'primary';
  @Input() placeholder = '00 00 00';
  @Output() clear = new EventEmitter<void>();

  constructor() {
    // todo - why is this commented out??
    // this.sortCodeControl.valueChanges
    //   .pipe(first())
    //   .subscribe(() => {
    //     const validators = [Validators.pattern(this.sortCodePattern)];
    //     if (this.required) {
    //       validators.push(Validators.required);
    //     }
    //     this.sortCodeControl.setValidators(validators);
    //   });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  validate() {
    return this.sortCodeControl.errors;
  }

  onBlur() {
    this.onTouched();
  }

  onInputBlur() {
    this.onTouched();
  }

  writeValue(v: any) {
    this.sortCodeControl.patchValue(v, {emitEvent: false});
    if (typeof v === 'string' && v.length === 6 && v.includes('*')) {
      this.sortCodeControl.disable({emitEvent: false});
    } else {
      this.sortCodeControl.enable({emitEvent: false});
    }
  }

  registerOnChange(fn: any) {
    this.sortCodeControl.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  clearInput() {
    this.sortCodeControl.setValue(undefined);
    this.sortCodeControl.enable();
    this.clear.emit();
  }
}

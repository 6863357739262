/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CsvInputError = 'required' | 'invalid email' | 'not unique' | 'key' | 'invalid int' | 'invalid currency' | 'invalid float' | 'invalid date' | 'invalid enum' | 'invalid frequency' | 'invalid start date' | 'invalid leaving date' | 'company name';

export const CsvInputError = {
    REQUIRED: 'required' as CsvInputError,
    INVALID_EMAIL: 'invalid email' as CsvInputError,
    NOT_UNIQUE: 'not unique' as CsvInputError,
    KEY: 'key' as CsvInputError,
    INVALID_INT: 'invalid int' as CsvInputError,
    INVALID_CURRENCY: 'invalid currency' as CsvInputError,
    INVALID_FLOAT: 'invalid float' as CsvInputError,
    INVALID_DATE: 'invalid date' as CsvInputError,
    INVALID_ENUM: 'invalid enum' as CsvInputError,
    INVALID_FREQUENCY: 'invalid frequency' as CsvInputError,
    INVALID_START_DATE: 'invalid start date' as CsvInputError,
    INVALID_LEAVING_DATE: 'invalid leaving date' as CsvInputError,
    COMPANY_NAME: 'company name' as CsvInputError
};


<div class="container">
  <lib-dialog-container
    [header]="data.header"
    [subHeader]="data.subHeader">
    <div class="button-panel">
      <button mat-flat-button
              color="primary"
              (click)="close()">Close
      </button>
    </div>
  </lib-dialog-container>
</div>

import { Component, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ReferralOpenApiService } from 'common-ui/open-api';
import { emailValidator } from 'common-ui/util';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lib-referral-form',
  templateUrl: './referral-form.component.html',
  styleUrls: ['./referral-form.component.css']
})
export class ReferralFormComponent {
  form: FormGroup;
  isLoading = false;
  errorMessage: string;
  @Output() referralCompleted = new EventEmitter();

  constructor(
    private referralService: ReferralOpenApiService,
    fb: FormBuilder
  ) {
    this.form = fb.group({
      companyName: ['', Validators.required],
      contactName: ['', Validators.required],
      contactEmail: ['', [emailValidator, Validators.required]],
      contactPhone: [''],
      contactJobTitle: ['']
    });
  }

  async onSubmit() {
    try {
      this.isLoading = true;
      this.errorMessage = '';
      await firstValueFrom(this.referralService.createReferral({
        ...this.form.value
      }));
      this.referralCompleted.emit();

    } catch (err) {
      this.errorMessage = err.message;
    } finally {
      this.isLoading = false;
    }
  }

  hasError(control: AbstractControl, key: string) {
    return control.touched && control.hasError(key);
  }

}

<div class="container">
  <lib-spinner-container [isWorking]="isWorking">
    <lib-dialog-container
      [header]="data.heading"
      [subHeader]="data.message">

      <form [formGroup]="form"
            (ngSubmit)="executeOperation()">
        <lib-datepicker formControlName="dateValue"></lib-datepicker>

        <lib-error-message [message]="errorMessage"></lib-error-message>

        <div class="button-panel">

          <button mat-stroked-button
                  (click)="cancelOperation()">
            Cancel
          </button>

          <button mat-flat-button
                  color="primary"
                  (click)="executeOperation()">
            {{ data.executeButtonText }}
          </button>
        </div>
      </form>

    </lib-dialog-container>
  </lib-spinner-container>
</div>

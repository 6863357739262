import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'lib-notification-preferences-dialog',
  templateUrl: './notification-preferences-dialog.component.html',
  styleUrls: ['./notification-preferences-dialog.component.scss']
})
export class NotificationPreferencesDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<NotificationPreferencesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userId: string
  ) {
  }

  close() {
    this.dialogRef.close();
  }

}

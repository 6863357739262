<div class="mat-typography" *ngIf="configs">

  <lib-control-bar>

    <div class="control-bar-container">

      <div class="config-controls">

        <mat-form-field class="data-type-select">
          <mat-label>Data Imported</mat-label>
          <mat-select [(ngModel)]="currentDataType"
                      data-e2e="target-select"
                      (ngModelChange)="dataTypeSelected($event)">
            <mat-option *ngFor="let dataType of csvDataTypes" [value]="dataType">{{dataType}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="config-selector">
          <mat-label>Field Mapping</mat-label>
          <mat-select
            data-e2e="config-select"
            [(ngModel)]="currentConfigId"
            (ngModelChange)="configSelected($event)">
            <mat-option *ngFor="let config of filteredStandardConfig" [value]="config._id">
              {{config.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="config-selector"
                        *ngIf="showCompanySelector">

          <mat-label>Company</mat-label>

          <mat-select [(ngModel)]="currentCompanyId"
                      (ngModelChange)="companySelected($event)">
            <mat-option *ngFor="let company of companies"
                        [value]="company._id">
              {{company.name}}
            </mat-option>
            <mat-option
              *ngIf="companies.length === 0"
              (click)="openCompanyEditDialog()">
              Create New Company
            </mat-option>
          </mat-select>

        </mat-form-field>

        <button mat-button
                color="primary"
                [disabled]="!csvConfig"
                (click)="downloadExampleFile()">
          <mat-icon>download</mat-icon>
          Example
        </button>

        <button mat-button
                color="primary"
                *ngIf="currentDataType === CsvDataType.PAY_CODE"
                (click)="downloadPayCodesFile()">
          <mat-icon>download</mat-icon>
          Current Pay Codes
        </button>

        <button mat-flat-button
                color="primary"
                class="run-import-button"
                [disabled]="csvConfig.status === TrafficLights.STOP || !this.currentCompanyId"
                (click)="runImport()">
          Run Import
        </button>

      </div>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <div class="chooser-row">
        <lib-csv-file-chooser (fileSelected)="loadCsvFile()"
                              (fileToLarge)="openWarningDialog()">
        </lib-csv-file-chooser>

        <button
          (click)="clearChosenFile()"
          class="clear-button"
          mat-button>
          Clear
        </button>
      </div>

      <div class="show-errors-checkbox">
        <mat-checkbox
          (change)="toggleErrorFilter()"
          [checked]="filterErrors">
          Show error rows
        </mat-checkbox>
      </div>
    </div>

  </lib-control-bar>

  <lib-csv-output-preview (configUpdated)="mappingUpdated()"
                          [filterErrors]="filterErrors"
                          [csvConfig]="csvConfig">
  </lib-csv-output-preview>

</div>



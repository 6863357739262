import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {
  PayrollDto,
  PayrollType,
  OverridePeriodDatesDto,
  PayrollScheduleDto,
  PayrollOpenApiService
} from 'common-ui/open-api';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-payroll-dates-edit-dialog',
  templateUrl: './payroll-dates-edit-dialog.component.html',
  styleUrls: ['./payroll-dates-edit-dialog.component.css']
})
export class PayrollDatesEditDialogComponent implements OnInit, OnDestroy {

  form: FormGroup;
  errorMessage: string;
  isWorking: boolean;
  schedule: PayrollScheduleDto;

  private ngUnsubscribe$: any = new Subject();

  constructor(
    formBuilder: FormBuilder,
    private payrollService: PayrollOpenApiService,
    @Inject(MAT_DIALOG_DATA) public payroll: PayrollDto,
    private dialogRef: MatDialogRef<PayrollDatesEditDialogComponent>
  ) {
    this.form = formBuilder.group({
      isOverrideActive: true,
      skipNextPeriodEnd: false,
      periodStart: '',
      periodEnd: '',
      deductionsExportDate: '',
      payDate: ''
    });

    this.configureFields(this.payroll.currentDatesOverrideActive);

    this.form.controls.isOverrideActive.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(isOverrideActive => {
        this.configureFields(isOverrideActive);
      });
  }

  private configureFields(isOverrideActive: boolean) {
    if (isOverrideActive === false) {
      this.form.removeControl('periodStart');
      this.form.removeControl('deductionsExportDate');
      this.form.removeControl('payDate');
      this.form.removeControl('periodEnd');
      this.form.removeControl('skipNextPeriodEnd');
    } else {

      this.form.addControl('skipNextPeriodEnd', new FormControl(this.payroll.skipNextPeriodEnd ?? false, [
        Validators.required
      ]));
      this.form.addControl('periodStart', new FormControl(this.payroll.periodStart, [
        Validators.required
      ]));
      this.form.addControl('periodEnd', new FormControl(this.payroll.periodEnd, [
        Validators.required
      ]));

      if (this.payroll.type === PayrollType.ADVANCED) {
        this.form.addControl('deductionsExportDate', new FormControl(this.payroll.deductionsExportDate, [
          Validators.required
        ]));

        this.form.addControl('payDate', new FormControl(this.payroll.payDate, [
          Validators.required
        ]));

      } else {
        if (this.payroll.delayChargeDateToPayDate) {
          this.form.removeControl('deductionsExportDate');
          this.form.addControl('payDate', new FormControl(this.payroll.payDate, [
            Validators.required
          ]));
        } else {
          this.form.removeControl('deductionsExportDate');
          this.form.removeControl('payDate');
        }
      }
    }
  }

  async ngOnInit() {
    let value: any = {
      isOverrideActive: this.payroll.currentDatesOverrideActive === true
    };

    if (value.isOverrideActive) {
      value = {
        ...value,
        skipNextPeriodEnd: this.payroll.skipNextPeriodEnd ?? false,
        periodStart: this.payroll.periodStart,
        periodEnd: this.payroll.periodEnd
      };

      if (this.payroll.type === PayrollType.ADVANCED) {
        value = {
          ...value,
          payDate: this.payroll.payDate,
          deductionsExportDate: this.payroll.deductionsExportDate
        };
      } else if (this.payroll.delayChargeDateToPayDate) {
        value = {
          ...value,
          payDate: this.payroll.payDate
        };
      }
    }

    this.configureFields(value.isOverrideActive);
    this.form.setValue(value);
    if (this.payroll.currentDatesOverrideActive) {
      this.payroll = await firstValueFrom(this.payrollService.getPayroll(this.payroll._id));
      this.schedule = await firstValueFrom(this.payrollService.getSchedule(this.payroll._id));
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  async setPayrollDates() {
    try {
      this.isWorking = true;

      const override: OverridePeriodDatesDto = {
        overrideActive: this.form.value.isOverrideActive,
        skipNextPeriodEnd: this.form.value.skipNextPeriodEnd
      };

      if (override.overrideActive) {
        override.periodDates = {
          periodEnd: this.form.value.periodEnd,
          periodStart: this.form.value.periodStart
        };

        if (this.payroll.type === PayrollType.ADVANCED) {
          override.periodDates.payDate = this.form.value.payDate;
          override.periodDates.deductionsExportDate = this.form.value.deductionsExportDate;
        } else if (this.payroll.delayChargeDateToPayDate) {
          override.periodDates.payDate = this.form.value.payDate;
        }
      }

      await firstValueFrom(this.payrollService.overrideCurrentPeriodDates(this.payroll._id, override));
      this.payroll = await firstValueFrom(this.payrollService.getPayroll(this.payroll._id));
      this.schedule = await firstValueFrom(this.payrollService.getSchedule(this.payroll._id));
      // this.dialogRef.close(true);
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { EmployeeDto } from 'common-ui/open-api';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';
import { SystemService } from 'common-ui/services';

export interface EmployeeWithdrawalDialogData {
  employee: EmployeeDto;
}

@Component({
  selector: 'lib-employee-withdrawal-dialog',
  templateUrl: './employee-withdrawal-dialog.component.html',
  styleUrls: ['./employee-withdrawal-dialog.component.css']
})
export class EmployeeWithdrawalDialogComponent implements OnInit {

  isLoading = true;
  dateRange: JustDateRange;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmployeeWithdrawalDialogData,
    private dialogRef: MatDialogRef<EmployeeWithdrawalDialogComponent>,
    private systemService: SystemService
  ) {
  }

  async ngOnInit() {
    const dateRange = this.systemService.getCurrentWeek();
    await this.selectPeriod(dateRange);
  }

  async selectPeriod(dateRange: JustDateRange) {
    this.isLoading = true;
    this.dateRange = dateRange;
    this.isLoading = false;
  }

  close() {
    this.dialogRef.close();
  }
}

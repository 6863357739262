import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface CompanyEditData {
  companyId?: string;
  masterAccountId: string;
  subHeader?: string;
  create: boolean;
}

@Component({
  selector: 'lib-company-edit-dialog',
  templateUrl: './company-edit-dialog.component.html',
  styleUrls: ['./company-edit-dialog.component.css']
})
export class CompanyEditDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CompanyEditData,
    private dialogRef: MatDialogRef<CompanyEditDialogComponent>
  ) {
  }

  close(success: boolean) {
    this.dialogRef.close(success);
  }

}

import { Component, Inject } from '@angular/core';
import { Currency, UpdateBankDetailsDto } from 'common-ui/open-api';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomerService } from 'common-ui/services/customer.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService } from 'common-ui/services/user.service';
import { AuthService } from 'common-ui/services';
import { validateIBAN } from 'ngx-iban-validator';

export interface EmployeePortalBankDetailsDialogData {
  employeeId: string;
  currency: Currency;
  sortCode: string;
  accountNumber: string;
  iban: string;
}

@Component({
  selector: 'lib-employee-portal-bank-details-dialog',
  templateUrl: './employee-portal-bank-details-dialog.component.html',
  styleUrls: ['./employee-portal-bank-details-dialog.component.css']
})
export class EmployeePortalBankDetailsDialogComponent {

  isWorking = new BehaviorSubject<boolean>(true);
  form: FormGroup;
  errorMessage: string;
  isOtpEmailSent = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EmployeePortalBankDetailsDialogData,
    private customerService: CustomerService,
    private authService: AuthService,
    private userService: UserService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EmployeePortalBankDetailsDialogData>
  ) {
    if ( this.currency === Currency.GBP) {
      this.form = this.fb.group({
        accountNumber: ['', Validators.required],
        accountSortCode: ['', Validators.required],
      });
    } else {
      this.form = this.fb.group({
        iban: ['', [Validators.required, validateIBAN]],
      });
    }
  }

  get currency() {
    return this.data.currency
  }

  async save() {
    try {

      this.isWorking.next(true);
      this.errorMessage = '';
      if (!this.isOtpEmailSent) {
        await this.authService.sendOTPEmail(this.userService.currentUser.email)
        this.isOtpEmailSent = true;
        this.form.addControl('otp', new FormControl() );

      } else {
        const updateDto: UpdateBankDetailsDto = this.currency === Currency.GBP ? {
          employeeId: this.data.employeeId,
          accountNumber: this.form.value.accountNumber,
          accountSortCode: this.form.value.accountSortCode,
          otp: this.form.value.otp
        } : {
          employeeId: this.data.employeeId,
          iban: this.form.value.iban,
          otp: this.form.value.otp
        };

        await this.customerService.updateBankDetails(updateDto);
        this.dialogRef.close(true);
      }

    } catch (err) {
      this.errorMessage = err.message;
    }

    this.isWorking.next(false);

  }

  close() {
    this.dialogRef.close(false);
  }

  protected readonly Currency = Currency;
}

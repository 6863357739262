import { Pipe, PipeTransform } from '@angular/core';
import { PayrollExportType } from 'common-ui/open-api';

@Pipe({
  name: 'payrollExportType'
})
export class PayrollExportTypePipe implements PipeTransform {

  transform(value: PayrollExportType): string {
    switch ( value) {
      case PayrollExportType.STANDARD:
        return 'Standard'
      case PayrollExportType.STANDARD_SINGLE_NAME_FIELD:
        return 'Standard (single name field)'
      case PayrollExportType.SAGE_50C:
        return 'Sage 50C'
    }
  }

}

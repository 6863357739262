import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PayrollEditDialogComponent, PayrollEditData } from '../payroll-edit-dialog/payroll-edit-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { ToolbarDataService, FilterService, FilterType, PayrollService } from 'common-ui/services';
import {
  RolloverPayrollDialogComponent,
  ConfirmEndPeriodData
} from '../confirm-end-period-dialog/rollover-payroll-dialog.component';
import {
  ConfirmDeletePayrollData,
  ConfirmDeletePayrollDialogComponent
} from '../confirm-delete-payroll-dialog/confirm-delete-payroll-dialog.component';
import { Subject } from 'rxjs';
import { PayrollDto, ApiSource, SortDirection } from 'common-ui/open-api';
import {
  PayrollDatesEditDialogComponent
} from 'common-ui/payroll-dates-edit-dialog/payroll-dates-edit-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Pages } from 'common-ui/services/filter.service';
import { Environment } from 'common-ui/models/environment.type';
import { PayrollScheduleComponent } from 'common-ui/payroll-schedule/payroll-schedule.component';


@Component({
  selector: 'lib-payrolls-page',
  templateUrl: './payroll-page.component.html',
  styleUrls: ['./payroll-page.component.css']
})
export class PayrollPageComponent implements OnInit, AfterViewInit, OnDestroy {

  public dataSource: MatTableDataSource<PayrollDto> = new MatTableDataSource<PayrollDto>();
  public displayedColumns = [
    'masterAccountName',
    'companyName',
    'currency',
    'type',
    'frequency',
    'unsettledAmount',
    'unsettledCount',
    'periodStart',
    'periodEnd',
    'delayChargeDateToPayDate',
    'menu'
  ];
  isLoading = true;
  private ngUnsubscribe = new Subject();
  @ViewChild(MatSort) private sort: MatSort;

  FilterType = FilterType;
  ApiSource = ApiSource;

  constructor(
    private dialog: MatDialog,
    private payrollService: PayrollService,
    private filterService: FilterService,
    private toolbarDataService: ToolbarDataService,
    @Inject('env') public environment: Environment
  ) {
  }

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle',
      title: 'Payrolls'
    });

    await this.getData();
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  async getData() {
    this.dataSource.data = await this.payrollService.getPayrollsForUser();
    this.isLoading = false;

    this.dataSource.filterPredicate = (data: PayrollDto) => {
      return this.filterService.checkGeneralFilters([
        {value: data.companyId, type: FilterType.COMPANY},
        {value: data.frequency, type: FilterType.PAY_FREQUENCY},
        ...this.environment.adminConsole ?
          [{value: data.masterAccountId, type: FilterType.MASTER_ACCOUNT}] :
          []
      ]);
    };

    this.dataSource.filter = JSON.stringify(this.filterService.currentFilter);
    this.filterService.filter$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(filter => {
        this.dataSource.filter = JSON.stringify(filter);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = (
      data: PayrollDto,
      sortHeaderId: string) => {
      if (sortHeaderId === 'companyName' || sortHeaderId === 'masterAccountName') {
        return data[sortHeaderId].toUpperCase();
      } else {
        return data[sortHeaderId];
      }
    };
    this.dataSource.sort = this.sort;

    const initialSort = this.filterService.getPageSort(Pages.PAYROLL);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }
    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.PAYROLL, newSort.active, newSort.direction as SortDirection);
      });
  }

  public async manualDeductionRollover(payroll: PayrollDto) {
    const data: ConfirmEndPeriodData = {
      payrollId: payroll._id,
      companyId: payroll.companyId,
      rolloverDeductions: true,
      rolloverPeriod: false
    };
    const ref = this.dialog.open(RolloverPayrollDialogComponent,
      {data, autoFocus: false, maxWidth: 500}
    );
    ref.afterClosed().subscribe(async () => {
      await this.ngOnInit();
    });
  }

  public async manualPeriodRollover(payroll: PayrollDto) {
    const data: ConfirmEndPeriodData = {
      payrollId: payroll._id,
      companyId: payroll.companyId,
      rolloverDeductions: false,
      rolloverPeriod: true
    };
    const ref = this.dialog.open(RolloverPayrollDialogComponent,
      {data, autoFocus: false, maxWidth: 500}
    );
    ref.afterClosed().subscribe(async () => {
      await this.ngOnInit();
    });
  }

  openEditPayrollDialog(payrollId: string) {
    const data: PayrollEditData = {
      payrollId,
      existingPayrolls: this.dataSource.data,
      create: false
    };

    const ref = this.dialog.open(PayrollEditDialogComponent, {data});
    ref.afterClosed().subscribe(async reload => {
      if (reload) {
        await this.ngOnInit();
      }
    });
  }

  openCreatePayrollDialog() {
    const data: PayrollEditData = {
      existingPayrolls: this.dataSource.data,
      create: true
    };

    const ref = this.dialog.open(PayrollEditDialogComponent, {data});
    ref.afterClosed().subscribe(async reload => {
      if (reload) {
        await this.ngOnInit();
      }
    });
  }

  openDeletePayrollDialog(payroll: PayrollDto) {
    const data: ConfirmDeletePayrollData = {payroll};
    const ref = this.dialog.open(ConfirmDeletePayrollDialogComponent, {data, autoFocus: false});
    ref.afterClosed().subscribe(async reload => {
      if (reload) {
        await this.ngOnInit();
      }
    });
  }

  openPayrollDatesEditDialog(payroll: PayrollDto) {
    const ref = this.dialog.open(PayrollDatesEditDialogComponent, {data: payroll});
    ref.afterClosed().subscribe(async success => {
      if (success) {
        await this.ngOnInit();
      }
    });
  }

  openPayrollScheduleDialog(payroll: PayrollDto) {
    this.dialog.open(PayrollScheduleComponent, {data: payroll});
  }
}

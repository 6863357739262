import { DataSource } from '@angular/cdk/collections';
import { CommsLogDto, CommsLogQueryDto } from 'common-ui/open-api';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommsLogService } from 'common-ui/services/comms-log.service';

export class CommsLogDataSource extends DataSource<CommsLogDto> {

  private dataSubject = new BehaviorSubject<CommsLogDto[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  public total$ = new BehaviorSubject<number>(0);

  constructor(
    private commsLogService: CommsLogService
  ) {
    super();
  }

  get currentValue() {
    return this.dataSubject.value;
  }

  connect(): Observable<CommsLogDto[] | readonly CommsLogDto[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.total$.complete();
  }

  load(query: CommsLogQueryDto) {
    this.loadingSubject.next(true);
    from(this.commsLogService.queryCommsLog(query))
      .pipe(
        finalize(() => this.loadingSubject.next(false)))
      .subscribe(async result => {
        this.total$.next(result.total);
        this.dataSubject.next(result.data);
      });
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import {
  IntegrationReportQueryDto,
  IntegrationReportQuery,
  IntegrationReportDto,
  ImportErrorDto
} from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(
    private api: ApiService
  ) {
  }

  async queryIntegrationJobs(query?: IntegrationReportQuery): Promise<IntegrationReportQueryDto> {
    return this.api.post('/api/integration/imports/query', query);
  }

  async getIntegrationReportErrors(integrationReportId: string): Promise<ImportErrorDto[]> {
    return this.api.get(`/api/integration/import-errors/${integrationReportId}`);
  }

  async runImport(masterAccountId: string): Promise<IntegrationReportDto> {
    return await this.api.post(`/api/integration/run-import/${masterAccountId}`, {});
  }

  async getLatestImport(masterAccountId: string): Promise<IntegrationReportDto> {
    return await this.api.get(`/api/integration/latest-import/${masterAccountId}`);
  }

  async setJobFailed(integrationReportId: string) {
    await this.api.patch(`/api/integration/set-job-failed/${integrationReportId}`, {});
  }
}

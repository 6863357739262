<lib-page-container>

  <lib-filter-bar [textSearchType]="FilterType.EMPLOYEE_SEARCH"
                  [isWorking]="(dataSource.loading$ | async) ?? false"
                  header>

    <lib-payroll-period-selector></lib-payroll-period-selector>
    <lib-custom-dates-range-selector></lib-custom-dates-range-selector>
    <lib-list-selector-component
      [singleSelect]="true"
      [filterType]="FilterType.WITHDRAWALS_SETTLED"
      [list]="filterOptions"
      label="Settlement Status">
    </lib-list-selector-component>
    <lib-withdrawal-type-selector></lib-withdrawal-type-selector>
    <lib-payment-status-selector></lib-payment-status-selector>
    <lib-company-selector></lib-company-selector>
    <lib-master-account-selector *ngIf="environment.adminConsole">
    </lib-master-account-selector>

  </lib-filter-bar>

  <table [dataSource]="dataSource"
         mat-table
         matSort
         matSortActive="lastName"
         matSortDirection="asc">
    <ng-container matColumnDef="select">
      <th *matHeaderCellDef mat-header-cell>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td *matCellDef="let row" mat-cell>
        <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                      (click)="$event.stopPropagation()"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th *matHeaderCellDef mat-header-cell>First Name</th>
      <td *matCellDef="let element" mat-cell>{{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th *matHeaderCellDef mat-header-cell>Last Name</th>
      <td *matCellDef="let element" mat-cell>{{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th *matHeaderCellDef mat-header-cell>Company</th>
      <td *matCellDef="let element" mat-cell>{{ element.companyName }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Date</th>
      <td *matCellDef="let element" mat-cell>{{ element.timestamp | date: "dd MMM yyyy" }}</td>
    </ng-container>

    <ng-container matColumnDef="time">
      <th *matHeaderCellDef mat-header-cell>Time</th>
      <td *matCellDef="let element" mat-cell>{{ element.timestamp | date: "HH:mm" }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Amount</th>
      <td *matCellDef="let element" mat-cell>{{ element.amount | pennyToPound: element.currency }}</td>
    </ng-container>

    <ng-container matColumnDef="fee">
      <th *matHeaderCellDef mat-header-cell>Fee</th>
      <td *matCellDef="let element" mat-cell>
        <div>
          {{ element.fee | pennyToPound: element.currency }}
        </div>
        <div>
          <span class="subtext">{{ element.employerPaysFee ? 'Employer Pays Fee' : '' }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentStatus">
      <th *matHeaderCellDef mat-header-cell>Status</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.paymentStatus | titlecase }}
        <div class="subtext" *ngIf="element.failureReason">{{ element.failureReason | truncate: 50 }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell>Type</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.type | hyphenatedToRegular }}
      </td>
    </ng-container>

    <ng-container matColumnDef="paymentApi">
      <th *matHeaderCellDef mat-header-cell>Api</th>
      <td *matCellDef="let element" mat-cell>
        {{ element.paymentApi ?? element.discountCardsApi ?? element.savingsApi | hyphenatedToRegular }}
      </td>
    </ng-container>

    <ng-container matColumnDef="settlementStatus">
      <th *matHeaderCellDef mat-header-cell>Settlement</th>
      <td *matCellDef="let element" mat-cell>{{ element.settlementPeriodId ? 'Settled' : 'Unsettled' }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" mat-cell>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu" xPosition="before">

          <button
            mat-menu-item
            (click)="openJsonDataDialog(element)">
            <mat-icon>open_in_new</mat-icon>
            View JSON
          </button>

          <button
            mat-menu-item
            *ngIf="isConsole"
            [disabled]="element.paymentStatus === PaymentStatus.SUCCESS"
            (click)="updateWithdrawalStatus(element._id)">
            <mat-icon>update</mat-icon>
            Update Status
          </button>

          <button
            mat-menu-item
            *ngIf="isConsole && element.isRefunded"
            (click)="openRefundingDialog(element, false)">
            <mat-icon>cancel</mat-icon>
            Cancel Refunded...
          </button>

          <button
            mat-menu-item
            *ngIf="isConsole && !element.isRefunded"
            [disabled]="element.paymentStatus !== PaymentStatus.SUCCESS"
            (click)="openRefundingDialog(element, true)">
            <mat-icon>cancel</mat-icon>
            Refunded...
          </button>

        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr (click)="selection.toggle(row)"
        *matRowDef="let row; columns: displayedColumns;"
        class="clickable"
        mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="12">{{
          (this.dataSource.loading$ | async) ? "Loading..." :
            (this.dataSource.queryToLarge$ | async) ? "Too many withdrawals - please add a filter" : "No data"
        }}
      </td>
    </tr>
  </table>

  <div footer>
    <mat-paginator
      [length]="dataSource.total$ | async"
      [pageSize]="pageSize"
      [showFirstLastButtons]=true></mat-paginator>
  </div>

</lib-page-container>

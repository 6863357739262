<div class="error-row" [ngClass]="control.hasError('required')
                               || control.hasError(errorName)
                               ? 'text-danger' : 'text-success'">
  <mat-icon
    class="icon-pending"
    *ngIf="!control.touched && (control.hasError('required')
                             || control.hasError(errorName))">
    panorama_fish_eye
  </mat-icon>
  <mat-icon
    class="icon-ok"
    *ngIf="!control.hasError('required') && !control.hasError(errorName)">
    task_alt
  </mat-icon>
  <mat-icon
    class="icon-error"
    *ngIf="control.touched && (control.hasError('required')
                            || control.hasError(errorName))">
    error_outline
  </mat-icon>
  {{ errorText }}
</div>

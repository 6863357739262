import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from 'common-ui/open-api';
import { getCurrencySymbol } from 'common-ui/util/get-currency-symbol';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(currency: Currency): string {
    return getCurrencySymbol(currency);
  }
}

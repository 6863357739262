import { Component, OnInit, OnDestroy } from '@angular/core';
import { FilterService, FilterType } from 'common-ui/services';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lib-payroll-period-selector',
  templateUrl: './payroll-period-selector.component.html',
  styleUrls: ['./payroll-period-selector.component.css']
})
export class PayrollPeriodSelectorComponent implements OnInit, OnDestroy {

  invoiceNumberControl = new FormControl();
  ngUnsubscribe$ = new Subject<void>;

  constructor(
    private filterService: FilterService
  ) {
  }

  async ngOnInit() {
    const initialValue = this.filterService.getFilter(FilterType.INVOICE_NUMBER);
    this.invoiceNumberControl.setValue(initialValue);
    this.invoiceNumberControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(async value => {
        this.filterService.setFilter(FilterType.INVOICE_NUMBER, parseInt(value, 10));
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}

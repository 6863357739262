import { Day, days } from 'common-ui/timesheets-table-page/days';

export class WeeklyHours {
  mon?: string;
  tue?: string;
  wed?: string;
  thu?: string;
  fri?: string;
  sat?: string;
  sun?: string;

  setAmount(amount: number, day: Day) {
    const amountStr = amount.toFixed(0);
    if (this[day] !== amountStr) {
      this[day] = amountStr;
    }
  }

  getAmount(day: Day): number {
    let amountString: string = this[day];
    if (amountString?.length > 0) {
      amountString = amountString.substring(1);
    }
    return amountString ? parseInt(amountString) : undefined;
  }

  clear() {
    for (const key of days) {
      if (this[key] !== '') {
        this[key] = '';
      }
    }
  }
}

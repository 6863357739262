import { UntypedFormGroup } from '@angular/forms';

export function setFormEnabled(form: UntypedFormGroup, enabledFields: string[]) {
  if (enabledFields) {
    for (const key of Object.keys(form.controls)) {
      const control = form.controls[key];
      if (enabledFields.includes(key)) {
        control.enable();
      } else {
        control.disable();
      }
    }
  }
}

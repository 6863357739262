<lib-dialog-container
  [header]="data.operationName + ' Failed!'"
  [subHeader]="data.message + '.'"
  class="mat-typography">
  <div class="button-panel">
    <button mat-flat-button
            mat-dialog-close=""
            color='primary'>
      Close
    </button>
  </div>
</lib-dialog-container>

<lib-control-bar>
  <ng-content select="[control-options]"></ng-content>
</lib-control-bar>

<div class="filter-bar">
  <mat-form-field appearance="legacy" class="text-search-bar" *ngIf="textSearchType">
    <mat-label>{{textSearchLabel}}</mat-label>
    <input [formControl]="searchControl"
           data-lpignore="true" matInput>
  </mat-form-field>
  <div *ngIf="!textSearchType"></div>

  <ng-content></ng-content>
</div>

import { ServerImportError } from './csv-input-file';
import { CsvInputCell } from './csv-input-cell';
import { TrafficLights } from 'common-ui/models/traffic-lights.model';

export class CsvInputRow {
  constructor(public id: string, public cells: CsvInputCell[]) {
  }

  get status() {
    if (this.cells.filter(cell => cell.status === TrafficLights.STOP).length > 0) {
      return TrafficLights.STOP;
    } else if (this.cells.filter(cell => cell.status === TrafficLights.WARN).length > 0) {
      return TrafficLights.WARN;
    } else {
      return TrafficLights.GO;
    }
  }

  get hasClientError() {
    return this.cells.filter(cell => cell.hasClientError).length > 0;
  }

  getValue(columnNumber: number) {
    return this.cells[columnNumber];
  }

  addServerError(column: number, error: ServerImportError) {
    this.cells[column].addServerError(error);
  }

  clearServerErrors() {
    this.cells.forEach(row => row.clearServerErrors());
  }
}

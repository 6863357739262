import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PeriodHistoryService } from 'common-ui/services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InvoiceStatus, PeriodHistoryDto } from 'common-ui/open-api';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-period-history-edit-dialog',
  templateUrl: './period-history-edit-dialog.component.html',
  styleUrls: ['./period-history-edit-dialog.component.css']
})
export class PeriodHistoryEditDialogComponent implements OnDestroy {

  form: FormGroup;
  error: string;
  isWorking = false;
  InvoiceStatus = InvoiceStatus;
  statuses = Object.values(InvoiceStatus);
  private ngUnsubscribe = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PeriodHistoryDto,
    private dialogRef: MatDialogRef<PeriodHistoryEditDialogComponent>,
    private periodHistoryService: PeriodHistoryService
  ) {
    this.form = new FormGroup({
      status: new FormControl(data.status, {validators: [Validators.required], nonNullable: true})
    });
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  cancel() {
    this.dialogRef.close(false);
  }

  async save() {
    try {
      this.isWorking = true;
      await this.periodHistoryService.updatePeriodHistory(this.data._id, this.form.value);
      this.dialogRef.close(true);
    } catch (err) {
      this.error = err.message;
    }
    this.isWorking = false;
  }
}

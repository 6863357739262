import { DataSource } from '@angular/cdk/collections';
import { EmployeeDto, EmployeeQuery, EmployeeOpenApiService } from 'common-ui/open-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class EmployeeDataSource extends DataSource<EmployeeDto> {

  private dataSubject = new BehaviorSubject<EmployeeDto[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  public total$ = new BehaviorSubject<number>(0);

  constructor(
    private employeeService: EmployeeOpenApiService
  ) {
    super();
  }

  get currentValue() {
    return this.dataSubject.value;
  }

  connect(): Observable<EmployeeDto[] | readonly EmployeeDto[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.total$.complete();
  }

  load(query: EmployeeQuery) {
    this.loadingSubject.next(true);
    this.employeeService.queryEmployees(query)
      .pipe(
        finalize(() => this.loadingSubject.next(false)))
      .subscribe(async result => {
        this.total$.next(result.total);
        this.dataSubject.next(result.data);
      });
  }
}

<div class="errors-table-container">
  <table [dataSource]="dataSource" class="errors-table" mat-table>

    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef>Source</th>
      <td mat-cell *matCellDef="let error">{{ error.source }}</td>
    </ng-container>

    <ng-container matColumnDef="target">
      <th mat-header-cell *matHeaderCellDef>Target</th>
      <td mat-cell *matCellDef="let error">{{ error.target }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let error">{{ error.isWarning ? 'Warning' : 'Error' }}</td>
    </ng-container>

    <ng-container matColumnDef="errorMessage">
      <th mat-header-cell *matHeaderCellDef>Error Message</th>
      <td mat-cell *matCellDef="let error">{{ error.errorMessage }}</td>
    </ng-container>

    <ng-container matColumnDef="companyInternalId">
      <th mat-header-cell *matHeaderCellDef>Company Internal Id</th>
      <td mat-cell *matCellDef="let error">{{ error.companyInternalId }}</td>
    </ng-container>

    <ng-container matColumnDef="employeeIdentifier">
      <th mat-header-cell *matHeaderCellDef>Employee</th>
      <td mat-cell *matCellDef="let error">{{ error.employeeIdentifier }}</td>
    </ng-container>

    <ng-container matColumnDef="employeeInternalId">
      <th mat-header-cell *matHeaderCellDef>Employee Internal Id</th>
      <td mat-cell *matCellDef="let error">{{ error.employeeInternalId }}</td>
    </ng-container>

    <ng-container matColumnDef="employeeExternalId">
      <th mat-header-cell *matHeaderCellDef>Employee External Id</th>
      <td mat-cell *matCellDef="let error">{{ error.employeeExternalId }}</td>
    </ng-container>

    <ng-container matColumnDef="timesheetIdentifier">
      <th mat-header-cell *matHeaderCellDef>Timesheet Identifier</th>
      <td mat-cell *matCellDef="let error">{{ error.timesheetIdentifier }}</td>
    </ng-container>

    <ng-container matColumnDef="timesheetExternalId">
      <th mat-header-cell *matHeaderCellDef>Timesheet External Id</th>
      <td mat-cell *matCellDef="let error">{{ error.timesheetExternalId }}</td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef>{{ apiSource | hyphenatedToRegular }} Id</th>
      <td mat-cell *matCellDef="let error">{{ getExternalId(error) }}</td>
    </ng-container>

    <ng-container matColumnDef="fieldValue">
      <th mat-header-cell *matHeaderCellDef>Field Value</th>
      <td mat-cell *matCellDef="let error">{{ error.fieldValue }}</td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr mat-row *matRowDef="let integrationImportError; columns: displayedColumns;"></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="3">{{ "No errors" }}</td>
    </tr>
  </table>
</div>

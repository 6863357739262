import { Component, forwardRef, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  ValidationErrors,
  ControlValueAccessor
} from '@angular/forms';
import {
  Currency,
  PayFrequency,
  PayrollType,
  WeekDay,
  PayrollDay,
  PeriodDates,
  CustomPeriod
} from 'common-ui/open-api';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { SystemService, PayrollService } from 'common-ui/services';
import { formatDateYYYYMMDD, parseDateYYYYMMDD } from 'common-ui/util';
import { getNextWeekDay } from 'common-ui/util/get-next-weekday';
import { takeUntil, debounceTime } from 'rxjs/operators';
import format from 'date-fns/format';

interface PayrollInputForm {
  currency: FormControl<Currency>;
  frequency: FormControl<PayFrequency>;
  type: FormControl<PayrollType>;
  twoOrFourWeeklyPeriodEnd?: FormControl<string>;
  periodEndDay?: FormControl<PayrollDay>;
  periodEndWeekDay?: FormControl<WeekDay>;
  deductionsExportDay?: FormControl<PayrollDay>;
  payDateDay?: FormControl<PayrollDay>;
  customSchedule?: FormControl<CustomPeriod[]>;
  delayChargeDateToPayDate?: FormControl<boolean>;
  paidInArrears?: FormControl<boolean>;
}

export interface PayrollInputFormValue {
  currency: Currency;
  frequency: PayFrequency;
  type: PayrollType;
  twoOrFourWeeklyPeriodEnd?: string;
  periodEndDay?: PayrollDay;
  periodEndWeekDay?: WeekDay;
  deductionsExportDay?: PayrollDay;
  payDateDay?: PayrollDay;
  customSchedule?: CustomPeriod[];
  delayChargeDateToPayDate?: boolean;
  paidInArrears?: boolean;
}

@Component({
  selector: 'lib-payroll-input',
  templateUrl: './payroll-input.component.html',
  styleUrls: ['./payroll-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PayrollInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PayrollInputComponent),
      multi: true
    }
  ]
})
export class PayrollInputComponent implements OnDestroy, ControlValueAccessor {

  form: FormGroup<PayrollInputForm>;

  PayFrequency = PayFrequency;
  PayrollType = PayrollType;
  Currency = Currency;
  WeekDay = WeekDay;

  frequencies: PayFrequency[] = Object.values(PayFrequency);
  currencies: Currency[] = Object.values(Currency);
  payrollTypes: PayrollType[] = Object.values(PayrollType);
  weekDays = Object.values(WeekDay);
  disabled = false;

  private ngUnsubscribe$ = new Subject();
  periodDates$ = new BehaviorSubject<PeriodDates>({
    periodStart: '',
    periodEnd: '',
    payDate: '',
    deductionsExportDate: ''
  });

  constructor(
    fb: FormBuilder,
    private systemService: SystemService,
    private payrollService: PayrollService
  ) {
    this.form = fb.group<PayrollInputForm>({
      currency: new FormControl<Currency>(Currency.GBP),
      frequency: new FormControl<PayFrequency>(null),
      type: new FormControl<PayrollType>(PayrollType.STANDARD)
    });

    this.form.controls.frequency.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(frequency => {
        if (frequency) {
          this.configureFrequencyControls(frequency);
          this.configureDelayChargeDateControl(frequency);
          this.configurePayDateDayControl(this.form.value.delayChargeDateToPayDate, frequency, this.form.value.type);
        }
      });

    this.form.controls.type.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(type => {
        if (type) {
          this.configureTypeControls(type);
          this.configurePayDateDayControl(this.form.value.delayChargeDateToPayDate, this.form.value.frequency, type);
          this.configurePaidInArrearsControl(type);
        }
      });


    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$), debounceTime(500))
      .subscribe(async formValue => {
        if (formValue.frequency !== PayFrequency.CUSTOM && this.form.valid) {
          const periodDates = await this.payrollService.getCurrentPeriodDates({
            currency: this.form.controls.currency.value,
            frequency: this.form.controls.frequency.value,
            type: this.form.controls.type.value,
            ...this.form.value
          });
          this.periodDates$.next({
            ...periodDates,
            periodEnd: format(parseDateYYYYMMDD(periodDates.periodEnd), 'dd/MM/yyyy'),
            payDate: periodDates.payDate ? format(parseDateYYYYMMDD(periodDates.payDate), 'dd/MM/yyyy') : null,
            deductionsExportDate: periodDates.deductionsExportDate ? format(parseDateYYYYMMDD(periodDates.deductionsExportDate), 'dd/MM/yyyy') : null
          });
        }
      });
  }

  private configureTypeControls(type: PayrollType) {
    if (type === PayrollType.STANDARD) {
      this.form.removeControl('deductionsExportDay');
      if (this.disabled) {
        this.form.controls.frequency.disable();
      } else {
        this.form.controls.frequency.enable();
      }
    } else if (type === PayrollType.ADVANCED) {
      this.form.addControl('deductionsExportDay', new FormControl<PayrollDay>({
          day: 17,
          avoidHolidaysAndWeekends: true
        }, [Validators.required, Validators.min(1), Validators.max(31)]
      ));
      this.form.controls.frequency.patchValue(PayFrequency.MONTHLY);
      this.form.controls.frequency.disable();
    }
  }

  sub: Subscription;

  configureDelayChargeDateControl(frequency: PayFrequency) {
    if (frequency === PayFrequency.CUSTOM || frequency === PayFrequency.MONTHLY) {
      this.form.addControl('delayChargeDateToPayDate', new FormControl(false));
      if (!this.sub) {
        this.sub = this.form.controls.delayChargeDateToPayDate.valueChanges
          .pipe(takeUntil(this.ngUnsubscribe$))
          .subscribe(delayCharge => {
            this.configurePayDateDayControl(delayCharge, this.form.value.frequency, this.form.value.type);
          });
      }
    } else {
      this.form.removeControl('delayChargeDateToPayDate');
      if (this.sub) {
        this.sub.unsubscribe();
        this.sub = null;
      }
    }
  }

  configurePayDateDayControl(delayChargeDateToPayDate: boolean, frequency: PayFrequency, type: PayrollType) {
    if ((delayChargeDateToPayDate && frequency === PayFrequency.MONTHLY) || type === PayrollType.ADVANCED) {
      this.form.addControl('payDateDay', new FormControl<PayrollDay>({
          day: 31,
          avoidHolidaysAndWeekends: true
        }, [Validators.required, Validators.min(1), Validators.max(31)]
      ));
    } else {
      this.form.removeControl('payDateDay');
    }
  }

  configurePaidInArrearsControl(type: PayrollType) {
    if ( type === PayrollType.ADVANCED ) {
      this.form.addControl('paidInArrears', new FormControl(false));
    } else {
      this.form.removeControl('paidInArrears');
    }
  }

  configureFrequencyControls(frequency: PayFrequency) {
    if (frequency === PayFrequency.CUSTOM) {
      this.form.addControl('customSchedule', new FormControl<CustomPeriod[]>([]));
      this.form.removeControl('periodEndDay');
      this.form.removeControl('twoOrFourWeeklyPeriodEnd');
      this.form.removeControl('periodEndWeekDay');

    } else if (frequency === PayFrequency.WEEKLY) {
      this.form.addControl('periodEndWeekDay', new FormControl<WeekDay>(WeekDay.FRIDAY));
      this.form.removeControl('periodEndDay');
      this.form.removeControl('twoOrFourWeeklyPeriodEnd');
      this.form.removeControl('customSchedule');
    } else if (frequency === PayFrequency.MONTHLY) {
      this.form.addControl('periodEndDay', new FormControl<PayrollDay>({
          day: 31,
          avoidHolidaysAndWeekends: true
        }, [Validators.required, Validators.min(1), Validators.max(31)]
      ));
      this.form.removeControl('periodEndWeekDay');
      this.form.removeControl('twoOrFourWeeklyPeriodEnd');
      this.form.removeControl('customSchedule');
    } else if (frequency === PayFrequency.FOUR_WEEKLY || frequency === PayFrequency.TWO_WEEKLY) {
      const defaultDate = formatDateYYYYMMDD(getNextWeekDay(WeekDay.FRIDAY, this.systemService.getSystemTime()));
      this.form.addControl('twoOrFourWeeklyPeriodEnd', new FormControl<string>(defaultDate));
      this.form.removeControl('periodEndDay');
      this.form.removeControl('periodEndWeekDay');
      this.form.removeControl('customSchedule');
    }
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(payroll: PayrollInputFormValue): void {
    if (payroll) {
      this.configureDelayChargeDateControl(payroll.frequency);
      this.configurePayDateDayControl(payroll.delayChargeDateToPayDate, payroll.frequency, payroll.type);
      this.configureTypeControls(payroll.type);
      this.configureFrequencyControls(payroll.frequency);
      this.configurePaidInArrearsControl(payroll.type);
      this.form.patchValue(payroll);
    }
  }

  validate(): ValidationErrors | null {
    return this.form.valid ? null : {payroll: true};
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }
}

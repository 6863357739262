<div class="file-chooser-container mat-typography">
  <button mat-stroked-button
          color="primary"
          type="button"
          (click)="onClick()">
    <mat-icon>file_upload</mat-icon>
    {{ buttonText }}
  </button>
</div>
<input #fileInput
       hidden
       [accept]=acceptFileType
       type="file">

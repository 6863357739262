<div class="input-form">

  <div class="label">{{ label }}</div>

  <form [formGroup]="form">

    <div class="date-row">

      <mat-form-field
        class="left-field">
        <mat-label>Day</mat-label>
        <input
          matInput
          type="number"
          formControlName="day"
          placeholder="Day of month" />

        <mat-error *ngIf="form.controls.day.invalid
                       && form.controls.day.dirty
                       && form.controls.day.touched
                       && form.controls.day.errors.required">
          Day is required
        </mat-error>

        <mat-error *ngIf="form.controls.day.invalid
                       && form.controls.day.dirty
                       && form.controls.day.touched
                       && (form.controls.day.errors.max
                        || form.controls.day.errors.min)">
          Day must be between 1 and 31
        </mat-error>

      </mat-form-field>

      <mat-form-field
        class="right-field">
        <mat-label>{{ label }}</mat-label>
        <input
          [value]="derivedDate"
          disabled="true"
          matInput
          placeholder="Derived Date" />
      </mat-form-field>

    </div>

    <mat-checkbox formControlName="avoidHolidaysAndWeekends">
      Avoid Holidays and Weekends
    </mat-checkbox>
  </form>
</div>

import { AbstractQueryDataSource } from 'common-ui/abstract-query-data-source/abstract-query-data-source';
import { DiscountsOpenApiService, DiscountCardDto, DiscountCardQuery } from 'common-ui/open-api';
import { Observable } from 'rxjs';

export class DiscountCardQueryDataSource
  extends AbstractQueryDataSource<DiscountCardDto, DiscountCardQuery> {

  constructor(
    private discountsService: DiscountsOpenApiService
  ) {
    super();
  }

  executeQuery(query: DiscountCardQuery): Observable<{
    data: DiscountCardDto[];
    total: number;
    tooManyRecords: boolean
  }> {
    return this.discountsService.queryDiscountCards(query);
  }
}

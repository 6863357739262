import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ContentType, ContentIcon } from 'common-ui/open-api';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CustomContentService } from 'common-ui/services/custom-content.service';
import { Environment } from 'common-ui/models/environment.type';

export interface CustomContentEditDialogData {
  create: boolean;
  id: string;
}

const textFieldValidators = [Validators.required, Validators.maxLength(400)];

@Component({
  selector: 'lib-custom-content-edit-dialog',
  templateUrl: './custom-content-edit-dialog.component.html',
  styleUrls: ['./custom-content-edit-dialog.component.css']
})
export class CustomContentEditDialogComponent implements OnInit {

  form: UntypedFormGroup;
  isLoading = true;
  contentTypes = Object.values(ContentType);
  ContentType = ContentType;
  contentIcons = Object.values(ContentIcon);
  errorMessage: string;
  assetsUrl: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CustomContentEditDialogData,
    private dialogRef: MatDialogRef<CustomContentEditDialogComponent>,
    private fb: UntypedFormBuilder,
    private customContentService: CustomContentService,
    @Inject('env') environment: Environment
  ) {
    this.form = fb.group({
      type: '',
      title: ['', [Validators.required, Validators.maxLength(60)]],
      text: ['', textFieldValidators],
      contentUrl: ['', Validators.required]
    });
    this.assetsUrl = environment.locations.images;

    this.form.controls['type'].valueChanges.subscribe(type => {
      this.setUpForm(type);
    });
  }

  private setUpForm(type: ContentType) {
    if ( type === ContentType.WELLBEING_EMAIL) {
      if (this.form.controls['icon']) {
        this.form.removeControl('icon');
      }
      if (!this.form.controls['text']) {
        this.form.addControl('text', new UntypedFormControl('', textFieldValidators));
      }
      if (!this.form.controls['contentUrl']) {
        this.form.addControl('contentUrl', new UntypedFormControl('', Validators.required));
      }
    } else if (type === ContentType.FAQ) {
      if (this.form.controls['contentUrl']) {
        this.form.removeControl('contentUrl');
      }
      if (!this.form.controls['text']) {
        this.form.addControl('text', new UntypedFormControl('', textFieldValidators));
      }
      if (this.form.controls['icon']) {
        this.form.removeControl('icon');
      }
    } else if (type === ContentType.TOOL) {
      if (!this.form.controls['contentUrl']) {
        this.form.addControl('contentUrl', new UntypedFormControl('', Validators.required));
      }
      if (this.form.controls['text']) {
        this.form.removeControl('text');
      }
      if (!this.form.controls['icon']) {
        this.form.addControl('icon', new UntypedFormControl(''));
      }
    } else {
      if (!this.form.controls['contentUrl']) {
        this.form.addControl('contentUrl', new UntypedFormControl('', Validators.required));
      }
      if (!this.form.controls['text']) {
        this.form.addControl('text', new UntypedFormControl('', textFieldValidators));
      }
      if (this.form.controls['icon']) {
        this.form.removeControl('icon');
      }
    }
  }

  async ngOnInit() {
    if (this.data.create) {
      this.form.patchValue({
        type: ContentType.ARTICLE
      });
    } else {
      const content = await this.customContentService.get(this.data.id);
      this.setUpForm(content.type);

      const value: any = {
        type: content.type,
        title: content.title,
        text: content.text,
        contentUrl: content.contentUrl
      };

      if (content.type === ContentType.TOOL) {
        delete value.text;
        value.icon = content.icon;
      }
      if (content.type === ContentType.FAQ) {
        delete value.contentUrl;
        delete value.icon;
      }
      this.form.setValue(value);
    }
    this.isLoading = false;
  }

  async submit() {
    try {
      if (this.data.create) {
        await this.customContentService.createContent(this.form.value);
      } else {
        await this.customContentService.updateContent(this.data.id, this.form.value);
      }
      this.dialogRef.close(true);
    } catch (err) {
      this.errorMessage = err.message;
    }
  }

  close() {
    this.dialogRef.close(false);
  }

  getImageUrl(icon: string) {
    if (icon) {
      return this.assetsUrl + '/' + icon.toString() + '.png';
    }
    return '';
  }

  getIconText(iconName: string) {
    return iconName.replace('_icon', '').replace('_', ' ');
  }
}

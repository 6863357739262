import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from 'common-ui/services/user.service';
import { AuthService } from 'common-ui/services';
import { LogoutTimerService } from 'common-ui/services/logout-timer.service';

@Injectable({
  providedIn: 'root'
})
export class PortalGuard  {
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private logoutTimer: LogoutTimerService
  ) {
  }

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    if (state.url === '/system-down') {
      return true;
    }

    if (!await this.authService.isSessionValid()) {
      return this.router.createUrlTree(['login']);
    }

    try {

      const user = await this.userService.getCurrentUser();
      const isPortalUser = await this.userService.isEmployerPortalUser();

      if (!this.logoutTimer.allowLogin()) {
        await this.authService.logout('You have been logged out due to inactivity');
      } else if (user.isSystemAdmin) {
        this.logoutTimer.init();
        return this.router.createUrlTree(['account-impersonator']);
      } else if (isPortalUser) {
        this.logoutTimer.init();
        return true;
      } else if (user.isEmployee) {
        return this.router.createUrlTree(['employee-portal']);
      } else {
        await this.authService.logout('Insufficient permissions to use the Portal')
      }

    } catch {
      await this.authService.logout('Failed to retrieve account details')
    }
  }

}


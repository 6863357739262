<div class="box">
  <div class="box-text">
    Withdrawal limit
  </div>
  <div class="number-box">

    <span class="major-number">{{ used }}</span>
    <span class="minor-number"> of </span>
    <span class="major-number">{{ allowed }}</span>
  </div>
  <div class="sub-message"
       *ngIf="subMessage">
    {{ subMessage }}
  </div>
</div>

import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { SelectionModel, DataSource } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { ToolbarDataService, FilterType, FilterService, WithdrawalService, SystemService } from 'common-ui/services';
import {
  WithdrawalDto,
  WithdrawalQuery,
  SortDirection,
  PaymentStatusEnum,
  WithdrawalOpenApiService
} from 'common-ui/open-api';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { BehaviorSubject, Observable, Subject, firstValueFrom, take } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { WeekSelectorComponent } from 'common-ui/week-selector/week-selector.component';
import { WithdrawalsSettledFilter, Pages } from 'common-ui/services/filter.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { getJustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';
import { Environment } from 'common-ui/models/environment.type';
import { JsonDialogComponent } from 'common-ui/json-dialog/json-dialog.component';
import {
  AreYouSureAsyncDialogComponent
} from 'common-ui/are-you-sure-async-dialog/are-you-sure-async-dialog.component';
import { pennyToPound } from 'common-ui/pipes/pennyToPound.pipe';

class WithdrawalDataSource extends DataSource<WithdrawalDto> {

  private dataSubject = new BehaviorSubject<WithdrawalDto[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(true);
  public loading$ = this.loadingSubject.asObservable();
  public total$ = new BehaviorSubject<number>(0);
  public queryToLarge$ = new BehaviorSubject<boolean>(false);

  constructor(
    private withdrawalService: WithdrawalService
  ) {
    super();
  }

  get currentValue() {
    return this.dataSubject.value;
  }

  connect(): Observable<WithdrawalDto[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.total$.complete();
  }

  async load(withdrawalQuery: WithdrawalQuery) {
    this.loadingSubject.next(true);
    this.queryToLarge$.next(false);
    try {
      const results = await this.withdrawalService.queryWithdrawals(withdrawalQuery);
      if (results.tooManyRecords) {
        this.queryToLarge$.next(true);
      }
      this.total$.next(results.total);
      this.dataSubject.next(results.data);
    } catch (err) {
      console.log(err);
    }
    this.loadingSubject.next(false);
  }
}

@Component({
  selector: 'lib-withdrawals-page',
  templateUrl: './withdrawals-page.component.html',
  styleUrls: ['./withdrawals-page.component.css']
})
export class WithdrawalsPageComponent implements OnInit, AfterViewInit, OnDestroy {

  public masterAccountIds: string[];

  constructor(
    private toolbarDataService: ToolbarDataService,
    private filterService: FilterService,
    private withdrawalService: WithdrawalService,
    private withdrawalOpenApiService: WithdrawalOpenApiService,
    private dialog: MatDialog,
    private systemService: SystemService,
    @Inject('env') public environment: Environment
  ) {
    if (this.isConsole) {
      this.displayedColumns.push('paymentApi');
      this.displayedColumns.push('actions');
    }
    this.dataSource = new WithdrawalDataSource(this.withdrawalService);
  }

  public dataSource: WithdrawalDataSource;
  public selection = new SelectionModel<WithdrawalDto>(true, []);
  public FilterType = FilterType;
  public PaymentStatus = PaymentStatusEnum;
  public displayedColumns = [
    'firstName',
    'lastName',
    'companyName',
    'date',
    'time',
    'amount',
    'fee',
    'paymentStatus',
    'settlementStatus',
    'type'
  ];
  private ngUnsubscribe = new Subject();
  filterOptions = Object.values(WithdrawalsSettledFilter).map(f => ({
    value: f.toString(),
    description: f.toString()
  }));

  @ViewChild('weekSelector') weekSelector: WeekSelectorComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle', title: 'Withdrawals'
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => await this.loadData());

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      await this.loadData();
      this.paginator.firstPage();
    });

    const initialSort = this.filterService.getPageSort(Pages.WITHDRAWAL);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.WITHDRAWAL, newSort.active, newSort.direction as SortDirection);
      });

    await this.loadData();
  }

  async loadData() {
    const currentFilter = this.filterService.currentFilter;
    const justDateRange = getJustDateRange(currentFilter.dateRange, this.systemService.getSystemTime());

    const query: WithdrawalQuery = {
      offset: this.paginator.pageIndex * this.environment.defaultPageSize,
      limit: this.environment.defaultPageSize,
      fromDate: justDateRange.fromDate,
      toDate: justDateRange.toDate,
      employeeName: currentFilter.employeeSearch,
      masterAccountIds: currentFilter.masterAccounts,
      companyIds: currentFilter.companies,
      paymentStatuses: currentFilter.paymentStatuses,
      withdrawalTypes: currentFilter.withdrawalTypes
    };

    if (this.sort.active) {
      const sort = {};
      sort[this.sort.active] = this.sort.direction;
      query.sort = sort;
    }

    if (currentFilter.withdrawalsSettled) {
      query.isSettled = currentFilter.withdrawalsSettled === WithdrawalsSettledFilter.SETTLED;
    }

    if (currentFilter.invoiceNumber) {
      query.invoiceNumber = currentFilter.invoiceNumber;
    }

    this.masterAccountIds = currentFilter.masterAccounts;

    await this.dataSource.load(query);
  }

  checkboxLabel(row?: WithdrawalDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} withdrawal`;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.currentValue.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.currentValue.forEach(row => this.selection.select(row));
    }
  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  openJsonDataDialog(withdrawal: WithdrawalDto) {
    this.dialog.open(JsonDialogComponent, {
      data: {
        header: 'Withdrawal JSON',
        data: withdrawal
      },
      autoFocus: false
    });
  }

  async updateWithdrawalStatus(withdrawalId: string) {
    await this.withdrawalService.updateWithdrawalStatus(withdrawalId);
    await this.loadData();
  }

  async openRefundingDialog(withdrawal: WithdrawalDto, isRefunded: boolean) {
    const ref = this.dialog.open(AreYouSureAsyncDialogComponent, {
      data: {
        heading: isRefunded ? 'Confirm Refunded' : 'Cancel Refunded',
        message: `Withdrawal ${pennyToPound(withdrawal.amount, '0', false, withdrawal.currency)} \
                  to ${withdrawal.firstName} ${withdrawal.lastName} will be set to ${isRefunded ? 'Failed' : 'Success'}.`,
        asyncFn: async () => {
          await firstValueFrom(this.withdrawalOpenApiService.setWithdrawalRefunded(withdrawal._id, {isRefunded}));
        }
      }
    });

    ref.afterClosed().pipe(take(1)).subscribe(async (result) => {
      if (result) {
        await this.loadData();
      }
    });
  }
}

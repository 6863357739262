import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import {
  PeriodHistoryUpdateDto,
  PeriodHistoryQuery,
  PeriodHistoryQueryResultDto,
  PeriodHistoryOpenApiService
} from 'common-ui/open-api';
import { PeriodExportType } from 'common-ui/models/period-export-type';
import { FileDownloadService } from 'common-ui/services/file-download.service';

@Injectable({
  providedIn: 'root'
})
export class PeriodHistoryService {
  constructor(
    private periodHistoryOpenApiService: PeriodHistoryOpenApiService,
    private fileDownloadService: FileDownloadService,
    private apiService: ApiService) {
  }

  async regenerateFiles(periodId: string) {
    await this.apiService.post(`/api/payroll-period/${periodId}/regen-files`, {});
  }

  async downloadFile(periodId: string, exportType: PeriodExportType) {
    const resObservable = this.periodHistoryOpenApiService.downloadFile(periodId, exportType, 'response');
    await this.fileDownloadService.downloadFile(resObservable);
  }

  async updatePeriodHistory(periodId: string, update: PeriodHistoryUpdateDto) {
    await this.apiService.put(`/api/payroll-period/${periodId}`, update);
  }

  async query(query: PeriodHistoryQuery): Promise<PeriodHistoryQueryResultDto> {
    return await this.apiService.post('/api/payroll-period/query', query);
  }
}

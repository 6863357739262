<mat-form-field
  class="form-field">
  <mat-label>Employee</mat-label>
  <mat-autocomplete
    (optionSelected)="selectEmployee($event)"
    #autocomplete="matAutocomplete"
    [displayWith]="displayWith">
    <mat-option
      *ngFor="let employee of this.filteredEmployees"
      [value]="employee">
      <div class="option-content">
        <div>
          {{ employee.firstName + ' ' + employee.lastName + ' (' + employee.masterAccountName + ')' }}
        </div>
        <div class="subtext">
          {{ employee.email }}
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>

  <input
    type="text"
    placeholder="Employee Name"
    matInput
    [formControl]="control"
    [matAutocompleteDisabled]="disableAutoComplete"
    [matAutocomplete]="autocomplete">
</mat-form-field>

<lib-dialog-container (exit)="close()"
                      [header]="mapping.label | camelToRegular"
                      [subHeader]="subHeading"
                      [maxHeaderWidth]="'350px'">

  <lib-spinner-container [isWorking]="isWorking" *ngIf="csvConfig">
    <div class="container">

      <div *ngIf="mapping.targetField?.helpText">
        <p>{{ mapping.targetField.helpText}}</p>
      </div>

      <mat-form-field class="column-chooser-form-field">
        <mat-label>Your Column</mat-label>
        <mat-select
          [(ngModel)]="updatedCsvColumnName"
          (ngModelChange)="mappingChanged($event)">

          <mat-option value="clear">Clear</mat-option>

          <mat-option *ngFor="let heading of headings"
                      [value]="heading.columnName">{{ heading.columnDescription }}
          </mat-option>

        </mat-select>
      </mat-form-field>

      <table>
        <tr>
          <td class="label-col">Required:</td>
          <td class="value-col">
            <lib-tick-cross [value]="mapping.isRequired"></lib-tick-cross>
          </td>
        </tr>

        <tr>
          <td>Unique:</td>
          <td>
            <lib-tick-cross [value]="isUnique"></lib-tick-cross>
          </td>
        </tr>

        <tr>
          <td>Case Sensitive:</td>
          <td>
            <lib-tick-cross [value]="!mapping.targetField.isCaseInsensitive"></lib-tick-cross>
          </td>
        </tr>

        <tr *ngIf="!isEnum">
          <td>Example Values:</td>
          <td>
            <div *ngFor="let example of mapping.targetField.exampleValues">
              {{ example }}
            </div>
          </td>
        </tr>

        <tr *ngIf="isEnum">
          <td class="allowedValues">Allowed Values:</td>
          <td class="allowedValuesV">
            <div *ngFor="let example of mapping.targetField.exampleValues">
              {{ example }}
            </div>
          </td>
        </tr>

      </table>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <lib-button-panel>
        <button mat-button
                color="primary"
                (click)="close()">
          Close
        </button>

        <button mat-flat-button
                color="primary"
                [disabled]="!isEdited || !isEditable"
                (click)="save()">
          Save
        </button>
      </lib-button-panel>

    </div>
  </lib-spinner-container>

</lib-dialog-container>

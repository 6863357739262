<lib-dialog-container (exit)="close(false)"
                      [header]="(data.create ? 'Create' : 'Edit') + ' Company'"
                      [subHeader]="data.subHeader">
  <lib-company-edit
    (operationCancelled)="close(false)"
    (operationComplete)="close(true)"
    [companyId]="data.companyId"
    [masterAccountId]="data.masterAccountId"
    [create]="data.create">
  </lib-company-edit>
</lib-dialog-container>

<mat-form-field
  class="form-field"
  *ngIf="!isLoading">
  <mat-label>Master Account</mat-label>
  <mat-autocomplete
    #autocomplete="matAutocomplete"
    (optionSelected)="selectMasterAccount($event)"
    [displayWith]="displayWith">
    <mat-option
      *ngFor="let masterAccount of this.filteredMasterAccounts"
      [value]="masterAccount">
      {{masterAccount.name}}
    </mat-option>
  </mat-autocomplete>

  <input
    type="text"
    placeholder="Master Account"
    matInput
    [formControl]="masterAccountControl"
    [matAutocomplete]="autocomplete">

  <button mat-button
          *ngIf="masterAccountControl.value"
          matSuffix mat-icon-button
          aria-label="Clear"
          [disabled]="disabled"
          type="button"
          (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>

import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import { PayCodeDto } from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class PayCodeService {
  constructor(private apiService: ApiService) {
  }

  async getPayCodesForCompany(companyId: string): Promise<PayCodeDto[]> {
    return this.apiService.get(`/api/pay-code?companyId=${companyId}`)
  }
}

<lib-employee-portal-page *ngIf="selectedProfile"
                          title="Make a Withdrawal"
                          class="mat-typography">
  <lib-spinner-container [isWorking]="isWorking">

    <mat-form-field *ngIf="isMultiCompany"
                    class="company-select">
      <mat-select [formControl]="selectedCompanyIdControl">
        <mat-option *ngFor="let companyProfile of profiles"
                    [value]="companyProfile.companyId">
          {{ companyProfile.companyName }}
        </mat-option>
      </mat-select>
      <mat-hint>Select Employer</mat-hint>
    </mat-form-field>

    <ng-container *ngIf="selectedProfile.isInClosedPeriod">
      <lib-employee-portal-withdraw-page-in-closed-period
        [profile]="selectedProfile">
      </lib-employee-portal-withdraw-page-in-closed-period>
    </ng-container>

    <ng-container *ngIf="!selectedProfile.isInClosedPeriod">
      <lib-employee-portal-company-withdrawal
        [profile]="selectedProfile">
      </lib-employee-portal-company-withdrawal>
    </ng-container>

  </lib-spinner-container>
</lib-employee-portal-page>

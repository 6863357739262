import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';
import { CompanyService } from 'common-ui/services/company.service';
import { PostcodeFormatValidator } from '../util/postcode-validation';
import { CompanyDto, CreateCompanyDto, UpdateCompanyDto, AddressCreateDto, ApiSource } from 'common-ui/open-api';
import { setFormEnabled } from 'common-ui/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Environment } from 'common-ui/models/environment.type';

@Component({
  selector: 'lib-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.css']
})
export class CompanyEditComponent implements OnInit, OnDestroy {

  @Input() create: boolean;
  @Input() companyId: string;
  @Input() masterAccountId: string;
  @Input() showButtonPanel = true;
  @Output() operationComplete = new EventEmitter<string>();
  @Output() operationCancelled = new EventEmitter();
  form: FormGroup;
  isLoading = false;
  errorMessage = '';
  timesheetIntegrationSource: ApiSource;
  company: CompanyDto;
  ApiSource = ApiSource;
  private ngUnsubscribe = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    @Inject('env') private env: Environment
  ) {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.minLength(3)]],
      isLegalEntityNameDifferent: false,
      firstLine: [null, [this.fieldRequiredValidator.bind(this), Validators.maxLength(50), Validators.minLength(2)]],
      secondLine: [null, [Validators.maxLength(50), Validators.minLength(2)]],
      town: [null, [this.fieldRequiredValidator.bind(this), Validators.maxLength(50), Validators.minLength(2)]],
      county: [null, [this.fieldRequiredValidator.bind(this), Validators.maxLength(50), Validators.minLength(2)]],
      country: [{value: 'UK', disabled: true}, [this.fieldRequiredValidator.bind(this)]],
      postcode: [null, [this.fieldRequiredValidator.bind(this), PostcodeFormatValidator]],
      mandateId: ''
    });

    this.form.controls.isLegalEntityNameDifferent
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(isLegalEntityDifferent => {
        if ( isLegalEntityDifferent ) {
          this.form.addControl('legalEntityName', new FormControl('', [ Validators.required]))
        } else {
          this.form.removeControl('legalEntityName')
        }
      })
  }

  get isConsole() {
    return this.env.adminConsole
  }

  fieldRequiredValidator(control: AbstractControl): ValidationErrors {
    if (this.hasSource()) {
      return null;
    } else {
      return Validators.required(control);
    }
  }

  hasSource() {
    return this.company?.timesheetIntegrationSource;
  }

  async ngOnInit() {
    this.isLoading = true;
    if (!this.create) {
      this.company = await this.companyService.getCompany(this.companyId);
      await this.setFormValuesWithExistingDetails(this.company);
      for (const key of Object.keys(this.form.controls)) {
        this.form.controls[key].updateValueAndValidity();
      }
    }
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined)
    this.ngUnsubscribe.complete()
  }

  async onSubmit() {
    try {
      this.errorMessage = '';
      this.isLoading = true;
      if (this.create) {
        this.companyId = await this.companyService.createCompany(this.getCreateDto());
      } else {
        const updateDto = this.getUpdateDto();
        if (!updateDto) {
          this.operationCancelled.emit();
        } else {
          await this.companyService.updateCompany(this.companyId, updateDto);
        }
      }
      this.isLoading = false;
      this.operationComplete.emit(this.companyId);
    } catch (err) {
      this.isLoading = false;
      this.errorMessage = err.message;
    }
  }

  getAddressFromForm(): AddressCreateDto {
    return {
      firstLine: this.form.controls.firstLine.value,
      town: this.form.controls.town.value,
      county: this.form.controls.county.value,
      postcode: this.form.controls.postcode.value,
      secondLine: this.form.controls.secondLine.value
    };
  }

  getCreateDto(): CreateCompanyDto {
    const createCompanyDto: CreateCompanyDto = {
      masterAccountId: this.masterAccountId,
      name: this.form.value.name,
      address: this.getAddressFromForm(),
      isLegalEntityNameDifferent: this.form.value.isLegalEntityNameDifferent,
      mandateId: this.form.value.mandateId
    };
    if ( createCompanyDto.isLegalEntityNameDifferent) {
      createCompanyDto.legalEntityName = this.form.value.legalEntityName
    }
    return createCompanyDto
  }

  getUpdateDto(): UpdateCompanyDto {
    const updateCompanyDto: UpdateCompanyDto = {
      name: this.form.controls.name.value,
      address: this.getAddressFromForm(),
      isLegalEntityNameDifferent: this.form.value.isLegalEntityNameDifferent,
      mandateId: this.form.value.mandateId
    };
    if ( updateCompanyDto.isLegalEntityNameDifferent) {
      updateCompanyDto.legalEntityName = this.form.value.legalEntityName
    }
    if (this.company.timesheetIntegrationId) {
      updateCompanyDto.companyNameOverride = this.form.controls.name.enabled;
    }
    return updateCompanyDto;
  }

  async setFormValuesWithExistingDetails(company: CompanyDto) {
    const address = company.address;
    this.timesheetIntegrationSource = company.timesheetIntegrationSource;
    const initialData = {
      name: company.name,
      firstLine: address.firstLine,
      secondLine: address.secondLine,
      town: address.town,
      county: address.county,
      postcode: address.postcode,
      isLegalEntityNameDifferent: company.isLegalEntityNameDifferent,
      legalEntityName: company.legalEntityName,
      mandateId: company.mandateId
    };

    if (!this.create) {
      setFormEnabled(this.form, company.enabledProperties);
      if (company.companyNameOverride) {
        this.form.controls.name.enable();
      }
    }

    this.form.patchValue(initialData);
  }

  close() {
    this.operationCancelled.emit();
  }

  hasError(control: AbstractControl, key: string) {
    return control.touched && control.hasError(key);
  }

  toggleCompanyNameControl(event: MouseEvent) {
    event.preventDefault();

    if (this.form.controls.name.enabled) {
      this.form.controls.name.disable();
    } else {
      this.form.controls.name.enable();
    }

    this.form.controls.name.markAsDirty();
  }
}

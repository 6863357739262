<lib-dialog-container
  (exit)="close()"
  [showCross]="true">

  <div class="about-dialog">
    <div class="logo-container">
      <img alt="FlexEarn logo" draggable="false"
           src="https://assets.flexearn.com/portal/v1/images/logo-purple.svg" width="150px">
    </div>

    <div class="details-table">

      <div>
        <div class="label">Version</div>
        <div>{{ this.version }}</div>
      </div>

      <div *ngIf="this.environment">
        <div class="label">Environment</div>
        <div>{{ this.environment.environment | hyphenatedToRegular }}</div>
      </div>

      <div>
        <div class="label">User Id</div>
        <div>{{ this.userId }}</div>
      </div>
    </div>

    <div class="copyright-info">
      <div>Copyright 2023 FlexEarn Ltd</div>
    </div>
  </div>
</lib-dialog-container>

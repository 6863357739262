import { Component, Input } from '@angular/core';
import { FilterType, FilterItem } from 'common-ui/services';

@Component({
  selector: 'lib-boolean-selector',
  templateUrl: './boolean-selector.component.html',
  styleUrls: ['./boolean-selector.component.css']
})
export class BooleanSelectorComponent {
  @Input() filterType: FilterType;
  @Input() description: string;

  FilterType = FilterType;

  values: FilterItem[] = [{
    value: 'yes',
    description: 'Yes'
  }, {
    value: 'no',
    description: 'No'
  }];
}

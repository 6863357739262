import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'common-ui/services/customer.service';
import { ProfileDto } from 'common-ui/open-api';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  EmployeePortalBankDetailsDialogComponent
} from '../employee-portal-bank-details-dialog/employee-portal-bank-details-dialog.component';

@Component({
  selector: 'lib-employee-portal-account-settings',
  templateUrl: './employee-portal-account-settings.component.html',
  styleUrls: ['./employee-portal-account-settings.component.css']
})
export class EmployeePortalAccountSettingsComponent implements OnInit {

  profiles: ProfileDto[];
  isWorking = true;

  constructor(
    private customerService: CustomerService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    this.profiles = await this.customerService.getProfiles();
    this.isWorking = false;
  }

  get profile(): ProfileDto {
    return this.profiles ? this.profiles[0] : null;
  }

  openBankDetailsDialog() {
    const ref = this.dialog.open(EmployeePortalBankDetailsDialogComponent, {
      data: {
        employeeId: this.profile.employeeId,
        accountNumber: this.profile.accountNumber,
        sortCode: this.profile.sortCode,
        iban: this.profile.iban,
        currency: this.profile.currency
      },
      width: '350px'
    });
    ref.afterClosed().subscribe(async success => {
      if (success) {
        this.profiles = await this.customerService.getProfiles();
      }
    });
  }
}

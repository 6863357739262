import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-employee-portal-page',
  templateUrl: './employee-portal-page.component.html',
  styleUrls: ['./employee-portal-page.component.css']
})
export class EmployeePortalPageComponent {
  @Input() title: string;

  constructor() {
  }
}

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class AbstractQueryDataSource<DataT, QueryT> extends DataSource<DataT> {

  private dataSubject = new BehaviorSubject<DataT[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();
  public total$ = new BehaviorSubject<number>(0);
  public queryToLarge$ = new BehaviorSubject<boolean>(false);

  get currentValue() {
    return this.dataSubject.value;
  }

  connect(): Observable<DataT[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.total$.complete();
  }

  async load(query: QueryT) {
    if (this.loadingSubject.value == true) {
      return;
    }

    this.loadingSubject.next(true);
    this.queryToLarge$.next(false);
    try {
      this.executeQuery(query)
        // .pipe(
        //   finalize(() => this.loadingSubject.next(false)))
        .subscribe(async result => {
          if (result.tooManyRecords) {
            this.queryToLarge$.next(true);
          }
          this.total$.next(result.total);
          this.dataSubject.next(result.data);
          this.loadingSubject.next(false);
        });
    } catch (err) {
      this.loadingSubject.next(false);
      console.log(err);
    }
  }

  abstract executeQuery(query: QueryT): Observable<{
    data: DataT[];
    total: number;
    tooManyRecords: boolean;
  }>;
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { WarningDialogComponent } from '../warning-dialog/warning-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'lib-file-chooser',
  templateUrl: './file-chooser.component.html',
  styleUrls: ['./file-chooser.component.css']
})
export class FileChooserComponent implements AfterViewInit {

  @Input() buttonText = 'Choose File';
  @Input() acceptFileType = '.csv';
  @Input() truncateChars = 100;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() fileSelected = new EventEmitter<string[]>();

  constructor(private dialog: MatDialog) {
  }

  ngAfterViewInit(): void {
    this.fileInput.nativeElement.onchange = async () => {
      const files = this.fileInput.nativeElement.files;
      if (files.length > 0) {
        const file = this.fileInput.nativeElement.files[0];
        if (file.size > 50 * 1024 * 1024) {
          this.openWarningDialog();
        } else {
          const content = await this.loadFile(file);
          this.fileSelected.emit(content);
        }
      }
    };
  }

  onClick() {
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  async loadFile(file: File): Promise<string[]> {
    return new Promise<string[]>(resolve => {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        const content = fileReader.result.toString()
          .replace(/\r\n/g, '\n')
          .split('\n');
        resolve(content);
      };
      fileReader.readAsText(file);
    });
  }

  openWarningDialog() {
    this.dialog.open(WarningDialogComponent, {
      data: {
        warning: 'Sorry, this file is too large.  Our file size limit is 50Mb'
      }
    });
  }

}

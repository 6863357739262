import { Component, forwardRef, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { Subject, firstValueFrom } from 'rxjs';
import { CompanyDto, CompanyOpenApiService } from 'common-ui/open-api';
import { MatLegacySelectChange } from '@angular/material/legacy-select';


export const COMPANY_SELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CompanySelectInputComponent),
  multi: true
};

const COMPANY_SELECT_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CompanySelectInputComponent),
  multi: true
};

@Component({
  selector: 'lib-company-select-input',
  templateUrl: './company-select-input.component.html',
  styleUrls: ['./company-select-input.component.css'],
  providers: [COMPANY_SELECT_VALUE_ACCESSOR, COMPANY_SELECT_VALIDATOR]
})
export class CompanySelectInputComponent implements OnDestroy, OnChanges, ControlValueAccessor {
  @Input() masterAccountId: string;
  companies: CompanyDto[];
  selectedCompany: CompanyDto;

  onTouched: () => void;
  onChange: (val: string) => void;
  selectedCompanyId: string;
  private ngUnsubscribe$ = new Subject<void>();
  disabled = false;

  constructor(
    private companyService: CompanyOpenApiService
  ) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.masterAccountId) {
      if (changes.masterAccountId.currentValue) {
        this.companies = (await firstValueFrom(this.companyService.queryCompanies({
          masterAccountIds: [changes.masterAccountId.currentValue]
        }))).data;
        if (this.selectedCompanyId) {
          this.selectedCompany = this.companies?.find(c => c._id === this.selectedCompanyId);
        } else {
          this.selectedCompany = this.companies[0];
          this.selectedCompanyId = this.selectedCompany._id;
          setTimeout(() => {
            this.onChange(this.selectedCompany._id);
          });
        }
      } else {
        console.log('company input clear selected company');
        setTimeout(() => {
          this.selectedCompany = null;
          this.selectedCompanyId = null;
          this.onChange('');
        });
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  // eslint-disable-next-line
  validate(control: FormControl) {
    return null;
  }

  onBlur() {
    this.onTouched();
  }

  onInputBlur() {
    this.onTouched();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  writeValue(v: any) {
    this.selectedCompanyId = v;
    this.selectedCompany = this.companies?.find(c => c._id === v);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  companySelected(event: MatLegacySelectChange) {
    this.onChange(event.value?._id ?? null);
  }
}

<lib-spinner-container [isWorking]="isWorking">

  <form *ngIf="form"
        class="settings-form"
        (ngSubmit)="update()"
        [formGroup]="form">

    <mat-form-field>
      <mat-label>Account Name</mat-label>
      <input formControlName="name" matInput placeholder="Master Account Name">
    </mat-form-field>

    <mat-form-field *ngIf="isConsole">
      <mat-label>Payment API</mat-label>
      <mat-select formControlName="paymentApi">
        <mat-option *ngFor="let type of paymentApiTypes" [value]="type">{{ type | hyphenatedToRegular }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox
      *ngIf="isConsole"
      formControlName="discountCardsEnabled">
      Discount Cards Enabled
    </mat-checkbox>

    <mat-checkbox
      *ngIf="isConsole"
      formControlName="savingsEnabled">
      Savings Enabled
    </mat-checkbox>

    <mat-checkbox
      *ngIf="isConsole"
      formControlName="screenLockEnabled">
      Screen Lock Enabled
    </mat-checkbox>

    <mat-form-field *ngIf="isConsole">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of masterAccountTypes" [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Payroll Export Type</mat-label>
      <mat-select formControlName="payrollExportType">
        <mat-option *ngFor="let type of payrollExportTypes" [value]="type">{{ type | payrollExportType }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field
      class="conditional-field-indent"
      *ngIf="form.controls['payrollExportType'].value===PayrollExportType.SAGE_50C">
      <mat-label>Deduction Id</mat-label>
      <input formControlName="deductionId"
             matInput
             placeholder="Deduction Id">
      <mat-error *ngIf="form.controls.deductionId.invalid && form.controls.deductionId.dirty
            && form.controls.deductionId.touched && form.controls.deductionId.errors.maxlength">
        Maximum length of Deduction Id is 50 characters
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Withdrawal Period Rollover Timing</mat-label>
      <mat-select formControlName="withdrawalPeriodEndType">
        <mat-option *ngFor="let type of withdrawalPeriodEndTypes"
                    [value]="type">
          {{ type | withdrawalPeriodEndType }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="input-field right">
      <mat-label>Default Withdrawal Allowance</mat-label>
      <input matInput
             suffix="%"
             mask="percent"
             [dropSpecialCharacters]="false"
             placeholder="Default Withdrawal Allowance"
             formControlName="defaultAllowance">

      <mat-error *ngIf="form.controls.defaultAllowance.invalid
                       && form.controls.defaultAllowance.dirty
                       && form.controls.defaultAllowance.touched
                       && (form.controls.defaultAllowance.errors.max ||
                           form.controls.defaultAllowance.errors.min
                          )">
        Default Allowance must be between 0% and 100%
      </mat-error>

      <mat-error *ngIf="form.controls.defaultAllowance.invalid
                       && form.controls.defaultAllowance.dirty
                       && form.controls.defaultAllowance.touched
                       && (form.controls.defaultAllowance.errors.required
                          )">
        Default Allowance is required
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="input-field right">
      <mat-label>Overtime Withdrawal Allowance</mat-label>
      <input matInput
             suffix="%"
             mask="percent"
             [dropSpecialCharacters]="false"
             placeholder="Overtime Withdrawal Allowance"
             formControlName="overtimeAllowance">

      <mat-error *ngIf="form.controls.overtimeAllowance.invalid
                       && form.controls.overtimeAllowance.dirty
                       && form.controls.overtimeAllowance.touched
                       && (form.controls.overtimeAllowance.errors.max ||
                           form.controls.overtimeAllowance.errors.min
                          )">
        Overtime Allowance must be between 0% and 100%
      </mat-error>

      <mat-error *ngIf="form.controls.overtimeAllowance.invalid
                       && form.controls.overtimeAllowance.dirty
                       && form.controls.overtimeAllowance.touched
                       && (form.controls.overtimeAllowance.errors.required
                          )">
        Overtime Allowance is required
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="input-field right">
      <mat-label>Zero Hours Withdrawal Allowance</mat-label>
      <input matInput
             suffix="%"
             mask="percent"
             [dropSpecialCharacters]="false"
             placeholder="Zero Withdrawal Allowance"
             formControlName="zeroHoursAllowance">

      <mat-error *ngIf="form.controls.zeroHoursAllowance.invalid
                       && form.controls.zeroHoursAllowance.dirty
                       && form.controls.zeroHoursAllowance.touched
                       && (form.controls.zeroHoursAllowance.errors.max ||
                           form.controls.zeroHoursAllowance.errors.min
                          )">
        Zero Hours Allowance must be between 0% and 80%
      </mat-error>

      <mat-error *ngIf="form.controls.zeroHoursAllowance.invalid
                       && form.controls.zeroHoursAllowance.dirty
                       && form.controls.zeroHoursAllowance.touched
                       && (form.controls.zeroHoursAllowance.errors.required
                          )">
        Zero Hours Allowance is required
      </mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="employerPaysFee">
      Employer Pays Fee
    </mat-checkbox>

    <div
      class="employerPaysFeeContainer"
      *ngIf="form.controls['employerPaysFee'].value === true">

      <lib-datepicker
        class="employerPaysFeeStartDate"
        formControlName="employerPaysFeeStartDate"
        label="Start Date">
      </lib-datepicker>

      <lib-datepicker
        class="employerPaysFeeEndDate"
        formControlName="employerPaysFeeEndDate"
        label="End Date">
      </lib-datepicker>
    </div>


    <mat-form-field>
      <mat-label>Withdrawal Fee</mat-label>

      <input formControlName="withdrawalFee"
             matInput
             (blur)="formatFee()"
             [value]="form.get('withdrawalFee').value"
             data-e2e="withdrawal-fee"
             placeholder="Withdrawal Fee">

      <mat-error *ngIf="form.controls.withdrawalFee.invalid
                       && form.controls.withdrawalFee.dirty
                       && form.controls.withdrawalFee.touched">
        Minimum Withdrawal Fee is £1.50
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Minimum Withdrawal</mat-label>
      <input formControlName="minimumWithdrawal"
             matInput
             mask="00"
             prefix="£"
             placeholder="Minimum Withdrawal">
      <mat-error *ngIf="form.controls.minimumWithdrawal.invalid
                       && form.controls.minimumWithdrawal.dirty
                       && form.controls.minimumWithdrawal.touched">
        Minimum Withdrawal must be between £10.00 and £50.00
      </mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="autoInviteNewEmployees">
      Auto Invite New Employees
    </mat-checkbox>

    <mat-checkbox
      class="conditional-field-indent"
      *ngIf="form.controls['autoInviteNewEmployees'].value"
      formControlName="autoInviteTimesheetEmployeesOnly">
      Timesheet Employees Only
    </mat-checkbox>

    <mat-form-field
      class="conditional-field-indent"
      *ngIf="form.controls['autoInviteNewEmployees'].value">
      <mat-label>Amount Available Threshold</mat-label>
      <input formControlName="autoInviteAmountAvailableThreshold"
             matInput
             mask="0*"
             prefix="£"
             placeholder="Amount Available Threshold">
      <mat-error *ngIf="form.controls.autoInviteAmountAvailableThreshold.invalid
                       && form.controls.autoInviteAmountAvailableThreshold.dirty
                       && form.controls.autoInviteAmountAvailableThreshold.touched">
        Threshold must be less than £200.00
      </mat-error>
    </mat-form-field>

    <mat-checkbox
      class="conditional-field-indent"
      *ngIf="isConsole && form.controls['autoInviteNewEmployees'].value"
      formControlName="noSignInBeforeInvite">
      Prevent Sign-In Before Invite
    </mat-checkbox>

    <mat-checkbox
      *ngIf="isConsole"
      formControlName="activatePushNotifications">
      Activate Push Notifications
    </mat-checkbox>

    <mat-checkbox formControlName="activateWellbeingCampaign">
      Activate Wellbeing Campaign
    </mat-checkbox>

    <mat-checkbox
      formControlName="activateAmountAvailableThresholdEmails">
      Activate Amount Available Emails
    </mat-checkbox>

    <div>
      <mat-form-field
        class="conditional-field-indent"
        *ngIf="form.controls['activateAmountAvailableThresholdEmails'].value">
        <mat-label>Amount Available Threshold</mat-label>
        <input formControlName="amountAvailableThreshold"
               matInput
               mask="0*"
               prefix="£"
               placeholder="Threshold">
        <mat-error *ngIf="form.controls.amountAvailableThreshold.invalid
                       && form.controls.amountAvailableThreshold.dirty
                       && form.controls.amountAvailableThreshold.touched">
          Threshold must be above £10.00
        </mat-error>
      </mat-form-field>
    </div>

    <lib-error-message [message]="errorMessage"></lib-error-message>

    <div class="button-panel">

      <button mat-flat-button
              color="primary"
              data-e2e="save-master-account"
              [disabled]="form.invalid || form.pristine"
              type="submit">
        Save
      </button>
    </div>

  </form>
</lib-spinner-container>

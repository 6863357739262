import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { FormGroup, FormControl } from '@angular/forms';

export interface EnterStringDialogData {
  title: string,
  placeholder: string
  buttonText: string;
}

@Component({
  selector: 'lib-enter-string-dialog',
  templateUrl: './enter-string-dialog.component.html',
  styleUrls: ['./enter-string-dialog.component.css']
})
export class EnterStringDialogComponent {

  form = new FormGroup({
    stringValue: new FormControl('')
  });

  constructor(
    private dialogRef: MatDialogRef<EnterStringDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EnterStringDialogData
  ) {
  }

  saveString() {
    this.dialogRef.close(this.form.value.stringValue);
  }

  close() {
    this.dialogRef.close();
  }

}

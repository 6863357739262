import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import { WithdrawalPeriodHistoryDto, WithdrawalDto, WithdrawalQueryDto, WithdrawalQuery } from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalService {

  constructor(
    private apiService: ApiService
  ) {
  }

  async getEmployeeWithdrawalsFromDateToDate(
    employeeId: string,
    fromDate: string,
    toDate: string
  ): Promise<WithdrawalDto[]> {
    return await this.apiService.get(`/api/withdrawal?fromDate=${fromDate}&toDate=${toDate}&employeeId=${employeeId}`);
  }

  async queryWithdrawals(withdrawalQuery: WithdrawalQuery): Promise<WithdrawalQueryDto> {
    return await this.apiService.post('/api/withdrawal/query', withdrawalQuery);
  }

  async getWithdrawalPeriodHistory(): Promise<WithdrawalPeriodHistoryDto[]> {
    return await this.apiService.get('/api/customer/period-history');
  }

  async updateWithdrawalStatus(withdrawalId: string): Promise<WithdrawalDto> {
    return await this.apiService.post('/api/withdrawal/update-status', {
      id: withdrawalId
    });
  }
}

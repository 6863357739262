import { UserDto } from 'common-ui/open-api';

export class CompanyUser {
  dto: UserDto;
  isLoggedIn: boolean;

  constructor(dto: UserDto, isLoggedIn: boolean) {
    this.dto = dto;
    this.isLoggedIn = isLoggedIn;
  }

  get displayEmail() {
    return `${this.dto.email}${this.isLoggedIn ? '*' : ''}`;
  }

  get verified() {
    return this.dto.verified;
  }

  get lastSignIn() {
    return this.dto.lastSignIn;
  }

  get email() {
    return this.dto.email;
  }

  get userDto() {
    return this.dto
  }

  get role() {
    return this.dto.role
  }

  get companies() {
    return this.dto.companies
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'lib-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.css']
})
export class PageContainerComponent {

  constructor() { }
}


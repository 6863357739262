<mat-form-field>
  <mat-label>{{ label ?? 'Amount' }}</mat-label>
  <input
    matInput
    mask="separator.2"
    thousandSeparator=","
    prefix="£"
    placeholder="Amount"
    [formControl]="formControl">
  <mat-error *ngIf="formControl.errors?.required">{{ label ?? 'Amount' }} is required</mat-error>
  <mat-error *ngIf="formControl.errors?.exceededMax">{{ label ?? 'Amount' }} must be below £{{ max }}</mat-error>
</mat-form-field>

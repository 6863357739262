import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, tap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'app-store-redirect',
  templateUrl: './app-link.component.html',
  styleUrls: ['./app-link.component.scss']
})
export class AppLinkComponent implements OnInit {
  playStoreUrl = 'https://play.google.com/store/apps/details?id=com.flexearn.flexearn';
  appStoreUrl = 'https://apps.apple.com/gb/app/flexearn/id1489632128';

  action: string;
  url$: Observable<string>;
  token$: Observable<string>;
  webLink: string;
  userAgent: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    // this.openPlatformSpecificAppStore();

    this.url$ = this.activatedRoute.url.pipe(
      map(segments => segments[1].path),
      tap(action => this.action = action)
    );

    this.token$ = this.activatedRoute.queryParamMap.pipe(
      map(params => params.get('token'))
    );

    this.url$.pipe(
      withLatestFrom(this.token$)
    ).subscribe(
      ([action, token]) => {
        if (this.action === 'install') {
          this.webLink = 'login';
        } else {
          this.webLink = action + '?token=' + token;
        }
      });
  }

  openPlatformSpecificAppStore() {

    this.userAgent = navigator.userAgent;

    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = this.playStoreUrl;
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      window.location.href = this.appStoreUrl;
    } else {
      // Fallback for other platforms, you can use the Play Store link or a custom landing page

    }
  }

  async navigateToWebLink() {
    console.log(this.webLink);
    await this.router.navigateByUrl(this.webLink);
  }

  navigateToPlayStore() {
    window.location.href = this.playStoreUrl;
  }

  navigateToAppStore() {
    window.location.href = this.appStoreUrl;
  }

}

import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { PayrollService, CompanyService } from 'common-ui/services';
import { CompanyDto, PayFrequency, PayrollDto, Currency, PayrollType, CreatePayrollDto } from 'common-ui/open-api';
import { Subject } from 'rxjs';
import { PayrollInputFormValue } from 'common-ui/payroll-input/payroll-input.component';
import { takeUntil } from 'rxjs/operators';

interface PayrollForm {
  companyId?: FormControl<string | null>;
  payroll: FormControl<PayrollInputFormValue>;
}

interface PayrollFormValue {
  companyId?: string | null;
  payroll: PayrollInputFormValue;
}

@Component({
  selector: 'lib-payroll-edit',
  templateUrl: './payroll-edit.component.html',
  styleUrls: ['./payroll-edit.component.css']
})
export class PayrollEditComponent implements OnInit, OnDestroy {

  @Input() companyId: string;
  @Input() payrollId: string;
  @Input() isCreate: boolean;
  @Input() existingPayrolls: PayrollDto[];
  @Input() companies: CompanyDto[];
  @Output() operationComplete = new EventEmitter<string>();
  @Output() operationCancelled = new EventEmitter();

  form: FormGroup<PayrollForm>;
  errorMessage: string;
  loading = false;

  loadedPayroll: PayrollDto;
  private ngUnsubscribe$ = new Subject();

  constructor(
    private companyService: CompanyService,
    private payrollService: PayrollService,
    fb: FormBuilder
  ) {
    this.form = fb.group<PayrollForm>({
      companyId: new FormControl({value: '', disabled: true}),
      payroll: new FormControl<PayrollInputFormValue>({
        currency: Currency.GBP,
        frequency: PayFrequency.MONTHLY,
        type: PayrollType.STANDARD,
        periodEndDay: {
          day: 31,
          avoidHolidaysAndWeekends: true
        },
        delayChargeDateToPayDate: false
      })
    });

    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        this.errorMessage = '';
      });
  }

  async ngOnInit() {

    this.loading = true;

    if (this.isCreate) {
      this.form.controls.companyId.enable();
    } else {
      this.form.controls.companyId.disable();
    }

    if (!this.companies) {
      this.companies = await this.companyService.getCompaniesForUser();
    }

    if (this.isCreate) {
      await this.loadDefaults();
    } else {
      await this.loadPayroll();
    }
    this.loading = false;
  }

  async loadDefaults() {
    this.form.setValue({
      companyId: this.companyId ? this.companyId : this.companies.length > 0 ? this.companies[0]._id : null,
      payroll: {
        currency: Currency.GBP,
        frequency: PayFrequency.MONTHLY,
        type: PayrollType.STANDARD,
        periodEndDay: {
          day: 31,
          avoidHolidaysAndWeekends: true
        },
        delayChargeDateToPayDate: false
      }
    });
  }

  async loadPayroll() {
    this.loadedPayroll = await this.payrollService.getPayroll(this.payrollId);
    const value: PayrollFormValue = {
      companyId: this.loadedPayroll.companyId,
      payroll: {
        currency: this.loadedPayroll.currency,
        frequency: this.loadedPayroll.frequency,
        type: this.loadedPayroll.type,
        delayChargeDateToPayDate: this.loadedPayroll.delayChargeDateToPayDate
      }
    };

    if (this.loadedPayroll.frequency === PayFrequency.WEEKLY) {
      value.payroll.periodEndWeekDay = this.loadedPayroll.periodEndWeekDay;
    } else if (this.loadedPayroll.frequency === PayFrequency.MONTHLY) {
      value.payroll.periodEndDay = this.loadedPayroll.periodEndDay;
    } else if (this.loadedPayroll.frequency === PayFrequency.CUSTOM) {
      value.payroll.customSchedule = this.loadedPayroll.customSchedule;
    } else {
      value.payroll.twoOrFourWeeklyPeriodEnd = this.loadedPayroll.twoOrFourWeeklyPeriodEnd;
    }

    if (this.loadedPayroll.type === PayrollType.ADVANCED) {
      value.payroll.payDateDay = this.loadedPayroll.payDateDay;
      value.payroll.paidInArrears = this.loadedPayroll.paidInArrears;
      value.payroll.deductionsExportDay = this.loadedPayroll.deductionsExportDay;
    } else {
      if (this.loadedPayroll.delayChargeDateToPayDate) {
        value.payroll.payDateDay = this.loadedPayroll.payDateDay;
      }
    }

    this.form.setValue(value);
  }

  async onSubmit() {
    try {
      this.loading = true;
      const value = this.form.value;
      const createPayrollDto: CreatePayrollDto = {
        frequency: value.payroll.type === PayrollType.ADVANCED ? PayFrequency.MONTHLY : value.payroll.frequency,
        currency: value.payroll.currency,
        companyId: value.companyId,
        type: value.payroll.type,
        delayChargeDateToPayDate: value.payroll.delayChargeDateToPayDate
      };

      if (createPayrollDto.frequency === PayFrequency.WEEKLY) {
        createPayrollDto.periodEndWeekDay = value.payroll.periodEndWeekDay;
      } else if (createPayrollDto.frequency === PayFrequency.MONTHLY) {
        createPayrollDto.periodEndDay = value.payroll.periodEndDay;
      } else if (createPayrollDto.frequency === PayFrequency.CUSTOM) {
        createPayrollDto.customSchedule = value.payroll.customSchedule;
      } else {
        createPayrollDto.twoOrFourWeeklyPeriodEnd = value.payroll.twoOrFourWeeklyPeriodEnd;
      }

      if (value.payroll.type === PayrollType.ADVANCED) {
        createPayrollDto.deductionsExportDay = value.payroll.deductionsExportDay;
        createPayrollDto.payDateDay = value.payroll.payDateDay;
        createPayrollDto.paidInArrears = value.payroll.paidInArrears;
      } else {
        if (value.payroll.delayChargeDateToPayDate) {
          createPayrollDto.payDateDay = value.payroll.payDateDay;
        }
      }

      if (this.isCreate) {
        this.payrollId = await this.payrollService.createPayroll(createPayrollDto);
      } else {
        delete createPayrollDto.companyId;
        await this.payrollService.updatePayroll(this.payrollId, createPayrollDto);
      }
      this.operationComplete.emit(this.payrollId);
    } catch (err) {
      this.errorMessage = err.message;
    } finally {
      this.loading = false;
    }
  }

  async cancel() {
    this.operationCancelled.emit();
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }
}

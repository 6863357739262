<lib-employee-portal-page
  title="Account Details"
  *ngIf="profiles">

  <div class="mat-title">Your Account</div>
  <mat-divider></mat-divider>

  <div class="account-sub-section">Personal</div>
  <div class="details-table">
    <div>
      <div class="label">Name</div>
      <div>{{ profile.firstName }} {{ profile.lastName }}</div>
    </div>
    <div>
      <div class="label">Email</div>
      <div>{{ profile.email }}</div>
    </div>
    <div>
      <div class="label">Phone</div>
      <div> {{ profile.phone ? profile.phone : 'No phone set' }}</div>
    </div>
    <div>
      <div class="label">Bank Details</div>
      <div>
        {{ profile.accountNumber ? profile.sortCode + "/" + profile.accountNumber : 'Not set' }}
      </div>
    </div>
  </div>

  <mat-divider class="divider"></mat-divider>

  <div class="account-sub-section">Employment</div>

  <div *ngFor="let companyProfile of profiles">
    <div class="details-table">
      <div>
        <div class="label">Company</div>
        <div>{{ companyProfile.companyName }}</div>
      </div>
      <div>
        <div class="label">Job Status</div>
        <div>{{companyProfile.isLeft ? "Left" :
          companyProfile.isLeaver ? "Leaving" : "Active" }}
        </div>
      </div>
      <div>
        <div class="label">Pay Type</div>
        <div>{{ companyProfile.isSalaried ? 'Salary' : 'Timesheet' }}</div>
      </div>
      <div *ngIf="companyProfile.isSalaried">
        <div class="label">Annual Salary</div>
        <div>{{ companyProfile.payAmount | pennyToPound: companyProfile.currency }}</div>
      </div>
      <div>
        <div class="label">Wages Accrued</div>
        <div>{{ companyProfile.amountAccrued | pennyToPound: companyProfile.currency }}</div>
      </div>
      <div>
        <div class="label">Available Funds</div>
        <div>{{ companyProfile.amountAvailable | pennyToPound: companyProfile.currency }}</div>
      </div>
    </div>
    <div *ngIf="companyProfile.isBlocked">This account is currently Blocked. Please check with your company
      administrators or contact FlexEarn support
    </div>
    <div *ngIf="!companyProfile.isTermsAccepted">You must accept the Terms and Conditions.</div>
    <mat-divider></mat-divider>
  </div>

  <div class="button-panel">
    <button mat-button
            color="primary"
            mat-stroked-button
            (click)="openBankDetailsDialog()">{{ profile.isBankDetailsSet ? 'Update' : 'Set' }} Bank Details
    </button>
  </div>

</lib-employee-portal-page>



import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Observable, catchError, from, switchMap, throwError, tap } from 'rxjs';
import { ApiService, ApiError } from 'common-ui/services/api.service';
import { Environment } from 'common-ui/models/environment.type';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private apiService: ApiService,
    @Inject('env') private environment: Environment,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('refresh-token') || req.url.endsWith('sign-out')) {
      return next.handle(req);
    }

    // Clone the request and set the new header in one step.
    if (!req.url.startsWith('/')) {
      throw new ApiError('path must start with /');
    }

    this.apiService.loginError = '';
    this.apiService.logRequest(req.method, req.url, req.body);

    if (this.apiService.isConsole) {
      this.apiService.snackBar.dismiss();
    }

    const startTime = new Date().getTime();
    this.apiService.statusEvent({
      status: 'running'
    });

    // Pass on the cloned request instead of the original request.
    return from(this.apiService.refreshTokenIfNeeded(req.url)).pipe(
      switchMap(() => {
        const httpHeaders: HttpHeaders = this.apiService.getHttpHeaders();
        const headers = {};
        httpHeaders.keys().forEach(headerName => {
          headers[headerName] = httpHeaders.get(headerName);
        });

        const clonedRequest = req.clone({
          setHeaders: headers
        });

        return next.handle(clonedRequest);
      }),
      tap((event) => {

        if (event.type === HttpEventType.Response) {

          const response = event;
          this.apiService.checkAngularZone();

          if (this.environment.environment !== 'production') {
            let offset = 0;
            if (response.headers.has('time-travel-offset')) {
              offset = parseInt(response.headers.get('time-travel-offset'), 10);
            }
            if (this.apiService.systemTimeOffset$.value !== offset) {
              this.apiService.systemTimeOffset$.next(offset);
            }
          }

          if (response.body && this.environment.debug) {
            console.log('<= ' + req.method + ':' + req.url, response.body);
          }

          this.apiService.statusEvent({
            status: 'success'
          });
        }

      }),
      catchError((error: HttpErrorResponse) => {
        return from(this.apiService.handleError(error, startTime)).pipe(
          switchMap(() => throwError(() => error))
        );
      }));
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface WarningDialogData {
  warning: string;
}

@Component({
  selector: 'lib-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.css']
})
export class WarningDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WarningDialogData,
    public dialogRef: MatDialogRef<WarningDialogComponent>
  ) {
  }
}

<div class="container">
  <lib-spinner-container [isWorking]="isWorking">
    <lib-dialog-container
      [header]="data.heading"
      [subHeader]="data.message">
      <lib-error-message [message]="errorMessage"></lib-error-message>

      <div class="button-panel">

        <button mat-stroked-button
                (click)="no()">
          No
        </button>

        <button mat-flat-button
                color="primary"
                (click)="yes()">
          Yes
        </button>

      </div>
    </lib-dialog-container>
  </lib-spinner-container>
</div>

<div class="container">

  <div *ngIf="profile.isLeft || profile.isBlocked || !profile.companyWithdrawalsAllowed"
       class="blocked-container">
    <div class="blocked-message-bar">
      <mat-icon color="warn">block</mat-icon>
      <div>
        <div class="welcome-message">
          Welcome back {{ profile.firstName }},
        </div>
        <div *ngIf="!profile.companyWithdrawalsAllowed"
             class="blocked-message">
          {{ !!profile.companyName ? profile.companyName : "Your company" }} is still being set up.
        </div>
        <div class="blocked-message"
             *ngIf="profile.isBlocked && !profile.isLeft">
          Your account is blocked.
        </div>
        <div class="blocked-message"
             *ngIf="profile.isLeft">
          Our records indicate you have left.
        </div>
        <div class="blocked-message">
          Withdrawals are not available{{ profile.isBlocked ? ' today.' : '.' }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!(profile.isLeft || profile.isBlocked || !profile.companyWithdrawalsAllowed)">

    <lib-employee-portal-time-left
      [text]="profile.payrollType === PayrollType.STANDARD ? 'Time before earnings reset' : 'Time before closed period'"
      [periodEnd]="profile.periodEnd"
      [systemTime]="profile.systemTime">
    </lib-employee-portal-time-left>

    <div *ngIf="profile.periodWithdrawalLimitActive">
      <lib-employee-portal-withdrawal-limit-panel
        [used]="profile.periodWithdrawalLimit - profile.periodWithdrawalsRemaining"
        [allowed]="profile.periodWithdrawalLimit">
      </lib-employee-portal-withdrawal-limit-panel>
    </div>

    <lib-employee-portal-info-panel
      text="Amount earned in this period"
      [subMessage]="subMessage"
      [currency]="profile.currency"
      [pennyAmount]="profile.amountAccrued">
    </lib-employee-portal-info-panel>

    <lib-employee-portal-info-panel
      text="Amount available to withdraw"
      [accent]=true
      [currency]="profile.currency"
      [pennyAmount]="profile.amountAvailable">
    </lib-employee-portal-info-panel>

    <lib-employee-portal-single-line-info-panel
      text="Withdrawals are {{ withdrawalFee }}">
    </lib-employee-portal-single-line-info-panel>

    <div class="gap">
    </div>

    <div *ngIf="!pendingWithdrawalStatus ||
      (pendingWithdrawalStatus && pendingWithdrawalStatus === PaymentStatusEnum.PENDING )">

      <form [formGroup]="form"
            (ngSubmit)="withdraw()">
        <div class="withdrawal-form">
          <div class="withdrawal-input-area">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Withdrawal Amount</mat-label>
              <input matInput
                     type="text"
                     class="withdraw-input"
                     appFirstFocus
                     formControlName="amount">

              <mat-error *ngIf="form.controls.amount.invalid && form.controls.amount.touched">
                <div *ngIf="form.controls.amount.errors.max">
                  You can withdraw up to {{ 20000 | pennyToPound: profile.currency }} per transaction
                </div>
                <div *ngIf="form.controls.amount.errors.moreThanAvailable && !form.controls.amount.errors.max">
                  You cannot withdraw more than amount available
                </div>
                <div *ngIf="form.controls.amount.errors.lessThanMinimum">
                  You cannot withdraw less than {{ profile.minimumWithdrawal | pennyToPound: profile.currency }}
                </div>
                <div *ngIf="form.controls.amount.errors.required">
                  Please enter the amount to withdraw
                </div>
                <div *ngIf="form.get('amount').errors?.pattern">
                  Withdrawal amount must be a whole number.
                </div>
              </mat-error>
            </mat-form-field>
          </div>

          <button mat-flat-button
                  data-e2e="continue-button"
                  class="employee-portal-button"
                  color="accent"
                  [disabled]="form.invalid || isWorking || withdrawalLimitReached"
                  type="submit">
            Continue
          </button>
        </div>
      </form>
    </div>

    <div *ngIf="pendingWithdrawalStatus === PaymentStatusEnum.SUCCESS"
         class="withdrawal-form">
      <div class="message-area">
        <div class="tick-cross-image">
          <img src="https://assets.flexearn.com/portal/v1/images/checkmark.png">
        </div>
        <p class="message">Your money is on it's way</p>
      </div>
      <button mat-raised-button
              color="accent"
              class="employee-portal-button"
              (click)="acknowledgeWithdrawal()">Make Another Withdrawal
      </button>
    </div>

    <div *ngIf="pendingWithdrawalStatus === PaymentStatusEnum.FAILED"
         class="withdrawal-form">

      <div class="message-area">
        <div class="tick-cross-image">
          <img src="https://assets.flexearn.com/portal/v1/images/cross.png">
        </div>
        <div>
          <div class="message">We are sorry, this transaction was not successful.</div>
          <div *ngIf="!errorMessage" class="message">Try again, or contact our helpdesk.</div>
          <div *ngIf="errorMessage" class="message">{{ errorMessage }}</div>
        </div>
      </div>

      <button mat-raised-button
              color="accent"
              class="employee-portal-button"
              (click)="acknowledgeWithdrawal()">
        Try Again
      </button>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { MasterAccountService, FilterType, FilterItem } from 'common-ui/services';

@Component({
  selector: 'lib-master-account-selector',
  templateUrl: './master-account-selector.component.html',
  styleUrls: ['./master-account-selector.component.css']
})
export class MasterAccountSelectorComponent implements OnInit {

  masterAccounts: FilterItem[];
  FilterType = FilterType;

  constructor(
    private masterAccountService: MasterAccountService
  ) {
  }

  async ngOnInit(): Promise<void> {
    const masterAccounts = await this.masterAccountService.findAll();
    this.masterAccounts = masterAccounts.map(m => ({
      value: m._id,
      description: m.name
    }));
  }
}

<lib-spinner-container [isWorking]="isWorking">
  <form *ngIf="form"
        class="settings-form"
        (ngSubmit)="updateMasterAccount()"
        [formGroup]="form">

    <mat-form-field>
      <mat-label>Source</mat-label>
      <mat-select formControlName="apiSource">
        <mat-option *ngFor="let source of integrations" [value]="source">
          {{ source |hyphenatedToRegular }}
        </mat-option>
      </mat-select>
      <mat-hint *ngIf="isSftp">
        Click <a class="link" (click)="downloadSftpPublicKey()">here</a> to download our public key
      </mat-hint>
    </mat-form-field>

    <div *ngIf="isLocalFile">
      <mat-form-field>
        <mat-label>Directory</mat-label>
        <input formControlName="sftpDirectory"
               matInput
               placeholder="Host">
        <mat-error *ngIf="formControlIsRequired('sftpDirectory')">
          Directory is required
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="isGcpBucket">
      <mat-form-field>
        <mat-label>Bucket Name</mat-label>
        <input formControlName="bucketName"
               matInput
               placeholder="Bucket Name">
        <mat-error *ngIf="formControlIsRequired('bucketName')">
          Bucket Name is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Project Id</mat-label>
        <input formControlName="projectId"
               matInput
               placeholder="Project Id">
        <mat-error *ngIf="formControlIsRequired('projectId')">
          Project Id is required
        </mat-error>
      </mat-form-field>

      <input formControlName="serviceAccount" type="hidden" />

      <div class="choose-button">
        <lib-file-chooser
          buttonText="Choose Service Account File"
          acceptFileType=".json"
          (fileSelected)="serviceAccountSelected($event)">
        </lib-file-chooser>
      </div>

    </div>

    <div *ngIf="isSftp">
      <mat-form-field>
        <mat-label>Host</mat-label>
        <input formControlName="sftpHost"
               matInput
               placeholder="Host">
        <mat-error *ngIf="formControlIsRequired('sftpHost')">
          Host is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Username</mat-label>
        <input formControlName="sftpUsername"
               matInput
               placeholder="Username">
        <mat-error *ngIf="formControlIsRequired('sftpUsername')">
          Username is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Directory</mat-label>
        <input formControlName="sftpDirectory"
               matInput
               placeholder="Directory">
        <mat-error *ngIf="formControlIsRequired('sftpDirectory')">
          Directory is required
        </mat-error>
      </mat-form-field>

    </div>

    <div *ngIf="isClientIdRequired">
      <mat-form-field>
        <mat-label>Client Id</mat-label>
        <input formControlName="clientId" matInput placeholder="Client Id">
        <mat-error *ngIf="formControlIsRequired('clientId')">
          Client Id is required
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="isClientSecretRequired">
      <mat-form-field>
        <mat-label>Client Secret</mat-label>
        <input formControlName="clientSecret"
               type="password"
               matInput placeholder="Client Secret">
        <mat-error *ngIf="formControlIsRequired('clientSecret')">
          Client Secret is required
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="isOrganisationNameRequired">
      <mat-form-field>
        <mat-label>Organisation Name</mat-label>
        <input formControlName="accountId"
               matInput
               placeholder="Organisation Name">
        <mat-error *ngIf="formControlIsRequired('accountId')">
          Organisation Name is required
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="isAccountUrlIsRequired">
      <mat-form-field>
        <mat-label>Account Url</mat-label>
        <input formControlName="accountUrl"
               matInput
               placeholder="https://*****.***.***">
        <mat-error *ngIf="formControlIsRequired('accountUrl')">
          Account URL is required
        </mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="isKelio">
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input formControlName="username"
               matInput
               placeholder="Username">
        <mat-error *ngIf="formControlIsRequired('username')">
          Username is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Password</mat-label>
        <input formControlName="password"
               matInput
               type="password"
               placeholder="Password">
        <mat-error *ngIf="formControlIsRequired('password')">
          Password is required
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Population Filter</mat-label>
        <input formControlName="populationFilter"
               matInput
               placeholder="Population Filter">
        <mat-error *ngIf="formControlIsRequired('populationFilter')">
          Population Filter is required
        </mat-error>
      </mat-form-field>

      <div class="chip-list-title">Population Exclusions</div>
      <div class="exclusions-list-container">
        <mat-chip-list formArrayName="populationExclusions">
          <mat-chip *ngFor="let itemControl of populationExclusions.controls; let i = index" [selectable]="false">
            {{ itemControl.value }}
            <mat-icon matChipRemove (click)="removePopulationExclusion(i)">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <div>
          <button mat-icon-button
                  class="add-exclusion-button"
                  color="primary"
                  type="button"
                  (click)="addPopulationExclusion()">
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="isApiKeyRequired">
      <mat-form-field>
        <mat-label>API Key</mat-label>
        <input formControlName="apiKey"
               matInput
               type="password"
               placeholder="API Key">
        <mat-error *ngIf="formControlIsRequired('apiKey')">
          Api Key is required
        </mat-error>
      </mat-form-field>
    </div>

    <mat-radio-group *ngIf="isMultiCompanyRequired"
                     formControlName="multiCompany">
      <mat-radio-button [value]="false">
        One Company Per Account
      </mat-radio-button>
      <mat-radio-button [value]="true">
        {{ multiCompanyOptionText }}
      </mat-radio-button>
    </mat-radio-group>

    <div *ngIf="isUseDefaultCompanyForTimesheetsRequired">
      <mat-checkbox formControlName="useDefaultCompanyForTimesheets">
        Use Default Home For Timesheets
      </mat-checkbox>
    </div>

    <div *ngIf="showDefaultPayroll">
      <lib-payroll-input
        formControlName="defaultPayroll">
      </lib-payroll-input>
    </div>

    <div *ngIf="showOnePayrollPerPayType">
      <mat-checkbox formControlName="onePayrollPerPayType">
        One Payroll Per Pay Type
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="useTimePunching"
                    *ngIf="isUseTimePunchingRequired">
        Using Time Punching
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="usePayCodes"
                    *ngIf="isPayCodeRequired">
        Use Pay Codes
      </mat-checkbox>
    </div>

    <div *ngIf="showExcludeBankStaff">
      <mat-checkbox formControlName="excludeBankStaff">
        Exclude Bank Staff
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="usePositionForPayCode"
                    *ngIf="isPositionForPayCodeRequired">
        Use Position For Pay Code
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="includeHolidayPay"
                    *ngIf="isTimesheetTypesRequired">
        Include Holiday Pay
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="includeExtraPay"
                    *ngIf="isTimesheetTypesRequired">
        Include Overtime/Extra Pay
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="excludeStandardHours"
                    *ngIf="isTimesheetTypesRequired">
        Exclude Standard Hours
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="loadTimesheetEmployeesOnly"
                    *ngIf="showLoadTimesheetEmployeesOnlyCheckBox">
        Load Timesheet Employees Only
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="useDefaultTimesheetRate"
                    *ngIf="showUseDefaultRateCheckBox">
        Use Default Timesheet Rate
      </mat-checkbox>
    </div>

    <div>
      <mat-checkbox formControlName="hourlyTimesheets"
                    *ngIf="isHourlyTimesheetsRequired">
        Hourly Timesheet Import
      </mat-checkbox>
    </div>

    <div *ngIf="showDefaultRateInput">
      <mat-form-field>
        <mat-label>Default Timesheet Rate</mat-label>
        <input formControlName="defaultTimesheetRate"
               matInput
               placeholder="Default Timesheet Rate">
        <mat-error *ngIf="formControlIsRequired('defaultTimesheetRate')">
          Default Rate is required
        </mat-error>
      </mat-form-field>
    </div>

    <!--    <div *ngIf="isCarePlanner">-->
    <!--      <mat-form-field>-->
    <!--        <mat-label>Salaried Payment Group</mat-label>-->
    <!--        <input matInput-->
    <!--               disabled="true"-->
    <!--               [value]="getCarePlannerSalariedPaymentGroup()">-->
    <!--        <mat-icon class="edit-icon"-->
    <!--                  *ngIf="isAdminConsole"-->
    <!--                  (click)="openSalariedPaymentGroupDialog()"-->
    <!--                  matSuffix>mode_edit-->
    <!--        </mat-icon>-->
    <!--      </mat-form-field>-->
    <!--    </div>-->

    <lib-error-message [message]="errorMessage"></lib-error-message>

    <div class="buttons">

      <button mat-stroked-button
              type="button"
              [disabled]="isAdminConsole"
              *ngIf="this.form.pristine && (isMultiTenant || (isOAuth2ConnectionRequired && isOauth2IntegrationDisconnected))"
              (click)="navigateToConnectionPage()">
        Connect
      </button>

      <button mat-stroked-button
              type="button"
              *ngIf="isOAuth2ConnectionRequired && this.form.pristine &&!isOauth2IntegrationDisconnected"
              (click)="disconnectOauth2()">
        Disconnect
      </button>

      <button mat-flat-button
              *ngIf="!form.pristine || !isIntegrationActive"
              color="primary"
              [disabled]="form.invalid"
              type="submit">
        Save
      </button>

      <button mat-stroked-button
              *ngIf="form.pristine && isIntegrationActive"
              [disabled]="isOAuth2ConnectionRequired && isOauth2IntegrationDisconnected"
              (click)="testConnection()"
              type="button">
        Test Connection
      </button>

      <button mat-stroked-button
              *ngIf="form.pristine && isIntegrationActive && isFileSource"
              (click)="downloadFileSourceFiles()"
              type="button">
        Download
      </button>

      <button mat-flat-button
              *ngIf="form.pristine && isIntegrationActive"
              [disabled]="isOAuth2ConnectionRequired && isOauth2IntegrationDisconnected"
              color="primary"
              (click)="openRunIntegrationDialog()"
              type="button">
        Open Runner
      </button>

    </div>

  </form>
</lib-spinner-container>


<div class="container">
  <lib-dialog-container
    header="Confirm Action"
    [subHeader]="data.message">
    <div class="button-panel">
      <button mat-button (click)="no()">No</button>
      <button mat-flat-button
              color="primary"
              (click)="yes()">Yes
      </button>
    </div>
  </lib-dialog-container>
</div>

<lib-spinner-container [isWorking]="isWorking">
  <lib-dialog-container (exit)="cancel()"
                        [header]="getHeader()">

    <lib-error-message [message]="errorMessage"></lib-error-message>
    <lib-button-panel>

      <button mat-button
              color="primary"
              [disabled]="isWorking"
              (click)="cancel()">
        Cancel
      </button>

      <button mat-flat-button
              color="primary"
              [disabled]="isWorking"
              (click)="rolloverPayroll()">
        Rollover
      </button>
    </lib-button-panel>
  </lib-dialog-container>
</lib-spinner-container>

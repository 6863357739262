<lib-spinner-container [isWorking]="isLoading">
  <lib-dialog-container
    (exit)="close()"
    [header]="data.employee.firstName + ' ' + data.employee.lastName + ' Timesheets'"
    [showCross]="true">

    <lib-employee-timesheet [employee]="data.employee"
                            [dateRange]="dateRange">
    </lib-employee-timesheet>

    <div class="period-selector">
      <lib-week-selector
        [dateRange]="dateRange"
        [isLoading]="false"
        (emitDates)="selectPeriod($event)">
      </lib-week-selector>
    </div>

  </lib-dialog-container>
</lib-spinner-container>

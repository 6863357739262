import { Injectable } from '@angular/core';
import { ExportFileDto, PaymentInstructionsOpenApiService } from 'common-ui/open-api';
import { FileDownloadService } from 'common-ui/services/file-download.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentInstructionService {
  constructor(
    private instructionsService: PaymentInstructionsOpenApiService,
    private fileDownloadService: FileDownloadService
  ) {
  }

  async exportPending() {
    await this.fileDownloadService.downloadTextFile(this.instructionsService.exportPending('response'));
  }

  async download(fileName: string) {
    await this.fileDownloadService.downloadTextFile(this.instructionsService.downloadPaymentInstructionsFile({
      fileName: fileName
    }, 'response'));
  }

  async getHistory(): Promise<ExportFileDto[]> {
    return await firstValueFrom(this.instructionsService.getPaymentInstructionFiles());
  }
}

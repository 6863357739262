<div class="container mat-typography" *ngIf="breakdown">
  <lib-dialog-container header="Amount Available Breakdown">
    <lib-spinner-container [isWorking]="isWorking">

      <table>
        <tr class="bold">
          <td></td>
          <td class="right">Earned</td>
          <td class="right">Allowance</td>
          <td class="right">Allowed</td>
        </tr>

        <tr *ngIf="!breakdown.isZeroHoursWorker">
          <td>Standard Hours</td>
          <td class="right">{{ breakdown.standardAccrual | pennyToPound:breakdown.currency }}</td>
          <td class="right">{{ breakdown.standardAllowance | percent }}</td>
          <td class="right">{{ (breakdown.standardAllowance * breakdown.standardAccrual) | pennyToPound:breakdown.currency }}</td>
        </tr>

        <tr *ngIf="!breakdown.isZeroHoursWorker">
          <td>Overtime Hours</td>
          <td class="right">{{ breakdown.overtimeAccrual | pennyToPound:breakdown.currency }}</td>
          <td class="right">{{ breakdown.overtimeAllowance | percent }}</td>
          <td class="right">{{ (breakdown.overtimeAccrual * breakdown.overtimeAllowance)  | pennyToPound:breakdown.currency }}</td>
        </tr>

        <tr *ngIf="breakdown.isZeroHoursWorker">
          <td>Zero Hours Timesheets</td>
          <td class="right">{{ breakdown.zeroHoursAccrual | pennyToPound:breakdown.currency }}</td>
          <td class="right">{{ breakdown.zeroHoursAllowance | percent }}</td>
          <td class="right">{{ (breakdown.zeroHoursAccrual * breakdown.zeroHoursAllowance)  | pennyToPound:breakdown.currency }}</td>
        </tr>

        <tr class="bold">
          <td >Total Allowed Earnings</td>
          <td></td>
          <td></td>
          <td class="right">{{ (breakdown.totalAllowedEarnings)  | pennyToPound:breakdown.currency }}</td>
        </tr>

        <tr class="empty-row">
          <td><hr></td>
          <td><hr></td>
          <td><hr></td>
          <td><hr></td>
        </tr>

        <tr>
          <td>Withdrawn this Period</td>
          <td></td>
          <td></td>
          <td class="right">{{ breakdown.withdrawalsFromCurrentPeriod | pennyToPound:breakdown.currency}}</td>
        </tr>

        <tr>
          <td>Withdrawn previous Period</td>
          <td></td>
          <td></td>
          <td class="right">{{ breakdown.withdrawalsFromPreviousPeriod | pennyToPound:breakdown.currency}}</td>
        </tr>

        <tr class="bold">
          <td>Total Withdrawals</td>
          <td></td>
          <td></td>
          <td class="right">{{ (breakdown.withdrawalsFromPreviousPeriod + breakdown.withdrawalsFromCurrentPeriod) | pennyToPound:breakdown.currency}}</td>
        </tr>

        <tr class="empty-row">
          <td><hr></td>
          <td><hr></td>
          <td><hr></td>
          <td><hr></td>
        </tr>

        <tr class="bold">
          <td>Amount Available to Withdraw</td>
          <td></td>
          <td></td>
          <td class="right">{{ breakdown.amountAvailable | pennyToPound:breakdown.currency:false:'£0' }}</td>
        </tr>

      </table>

    </lib-spinner-container>
  </lib-dialog-container>
</div>

import { Component, OnInit, Input, Inject } from '@angular/core';
import { FilterService, FilterType } from 'common-ui/services';
import { Environment } from 'common-ui/models/environment.type';
import { FormControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

type AllowedFilterType = FilterType.EMPLOYEE_SEARCH |
  FilterType.COMPANY_SEARCH |
  FilterType.MASTER_ACCOUNT_NAME_SEARCH |
  FilterType.API_KEY_SEARCH |
  FilterType.USER_SEARCH |
  FilterType.BANK_TRANSACTION_SEARCH |
  FilterType.PAY_CODE_SEARCH;

@Component({
  selector: 'lib-filter-bar',
  templateUrl: './control-bar-filter.component.html',
  styleUrls: ['./control-bar-filter.component.css']
})
export class ControlBarFilterComponent implements OnInit {
  searchControl = new FormControl();
  @Input() textSearchType: AllowedFilterType = undefined;
  @Input() isWorking = false;

  constructor(
    private filterService: FilterService,
    @Inject('env') private environment: Environment
  ) {
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  ngOnInit(): void {
    if (this.textSearchType) {
      this.searchControl.setValue(this.filterService.getFilter(this.textSearchType));
    }

    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(searchText => {
      this.filterService.setFilter(this.textSearchType, searchText);
    });
  }

  get textSearchLabel(): string {
    switch (this.textSearchType) {
      case FilterType.USER_SEARCH:
        return 'Search by user email';
      case FilterType.EMPLOYEE_SEARCH:
        return 'Search by employee name or email';
      case FilterType.COMPANY_SEARCH:
        if (this.isConsole) {
          return 'Search by company, legal entity or master account name';
        } else {
          return 'Search by company or legal entity name';
        }
      case FilterType.MASTER_ACCOUNT_NAME_SEARCH:
        return 'Search by master account name';
      case FilterType.API_KEY_SEARCH:
        return 'Search by api key description';
      case FilterType.BANK_TRANSACTION_SEARCH:
        return 'Search by payment reference or counterparty';
        case FilterType.PAY_CODE_SEARCH:
          return 'Search by Pay Code'
      default:
        return '';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { UserService } from 'common-ui/services/user.service';
import { ToolbarDataService } from 'common-ui/services';

@Component({
  selector: 'app-csv-import-page',
  templateUrl: './csv-import-page.component.html',
  styleUrls: ['./csv-import-page.component.scss']
})
export class CsvImportPageComponent implements OnInit {

  masterAccountId: string;

  constructor(
    private userService: UserService,
    private toolbarService: ToolbarDataService
  ) {
  }

  async ngOnInit() {
    this.toolbarService.setupToolbar({
      action: 'toggle',
      title: 'CSV Import'
    });

    const user = await this.userService.getCurrentUser();
    this.masterAccountId = user.masterAccountId;
  }

}

import { Component, Inject, HostListener } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { PayrollService } from 'common-ui/services/payroll.service';

export interface ConfirmEndPeriodData {
  payrollId: string;
  companyId: string;
  rolloverPeriod: boolean;
  rolloverDeductions: boolean;
}

@Component({
  selector: 'lib-rollover-payroll-dialog',
  templateUrl: './rollover-payroll-dialog.component.html',
  styleUrls: ['./rollover-payroll-dialog.component.css']
})
export class RolloverPayrollDialogComponent {

  isWorking = false;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmEndPeriodData,
    private dialogRef: MatDialogRef<RolloverPayrollDialogComponent>,
    private payrollService: PayrollService
  ) {
  }

  @HostListener('keypress', ['$event'])
  async onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (!this.isWorking) {
        await this.rolloverPayroll();
      }
      event.preventDefault();
    }
  }

  public async rolloverPayroll() {
    this.isWorking = true;
    this.errorMessage = '';
    try {
      if (this.data.rolloverDeductions) {
        await this.payrollService.manualDeductionRollover(this.data.payrollId);
      } else if (this.data.rolloverPeriod) {
        await this.payrollService.manualPeriodRollover(this.data.payrollId);
      }
      this.dialogRef.close();
    } catch (err) {
      this.errorMessage = err?.message ? err.message : 'Unknown error, unable to end pay period.';
    } finally {
      this.isWorking = false;
    }
  }

  public cancel() {
    this.dialogRef.close();
  }

  getHeader() {
    if (this.data.rolloverPeriod) {
      return 'Manually Rollover Period';
    } else if (this.data.rolloverDeductions) {
      return 'Manually Rollover Deductions';
    } else {
      return 'Error';
    }
  }
}

<lib-spinner-container [isWorking]="isWorking">

  <form (ngSubmit)="save()"
        class="settings-form"
        [formGroup]="form">
    <div class="sub-header">Export the five management information reports in CSV format to SFTP</div>
    <div class="sub-header">Click <a class="link" (click)="downloadSftpPublicKey()">here</a> to download our public key.  Give this user write permissions on the SFTP directory.</div>

    <mat-checkbox formControlName="isEnabled">
      Enable Report Exports
    </mat-checkbox>

    <mat-form-field class="is-enabled" *ngIf="form.get('isEnabled').value">
      <mat-label>Activity Group By</mat-label>
      <mat-select
        formControlName="dateGroupByType">
        <mat-option *ngFor="let dateGroupBy of dateGroupBys" [value]="dateGroupBy">
          {{ dateGroupBy | titlecase }}
        </mat-option>
      </mat-select>
      <mat-hint>Determines how the activity report is aggregated</mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="form.get('isEnabled').value">
      <mat-label>Host</mat-label>
      <input formControlName="sftpHost"
             matInput
             placeholder="Host">
      <mat-error *ngIf="formControlIsRequired('sftpHost')">
        Host is required
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="form.get('isEnabled').value">
      <mat-label>Username</mat-label>
      <input formControlName="sftpUsername"
             matInput
             placeholder="Username">
      <mat-error *ngIf="formControlIsRequired('sftpUsername')">
        Username is required
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="form.get('isEnabled').value">
      <mat-label>Directory</mat-label>
      <input formControlName="sftpDirectory"
             matInput
             placeholder="Directory">
      <mat-error *ngIf="formControlIsRequired('sftpDirectory')">
        Directory is required
      </mat-error>
    </mat-form-field>

    <div class="button-panel">
      <button mat-flat-button
              color="primary"
              [disabled]="form.invalid || form.pristine || isWorking || !isConsole"
              type="submit">
        Save
      </button>

      <button mat-flat-button
              color="primary"
              (click)="testConnection()"
              [disabled]="form.invalid || !form.pristine || isWorking || !isConsole || !form.get('isEnabled').value"
              type="button">
        Test Connection
      </button>

      <button mat-flat-button
              color="primary"
              (click)="runExport()"
              [disabled]="form.invalid || !form.pristine || isWorking || !isConsole || !form.get('isEnabled').value"
              type="button">
        Run Export
      </button>
    </div>
  </form>

</lib-spinner-container>

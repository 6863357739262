import addDays from 'date-fns/addDays';
import { formatDateYYYYMMDD } from 'common-ui/util/format-date-yyyymmdd';
import { parseDateYYYYMMDD } from 'common-ui/util/parse-date-yyyymmdd';

export const getShiftedDate = (date: string, days: number): string => {
  const d = parseDateYYYYMMDD(date);
  const shiftedDate = addDays(d, days);
  return formatDateYYYYMMDD(shiftedDate);
};



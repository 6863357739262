import { Component, Input, OnChanges } from '@angular/core';
import { EmployeeDto, TimesheetDto } from 'common-ui/open-api';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TimesheetService } from 'common-ui/services';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';


@Component({
  selector: 'lib-employee-timesheet',
  templateUrl: './employee-timesheet.component.html',
  styleUrls: ['./employee-timesheet.component.css']
})
export class EmployeeTimesheetComponent implements OnChanges {

  @Input() employee: EmployeeDto;
  @Input() dateRange: JustDateRange;
  isLoading = true;

  dataSource: MatTableDataSource<TimesheetDto> = new MatTableDataSource<TimesheetDto>();

  displayedColumns: string[] = [
    'date',
    'unitsWorked',
    'ratePerUnit',
    'accrued',
    'approved',
    'cancelled'
  ];

  constructor(
    private timesheetService: TimesheetService
  ) {
  }

  async ngOnChanges() {
    await this.selectPeriod(this.dateRange);
  }

  async selectPeriod(dateRange: JustDateRange) {
    if (!dateRange || !this.employee) return;
    this.isLoading = true;
    const timesheets = await this.timesheetService.getTimesheetsForEmployeeByDate(this.employee._id, dateRange);
    this.dataSource.data = timesheets.sort((a, b) => {
      return a.date > b.date ? 1 : -1;
    });
    this.isLoading = false;
  }
}

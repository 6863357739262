<lib-dialog-container (exit)="close(false)"
                      [header]="(data.create ? 'Create' : 'Edit') + ' Payroll'">

  <lib-payroll-edit
    (operationCancelled)="close(false)"
    (operationComplete)="close(true)"
    [payrollId]="data.payrollId"
    [companyId]="data.companyId"
    [isCreate]="data.create"
    [existingPayrolls]="data.existingPayrolls"
    [companies]="data.availableCompanies">
  </lib-payroll-edit>
</lib-dialog-container>

import { AuthErrorCode } from 'common-ui/open-api';

export const getAuthErrorDescription = (loginFailure: AuthErrorCode) => {
  switch (loginFailure) {
    case AuthErrorCode.SIGN_IN_FAILED:
      return "Invalid email or password";
    case AuthErrorCode.TOKEN_EXPIRED:
      return "Sorry, but this link has expired. Please double check you are using the most recent email";
    case AuthErrorCode.INVALID_TOKEN:
      return "Sorry, but we do not recognise that link.  Please try again";
    case AuthErrorCode.NOT_VERIFIED:
      return 'Account not verified, please check your emails';
    case AuthErrorCode.REFRESH_TOKEN_EXPIRED:
      return 'Your session has expired, please sign in again';
    default:
      return loginFailure;
  }
}

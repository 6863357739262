import { Component } from '@angular/core';
import { JobStatus } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';

@Component({
  selector: 'lib-job-status-selector',
  templateUrl: './job-status-selector.component.html',
  styleUrls: ['./job-status-selector.component.css']
})
export class JobStatusSelectorComponent {
  jobStatus = Object.values(JobStatus).map(js => ({
    value: js,
    description: js
  }));
  FilterType = FilterType;

}

import { Component, forwardRef, OnDestroy, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ControlValueAccessor } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CompanyDto } from 'common-ui/open-api';


export const COMPANY_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CompanyInputComponent),
  multi: true
};

const COMPANY_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CompanyInputComponent),
  multi: true
};

@Component({
  selector: 'lib-company-input',
  templateUrl: './company-input.component.html',
  styleUrls: ['./company-input.component.css'],
  providers: [COMPANY_VALUE_ACCESSOR, COMPANY_VALIDATOR]
})
export class CompanyInputComponent implements OnDestroy, ControlValueAccessor {
  @Input() masterAccountId: string;

  onTouched: () => void;
  onChange: (val: string) => void;
  control = new FormControl();
  private ngUnsubscribe$ = new Subject<void>();

  constructor() {
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  // eslint-disable-next-line
  validate(control: FormControl) {
    return this.control.errors;
  }

  onBlur() {
    this.onTouched();
  }

  onInputBlur() {
    this.onTouched();
  }

  writeValue(v: any) {
    this.control.patchValue(v, {emitEvent: false});
  }

  registerOnChange(fn: any) {
    this.control.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(fn);
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  companySelected(companyDto: CompanyDto) {
    this.onChange(companyDto?._id ?? null);
  }
}

import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'lib-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.css']
})
export class DialogContainerComponent {

  @Output() exit = new EventEmitter<void>();
  @Input() showCross = false;

  @Input() header: string;
  @Input() subHeader: string;

  @Input() maxHeaderWidth: string;

  @Input() width: string;
  @Input() height: string;

  constructor() {
  }
}

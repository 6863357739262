<lib-page-container>

  <div class="centre-wrapper">

    <div *ngIf="referralComplete" class="page">
      <h1>Thank you!</h1>
      <p>We have sent you an email to confirm your referral.  And we'll be in contact once the company has signed up.</p>
    </div>

    <div *ngIf="!referralComplete" class="page">
      <div class="header">
        <h1>FlexEarn Referral Scheme</h1>
        <p>Do you know another business that would love FlexEarn? Refer them to us
          and you'll get £200 once they have signed up. Paid when the first employee makes a withdrawal.</p>
      </div>

      <div class="container">

        <div class="col">
          <div class="man-with-phone-container">
            <img class="man-with-phone" [src]="assetsUrl + '/ManWithPhone+Rounded.jpg'" />
          </div>
        </div>
        <div class="col right">
          <lib-referral-form (referralCompleted)="referralCompleted()"></lib-referral-form>
        </div>
      </div>

    </div>
  </div>

</lib-page-container>
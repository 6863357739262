<div class="table-container">

  <table [dataSource]="dataSource" mat-table class="mat-table mat-elevation-z8 preview-table">
    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell>Status</th>
      <td *matCellDef="let element" mat-cell>
        <lib-traffic-lights [value]="getRowStatus(element)"></lib-traffic-lights>
      </td>
    </ng-container>

    <ng-container *ngFor="let mapping of mappings; let i = index; trackBy: trackByIndex"
                  [matColumnDef]="mapping.targetField.name">
      <th *matHeaderCellDef mat-header-cell>
        <div>
          {{ mapping.label | camelToRegular }}{{ mapping.isRequired ? '*' : '' }}
        </div>
<!--        <div>-->
<!--          ({{ mapping.csvColumnName ? mapping.csvColumnName : 'unmapped' }})-->
<!--        </div>-->
      </th>
      <td *matCellDef="let element" mat-cell>
        <div>
          {{ getCell(element, mapping).value }}
        </div>
        <div class="cellError" *ngIf="getCell(element, mapping).errors.length > 0">
          {{getCell(element, mapping).errors}}
        </div>
        <div class="cellWarning" *ngIf="getCell(element, mapping).warnings.length > 0">
          {{getCell(element, mapping).warnings}}
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columns;" mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell">{{ "No data" }}</td>
      <td class="mat-cell" *ngFor="let col of this.csvConfig.mappings"></td>
    </tr>
  </table>

</div>

<div class="paginator">
  <mat-paginator [pageSize]="50" [showFirstLastButtons]=true>
  </mat-paginator>
</div>

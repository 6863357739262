<lib-page-container>
  <lib-filter-bar [textSearchType]="FilterType.EMPLOYEE_SEARCH" header>

    <div control-options>
      <button mat-button
              [disabled]="isWorking"
              (click)="downloadEmailIssueReport()">
        Download Issues
      </button>
    </div>

    <lib-comms-log-status-selector>
    </lib-comms-log-status-selector>

    <lib-email-template-type-selector>
    </lib-email-template-type-selector>

    <lib-master-account-selector *ngIf="isConsole">
    </lib-master-account-selector>

  </lib-filter-bar>

  <table [dataSource]="dataSource"
         mat-table
         matSort
         matSortActive="employeeName"
         matSortDirection="asc">

    <ng-container matColumnDef="email">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Email</th>
      <td *matCellDef="let element" mat-cell>{{ element.email }}
        <div *ngIf="element.employeeName"
             class="col-value-subtext">
          {{ element.employeeName }}
        </div>
      </td>

    </ng-container>

    <ng-container matColumnDef="employeeName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Employee Name</th>
      <td *matCellDef="let element" mat-cell>{{ element.employeeName }}</td>
    </ng-container>

    <ng-container matColumnDef="templateType">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Type</th>
      <td *matCellDef="let element" mat-cell>{{ element.templateType | hyphenatedToRegular }}</td>
    </ng-container>

    <ng-container matColumnDef="createdDate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Sent</th>
      <td *matCellDef="let element" mat-cell>{{ element.createdDate | date: 'dd MMM yyyy, HH:mm' }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Status</th>
      <td *matCellDef="let element"
          class="status-column"
          mat-cell>
        {{ element.status | hyphenatedToRegular }}{{ element.attempt ? " (attempt " + element.attempt + ")": '' }}
        <div *ngIf="element.reason"
             class="col-value-subtext">
          {{ element.reason }}
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="opened">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Opened</th>
      <td *matCellDef="let element" mat-cell>
        <lib-tick-cross [value]="element.opened">
        </lib-tick-cross>
      </td>
    </ng-container>

    <ng-container matColumnDef="clicked">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Clicked</th>
      <td *matCellDef="let element" mat-cell>
        <lib-tick-cross [value]="element.clicked">
        </lib-tick-cross>
      </td>
    </ng-container>

    <ng-container matColumnDef="spamReport">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Spam</th>
      <td *matCellDef="let element" mat-cell>
        <lib-tick-cross [value]="element.spamReport">
        </lib-tick-cross>
      </td>
    </ng-container>

    <ng-container matColumnDef="unsubscribed">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Unsub</th>
      <td *matCellDef="let element" mat-cell>
        <lib-tick-cross [value]="element.unsubscribed">
        </lib-tick-cross>
      </td>
    </ng-container>

    <ng-container matColumnDef="menu">
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" mat-cell (click)="$event.stopPropagation()">
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button (click)="openJsonDialog(element)" mat-menu-item>
            <mat-icon>visibility</mat-icon>
            <span>View Details</span>
          </button>
          <button
            (click)="resendComms(element._id)"
            mat-menu-item>
            <mat-icon>send</mat-icon>
            <span>Resend</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;"
        class="clickable"
        mat-row>
    </tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="9">{{ (this.dataSource.loading$ | async) ? "Loading..." : "No data" }}</td>
    </tr>
  </table>

  <div footer>
    <mat-paginator [length]="dataSource.total$ | async" [pageSize]="pageSize" [showFirstLastButtons]=true></mat-paginator>
  </div>
</lib-page-container>

<div class="box">
  <div class="box-text">
    {{ text }}
  </div>
  <div class="number-box"
       [ngClass]="{ 'accent-number': accent === true }">
    <span class="minor-number">{{ currency | currencySymbol }}</span>
    <span class="major-number">{{ majorAmount }}</span>
    <span class="minor-number">.{{ minorAmount }}</span>
  </div>
  <div class="sub-message"
       *ngIf="subMessage">
    {{ subMessage }}
  </div>
</div>

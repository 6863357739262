/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Payroll Frequency
 */
export type PayFrequency = 'monthly' | 'four-weekly' | 'two-weekly' | 'weekly' | 'custom';

export const PayFrequency = {
    MONTHLY: 'monthly' as PayFrequency,
    FOUR_WEEKLY: 'four-weekly' as PayFrequency,
    TWO_WEEKLY: 'two-weekly' as PayFrequency,
    WEEKLY: 'weekly' as PayFrequency,
    CUSTOM: 'custom' as PayFrequency
};


<lib-centered-page>
  <div class="container">

    <h1>Delete Account</h1>

    <p *ngIf="isDeleted">
      Your account has been deleted.
    </p>

    <lib-spinner-container
      *ngIf="!isDeleted"
      [isWorking]="isWorking">

      <form [formGroup]="form">
        <div class="form-container">
          <mat-form-field>
            <mat-label>Email</mat-label>
            <input
              matInput
              formControlName="email"
              placeholder="Email address to delete" />
            <mat-error *ngIf="form.get('email').hasError('required') && form.get('email').touched">
              You must enter your email address
            </mat-error>
            <mat-error *ngIf="form.get('email').hasError('invalidEmail') && form.get('email').touched">
              Please enter a valid email address
            </mat-error>
          </mat-form-field>

          <div class="otp-input"
               data-e2e="otp-input"
               *ngIf="form.contains('otp')">
            <mat-form-field
              class="otp-input"
              appearance="standard">
              <mat-label>One Time Passcode</mat-label>
              <input
                matInput
                formControlName="otp"
                placeholder="otp"
                type="text">
              <mat-error *ngIf="form.get('otp').hasError('required') && form.get('otp').touched">
                You must enter the OPT to update bank details
              </mat-error>
            </mat-form-field>
            <lib-error-message [message]="errorMessage"></lib-error-message>
          </div>

          <lib-button-panel>
            <button
              *ngIf="!form.contains('otp')"
              [disabled]="form.invalid || isWorking"
              mat-flat-button
              color="primary"
              (click)="requestOtp()">
              Request Account Deletion
            </button>

            <button
              *ngIf="form.contains('otp')"
              [disabled]="form.invalid || isWorking"
              mat-flat-button
              color="primary"
              (click)="confirmOtp()">
              Confirm Account Deletion
            </button>
          </lib-button-panel>
        </div>
      </form>

    </lib-spinner-container>
  </div>

</lib-centered-page>

import { Injectable } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import { CommsLogQueryResultDto, CommsLogQueryDto, CommsLogDto } from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class CommsLogService {

  constructor(private api: ApiService) {
  }

  async get(id: string): Promise<CommsLogDto> {
    return await this.api.get(`/api/comms-log/${id}`);
  }

  async queryCommsLog(query: CommsLogQueryDto): Promise<CommsLogQueryResultDto> {
    return await this.api.post('/api/comms-log', query);
  }

  async resendComms(commsLogId: string) {
    return await this.api.post(`/api/comms-log/${commsLogId}/resend`, {});
  }
}

import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

declare function loadLinkedInScript(): void;

@Component({
  selector: 'app-request-account-container',
  templateUrl: './request-account-container.component.html',
  styleUrls: ['./request-account-container.component.scss']
})
export class RequestAccountContainerComponent implements OnInit {

  imageSource: string;

  constructor() {
    this.imageSource = environment.locations.images;
  }

  ngOnInit(): void {
    try {
      loadLinkedInScript();
    } catch (err) {
      console.error(err);
    }
  }

  demoRequested() {
    if (typeof window['lintrk'] === 'function') {
      window['lintrk']('track', {conversion_id: 16975466});
    } else {
      console.error('LinkedIn tracking script is not loaded');
    }
  }
}

import { CsvFieldValidator } from './csv-field-validator';
import { PayFrequency } from 'common-ui/open-api';

export class CsvPayFrequencyValidator extends CsvFieldValidator {

  static twoContains = ['2', 'two', 'bi', 'fortnight'];
  static fourContains = ['4', 'four'];
  static weeklyContains = ['weekly', 'week'];
  static monthlyContains = ['monthly', 'month'];

  static findValue(value: string, containsArray: string[]) {
    for (const contains of containsArray) {
      if (value.indexOf(contains) > -1) {
        return true;
      }
    }
    return false;
  }

  isValid(value: string): boolean {
    const mappedValue = this.map(value);
    return !!mappedValue;
  }

  map(value: string): string {
    const lowerValue = value.toLowerCase();
    if (CsvPayFrequencyValidator.findValue(lowerValue, CsvPayFrequencyValidator.twoContains)) {
      return PayFrequency.TWO_WEEKLY;
    } else if (CsvPayFrequencyValidator.findValue(lowerValue, CsvPayFrequencyValidator.fourContains)) {
      return PayFrequency.FOUR_WEEKLY;
    } else if (CsvPayFrequencyValidator.findValue(lowerValue, CsvPayFrequencyValidator.weeklyContains)) {
      return PayFrequency.WEEKLY;
    } else if (CsvPayFrequencyValidator.findValue(lowerValue, CsvPayFrequencyValidator.monthlyContains)) {
      return PayFrequency.MONTHLY;
    } else if ( lowerValue === 'custom' ) {
      return PayFrequency.CUSTOM
    }
    return null;
  }

}

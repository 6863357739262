<lib-dialog-container
  (exit)="close(false)"
  subHeader="Invite {{ inviteAll ? 'employees that meet the following criteria:' : employeeIdsToInvite.length + ' selected employees.' }}"
  header="Invite Manager">

  <lib-spinner-container [isWorking]="isWorking">

    <form [formGroup]="form" (ngSubmit)="sendInvites()">

      <div class="employee-invite-container mat-typography">

        <div class="employee-invite-list" *ngIf="!inviteAll">
          <table mat-table [dataSource]="data.selectedEmployees" class="employee-invite-table">
            <ng-container matColumnDef="name">
              <td mat-cell
                  [ngClass]="{ excluded: disableEmployee(employee) }"
                  *matCellDef="let employee">
                {{employee.firstName}} {{employee.lastName}}
                <div></div>
              </td>
            </ng-container>

            <ng-container matColumnDef="exclusion-reason">
              <td mat-cell
                  [ngClass]="{ excluded: disableEmployee(employee) }"
                  *matCellDef="let employee">{{ excludedReason(employee) }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
              <td mat-cell
                  [ngClass]="{ excluded: disableEmployee(employee) }"
                  *matCellDef="let employee">{{employee.email}}</td>
            </ng-container>

            <ng-container matColumnDef="remove">
              <td mat-cell
                  [ngClass]="{ excluded: disableEmployee(employee) }"
                  *matCellDef="let employee">
                <button mat-icon-button
                        (click)="removeEmployee(employee._id)">
                  <mat-icon>close</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell no-withdrawals-found" colspan="2">
                No employees selected.
              </td>
            </tr>
          </table>
        </div>

        <div class="checkbox-panel">
          <mat-checkbox formControlName="excludeInvited">
            Exclude employees who have already been invited
          </mat-checkbox>
        </div>

        <div class="checkbox-panel">
          <mat-checkbox formControlName="excludeActive">
            Exclude employees who have already logged in
          </mat-checkbox>
        </div>

        <div class="checkbox-panel">
          <mat-checkbox formControlName="excludeBlocked">
            Exclude employees who are blocked
          </mat-checkbox>
        </div>

        <div class="checkbox-panel">
          <mat-checkbox formControlName="excludeLeaving">
            Exclude employees who are leaving
          </mat-checkbox>
        </div>

        <div class="checkbox-panel">
          <mat-checkbox formControlName="timesheetEmployeesOnly">
            Timesheet Employees Only
          </mat-checkbox>
        </div>

        <mat-form-field
          class="amount-available-threshold">
          <mat-label>Amount Available Threshold</mat-label>
          <input formControlName="amountAvailableThreshold"
                 matInput
                 mask="0*"
                 prefix="£"
                 placeholder="Amount Available Threshold">
          <mat-error *ngIf="form.controls.amountAvailableThreshold.invalid
                       && form.controls.amountAvailableThreshold.dirty
                       && form.controls.amountAvailableThreshold.touched">
            Threshold must be less than £200.00
          </mat-error>
        </mat-form-field>

        <div *ngIf="inviteAll" class="message-body">
          To invite a subset of employees, selecting them the employee list.
        </div>

        <lib-error-message [message]="errorMessage"></lib-error-message>

        <lib-button-panel>
          <button (click)="close(false)"
                  mat-stroked-button
                  color="primary"
                  type="button">
            Close
          </button>

          <button color="primary"
                  type="submit"
                  [disabled]="!inviteAll && employeeIdsToInvite.length === 0"
                  mat-flat-button>
            Send
          </button>
        </lib-button-panel>
      </div>
    </form>
  </lib-spinner-container>
</lib-dialog-container>

import { Component } from '@angular/core';
import { PayFrequency } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';
import { hyphenatedToRegular } from 'common-ui/pipes/hyphenated-to-regular';

@Component({
  selector: 'lib-pay-frequency-selector',
  templateUrl: './pay-frequency-selector.component.html'
})
export class PayFrequencySelectorComponent {
  payFrequencies = Object.values(PayFrequency).map(f => ({
    value: f.toString(),
    description: hyphenatedToRegular(f)
  }));
  FilterType = FilterType;
}


/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AuthErrorCode = 'sign-in-failed' | 'incorrect-otp' | 'invalid-token' | 'token-expired' | 'refresh-token-expired' | 'not-verified' | 'otp-counter-not-set' | 'otp-expired';

export const AuthErrorCode = {
    SIGN_IN_FAILED: 'sign-in-failed' as AuthErrorCode,
    INCORRECT_OTP: 'incorrect-otp' as AuthErrorCode,
    INVALID_TOKEN: 'invalid-token' as AuthErrorCode,
    TOKEN_EXPIRED: 'token-expired' as AuthErrorCode,
    REFRESH_TOKEN_EXPIRED: 'refresh-token-expired' as AuthErrorCode,
    NOT_VERIFIED: 'not-verified' as AuthErrorCode,
    OTP_COUNTER_NOT_SET: 'otp-counter-not-set' as AuthErrorCode,
    OTP_EXPIRED: 'otp-expired' as AuthErrorCode
};


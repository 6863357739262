<div class="container">

  <lib-dialog-container header="{{ data.create ? 'Create Content' : 'Edit Content' }}">

    <lib-spinner-container [isWorking]="isLoading">

      <form [formGroup]="form" (ngSubmit)="submit()">

        <div class="field-list">

          <mat-form-field>
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of contentTypes"
                          [value]="type">{{ type | hyphenatedToRegular }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput
                   formControlName="title"
                   placeholder="Title" />

            <mat-error *ngIf="form.controls.title.errors?.maxlength">
              Title must be less than {{ form.controls.title.errors.maxlength['requiredLength'] }} characters.
            </mat-error>

            <mat-error *ngIf="form.controls.title.errors?.required">
              Title is required.
            </mat-error>

          </mat-form-field>

          <mat-form-field *ngIf="form.value.type === ContentType.FAQ || form.value.type === ContentType.ARTICLE || form.value.type === ContentType.WELLBEING_EMAIL">
            <mat-label>Text</mat-label>
            <textarea matInput
                      formControlName="text"
                      placeholder="{{ form.value.type === ContentType.FAQ ? 'FAQ' : 'Excerpt' }}">
            </textarea>

            <mat-error *ngIf="form.controls.text?.errors?.maxlength">
              Text must be less than {{ form.controls.text?.errors.maxlength['requiredLength'] }} characters.
            </mat-error>

            <mat-error *ngIf="form.controls.text?.errors?.required">
              Text is required.
            </mat-error>

          </mat-form-field>

          <mat-form-field *ngIf="form.value.type !== ContentType.FAQ">
            <mat-label>Content URL</mat-label>
            <input matInput
                   formControlName="contentUrl"
                   placeholder="Content URL" />
          </mat-form-field>

          <mat-form-field *ngIf="form.value.type === ContentType.TOOL">
            <mat-label>Icon</mat-label>
            <mat-select formControlName="icon">
              <mat-select-trigger>
                <div class="icon-option">
                  <img *ngIf="form.controls.icon.value" [src]="getImageUrl(form.controls.icon.value)" />
                  <span class="icon-text">{{ getIconText(form.controls.icon.value) | titlecase }}</span>
                </div>
              </mat-select-trigger>
              <mat-option *ngFor="let icon of contentIcons"
                          [value]="icon">
                <div class="icon-option">
                  <img *ngIf="icon" [src]="getImageUrl(icon)" />
                  <span class="icon-text">{{ getIconText(icon) | titlecase }}</span>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>

        <lib-error-message [message]="errorMessage"></lib-error-message>

        <div class="button-panel">

          <button mat-stroked-button
                  color="primary"
                  (click)="close()"
                  type="button">
            Close
          </button>

          <button mat-flat-button
                  type="submit"
                  [disabled]="!form.valid"
                  color="primary">
            {{ data.create ? 'Create' : 'Save' }}
          </button>

        </div>
      </form>

    </lib-spinner-container>
  </lib-dialog-container>
</div>

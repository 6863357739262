<lib-employee-portal-page>
  <lib-spinner-container [isWorking]="isWorking" class="spinner">
    <div class="container">

      <mat-tab-group mat-align-tabs="center">
        <mat-tab label="Terms of Service">
          <div class="markdown-container">
            <markdown [data]="termsOfService">
            </markdown>
          </div>
        </mat-tab>
        <mat-tab label="Privacy">
          <div class="markdown-container">
            <markdown [data]="privacyPolicy">
            </markdown>
          </div>
        </mat-tab>
      </mat-tab-group>

      <button mat-flat-button
              class="next-button"
              color="accent"
              (click)="acceptTerms()">
        Accept
      </button>

    </div>
  </lib-spinner-container>
</lib-employee-portal-page>

import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { getWeekEnd } from 'common-ui/util';
import { getShiftedDate } from 'common-ui/util/get-shifted-date';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';

@Component({
  selector: 'lib-week-selector',
  templateUrl: './week-selector.component.html',
  styleUrls: ['./week-selector.component.css']
})
export class WeekSelectorComponent implements OnInit {
  @Output() emitDates = new EventEmitter<JustDateRange>();
  @Input() isLoading: boolean;
  @Input() dateRange: JustDateRange;
  displayedWeek: JustDateRange;

  ngOnInit() {
    this.displayedWeek = this.dateRange;
  }

  async nextWeek() {
    const fromDate = getShiftedDate(this.displayedWeek.fromDate, 7);
    this.displayedWeek = {
      fromDate: fromDate,
      toDate: getWeekEnd(fromDate)
    };
    this.emitDates.emit(this.displayedWeek);
  }

  async prevWeek() {
    const fromDate = getShiftedDate(this.displayedWeek.fromDate, -7);
    this.displayedWeek = {
      fromDate: fromDate,
      toDate: getWeekEnd(fromDate)
    };
    this.emitDates.emit(this.displayedWeek);
  }

  setDateRange(dates: JustDateRange) {
    this.displayedWeek = dates;
  }
}

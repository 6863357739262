<lib-dialog-container
  (exit)="close()"
  header="Pay Codes"
  [subHeader]="subHeader"
  [showCross]="true">

  <div class="table-container">

    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="payCode">
        <th mat-header-cell *matHeaderCellDef>Pay Code</th>
        <td mat-cell *matCellDef="let element"> {{element.payCode }} </td>
      </ng-container>

      <ng-container matColumnDef="rate">
        <th mat-header-cell *matHeaderCellDef>Hourly Rate</th>
        <td mat-cell *matCellDef="let element">{{element.rate | pennyToPound: element.currency }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell none-found" [attr.colspan]="displayedColumns.length">
          {{ isLoading ? 'Loading...' : 'No pay codes' }}
        </td>
      </tr>

    </table>

  </div>


</lib-dialog-container>

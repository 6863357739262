<div class="table-container">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="periodStart">
      <th class="date-column" mat-header-cell *matHeaderCellDef>Period Start</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="period-date">
          {{ element.periodStart | date: "dd/MM/yyyy - EEE" }}
          <span *ngIf="(i === 0 || i === 1) && isOverrideActive" class="sub-text">overridden</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="periodEnd">
      <th class="date-column" mat-header-cell *matHeaderCellDef>Period End</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="period-date">
          {{ element.periodEnd | date: "dd/MM/yyyy - EEE" }}
          <span *ngIf="i === 0 && isOverrideActive" class="sub-text">overridden</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="deductionsExportDate">
      <th class="date-column" mat-header-cell *matHeaderCellDef>Deductions Export</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="period-date">
          {{ element.deductionsExportDate | date: "dd/MM/yyyy - EEE" }}
          <span *ngIf="i === 0 && isOverrideActive" class="sub-text">overridden</span>
        </div>
      </td>

    </ng-container>

    <ng-container matColumnDef="payDate">
      <th class="date-column" mat-header-cell *matHeaderCellDef>Pay Date</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="period-date">
          {{ element.payDate | date: "dd/MM/yyyy - EEE" }}
          <span *ngIf="i === 0 && isOverrideActive" class="sub-text">overridden</span>
        </div>
      </td>
    </ng-container>

    <tr class="header-row" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr  class="header-row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CsvColumnMapping, CsvConfig, ColumnHeading } from '../csv-services';
import { convertCamelToRegular } from 'common-ui/pipes/convert-camel-to-regular';
import { ConfigType, CsvInputError } from 'common-ui/open-api';

export interface CsvMappingEditDialogData {
  targetColumn: string;
  csvConfig: CsvConfig;
}

const FORMAT_VALIDATIONS = [
  CsvInputError.INVALID_EMAIL,
  CsvInputError.INVALID_INT,
  CsvInputError.INVALID_DATE
];

interface NameLabel {
  name: string;
  label: string;
}


@Component({
  selector: 'lib-csv-mapping-edit-dialog',
  templateUrl: './csv-single-mapping-edit-dialog.component.html',
  styleUrls: ['./csv-single-mapping-edit-dialog.component.css']
})
export class CsvSingleMappingEditDialogComponent implements OnInit {

  errorMessage: string;
  subHeading: string;
  isWorking: boolean;
  isEdited: boolean;
  mapping: CsvColumnMapping;
  updatedCsvColumnName: string;
  headings: ColumnHeading[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CsvMappingEditDialogData,
    private dialogRef: MatDialogRef<CsvSingleMappingEditDialogComponent>
  ) {
  }

  ngOnInit() {
    if (!this.data.csvConfig.isFileLoaded) {
      this.errorMessage = 'Please choose a file before editing.';
    }
    if (this.data.csvConfig.type === ConfigType.STANDARD) {
      this.errorMessage = 'Standard Mappings are not editable. Please make a copy.';
    }
    this.mapping = this.csvConfig.getMapping(this.data.targetColumn);
    if (this.mapping.csvColumnName) {
      this.subHeading = `Our column ${convertCamelToRegular(this.mapping.label)} is mapped to ${this.mapping.csvColumnName} of your csv file.`;
    } else {
      this.subHeading = `${convertCamelToRegular(this.mapping.label)} is not mapped.  Select a column from your csv file`;
    }

    this.headings = this.createHeadings();
    this.updatedCsvColumnName = this.mapping.csvColumnName;
  }

  get isEditable() {
    return this.data.csvConfig.type === ConfigType.USER;
  }

  get csvConfig() {
    return this.data.csvConfig;
  }

  close() {
    this.dialogRef.close(false);
  }

  save() {
    if (this.isEdited) {
      if (this.updatedCsvColumnName !== this.mapping.csvColumnName) {
        if (this.updatedCsvColumnName === 'clear') {
          this.csvConfig.removeMapping(this.data.targetColumn);
        } else {
          this.csvConfig.setMapping(this.data.targetColumn, this.updatedCsvColumnName);
        }
      }
      this.dialogRef.close(true);
    }
  }

  mappingChanged(csvColumnName: string) {
    this.updatedCsvColumnName = csvColumnName;
    this.isEdited = true;
  }

  private createHeadings(): ColumnHeading[] {
    if (this.csvConfig.csvInputFile) {
      return this.csvConfig.csvInputFile.createHeadings(this.csvConfig.noHeaderRow);
    } else {
      return [];
    }
  }

  get isUnique() {
    return this.mapping.targetField.validations.indexOf(CsvInputError.NOT_UNIQUE) > -1;
  }

  get isEnum() {
    return this.mapping.targetField.validations.indexOf(CsvInputError.INVALID_ENUM) > -1 ||
      this.mapping.targetField.validations.indexOf(CsvInputError.INVALID_FREQUENCY) > -1;
  }

  get isFormatValidation() {
    return this.mapping.targetField.validations.filter(x => FORMAT_VALIDATIONS.includes(x)).length > 0;
  }

  get getPrimaryKeys(): NameLabel[] {
    const uniqueKeyTargetFields = this.csvConfig.mappings.filter(x => x.isKey);
    return uniqueKeyTargetFields.map(m => ({
      name: m.targetField.name,
      label: m.targetField?.label ?? m.targetField.name
    }));
  }
}

<div *ngIf="list">
  <mat-form-field appearance="outline" class="filter-option">
    <mat-label>{{ label || filterType }}</mat-label>
    <mat-select [formControl]="formControl"
                [multiple]="!singleSelect"
                (selectionChange)="emitSelectorValue($event)">
      <mat-option [value]="singleSelect ? undefined : 'all'">
        All
      </mat-option>
      <mat-option *ngFor="let item of list" [value]="item.value">
        {{ item.description | hyphenatedToRegular }}
        <ng-template #noFormat>
          {{ item }}
        </ng-template>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { CsvConfig, CsvInputRow, CsvInputCell, CsvColumnMapping } from '../csv-services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  CsvSingleMappingEditDialogComponent
} from '../csv-single-mapping-edit-dialog/csv-single-mapping-edit-dialog.component';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { TrafficLights } from 'common-ui/models/traffic-lights.model';
import { CsvInputError } from 'common-ui/open-api';

const MISSING_CELL = new CsvInputCell('');
MISSING_CELL.cellErrors = [CsvInputError.REQUIRED];

const UNMAPPED_CELL = new CsvInputCell('');
UNMAPPED_CELL.cellErrors = [CsvInputError.REQUIRED];

const OPTIONAL_CELL = new CsvInputCell('');

const ERROR_CELL = new CsvInputCell('SYS ERROR');

@Component({
  selector: 'lib-csv-output-preview',
  templateUrl: './csv-output-preview.component.html',
  styleUrls: ['./csv-output-preview.component.css']
})
export class CsvOutputPreviewComponent implements OnChanges, OnDestroy, AfterViewInit {

  @Input() csvConfig: CsvConfig;
  @Output() configUpdated = new EventEmitter<CsvConfig>();
  @Input() filterErrors: boolean;
  dataSource: MatTableDataSource<CsvInputRow> = new MatTableDataSource<CsvInputRow>();
  columns: string[];
  mappings: CsvColumnMapping[];
  ngUnsubscribe$ = new Subject();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog: MatDialog, private changeDetector: ChangeDetectorRef) {
    this.dataSource.filterPredicate = (row: CsvInputRow) => {
      return this.filterErrors ? row.status !== TrafficLights.GO : true;
    };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.updateDataSource();
    this.changeDetector.detectChanges();
  }

  trackByIndex = i => i;

  ngOnChanges(): void {
    this.ngUnsubscribe$.next(undefined);

    if (this.paginator) {
      this.updateDataSource();
    }

    this.csvConfig.configChanges
      .pipe(takeUntil(this.ngUnsubscribe$.asObservable()))
      .subscribe(() => {
        this.updateDataSource();
      });
  }

  updateDataSource() {
    this.mappings = this.csvConfig.mappings;
    this.columns = ['status', ...this.csvConfig.mappings.map(mapping => mapping.targetField.name)];
    if (this.csvConfig.csvInputFile) {
      this.dataSource.data = this.csvConfig.csvInputFile.rows;
    } else {
      this.dataSource.data = [];
    }
    this.dataSource.filter = 'filter errors';
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  get noData() {
    return this.dataSource.data.length === 0;
  }

  openMappingEditDialog(mapping: CsvColumnMapping) {
    const dialogRef = this.dialog.open(CsvSingleMappingEditDialogComponent, {
      data: {
        targetColumn: mapping.targetField.name,
        csvConfig: this.csvConfig
      }
    });
    dialogRef.afterClosed().subscribe(isUpdated => {
      if (isUpdated) {
        this.configUpdated.emit(isUpdated);
      }
    });
  }

  getRowStatus(row: CsvInputRow): TrafficLights {
    if (this.csvConfig.csvInputFile.fileErrors.length > 0) {
      return TrafficLights.STOP;
    } else {
      return row.status;
    }
  }

  getCell(row: CsvInputRow, mapping: CsvColumnMapping) {
    if (!mapping.csvColumnName) {
      return mapping.isRequired ? UNMAPPED_CELL : OPTIONAL_CELL;
    }
    if (mapping.status) {
      return mapping.isRequired ? UNMAPPED_CELL : OPTIONAL_CELL;
    }
    const index = this.csvConfig.csvInputFile.getColumnIndex(mapping.csvColumnName);
    if (index === -1) {
      return mapping.isRequired ? UNMAPPED_CELL : OPTIONAL_CELL;
    }
    const cell = row.cells[index];
    if (!cell) {
      return ERROR_CELL;
    }
    return cell;
  }
}

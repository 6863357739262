<lib-page-container>

  <lib-filter-bar header>
    <div control-options>
      <button mat-button (click)="openCreatePayrollDialog()" *ngIf="!environment.adminConsole">
        Create
      </button>
    </div>

    <lib-company-selector>
    </lib-company-selector>

    <lib-pay-frequency-selector>
    </lib-pay-frequency-selector>

    <lib-master-account-selector *ngIf="environment.adminConsole">
    </lib-master-account-selector>

  </lib-filter-bar>

  <table [dataSource]="dataSource" mat-table matSort>

    <ng-container matColumnDef="masterAccountName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Master Account</th>
      <td *matCellDef="let element" mat-cell>{{ element.masterAccountName }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Company</th>
      <td *matCellDef="let element" mat-cell>{{ element.companyName }}</td>
    </ng-container>

    <ng-container matColumnDef="currency">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Currency</th>
      <td *matCellDef="let element" mat-cell>{{ element.currency | uppercase }}</td>
    </ng-container>

    <ng-container matColumnDef="frequency">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Frequency</th>
      <td *matCellDef="let element" mat-cell>
        <div>{{ element.frequency | hyphenatedToRegular }}</div>
        <span *ngIf="element.payType" class="sub-text">{{ element.payType | hyphenatedToRegular }}</span>
      </td>

    </ng-container>

    <ng-container matColumnDef="unsettledAmount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Unsettled Amount</th>
      <td *matCellDef="let element" mat-cell>{{(element.unsettledAmount) | pennyToPound: element.currency: false}}</td>
    </ng-container>

    <ng-container matColumnDef="unsettledCount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Unsettled Count</th>
      <td *matCellDef="let element" mat-cell>{{element.unsettledCount}}</td>
    </ng-container>

    <ng-container matColumnDef="periodStart">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Period Start</th>
      <td *matCellDef="let element" mat-cell>
        <div>{{ element.periodStart | date: "dd/MM/yyyy" }}</div>
        <span *ngIf="element.currentDatesOverrideActive" class="sub-text">overridden</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="periodEnd">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Period End</th>
      <td *matCellDef="let element" mat-cell>
        <div>{{ element.periodEnd | date: "dd/MM/yyyy" }}</div>
        <span *ngIf="element.currentDatesOverrideActive" class="sub-text">overridden</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th *matHeaderCellDef mat-header-cell>Type</th>
      <td *matCellDef="let element" mat-cell>
        <div>{{ element.type | hyphenatedToRegular }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="delayChargeDateToPayDate">
      <th *matHeaderCellDef mat-header-cell>DD Deferred?</th>
      <td *matCellDef="let element" mat-cell>
        <div>{{ element.delayChargeDateToPayDate ? 'Yes' : '' }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="menu" stickyEnd>
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" mat-cell>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">

          <button (click)="openEditPayrollDialog(element._id)" mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit Payroll</span>
          </button>

          <button (click)="openDeletePayrollDialog(element)" mat-menu-item>
            <mat-icon>delete</mat-icon>
            <span>Delete Payroll</span>
          </button>

          <button *ngIf="isConsole"
                  (click)="openPayrollDatesEditDialog(element)"
                  mat-menu-item>
            <mat-icon>date_range</mat-icon>
            <span>Date Override</span>
          </button>

          <button (click)="openPayrollScheduleDialog(element)"
                  mat-menu-item>
            <mat-icon>schedule</mat-icon>
            <span>View Schedule</span>
          </button>

          <button (click)="manualPeriodRollover(element)"
                  *ngIf="environment.adminConsole"
                  mat-menu-item>
            <mat-icon>update</mat-icon>
            <span>Period Rollover</span>
          </button>

          <button (click)="manualDeductionRollover(element)"
                  *ngIf="environment.adminConsole"
                  mat-menu-item>
            <mat-icon>update</mat-icon>
            <span>Deduction Rollover</span>
          </button>

        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="9">{{ isLoading ? "Loading..." : "No data" }}</td>
    </tr>
  </table>

  <div footer>
    <mat-paginator [length]="dataSource.data.length"></mat-paginator>
  </div>
</lib-page-container>

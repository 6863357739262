import {
  EmployeePortalWithdrawalHistoryComponent
} from 'employee-portal/employee-portal-withdrawal-history/employee-portal-withdrawal-history.component';
import {
  EmployeePortalAccountSettingsComponent
} from 'employee-portal/employee-portal-account-settings/employee-portal-account-settings.component';
import {
  EmployeePortalAcceptTermsComponent
} from 'employee-portal/employee-portal-accept-terms/employee-portal-accept-terms.component';
import { EmployeePortalComponent } from 'employee-portal/employee-portal/employee-portal.component';
import { EmployeePortalGuard } from './employee-portal.guard';
import {
  EmployeePortalBankDetailsComponent
} from 'employee-portal/employee-portal-bank-details/employee-portal-bank-details.component';
import {
  EmployeePortalWithdrawalComponent
} from 'employee-portal/employee-portal-withdrawal/employee-portal-withdrawal.component';
import { Route } from '@angular/router';
import {
  EmployeePortalNotificationsPrefsComponent
} from 'employee-portal/employee-portal-notifications-prefs/employee-portal-notifications-prefs.component';
import {
  EmployeePortalAmountAvailableBreakdownComponent
} from 'employee-portal/employee-portal-amount-available-breakdown/employee-portal-amount-available-breakdown.component';

export const employeePortalRoutes: Route = {
  path: 'employee-portal',
  component: EmployeePortalComponent,
  canActivateChild: [EmployeePortalGuard],
  children: [
    {path: '', redirectTo: 'withdraw', pathMatch: 'full'},
    {path: 'bank-details', component: EmployeePortalBankDetailsComponent},
    {path: 'withdraw', component: EmployeePortalWithdrawalComponent},
    {path: 'accept-terms', component: EmployeePortalAcceptTermsComponent},
    {path: 'history', component: EmployeePortalWithdrawalHistoryComponent},
    {path: 'account', component: EmployeePortalAccountSettingsComponent},
    {path: 'breakdown', component: EmployeePortalAmountAvailableBreakdownComponent},
    {path: 'notifications', component: EmployeePortalNotificationsPrefsComponent}
  ]
};

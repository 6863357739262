import { Component, Input, OnChanges } from '@angular/core';
import { Currency } from 'common-ui/open-api';

@Component({
  selector: 'lib-employee-portal-info-panel',
  templateUrl: './employee-portal-info-panel.component.html',
  styleUrls: ['./employee-portal-info-panel.component.css']
})
export class EmployeePortalInfoPanelComponent implements OnChanges {

  @Input() text: string;
  @Input() pennyAmount: number;
  @Input() accent: boolean;
  @Input() subMessage: string;
  @Input() currency: Currency
  majorAmount: string;
  minorAmount: string;

  constructor() {
  }

  ngOnChanges(): void {
    const fixedValueString = (this.pennyAmount / 100).toFixed(2).toString();
    const parts = fixedValueString.split('.');
    this.majorAmount = parts[0];
    this.minorAmount = parts[1];
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { AmountAvailableBreakdownDto } from 'common-ui/open-api';
import { EmployeeService } from 'common-ui/services';

@Component({
  selector: 'lib-amount-available-explainer',
  templateUrl: './amount-available-breakdown.component.html',
  styleUrls: ['./amount-available-breakdown.component.css']
})
export class AmountAvailableBreakdownComponent implements OnInit {

  @Input() employeeId: string;
  breakdown: AmountAvailableBreakdownDto;
  isWorking = true;

  constructor(
    private employeeService: EmployeeService
  ) {
  }

  async ngOnInit() {
    try {
      this.isWorking = true;
      this.breakdown = await this.employeeService.getAmountAvailableBreakdown(this.employeeId);
      this.isWorking = false;
    } catch (err) {
      console.log(err);
    }
  }

}

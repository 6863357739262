import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { FormGroup, FormControl } from '@angular/forms';

export interface DateOperationDialogData {
  heading: string;
  message: string;
  executeButtonText: string;
  asyncFn: (date: string) => Promise<void>;
}

@Component({
  selector: 'lib-are-you-sure-dialog',
  templateUrl: './date-operation-dialog.component.html',
  styleUrls: ['./date-operation-dialog.component.css']
})
export class DateOperationDialogComponent {

  isWorking = false;
  errorMessage: string;
  form: FormGroup = new FormGroup({
    dateValue: new FormControl()
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DateOperationDialogData,
    private dialogRef: MatDialogRef<DateOperationDialogComponent>
  ) {
  }

  async executeOperation() {
    try {
      this.isWorking = true;
      this.errorMessage = '';
      await this.data.asyncFn(this.form.value.dateValue);
      this.dialogRef.close(true);
    } catch (err) {
      this.isWorking = false;
      this.errorMessage = err.message;
    }

  }

  cancelOperation() {
    this.dialogRef.close(false);
  }

}

<lib-spinner-container [isWorking]="isWorking">
  <lib-dialog-container header="Care Planner Payment Group" subHeader="Select Payment Group for Salaried Staff">
    <div class="container">
      <mat-form-field>
        <mat-select [formControl]="formControl">
          <mat-option *ngFor="let group of paymentGroups" [value]="group">
            {{ group.organisation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <lib-button-panel>
        <button mat-stroked-button (click)="cancel()">Cancel</button>
        <button mat-flat-button color="primary" (click)="save()">Save</button>
      </lib-button-panel>
    </div>
  </lib-dialog-container>
</lib-spinner-container>


<lib-spinner-container [isWorking]="isLoading">

  <div *ngIf="(!create && employee) || create" class="employee-edit-container">

    <form (ngSubmit)="onSubmit()"
          [formGroup]="employeeForm">

      <div class="input-row">
        <mat-form-field class="input-field">
          <mat-label>Email</mat-label>
          <input matInput
                 class="email-input"
                 required
                 placeholder="Email"
                 formControlName="email">

          <mat-error *ngIf="employeeForm.controls.email.invalid
                       && employeeForm.controls.email.dirty
                       && employeeForm.controls.email.touched
                       && (employeeForm.controls.email.errors.required)">
            Email is required
          </mat-error>

          <mat-error *ngIf="employeeForm.controls.email.invalid
                       && employeeForm.controls.email.dirty
                       && employeeForm.controls.email.touched
                       && (employeeForm.controls.email.errors.invalidEmail)">
            Email is invalid
          </mat-error>
        </mat-form-field>

        <button
          *ngIf="employee?.timesheetIntegrationSource"
          (click)="toggleEnabled('email')"
          mat-icon-button
          type="button"
          class="helper-button">
          <mat-icon>{{employeeForm.controls.email.enabled ? 'lock_open' : 'lock'}}</mat-icon>
        </button>
      </div>

      <div class="input-row">
        <div class="input-row-with-info-btn">

          <mat-form-field class="input-field left">
            <mat-label>First Name</mat-label>
            <input matInput
                   required
                   placeholder="First Name"
                   formControlName="firstName">

            <mat-error *ngIf="employeeForm.controls.firstName.invalid
                       && employeeForm.controls.firstName.dirty
                       && employeeForm.controls.firstName.touched
                       && (employeeForm.controls.firstName.errors.required)">
              First name is required
            </mat-error>

          </mat-form-field>

          <button
            *ngIf="employee?.timesheetIntegrationSource"
            (click)="toggleEnabled('firstName')"
            mat-icon-button
            type="button"
            class="helper-button">
            <mat-icon>{{employeeForm.controls.firstName.enabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>
        </div>

        <div class="input-row-with-info-btn">

          <mat-form-field class="input-field right">
            <mat-label>Last Name</mat-label>
            <input matInput
                   required
                   placeholder="Last Name"
                   formControlName="lastName">

            <mat-error *ngIf="employeeForm.controls.lastName.invalid
                       && employeeForm.controls.lastName.dirty
                       && employeeForm.controls.lastName.touched
                       && (employeeForm.controls.lastName.errors.required)">
              Last name is required
            </mat-error>

          </mat-form-field>

          <button
            *ngIf="employee?.timesheetIntegrationSource"
            (click)="toggleEnabled('lastName')"
            mat-icon-button
            type="button"
            class="helper-button">
            <mat-icon>{{employeeForm.controls.lastName.enabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>
        </div>
      </div>

      <div class="input-row">
        <div class="input-row-with-info-btn">
          <mat-form-field class="input-field left">
            <mat-label>Company</mat-label>
            <mat-select formControlName="companyId">
              <mat-option *ngFor="let company of availableCompanies" [value]="company._id">{{company.name}}</mat-option>
            </mat-select>
          </mat-form-field>

          <button
            *ngIf="employee?.timesheetIntegrationSource"
            (click)="toggleEnabled('companyId')"
            mat-icon-button
            type="button"
            class="helper-button">
            <mat-icon>{{employeeForm.controls.companyId.enabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>
        </div>

        <div class="input-row-with-info-btn">
          <mat-form-field class="input-field right">
            <mat-label>Payroll</mat-label>
            <mat-select formControlName="payrollId">
              <mat-option *ngFor="let payroll of availablePayrolls"
                          [value]="payroll._id">{{payroll.frequency | payFrequency}}-{{payroll.currency}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button
            *ngIf="employee?.timesheetIntegrationSource"
            (click)="toggleEnabled('payrollId')"
            id="payroll-id-lock"
            mat-icon-button
            type="button"
            class="helper-button">
            <mat-icon>{{employeeForm.controls.payrollId.enabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>
        </div>
      </div>

      <div class="input-row">

        <div class="input-row-with-info-btn">
          <mat-form-field class="input-field left">
            <mat-label>Pay Type</mat-label>
            <mat-select formControlName="payType">
              <mat-option *ngFor="let payType of payTypes"
                          [value]="payType">{{payType | camelToRegular }}</mat-option>
            </mat-select>
          </mat-form-field>

          <button
            *ngIf="employee?.timesheetIntegrationSource"
            (click)="toggleEnabled('payType')"
            id="e2e-pay-type-lock"
            mat-icon-button
            type="button"
            class="helper-button">
            <mat-icon>{{employeeForm.controls.payType.enabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>
        </div>


        <div class="input-row-with-info-btn">
          <mat-form-field class="input-field right">

            <mat-label>Gross Annual Pay</mat-label>
            <input
              [ngClass]="{invisible: !isPayAmountInputVisible}"
              matInput
              mask="separator.2"
              required
              thousandSeparator=","
              [prefix]="currencySymbol"
              placeholder="Gross Annual Pay"
              formControlName="payAmount">

            <div
              *ngIf="!isPayAmountInputVisible"
              class="pay-amount-not-available-container right">
              N/A
            </div>

            <mat-error *ngIf="employeeForm.controls.payAmount.invalid
                       && employeeForm.controls.payAmount.dirty
                       && employeeForm.controls.payAmount.touched
                       && (employeeForm.controls.payAmount.errors.required)">
              Gross annual pay is required
            </mat-error>
          </mat-form-field>


          <button
            *ngIf="employee?.timesheetIntegrationId"
            (click)="togglePayAmountEnabled()"
            id="e2e-pay-amount-lock"
            mat-icon-button
            [disabled]="isPayAmountLockDisabled"
            type="button"
            class="helper-button">
            <mat-icon>{{isPayAmountEnabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>

        </div>
      </div>

      <div class="input-row">
        <div class="input-row-with-info-btn">
          <mat-form-field class="input-field left">
            <mat-label>External Payroll Id</mat-label>
            <input matInput
                   required
                   placeholder="External Payroll Id"
                   formControlName="payrollExternalId">

            <mat-error *ngIf="employeeForm.controls.payrollExternalId.invalid
                         && employeeForm.controls.payrollExternalId.dirty
                         && employeeForm.controls.payrollExternalId.touched
                         && (employeeForm.controls.payrollExternalId.errors.required)">
              Payroll Id is required
            </mat-error>
          </mat-form-field>

          <button
            *ngIf="employee?.timesheetIntegrationSource"
            (click)="toggleEnabled('payrollExternalId')"
            mat-icon-button
            type="button"
            class="helper-button">
            <mat-icon>{{employeeForm.controls.payrollExternalId.enabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>
        </div>

        <div class="input-row-with-info-btn">
          <div class="input-row-with-info-btn">

            <mat-form-field class="input-field right">
              <mat-label>Withdrawal Allowance</mat-label>
              <input matInput
                     suffix="%"
                     mask="percent"
                     [dropSpecialCharacters]="true"
                     required
                     placeholder="Withdrawal Allowance"
                     formControlName="allowance">

              <mat-error *ngIf="employeeForm.controls.allowance.invalid
                       && employeeForm.controls.allowance.dirty
                       && employeeForm.controls.allowance.touched
                       && employeeForm.controls.allowance.errors.required">
                'Allowance is required'
              </mat-error>

              <mat-error *ngIf="employeeForm.controls.allowance.invalid
                       && employeeForm.controls.allowance.dirty
                       && employeeForm.controls.allowance.touched
                       && employeeForm.controls.allowance.errors.min">
                {{ allowanceRangeError }}
              </mat-error>

              <mat-error *ngIf="employeeForm.controls.allowance.invalid
                       && employeeForm.controls.allowance.dirty
                       && employeeForm.controls.allowance.touched
                       &&  employeeForm.controls.allowance.errors.max">
                {{ allowanceRangeError }}
              </mat-error>

            </mat-form-field>

            <button
              (click)="toggleAllowance()"
              mat-icon-button
              type="button"
              class="helper-button">
              <mat-icon>{{useEmployeeAllowanceOverride ? 'lock_open' : 'lock'}}</mat-icon>
            </button>

          </div>
        </div>
      </div>

      <div class="input-row">
        <div class="input-row-with-info-btn">
          <div class="input-field left">
            <lib-datepicker
              formControlName="startDate"
              label="Start Date">
            </lib-datepicker>
          </div>

          <button
            *ngIf="employee?.timesheetIntegrationSource"
            (click)="toggleEnabled('startDate')"
            mat-icon-button
            type="button"
            class="helper-button">
            <mat-icon>{{employeeForm.controls.startDate.enabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>

        </div>

        <div *ngIf="(!create && employee)" class="input-row-with-info-btn">
          <div class="input-field right">
            <lib-datepicker
              formControlName="leavingDate"
              label="Leaving Date"
              [min]="employeeForm.controls['startDate'].value"
              [errorMessageOverrides]="{dateMin: 'Must be after start date'}">
            </lib-datepicker>
          </div>
          <button
            *ngIf="employee?.timesheetIntegrationSource"
            (click)="toggleEnabled('leavingDate')"
            mat-icon-button
            type="button"
            class="helper-button">
            <mat-icon>{{employeeForm.controls.leavingDate.enabled ? 'lock_open' : 'lock'}}</mat-icon>
          </button>
        </div>

        <div *ngIf="create" class="input-row-with-info-btn">
          <div class="input-field right"></div>
        </div>
      </div>

      <lib-integration-source *ngIf="employee"
                              [timesheetIntegrationSource]="employee.timesheetIntegrationSource"
                              target="employee">
      </lib-integration-source>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <lib-button-panel>
        <button
          (click)="cancelled()"
          mat-stroked-button
          color="primary"
          type="button">
          Cancel
        </button>

        <button
          [disabled]="employeeForm.invalid || isLoading"
          color="primary"
          mat-flat-button>
          Save
        </button>
      </lib-button-panel>
    </form>
  </div>
</lib-spinner-container>

import { Component, OnInit, Input, Inject } from '@angular/core';
import { AuthService } from 'common-ui/services';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {

  imageSource: string;
  title: string;
  @Input() adminConsoleMode: boolean;

  constructor(
    @Inject('env') private environment: any,
    public authService: AuthService,
    private router: Router
  ) {
    this.imageSource = this.environment.locations.images;
  }

  ngOnInit(): void {
    this.title = this.adminConsoleMode ? 'Flexearn (Console)' : 'FlexEarn';
  }

  async tryAgain() {
    await this.router.navigateByUrl('/');
  }

}

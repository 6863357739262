/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmailTemplateType = 'successful-withdrawal' | 'failed-withdrawal' | 'employee-invite' | 'employee-reinvite' | 'employee-feedback' | 'admin-account-invite' | 'account-request' | 'password-reset' | 'wellbeing-campaign' | 'verify-your-email' | 'otp' | 'invoice-period-end' | 'amount-available-threshold' | 'cash-balance-alert' | 'general-message' | 'employee-efficient-use';

export const EmailTemplateType = {
    SUCCESSFUL_WITHDRAWAL: 'successful-withdrawal' as EmailTemplateType,
    FAILED_WITHDRAWAL: 'failed-withdrawal' as EmailTemplateType,
    EMPLOYEE_INVITE: 'employee-invite' as EmailTemplateType,
    EMPLOYEE_REINVITE: 'employee-reinvite' as EmailTemplateType,
    EMPLOYEE_FEEDBACK: 'employee-feedback' as EmailTemplateType,
    ADMIN_ACCOUNT_INVITE: 'admin-account-invite' as EmailTemplateType,
    ACCOUNT_REQUEST: 'account-request' as EmailTemplateType,
    PASSWORD_RESET: 'password-reset' as EmailTemplateType,
    WELLBEING_CAMPAIGN: 'wellbeing-campaign' as EmailTemplateType,
    VERIFY_YOUR_EMAIL: 'verify-your-email' as EmailTemplateType,
    OTP: 'otp' as EmailTemplateType,
    INVOICE_PERIOD_END: 'invoice-period-end' as EmailTemplateType,
    AMOUNT_AVAILABLE_THRESHOLD: 'amount-available-threshold' as EmailTemplateType,
    CASH_BALANCE_ALERT: 'cash-balance-alert' as EmailTemplateType,
    GENERAL_MESSAGE: 'general-message' as EmailTemplateType,
    EMPLOYEE_EFFICIENT_USE: 'employee-efficient-use' as EmailTemplateType
};


import { CsvFieldValidator } from './csv-field-validator';
import { Currency } from 'common-ui/open-api';

export class CsvCurrencyValidator implements CsvFieldValidator {
  currencies = Object.values(Currency).map(currency => currency.toString());

  isValid(value: string): boolean {
    return this.currencies.includes(value.toUpperCase());
  }

  map(value: string): string {
    return value?.toUpperCase() ?? value;
  }
}

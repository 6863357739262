<div class="form-container">
  <lib-dialog-container
    header=""
    subHeader="Please complete the form to start your referal.">

    <lib-spinner-container [isWorking]="isLoading">

      <form (ngSubmit)="onSubmit()" [formGroup]="form">

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Company Name</mat-label>
          <input formControlName="companyName"
                 matInput
                 appFirstFocus
                 placeholder="Name of company being referred"
                 required>

          <mat-error *ngIf=" hasError(form.controls.companyName, 'required')">
            Company Name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Contact Name</mat-label>
          <input formControlName="contactName"
                 matInput
                 placeholder="Name of contact at company"
                 required>

          <mat-error *ngIf=" hasError(form.controls.contactName, 'required')">
            Contact Name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Contact Email</mat-label>
          <input formControlName="contactEmail"
                 matInput
                 placeholder="Email of Contact"
                 required>

          <mat-error *ngIf=" hasError(form.controls.contactEmail, 'required')">
            Contact Email is required
          </mat-error>

          <mat-error *ngIf=" hasError(form.controls.contactEmail, 'invalidEmail')">
            Invalid email
          </mat-error>

        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Contact Phone</mat-label>
          <input formControlName="contactPhone"
                 matInput
                 placeholder="Contact Phone Number">

          <mat-error *ngIf=" hasError(form.controls.contactPhone, 'required')">
            Contact Phone is required
          </mat-error>

        </mat-form-field>

        <mat-form-field appearance="outline" class="input-field">
          <mat-label>Contact Job Title</mat-label>
          <input formControlName="contactJobTitle"
                 matInput
                 placeholder="Contact Job Title">

          <mat-error *ngIf=" hasError(form.controls.contactJobTitle, 'required')">
            Contact Job Title is required
          </mat-error>
        </mat-form-field>

        <lib-error-message [message]="errorMessage"></lib-error-message>

        <lib-button-panel>
          <button
            class="email-me-button"
            color="primary"
            mat-flat-button
            [disabled]="!form.valid || !form.dirty || isLoading"
            type="submit">
            Send Referral
          </button>
        </lib-button-panel>

      </form>

    </lib-spinner-container>
  </lib-dialog-container>
</div>

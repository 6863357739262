<div class="request-account-container">

  <header>
    <div class="logo">
      <a href="https://www.flexearn.com">
        <img draggable="false" src="{{ imageSource }}/logo_whitetext.svg">
      </a>
    </div>

    <nav>
      <ul>
        <li><a>FlexEarn Demo</a></li>
        <li><a href="https://www.flexearn.com/employees">Employees</a></li>
        <li><a routerLink="/login">Login</a></li>
        <li><a href="https://www.flexearn.com/hub">Hub</a></li>
        <li><a href="https://www.flexearn.com/partners">Partners</a></li>
      </ul>
    </nav>
  </header>

  <div class="form-wrapper">
    <div class="request-account-area">
      <lib-request-account (demoRequested)="demoRequested()"></lib-request-account>
    </div>
  </div>
</div>

<div class="wrapper mat-typography">
  <mat-toolbar class="toolbar" color="primary">
    <span>{{ navBarTitle }}</span>
  </mat-toolbar>

  <div class="logo-container">
    <img class="logo" src="{{ imageSource }}/logo_purpletext.svg">
  </div>

  <div class="mat-typography centre-text">
    <h1>FlexEarn is down for maintenance</h1>
    <div class="sub-text">Back in a jiffy.</div>
  </div>

  <button mat-flat-button color="primary" (click)="tryNow()">Check Now</button>
</div>


import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent {
  file: File;
  @Output() fileSelected = new EventEmitter<File>();

  get fileName() {
    return this.file?.name ?? '';
  }

  onFileSelected($event: Event) {
    this.file = $event.target['files'][0];
    this.fileSelected.emit(this.file);
  }
}

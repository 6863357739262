<div [class.example-is-mobile]="mobileQuery.matches" class="main-container mat-typography">
  <mat-sidenav-container
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    class="sidenav-container noselect">
    <mat-sidenav
      #sideNav
      [mode]="mobileQuery.matches ? 'over' : 'side'">
      <div id="logo-area">
        <img draggable="false" src="https://assets.flexearn.com/portal/v1/images/logo-white.svg">
      </div>

      <mat-nav-list>
        <ul class="sideULtag">

          <li [ngClass]="{ 'menu-selected': selected === 'employees' }"
              routerLink="employees">
            <mat-icon class="list-icon">group</mat-icon>
            <a routerLink="employees"
               routerLinkActive="active"
               tourAnchor="mainNav.employees">Employees</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'timesheets' }" routerLink="timesheets">
            <mat-icon class="list-icon">receipt_long</mat-icon>
            <a routerLink="timesheets"
               routerLinkActive="active"
               tourAnchor="mainNav.timesheets">Timesheets</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'withdrawals' }"
              routerLink="withdrawals">
            <mat-icon class="list-icon">money</mat-icon>
            <a routerLink="withdrawals"
               routerLinkActive="active"
               tourAnchor="mainNav.withdrawals">Withdrawals</a>
          </li>

          <li *ngIf="showDiscountCardsPage"
              [ngClass]="{ 'menu-selected': selected === 'discount-cards' }"
              routerLink="discount-cards">
            <mat-icon class="list-icon">money</mat-icon>
            <a routerLink="discount-cards"
               routerLinkActive="active"
               tourAnchor="mainNav.discount-cards">Discount Cards</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'payrolls' }"
              routerLink="payrolls">
            <mat-icon class="list-icon">payments</mat-icon>
            <a routerLink="payrolls"
               routerLinkActive="active"
               tourAnchor="mainNav.payrolls">Payrolls</a>
          </li>

          <li *ngIf="showPayCodesPage"
              [ngClass]="{ 'menu-selected': selected === 'pay-codes' }"
              routerLink="pay-codes">
            <mat-icon class="list-icon">payments</mat-icon>
            <a routerLink="pay-codes"
               routerLinkActive="active"
               tourAnchor="mainNav.pay-codes">Pay Codes</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'companies' }"
              routerLink="companies">
            <mat-icon class="list-icon">business</mat-icon>
            <a routerLink="companies"
               routerLinkActive="active"
               tourAnchor="mainNav.companies">Companies</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'history' }"
              routerLink="history">
            <mat-icon class="list-icon">history</mat-icon>
            <a routerLink="history"
               routerLinkActive="active"
               tourAnchor="mainNav.history">Invoice History</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'reporting' }"
              *ngIf="isMasterAccountAdmin"
              routerLink="reporting">
            <mat-icon class="list-icon">summarize</mat-icon>
            <a routerLink="reporting"
               routerLinkActive="active"
               tourAnchor="mainNav.reporting">Reporting</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'emails' }"
              routerLink="emails">
            <mat-icon class="list-icon">email</mat-icon>
            <a routerLink="emails"
               routerLinkActive="active"
               tourAnchor="mainNav.email">Email Log</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'integration' }"
              *ngIf="isMasterAccountAdmin && showIntegrationLink"
              routerLink="integration">
            <mat-icon class="list-icon">cloud_download</mat-icon>
            <a routerLink="emails"
               routerLinkActive="active">Integration</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'csv import' }"
              routerLink="import">
            <mat-icon class="list-icon">publish</mat-icon>
            <a routerLink="import"
               routerLinkActive="active"
               tourAnchor="mainNav.import">CSV Import</a>
          </li>

          <li [ngClass]="{ 'menu-selected': selected === 'account' }"
              *ngIf="isMasterAccountAdmin"
              routerLink="account">
            <mat-icon class="list-icon">manage_accounts</mat-icon>
            <a routerLink="account"
               routerLinkActive="active"
               tourAnchor="mainNav.account">Account</a>
          </li>
        </ul>
      </mat-nav-list>

      <div id="usefulLinks">
        <div class="list-data">
          <a routerLink="/employee-portal"
             *ngIf="showEmployeePortalLink"
             class="dialogAnchor">Employee Portal
          </a>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar class="toolbar" color="primary">
        <button (click)="toolbarAction()" mat-icon-button>
          <img draggable="false" src="{{ toolBarIconImgSrc }}">
        </button>
        <h1 class="app-name">{{ toolbarData.title }}</h1>
        <span class="toolbar-spacer"></span>

        <button
          mat-button
          class="referrals-button"
          (click)="gotoReferralsPage()">
          <mat-icon>favorite_outline</mat-icon>
          Refer & get £200
        </button>

        <button
          mat-icon-button
          data-e2e="contact-support-button"
          class="contact-support-button"
          (click)="openContactSupportDialog()">
          <mat-icon>help_outlined</mat-icon>
        </button>

        <lib-account-settings-menu></lib-account-settings-menu>

      </mat-toolbar>
      <router-outlet #router_outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>


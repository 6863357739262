<lib-spinner-container [isWorking]="isLoading">

  <div class="form-container">

    <form (ngSubmit)="onSubmit()" [formGroup]="form">
      <lib-dialog-header *ngIf="!showButtonPanel">
        Your Company
      </lib-dialog-header>

      <div class="input-row">
        <mat-form-field class="input-field">
          <mat-label>Company Name</mat-label>
          <input formControlName="name"
                 id="companyName"
                 matInput
                 appFirstFocus
                 placeholder="Company name"
                 required>

          <mat-error *ngIf="form.controls.name.invalid
                       && form.controls.name.dirty
                       && form.controls.name.touched
                       && form.controls.name.errors.required">
            Company name is required
          </mat-error>

          <mat-error
            *ngIf="form.controls.name.invalid && form.controls.name.dirty
               && form.controls.name.touched && form.controls.name.errors.minlength">
            Minimum length is 3
          </mat-error>

        </mat-form-field>

        <button
          *ngIf="hasSource()"
          mat-icon-button
          type="button"
          class="helper-button"
          (click)="toggleCompanyNameControl($event)">
          <mat-icon>{{form.controls.name.enabled ? 'lock_open' : 'lock'}}</mat-icon>
        </button>
      </div>

      <div class="input-row">
        <div class="legal-entity-row">
          <mat-checkbox class="input-field"
                        formControlName="isLegalEntityNameDifferent">
            Legal Entity Name is different
          </mat-checkbox>

          <mat-form-field
            *ngIf="form.value['isLegalEntityNameDifferent']"
            class="legal-entity-name">

            <mat-label>Legal Entity Name</mat-label>
            <input formControlName="legalEntityName"
                   matInput
                   appFirstFocus
                   placeholder="Legal Entity Name"
                   required>

            <mat-error *ngIf="form.controls.legalEntityName.invalid
                       && form.controls.legalEntityName.dirty
                       && form.controls.legalEntityName.touched
                       && form.controls.legalEntityName.errors.required">
              Legal Entity Name is required
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="input-row">

        <mat-form-field class="input-field left">
          <mat-label>First line of address</mat-label>
          <input
            matInput
            placeholder="First line of address"
            formControlName="firstLine"
            [required]="!hasSource()">

          <mat-error
            *ngIf="form.controls.firstLine.invalid && form.controls.firstLine.dirty
              && form.controls.firstLine.touched && form.controls.firstLine.errors.required">
            First line of address is required
          </mat-error>
          <mat-error
            *ngIf="form.controls.firstLine.invalid && form.controls.firstLine.dirty
              && form.controls.firstLine.touched && form.controls.firstLine.errors.maxlength">
            Maximum length of first line is 50
          </mat-error>
          <mat-error
            *ngIf="form.controls.firstLine.invalid && form.controls.firstLine.dirty
               && form.controls.firstLine.touched && form.controls.firstLine.errors.minlength">
            Minimum length of first line is 2
          </mat-error>
        </mat-form-field>

        <mat-form-field class="input-field right">
          <mat-label>Second line of address</mat-label>
          <input
            matInput
            formControlName="secondLine"
            placeholder="Second line of address">

          <mat-error
            *ngIf="form.controls.secondLine.invalid && form.controls.secondLine.dirty
              && form.controls.secondLine.touched && form.controls.secondLine.errors.maxlength">
            Maximum length is 50
          </mat-error>
          <mat-error
            *ngIf="form.controls.secondLine.invalid && form.controls.secondLine.dirty
               && form.controls.secondLine.touched && form.controls.secondLine.errors.minlength">
            Minimum length is 2
          </mat-error>
        </mat-form-field>
      </div>

      <div class="input-row">

        <mat-form-field class="input-field left">
          <mat-label>Town</mat-label>
          <input
            formControlName="town"
            matInput
            placeholder="town"
            [required]="!hasSource()">

          <mat-error
            *ngIf="form.controls.town.invalid && form.controls.town.dirty
              && form.controls.town.touched && form.controls.town.errors.required">
            Town is required
          </mat-error>
          <mat-error
            *ngIf="form.controls.town.invalid && form.controls.town.dirty
              && form.controls.town.touched && form.controls.town.errors.maxlength">
            Maximum length is 50
          </mat-error>
          <mat-error
            *ngIf="form.controls.town.invalid && form.controls.town.dirty
               && form.controls.town.touched && form.controls.town.errors.minlength">
            Minimum length is 2
          </mat-error>
        </mat-form-field>

        <mat-form-field class="input-field right">
          <mat-label>County</mat-label>
          <input
            formControlName="county"
            matInput
            placeholder="County"
            [required]="!hasSource()">

          <mat-error
            *ngIf="form.controls.county.invalid && form.controls.county.dirty
              && form.controls.county.touched && form.controls.county.errors.required">
            County is required
          </mat-error>
          <mat-error
            *ngIf="form.controls.county.invalid && form.controls.county.dirty
              && form.controls.county.touched && form.controls.county.errors.maxlength">
            Maximum length is 50
          </mat-error>
          <mat-error
            *ngIf="form.controls.county.invalid && form.controls.county.dirty
               && form.controls.county.touched && form.controls.county.errors.minlength">
            Minimum length is 2
          </mat-error>
        </mat-form-field>

      </div>

      <div class="input-row">

        <mat-form-field class="input-field left">
          <mat-label>Country</mat-label>
          <input
            formControlName="country"
            matInput
            placeholder="Country"
            [required]="!hasSource()">

          <mat-error
            *ngIf="form.controls.country.invalid && form.controls.country.dirty
              && form.controls.country.touched && form.controls.country.errors.required">
            First line of address is required
          </mat-error>
          <mat-error
            *ngIf="form.controls.country.invalid && form.controls.country.dirty
              && form.controls.country.touched && form.controls.country.errors.maxlength">
            Maximum length is 50
          </mat-error>
          <mat-error
            *ngIf="form.controls.country.invalid && form.controls.country.dirty
               && form.controls.country.touched && form.controls.country.errors.minlength">
            Minimum length is 2
          </mat-error>
        </mat-form-field>

        <mat-form-field class="input-field right">
          <mat-label>Postcode</mat-label>
          <input matInput
                 oninput="this.value = this.value.toUpperCase()"
                 [required]="!hasSource()"
                 formControlName="postcode"
                 placeholder="Postcode">

          <mat-error *ngIf="form.controls.postcode.invalid && form.controls.postcode.dirty
            && form.controls.postcode.touched && form.controls.postcode.errors.required">
            Postcode required
          </mat-error>

          <mat-error
            *ngIf="form.controls.postcode.invalid && form.controls.postcode.dirty
              && form.controls.postcode.touched && form.controls.postcode.errors.invalidPostcode && !form.controls.postcode.errors.required">
            Sorry, this is not a valid postcode
          </mat-error>

        </mat-form-field>
      </div>

      <div *ngIf="isConsole" class="input-row">
        <mat-form-field class="mandate-id">
          <mat-label>Mandate Id</mat-label>
          <input formControlName="mandateId"
                 matInput
                 placeholder="MandateId">
        </mat-form-field>
      </div>

      <div class="input-field">
        <lib-integration-source [timesheetIntegrationSource]="timesheetIntegrationSource"
                                target="company">
        </lib-integration-source>
        <lib-error-message [message]="errorMessage"></lib-error-message>
      </div>

      <lib-button-panel *ngIf="showButtonPanel">

        <button (click)="close()"
                mat-stroked-button
                color="primary"
                type="button">
          Cancel
        </button>

        <button
          color="primary"
          mat-flat-button
          id="submit"
          [disabled]="!form.valid || !form.dirty || isLoading"
          type="submit">
          Save
        </button>

      </lib-button-panel>

    </form>
  </div>
</lib-spinner-container>

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { CompanyDto, CreateCompanyDto, UpdateCompanyDto } from 'common-ui/open-api';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private apiService: ApiService
  ) {
  }

  async getCompaniesForUser(): Promise<CompanyDto[]> {
    return await this.apiService.get('/api/company');
  }

  async getCompaniesForMasterAccounts(masterAccountIds: string[]): Promise<CompanyDto[]> {
    const companiesForUser = await this.getCompaniesForUser();
    return masterAccountIds.length === 0 ? companiesForUser : companiesForUser.filter(c => masterAccountIds.includes(c.masterAccountId));
  }

  async getCompaniesForMasterAccount(masterAccountId: string): Promise<CompanyDto[]> {
    const companiesForUser = await this.getCompaniesForUser();
    return companiesForUser.filter(c => c.masterAccountId === masterAccountId);
  }

  async getCompany(companyId: string): Promise<CompanyDto> {
    return await this.apiService.get(`/api/company/${companyId}`);
  }

  async contractSigned(companyId: string) {
    await this.apiService.post(`/api/company/${companyId}/contract-signed`, {});
  }

  async contractRevoked(companyId: string) {
    await this.apiService.post(`/api/company/${companyId}/contract-revoked`, {});
  }

  async updateCompany(companyId: string, companyUpdateDto: UpdateCompanyDto) {
    await this.apiService.patch('/api/company/' + companyId,
      companyUpdateDto
    );
  }

  async createCompany(createDto: CreateCompanyDto) {
    const response = await this.apiService.post('/api/company/', createDto);
    return response.id;
  }

  async blockCompany(companyId: string) {
    await this.apiService.patch(`/api/company/${companyId}/block`, {});
  }

  async unblockCompany(companyId: string) {
    await this.apiService.patch(`/api/company/${companyId}/unblock`, {});
  }

  async deleteCompany(companyId: string) {
    await this.apiService.delete(`/api/company/${companyId}`);
  }
}

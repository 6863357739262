<lib-spinner-container [isWorking]="isWorking">
  <lib-dialog-container [header]="data.isCreate ? 'Create Pay Code' :'Update Pay Code'">

    <form [formGroup]="form"
          class="pay-code-form"
          (ngSubmit)="save()">

      <lib-master-account-input
        *ngIf="isConsole"
        formControlName="masterAccountId">
      </lib-master-account-input>

      <lib-company-select-input
        [masterAccountId]="masterAccountId"
        formControlName="companyId">
      </lib-company-select-input>

      <mat-form-field>
        <mat-label>Pay Code</mat-label>
        <input matInput
               placeholder="Pay Code"
               formControlName="payCode" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Rate</mat-label>
        <input matInput
               mask="separator.2"
               thousandSeparator=","
               prefix="£"
               placeholder="Rate"
               formControlName="rate" />
      </mat-form-field>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <lib-button-panel>
        <button
          (click)="close()"
          mat-stroked-button
          color="primary"
          type="button">
          Cancel
        </button>

        <button
          [disabled]="form.invalid || isWorking"
          color="primary"
          mat-flat-button>
          {{ data.isCreate ? 'Create' : 'Update' }}
        </button>
      </lib-button-panel>
    </form>

  </lib-dialog-container>

</lib-spinner-container>
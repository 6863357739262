<div class="impersonate-user-container">
  <lib-spinner-container [isWorking]="loading">

    <div class="center-dialog-container">

      <lib-dialog-header subHeader="Select user to impersonate.">
        Account Impersonator
      </lib-dialog-header>

      <form [formGroup]="form" (ngSubmit)="selectUserAndGo()">
        <div class="content-container">
          <mat-form-field *ngIf="!loading">
            <mat-label>User</mat-label>
            <mat-autocomplete
              #autocomplete="matAutocomplete"
              [displayWith]="displayUser">
              <mat-option
                *ngFor="let email of this.filteredEmails"
                [value]="email">
                {{email.email}}
              </mat-option>
            </mat-autocomplete>

            <input
              type="text"
              placeholder="User"
              matInput
              formControlName="email"
              [matAutocomplete]="autocomplete">

            <button mat-button
                    type="button"
                    *ngIf="form.controls.email.value"
                    matSuffix mat-icon-button
                    aria-label="Clear"
                    (click)="clearUser()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <lib-button-panel>
            <button
              mat-flat-button
              color="primary"
              type="submit"
              [disabled]="form.invalid">
              Sign In
            </button>
          </lib-button-panel>
        </div>
      </form>
    </div>
  </lib-spinner-container>
</div>

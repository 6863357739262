<lib-page-container>

  <lib-filter-bar [textSearchType]="FilterType.PAY_CODE_SEARCH"
                  [isWorking]="(dataSource.loading$ | async) ?? false"
                  header>

    <div control-options>
      <button mat-button (click)="openCreateDialog()">Create</button>
    </div>

    <lib-company-selector>
    </lib-company-selector>

    <lib-master-account-selector *ngIf="isConsole">
    </lib-master-account-selector>

  </lib-filter-bar>

  <table [dataSource]="dataSource"
         mat-table
         matSort
         matSortActive="payCode"
         matSortDirection="asc">

    <ng-container matColumnDef="payCode">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Pay Code</th>
      <td *matCellDef="let element" mat-cell>{{ element.payCode }}</td>
    </ng-container>

    <ng-container matColumnDef="masterAccountName">
      <th *matHeaderCellDef mat-header-cell>Master Account</th>
      <td *matCellDef="let element" mat-cell>{{ element.masterAccountName }}</td>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <th *matHeaderCellDef mat-header-cell>Company</th>
      <td *matCellDef="let element" mat-cell>{{ element.companyName }}</td>
    </ng-container>

    <ng-container matColumnDef="rate">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Rate</th>
      <td *matCellDef="let element" mat-cell>{{ element.rate | pennyToPound: element.currency }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell>Actions</th>
      <td *matCellDef="let element" mat-cell>
        <button [matMenuTriggerFor]="menu" mat-icon-button>
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu" xPosition="before">
          <button (click)="openEditDialog(element)"
                  mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item
                  (click)="openDeleteDialog(element)">
            <mat-icon>delete</mat-icon>
            <span>Delete</span>
          </button>

          <button
            mat-menu-item
            (click)="openJsonDialog(element)"
            *ngIf="isConsole">
            <mat-icon>description</mat-icon>
            <span>View JSON</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;"
        class="clickable"
        mat-row>
    </tr>

    <tr *matNoDataRow class="mat-row">
      <td class="mat-cell" colspan="5">{{
        (this.dataSource.loading$ | async) ? "Loading..." :
          (this.dataSource.queryToLarge$ | async) ? "Too many pay codes - please narrow your search" : "No data" }}</td>
    </tr>
  </table>

  <div footer>
    <mat-paginator
      [length]="dataSource.total$ | async"
      [pageSize]="pageSize"
      [showFirstLastButtons]=true></mat-paginator>
  </div>

</lib-page-container>

<lib-page-container>

  <lib-filter-bar [textSearchType]="FilterType.EMPLOYEE_SEARCH" header>

    <div control-options class="filter-bar-controls">
      <button mat-button (click)="switchToListView()">List View</button>
      <span class="spacer"></span>
      <lib-week-selector
        [dateRange]="initialDateRange"
        [isLoading]="dataSource.loading$ | async"
        (emitDates)="setDates($event)"
        #weekSelector>
      </lib-week-selector>
      <span class="spacer"></span>
    </div>

    <lib-company-selector>
    </lib-company-selector>

    <lib-pay-frequency-selector>
    </lib-pay-frequency-selector>

    <lib-employment-status-selector>
    </lib-employment-status-selector>

    <lib-master-account-selector *ngIf="showMasterAccountFilter">
    </lib-master-account-selector>
  </lib-filter-bar>

  <div class="timesheets-table">

    <table mat-table
           matSort
           [dataSource]="dataSource">
      <ng-container matColumnDef="masterAccountName">
        <th
          *matHeaderCellDef
          mat-header-cell
          class="table-regular-column">
          Master Account
        </th>

        <td
          *matCellDef="let element"
          mat-cell
          class="table-regular-column">
          {{ element.masterAccountName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th
          *matHeaderCellDef
          mat-header-cell
          class="table-regular-column">
          Company
        </th>

        <td
          *matCellDef="let element"
          mat-cell
          class="table-regular-column">
          {{ element.companyName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          class="table-regular-column">
          First Name
        </th>

        <td
          *matCellDef="let element"
          mat-cell
          class="table-regular-column">
          {{ element.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
          class="table-regular-column">
          Last Name
        </th>

        <td
          *matCellDef="let element"
          mat-cell
          class="table-regular-column">
          {{ element.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="payFrequency">
        <th
          *matHeaderCellDef
          mat-header-cell
          class="table-regular-column">
          Payroll
        </th>

        <td
          *matCellDef="let element"
          mat-cell
          class="table-regular-column">
          {{ element.payFrequency | payFrequency }}
        </td>
      </ng-container>

      <ng-container matColumnDef="amountAccrued">

        <th *matHeaderCellDef
            mat-header-cell
            mat-sort-header
            class="table-regular-column">
          Accrued
        </th>

        <td *matCellDef="let element"
            mat-cell
            class="table-regular-column">
          {{ element.amountAccrued | pennyToPound: element.currency }}
        </td>

      </ng-container>

      <ng-container *ngFor="let key of dayKeys" matColumnDef="{{key}}">
        <th *matHeaderCellDef mat-header-cell class="timesheet-input-header">{{getKeyHeaderString(key)}}</th>
        <td
          *matCellDef="let element"
          mat-cell class="timesheet-input-cell"
          [ngClass]="{
              'invalid-date': isDisabled(element, key),
              'is-accrual-start': isAccrualStart(element, key),
              'is-accrual-end': isAccrualEnd(element, key)
            }">
          <div class="input-container"
               *ngIf="!element.isSourcedFromIntegration">
            <input
              [specialCharacters]="['£', '.']"
              mask="£0*.00"
              [dropSpecialCharacters]="false"
              [disabled]="isDisabled(element, key)"
              class="timesheet-cell-input-value"
              id="{{buildCellId(element.employeeId, key)}}"
              (keydown)="changeValue($event)"
              (blur)="updateList(element.employeeId, key)"
              (focus)="focusCell(element.employeeId, key)"
              [(ngModel)]="element['approvedWages'][key]" />
          </div>
          <div *ngIf="element.isSourcedFromIntegration">
            <div class="input-container">
              {{ element['approvedWages'][key] }}
            </div>
            <div class="unapproved-wages"
                 *ngIf="!!element['unapprovedWages'][key]">
              Unapproved {{ element['unapprovedWages'][key] }}
            </div>
            <div class="hours-with-no-rate"
                 *ngIf="element['hoursWithNoRate'][key] > 0">
              No rate {{ element['hoursWithNoRate'][key] }} hrs
            </div>
          </div>
          <div class="hours-with-no-rate"
               *ngIf="element['cancelledHours'][key] > 0">
            Cancelled {{ element['cancelledHours'][key] }} hrs
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell"
            [attr.colspan]="displayedColumns.length">{{ (this.dataSource.loading$ | async) ? "Loading..." : "No timesheet employees." }}</td>
      </tr>
    </table>
  </div>

  <div footer>
    <mat-paginator [pageSize]="pageSize"
                   [length]="dataSource.total$ | async"
                   [showFirstLastButtons]=true>
    </mat-paginator>
  </div>

</lib-page-container>

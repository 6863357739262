import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface MessageDialogData {
  header: string;
  subHeader: string;
}

@Component({
  selector: 'lib-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
    private dialogRef: MatDialogRef<MessageDialogComponent>
  ) {
  }

  close() {
    this.dialogRef.close();
  }
}

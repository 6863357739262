import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'common-ui/services/auth.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { PasswordResetType, TokenStatus, UserDto } from 'common-ui/open-api';
import { getAuthErrorDescription } from 'common-ui/util/get-auth-error-description';
import { CustomValidators } from 'common-ui/util/custom-validators';
import { UserService } from 'common-ui/services/user.service';
import { emailValidator } from 'common-ui/util';

@Component({
  selector: 'lib-login-password-reset',
  templateUrl: './login-password-reset.component.html',
  styleUrls: ['./login-password-reset.component.css']
})
export class LoginPasswordResetComponent implements OnInit {

  isWorking = false;
  isLoading = true;
  passwordForm: FormGroup;
  resetForm: FormGroup;
  token: string;
  resetComplete = false;
  errorMessage: string;
  resetLinkSent: boolean;
  tokenStatus: TokenStatus;
  TokenStatus = TokenStatus;
  user: UserDto;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    @Inject('env') private environment
  ) {
    this.token = route.snapshot.queryParamMap.get('token');

    this.passwordForm = fb.group({
      password: ['', CustomValidators.passwordValidator],
      confirmPassword: ['', Validators.required]
    }, {
      validators: CustomValidators.passwordMatchValidator
    });

    this.passwordForm.valueChanges.subscribe(() => this.errorMessage = '');

    this.resetForm = fb.group({
      email: ['', [emailValidator, Validators.required]]
    });

    this.resetForm.valueChanges.subscribe(() => this.errorMessage = '');
  }

  async ngOnInit() {
    if (this.token) {
      try {
        const checkTokenResultDto = await this.auth.checkToken(this.token);
        this.tokenStatus = checkTokenResultDto.status;
        this.resetForm.setValue({email: checkTokenResultDto.email});
      } catch (err) {
        this.errorMessage = err.message;
      }
    }
    this.isLoading = false;
  }

  async setPassword() {
    if (!this.passwordForm.valid) {
      return;
    }

    try {
      this.isWorking = true;
      await this.auth.checkTokenAndSignIn(this.token, this.passwordForm.controls.password.value);
      this.user = await this.userService.getCurrentUser();
      this.resetComplete = true;
    } catch (err) {
      if (err.message) {
        this.errorMessage = getAuthErrorDescription(err.message);
      } else {
        this.errorMessage = 'Password reset failed';
      }
    }
    this.isWorking = false;
  }

  async enterPortal() {
    this.isWorking = true;
    await this.router.navigateByUrl('/employees');
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  async requestNewPasswordResetLink() {
    try {
      this.isWorking = true;
      await this.auth.sendPasswordReset(this.resetForm.controls.email.value, PasswordResetType.RESET);
      this.resetLinkSent = true;
    } catch (err) {
      if (err.message) {
        this.errorMessage = getAuthErrorDescription(err.message);
      } else {
        this.errorMessage = 'Password reset failed';
      }
    }
    this.isWorking = false;
  }

}

import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { CompanyDto, CompanyOpenApiService } from 'common-ui/open-api';
import { FormControl } from '@angular/forms';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent
} from '@angular/material/legacy-autocomplete';

@Component({
  selector: 'lib-company-typeahead-selector',
  templateUrl: './company-typeahead-selector.component.html',
  styleUrls: ['./company-typeahead-selector.component.css']
})
export class CompanyTypeaheadSelectorComponent implements OnInit, OnDestroy {

  @Output() companySelected = new EventEmitter<CompanyDto>;
  @Input() masterAccountId: string;

  isLoading = true;
  filteredCompanies: CompanyDto[];
  companyControl = new FormControl();
  companies: CompanyDto[];
  ngUnsubscribe = new Subject<void>();

  displayWith(company: CompanyDto) {
    return company ? company.name : '';
  }

  constructor(
    private companyService: CompanyOpenApiService
  ) {
  }

  selectCompany(event: MatAutocompleteSelectedEvent) {
    localStorage.setItem('company-typeahead-selected-id', event.option.value._id);
    this.companySelected.emit(event.option.value);
  }

  async ngOnInit() {
    const selectedId = localStorage.getItem('company-typeahead-selected-id');
    if (selectedId) {
      const selectedCompany = await firstValueFrom(this.companyService.getCompany(selectedId));
      if (selectedCompany) {
        this.companyControl.setValue(selectedCompany);
        this.companySelected.emit(selectedCompany);
      } else {
        localStorage.setItem('master-account-typeahead-selected-id', null);
      }
    }
    this.companyControl.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(700)
    ).subscribe(async filter => {
      if (typeof filter === 'string') {
        this.companySelected.emit(null);
        this.filteredCompanies = (await firstValueFrom(this.companyService.queryCompanies({
          name: filter,
          masterAccountIds: this.masterAccountId ? [this.masterAccountId] : null,
          limit: 10
        }))).data;
      }
    });
    this.isLoading = false;
  }

  async ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  clear() {
    this.companyControl.setValue(undefined);
    this.companySelected.emit(null);
  }

}

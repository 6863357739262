import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  constructor(
    @Inject(DOCUMENT) private document: any,
  ) {
  }

  private getCookie(name: string): string[] {
    const nameLenPlus = name.length + 1;
    return this.document.cookie
      .split(';')
      .map(c => c.trim())
      .filter(cookie => cookie.substring(0, nameLenPlus) === `${name}=`)
      .map(cookie => decodeURIComponent(cookie.substring(nameLenPlus)));
  }

  get(name: string): string {
    if (!this.document) {
      return null;
    }
    const value = '; ' + this.document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts
        .pop()
        .split(';')
        .shift();
    }
    return null;
  }

  set(
    name: string,
    value: string,
    expires?: Date,
    path?: string,
    sameSite: 'Strict' | 'Lax' | 'None' = 'Strict'
  ): void {
    if (!this.document) {
      return;
    }
    let cookieValue = `${name}=${value}${path ? `;path=${path}` : ''};SameSite=${sameSite}`;
    // Set the top domain on the cookie if in prod or staging
    if (this.document.location.host.includes('flexearn.com')) {
      cookieValue += ';domain=.flexearn.com';
    }
    if (expires) {
      cookieValue += `;expires='${expires.toUTCString()}';path=/`;
    }
    document.cookie = cookieValue;
  }

  remove(name: string): void {
    if (!this.document) {
      return;
    }
    let cookieValue = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
    if (this.document.location.host.includes('flexearn.co.uk')) {
      cookieValue += ';domain=.flexearn.com';
    }
    document.cookie = cookieValue;
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileDto } from 'common-ui/open-api';
import { Subject } from 'rxjs';
import { CustomerService } from 'common-ui/services/customer.service';

@Component({
  selector: 'lib-employee-portal-amount-available-breakdown',
  templateUrl: './employee-portal-amount-available-breakdown.component.html',
  styleUrls: ['./employee-portal-amount-available-breakdown.component.css']
})
export class EmployeePortalAmountAvailableBreakdownComponent implements OnInit, OnDestroy {
  profiles: ProfileDto[];
  isWorking = false;
  ngUnsubscribe$ = new Subject();
  employeeId: string;

  constructor(
    private customerService: CustomerService
  ) {
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  async ngOnInit() {
    this.profiles = await this.customerService.getProfiles();
    this.employeeId = this.profiles[0].employeeId;
  }
}

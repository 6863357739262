/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ApiSource = 'Kelio' | 'Cool Care' | 'Care-Planner' | 'Deputy' | 'employment-hero' | 'Planday' | 'People HR' | 'RotaCloud' | '7-Shifts' | 'Xero' | 'SFTP' | 'GCP Bucket' | 'Local File' | 'Fake' | 'Fake-Mce' | 'None';

export const ApiSource = {
    KELIO: 'Kelio' as ApiSource,
    COOL_CARE: 'Cool Care' as ApiSource,
    CARE_PLANNER: 'Care-Planner' as ApiSource,
    DEPUTY: 'Deputy' as ApiSource,
    EMPLOYMENT_HERO: 'employment-hero' as ApiSource,
    PLANDAY: 'Planday' as ApiSource,
    PEOPLE_HR: 'People HR' as ApiSource,
    ROTA_CLOUD: 'RotaCloud' as ApiSource,
    _7_SHIFTS: '7-Shifts' as ApiSource,
    XERO: 'Xero' as ApiSource,
    SFTP: 'SFTP' as ApiSource,
    GCP_BUCKET: 'GCP Bucket' as ApiSource,
    LOCAL_FILE: 'Local File' as ApiSource,
    FAKE: 'Fake' as ApiSource,
    FAKE_MCE: 'Fake-Mce' as ApiSource,
    NONE: 'None' as ApiSource
};


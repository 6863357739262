import { WeekDay } from 'common-ui/open-api';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

export function getNextWeekDay(weekDay: WeekDay, currentDate: Date) {
  let next = addDays(currentDate, 1);
  while (format(next, 'EEEE') !== weekDay) {
    next = addDays(next, 1);
  }
  return next;
}

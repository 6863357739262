import { Component, OnInit, Inject } from '@angular/core';
import { UserDto, AdminUserRole, CompanyDto } from 'common-ui/open-api';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService } from 'common-ui/services/user.service';
import { CompanyService } from 'common-ui/services';
import { Validators, UntypedFormControl, ValidationErrors, FormGroup, FormBuilder } from '@angular/forms';
import { emailValidator } from 'common-ui/util';

export interface UserEditDialogData {
  create: boolean;
  masterAccountId?: string;
  user?: UserDto;
}

@Component({
  selector: 'lib-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.css']
})
export class UserEditDialogComponent implements OnInit {

  companies: CompanyDto[];
  isWorking: boolean;
  errorMessage: string;
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserEditDialogData,
    private dialogRef: MatDialogRef<UserEditDialogComponent>,
    private userService: UserService,
    private companyService: CompanyService,
    private formBuilder: FormBuilder
  ) {
  }

  atLeastOneCompanySelected(control: FormGroup): ValidationErrors | null {
    const isMasterAccountAdmin = control.get('isMasterAccountAdmin').value;

    if (isMasterAccountAdmin) {
      return null;
    }

    if (!this.companies) {
      return null;
    }

    const selectedCompanies = this.companies.reduce((count, next) => {
      return control.controls[next._id].value ? count + 1 : count;
    }, 0);

    return selectedCompanies === 0 ? {
      selectAtLeastOneCompany: true
    } : null;
  }

  async ngOnInit() {

    this.form = this.formBuilder.group({
      email: ['', [emailValidator, Validators.required]],
      isMasterAccountAdmin: false,
      sendInvite: false
    }, {
      validators: this.atLeastOneCompanySelected.bind(this)
    });

    let masterAccountId = this.data.create ? this.data.masterAccountId : this.data.user.masterAccountId;
    const isMasterAccount = this.data.create ? false : this.data.user.isMasterAccount;

    if (!this.data.create) {

      masterAccountId = this.data.user.masterAccountId;
      this.form.reset({
        email: this.data.user.email,
        isMasterAccountAdmin: this.data.user.isMasterAccount,
        sendInvite: false
      });

      this.form.controls.email.disable();
    }

    const companiesForMasterAccount = await this.companyService.getCompaniesForMasterAccount(masterAccountId);
    companiesForMasterAccount.forEach(company => {
      let checked = false;
      if (!this.data.create) {
        checked = !!this.data.user.companies.find(c => c._id === company._id);
      }
      this.form.addControl(company._id, new UntypedFormControl({value: checked, disabled: isMasterAccount}));
    });

    this.form.controls.isMasterAccountAdmin.valueChanges.subscribe(isMasterAccount => {
      this.setCompanyCheckboxStates(isMasterAccount, isMasterAccount);
    });

    this.companies = companiesForMasterAccount;
  }

  private setCompanyCheckboxStates(disabled: boolean, value: boolean) {
    const patchValue: any = {};
    this.companies.forEach(company => {
      patchValue[company._id] = value;
      if (disabled) {
        this.form.controls[company._id].disable();
      } else {
        this.form.controls[company._id].enable();
      }
    });
    if (value) {
      this.form.patchValue(patchValue);
    }
  }

  async createOrUpdateUser() {
    this.isWorking = true;
    this.errorMessage = '';
    try {
      const selectedCompanyIds: string[] = [];
      this.companies.forEach(company => {
        if (this.form.controls[company._id].value) {
          selectedCompanyIds.push(company._id);
        }
      });

      if (this.data.create) {

        await this.userService.createUser({
          masterAccountId: this.data.masterAccountId,
          role: this.form.value.isMasterAccountAdmin ? AdminUserRole.MASTER_ACCOUNT_ADMIN : AdminUserRole.COMPANY_ADMIN,
          sendInvite: this.form.value.sendInvite,
          email: this.form.value.email.trim(),
          companyIds: this.form.value.isMasterAccountAdmin ? [] : selectedCompanyIds
        });

      } else {

        const targetRole = this.form.value.isMasterAccountAdmin ? AdminUserRole.MASTER_ACCOUNT_ADMIN : AdminUserRole.COMPANY_ADMIN;

        await this.userService.updateUser({
          userId: this.data.user._id,
          role: targetRole,
          companyIds: targetRole === AdminUserRole.COMPANY_ADMIN ? selectedCompanyIds : []
        });
      }

      this.dialogRef.close(true);
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }

  close() {
    this.dialogRef.close();
  }


}

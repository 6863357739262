import { Component } from '@angular/core';

@Component({
  selector: 'lib-control-bar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.css']
})
export class ControlBarComponent {
  constructor() {
  }
}

<mat-form-field class="field"
                [color]="color">

  <mat-label>Account Number</mat-label>
  <input [formControl]="accountNumberControl"
         matInput
         [patterns]="customPatterns"
         mask="00000000"
         [placeholder]="placeholder">

  <button
    mat-icon-button
    matSuffix
    type="button"
    *ngIf="clearable"
    (click)="clearInput()"
    [disabled]="accountNumberControl.enabled">
    <mat-icon>clear</mat-icon>
  </button>

  <mat-error
    *ngIf="accountNumberControl.touched && accountNumberControl.dirty && accountNumberControl.hasError('pattern')">
    Account Number is not valid
  </mat-error>

  <mat-error
    *ngIf="accountNumberControl.touched && accountNumberControl.dirty && accountNumberControl.hasError('mask')">
    Account Number is not valid
  </mat-error>

  <mat-error
    *ngIf="accountNumberControl.touched && accountNumberControl.dirty && accountNumberControl.hasError('required')">
    Account Number is required
  </mat-error>
</mat-form-field>

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmploymentStatus = 'joining' | 'current' | 'leaving' | 'left';

export const EmploymentStatus = {
    JOINING: 'joining' as EmploymentStatus,
    CURRENT: 'current' as EmploymentStatus,
    LEAVING: 'leaving' as EmploymentStatus,
    LEFT: 'left' as EmploymentStatus
};


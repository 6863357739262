import { Component, Input } from '@angular/core';
import { EmailEventType } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';
import { HyphenatedToRegularPipe } from 'common-ui/pipes/hyphenated-to-regular.pipe';

@Component({
  selector: 'lib-email-event-selector',
  templateUrl: './email-event-selector.component.html',
  styleUrls: ['./email-event-selector.component.css']
})
export class EmailEventSelectorComponent {
  @Input() label = 'Email Event'
  transformer = new HyphenatedToRegularPipe();
  events = Object.values(EmailEventType).map(value => ({
    value: value.toString(),
    description: value==='spamreport' ? 'Spam Report' : this.transformer.transform(value.toString())
  }));
  FilterType = FilterType;
}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CustomerService } from 'common-ui/services/customer.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Currency, UpdateBankDetailsDto } from 'common-ui/open-api';
import { Router } from '@angular/router';
import { validateIBAN } from 'ngx-iban-validator';

@Component({
  selector: 'lib-employee-portal-bank-details',
  templateUrl: './employee-portal-bank-details.component.html',
  styleUrls: ['./employee-portal-bank-details.component.css']
})
export class EmployeePortalBankDetailsComponent implements OnInit {

  employeeId: string;
  currency: Currency;
  isWorking = true;
  form: FormGroup;
  protected readonly Currency = Currency;

  constructor(
    private customerService: CustomerService,
    private router: Router,
    private fb: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  async ngOnInit() {
    const profiles = await this.customerService.getProfiles();

    this.employeeId = profiles[0].employeeId;
    this.currency = profiles[0].currency;

    if (this.currency === Currency.GBP) {
      this.form = this.fb.group({
        accountNumber: '',
        accountSortCode: ''
      });
    } else {
      this.form = this.fb.group({
        iban: [null, [Validators.required, validateIBAN]]
      });
    }
    this.isWorking = false;
    this.changeDetectorRef.detectChanges();
  }

  async next() {
    this.isWorking = true;
    try {
      const body: UpdateBankDetailsDto = this.currency === Currency.GBP ? {
        employeeId: this.employeeId,
        accountNumber: this.form.value.accountNumber,
        accountSortCode: this.form.value.accountSortCode
      } : {
        employeeId: this.employeeId,
        iban: this.form.value.iban
      };

      await this.customerService.updateBankDetails(body);
      await this.router.navigateByUrl('/employee-portal/withdraw');
    } catch (err) {
      console.error(err);
    }
    this.isWorking = false;
  }


}

<mat-form-field class="field"
                [color]="color">

  <mat-label>Sort Code</mat-label>
  <input [formControl]="sortCodeControl"
         matInput
         [patterns]="customPatterns"
         mask="00 00 00"
         [placeholder]="placeholder">

  <button
    matSuffix
    *ngIf="clearable"
    mat-icon-button
    type="button"
    (click)="clearInput()"
    [disabled]="sortCodeControl.enabled">
    <mat-icon>clear</mat-icon>
  </button>

  <mat-error *ngIf="sortCodeControl.touched && sortCodeControl.dirty && sortCodeControl.hasError('pattern')">
    Sort code is not valid
  </mat-error>

  <mat-error *ngIf="sortCodeControl.touched && sortCodeControl.dirty && sortCodeControl.hasError('mask')">
    Sort code is not valid
  </mat-error>

  <mat-error *ngIf="sortCodeControl.touched && sortCodeControl.dirty && sortCodeControl.hasError('required')">
    Sort code is required
  </mat-error>
</mat-form-field>

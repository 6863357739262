/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CommsLogStatus = 'processing' | 'bounce' | 'dropped' | 'deferred' | 'delivered' | 'failed';

export const CommsLogStatus = {
    PROCESSING: 'processing' as CommsLogStatus,
    BOUNCE: 'bounce' as CommsLogStatus,
    DROPPED: 'dropped' as CommsLogStatus,
    DEFERRED: 'deferred' as CommsLogStatus,
    DELIVERED: 'delivered' as CommsLogStatus,
    FAILED: 'failed' as CommsLogStatus
};


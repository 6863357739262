<div class="request-account-container">
  <div class="request-account-area">
    <img class="main-logo-image" draggable="false" src="{{ imageSource }}/logo_purpletext.svg">
    <div class="request-sent-box">
      <p>
        Thank you for your interest in Flexearn!
      </p>
      <p *ngIf="message">
        {{ message }}
      </p>
    </div>
    <lib-button-panel>
      <button color="primary" mat-stroked-button (click)="goHome()">
        Back to Home Page
      </button>
    </lib-button-panel>
  </div>
</div>

import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { AuthService } from 'common-ui/services';
import { UserDto, MasterAccountCoreDto } from 'common-ui/open-api';
import { MasterAccountService } from 'common-ui/services/master-account.service';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserService } from 'common-ui/services/user.service';
import { AboutDialogComponent } from 'common-ui/about-dialog/about-dialog.component';
import { ImpersonationService } from 'common-ui/services/impersonation.service';
import {
  NotificationPreferencesDialogComponent
} from 'common-ui/notification-preferences-dialog/notification-preferences-dialog.component';

@Component({
  selector: 'lib-account-settings-menu',
  templateUrl: './account-settings-menu.component.html',
  styleUrls: ['./account-settings-menu.component.scss']
})
export class AccountSettingsMenuComponent implements OnInit, OnDestroy {

  isOpen = false;
  @ViewChild(CdkConnectedOverlay) connectedOverlay: CdkConnectedOverlay;
  backDropClickSub: Subscription;
  user: UserDto;
  masterAccount: MasterAccountCoreDto;

  constructor(
    private authService: AuthService,
    private masterAccountService: MasterAccountService,
    private router: Router,
    private dialog: MatDialog,
    private userService: UserService,
    public impersonationService: ImpersonationService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.userService.getCurrentUser();

    if (!this.user.isSystemAdmin) {
      this.masterAccount = await this.masterAccountService.getCoreMasterAccount(this.user.masterAccountId);
    }
  }

  openPanel() {
    this.isOpen = true;

    if (!this.backDropClickSub) {
      this.backDropClickSub = this.connectedOverlay.backdropClick.subscribe(() => {
        this.isOpen = false;
      });
    }
  }

  async logOut() {
    await this.authService.logout();
    this.isOpen = false;
  }

  showAboutDialog() {
    this.dialog.open(AboutDialogComponent, {autoFocus: false});
    this.isOpen = false;
  }

  async openNotificationDialog() {
    this.dialog.open(NotificationPreferencesDialogComponent, {
      width: '500px',
      data: this.user._id
    });
    this.isOpen = false;
  }

  ngOnDestroy() {
    if (this.backDropClickSub) {
      this.backDropClickSub.unsubscribe();
    }
  }
}

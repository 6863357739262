/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmployeeUsageRating = 'normal' | 'suboptimal' | 'inefficient';

export const EmployeeUsageRating = {
    NORMAL: 'normal' as EmployeeUsageRating,
    SUBOPTIMAL: 'suboptimal' as EmployeeUsageRating,
    INEFFICIENT: 'inefficient' as EmployeeUsageRating
};


import { CsvFieldValidator } from './csv-field-validator';
import { CompanyDto } from 'common-ui/open-api';

export class CsvCompanyNameValidator implements CsvFieldValidator {

  companyMap = new Map<string, string>();

  constructor(public companyDtos: CompanyDto[]) {
    companyDtos.forEach(companyDto => {
      this.companyMap.set(companyDto.name.toLowerCase(), companyDto._id)
    })
  }

  isValid(companyName: string): boolean {
    return companyName && this.companyMap.has(companyName.toString());
  }

  map(companyName: string): string {
    return this.companyMap.get(companyName.toLowerCase());
  }

}

export const environment = {
  production: true,
  environment: 'production',
  version: '1.10.1501',
  locations: {
    images: 'https://assets.flexearn.com/portal/v1/images'
  },
  debug: false,
  sentry: 'https://5fe6374b23f74202857c22640589de07@o557189.ingest.sentry.io/5692294',
  gtmTagId: '',
  defaultPageSize: 50,
  logoutTimer: 600,
  logoutTimerInterval: 30,
  googleSignInClientId: '164063187774-65d9674oqkhvu5v2dbo2ael5n4sf23a5.apps.googleusercontent.com'
};

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProfileDto, PaymentStatusEnum } from 'common-ui/open-api';
import { CustomerService } from 'common-ui/services/customer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'lib-employee-portal-withdrawal',
  templateUrl: './employee-portal-withdrawal.component.html',
  styleUrls: ['./employee-portal-withdrawal.component.css']
})
export class EmployeePortalWithdrawalComponent implements OnInit, OnDestroy {

  profiles: ProfileDto[];
  selectedCompanyIdControl: FormControl;
  selectedProfile: ProfileDto;
  ngUnsubscribe$ = new Subject();
  isWorking = true;

  constructor(
    private customerService: CustomerService
  ) {
  }

  get selectedCompanyId() {
    return localStorage.getItem('selected-company-id');
  }

  set selectedCompanyId(companyId: string) {
    localStorage.setItem('selected-company-id', companyId);
  }

  async ngOnInit() {
    this.profiles = await this.customerService.getProfiles();
    const pendingProfile = this.profiles.find(p => p.amountPending > 0);

    let companyId: string;
    if (pendingProfile) {
      companyId = pendingProfile.companyId;
    } else if (this.selectedCompanyId && !!this.profiles.find(p => p.companyId === this.selectedCompanyId)) {
      companyId = this.selectedCompanyId;
    } else {
      companyId = this.profiles[0].companyId;
    }

    this.selectedCompanyIdControl = new FormControl(companyId);
    this.selectedProfile = this.profiles.find(p => p.companyId === this.selectedCompanyIdControl.value);
    this.selectedCompanyIdControl.valueChanges.subscribe(value => {
      this.selectedCompanyId = value;
      this.selectedProfile = this.profiles.find(p => p.companyId === value);
    });
    this.isWorking = this.customerService.withdrawalStatus === PaymentStatusEnum.PENDING;

    this.customerService.withdrawalInProgress$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(status => {
        this.isWorking = status.status === PaymentStatusEnum.PENDING;
        if ( this.isWorking ) {
          this.selectedCompanyIdControl.disable()
        } else {
          this.selectedCompanyIdControl.enable()
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  get isMultiCompany() {
    return this.profiles.length > 1;
  }

}

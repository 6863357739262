import { Pipe, PipeTransform } from '@angular/core';
import { WithdrawalPeriodEndType } from 'common-ui/open-api';

@Pipe({
  name: 'withdrawalPeriodEndType'
})
export class WithdrawalPeriodEndTypePipe implements PipeTransform {

  transform(value: WithdrawalPeriodEndType): string {
    switch (value) {
      case WithdrawalPeriodEndType.EOD:
        return 'EOD period end';
      case WithdrawalPeriodEndType.EOD_PLUS_ONE:
        return 'EOD period end, plus one day';
    }
  }

}

export const hyphenatedToRegular = (text: string): string => {
  if ( !text) {
    return '-'
  }

  // Regular expression to match hyphenated words
  const pattern = /(?:^|\b)([a-z])/g;

  // Replace matched letters with capitalized letters
  return text.replace(pattern, (_, p1) => {
    return p1.toUpperCase();
  }).replace(/-/g, ' ')
    .replace(/_/g, ' ');
}

import { Component, OnInit, Input, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { FilterService, FilterType, FilterItem } from 'common-ui/services';
import { Subject } from 'rxjs';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Component({
  selector: 'lib-tri-list-selector-component',
  templateUrl: './tri-list-selector.component.html',
  styleUrls: ['./tri-list-selector.component.css']
})
export class TriListSelectorComponent implements OnInit, OnDestroy {
  @Input() list: FilterItem[];
  @Input() filterType: FilterType;
  @Input() label: string;

  @ViewChildren('menuTrigger')
  menuTriggerQuery: QueryList<MatMenuTrigger>;

  ngUnSubscribe = new Subject();
  form: UntypedFormGroup;
  heading: string

  constructor(
    private filterService: FilterService,
    private fb: UntypedFormBuilder
  ) {
    this.form = fb.group({});
  }

  ngOnInit() {
    this.list.forEach(item => {
      this.form.addControl(item.value, new UntypedFormControl());
    });
    const filterValues = this.filterService.getFilter(this.filterType);
    this.form.setValue(filterValues);
    this.updateHeading();
  }

  updateHeading() {
    let nullFilter = true;
    const filteredValues = [];

    for ( const item of this.list) {
      if ( this.form.value[item.value] !== null ) {
        nullFilter = false
        const not = this.form.value[item.value] === false ? 'Not ' : ''
        filteredValues.push(not + item.description)
      }
    }

    if ( nullFilter ) {
      this.heading =  this.label;
    } else {
      this.heading = filteredValues.toString()
    }
  }

  updateFilterService() {
    if (!this.form.valid) {
      return;
    }
    this.filterService.setFilter(this.filterType, this.form.value);
    this.updateHeading();
  }

  closeMenu() {
    if (!this.form.valid) {
      return;
    }
    const menuTrigger = this.menuTriggerQuery.first;
    menuTrigger.closeMenu();
  }

  ngOnDestroy() {
    this.ngUnSubscribe.next(undefined);
    this.ngUnSubscribe.complete();
  }
}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { CompanyUser } from 'common-ui/models/CompanyUser';
import { SelectionModel } from '@angular/cdk/collections';
import { MasterAccountDto, UserDto, AdminUserRole } from 'common-ui/open-api';
import { UserService } from 'common-ui/services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserEditDialogComponent } from 'common-ui/user-edit-dialog/user-edit-dialog.component';
import { AreYouSureDialogComponent } from 'common-ui/are-you-sure-dialog/are-you-sure-dialog.component';

@Component({
  selector: 'lib-master-account-user-settings',
  templateUrl: './master-account-user-settings.component.html',
  styleUrls: ['./master-account-user-settings.component.css']
})
export class MasterAccountUserSettingsComponent implements OnInit {

  @Output() closeEmitter = new EventEmitter<boolean>();
  @Input() masterAccount: MasterAccountDto;
  dataSource = new MatTableDataSource<CompanyUser>();
  currentUserEmail: string;
  isWorking: boolean;
  selection = new SelectionModel<CompanyUser>(false, []);
  errorMessage: string;
  displayedColumns = [
    'email',
    'roleType',
    'companies',
    'menu'
  ];
  AdminUserRole = AdminUserRole;

  constructor(
    private userService: UserService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.currentUserEmail = (await this.userService.getCurrentUser()).email;
    try {
      const userDtos = await this.userService.getUsers({
        masterAccountId: this.masterAccount._id,
        isAdmin: true
      });
      this.dataSource.data = userDtos.map(u => {
        return new CompanyUser(u, u.email === this.currentUserEmail);
      });
    } catch (err) {
      this.errorMessage = err.message;
    }

  }

  async openCreateUserDialog() {
    const ref = this.dialog.open(UserEditDialogComponent, {
      data: {
        create: true,
        masterAccountId: this.masterAccount._id
      }
    });
    ref.afterClosed().subscribe(async result => {
      if (result) {
        await this.ngOnInit();
      }
    });
  }


  async openEditUserDialog(user: UserDto) {
    const ref = this.dialog.open(UserEditDialogComponent, {
      data: {
        create: false,
        user: user
      }
    });
    ref.afterClosed().subscribe(async result => {
      if (result) {
        await this.ngOnInit();
      }
    });
  }

  async openRemovePermissionsDialog(user: UserDto) {
    const ref = this.dialog.open(AreYouSureDialogComponent, {
      data: {
        message: `Remove admin permissions for ${user.email}?`
      }
    });
    ref.afterClosed().subscribe(async result => {
      if (result) {
        try {
          this.isWorking = true;
          this.errorMessage = '';
          await this.userService.updateUser({
            userId: user._id,
            role: null,
            companyIds: []
          });
          await this.ngOnInit();
        } catch (err) {
          this.errorMessage = err.message;
        }
        this.isWorking = false;
      }
    });
  }

  async sendAdminInvite(userDto: any) {
    await this.userService.resendAdminAccountInvite(userDto._id);
  }
}

import { Component } from '@angular/core';
import { EntityStatus } from 'common-ui/open-api';
import { FilterType } from 'common-ui/services';

@Component({
  selector: 'lib-entity-status-selector',
  templateUrl: './entity-status-selector.component.html',
  styleUrls: ['./entity-status-selector.component.css']
})
export class EntityStatusSelectorComponent {
  statuses = Object.values(EntityStatus).map(pt => ({
    value: pt.toString(),
    description: pt.toString()
  }));
  FilterType = FilterType;
}

import { Injectable, Inject } from '@angular/core';
import { ApiService } from 'common-ui/services/api.service';
import { SetSystemTimeDto } from 'common-ui/open-api';
import { formatDateYYYYMMDD } from 'common-ui/util';
import { JustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  constructor(
    private api: ApiService,
    @Inject('env') private environment: any
  ) {
  }

  get systemTime$() {
    return this.api.systemTimeOffset$.pipe(
      map(offset => {
        if (offset === 0) {
          return formatDateYYYYMMDD(new Date());
        } else {
          const now = new Date();
          now.setTime(now.getTime() - offset);
          return formatDateYYYYMMDD(now);
        }
      })
    );
  }

  getCurrentWeek(): JustDateRange {
    const today = this.getSystemTime();
    const sow = startOfWeek(today, {
      weekStartsOn: 1
    });
    const eow = endOfWeek(today, {
      weekStartsOn: 1
    });

    return {
      fromDate: formatDateYYYYMMDD(sow),
      toDate: formatDateYYYYMMDD(eow)
    };
  }

  get isTimeTravelled() {
    return this.api.systemTimeOffset$.value !== 0;
  }

  getSystemTime(): Date {
    if (this.isTimeTravelled) {
      const now = new Date();
      now.setTime(now.getTime() - this.api.systemTimeOffset$.value);
      return now;
    } else {
      return new Date();
    }
  }

  async setSystemTime(body: SetSystemTimeDto) {
    return await this.api.patch('/api/system/system-time', body);
  }

  async test() {
    await this.api.get('/api/system/test/');
  }

  async setMinAppVersion(minAppVersion: number) {
    await this.api.patch('/api/system/min-app-version', {minAppVersion});
  }
}

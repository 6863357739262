import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvFileChooserService {

  constructor() {
  }

  get currentFile() {
    return localStorage.getItem('csv-file') || 'No file chosen';
  }

  get currentContent() {
    return localStorage.getItem('csv-content');
  }

  setCurrentFile(fileName: string, content: string) {
    localStorage.setItem('csv-file', fileName);
    localStorage.setItem('csv-content', content);
  }

  clearCurrent() {
    localStorage.removeItem('csv-file');
    localStorage.removeItem('csv-content');
  }
}

import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

export interface AreYouSureAsyncDialogData {
  heading: string;
  message: string;
  asyncFn: () => Promise<void>;
}

@Component({
  selector: 'lib-are-you-sure-dialog',
  templateUrl: './are-you-sure-async-dialog.component.html',
  styleUrls: ['./are-you-sure-async-dialog.component.css']
})
export class AreYouSureAsyncDialogComponent {

  isWorking = false;
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AreYouSureAsyncDialogData,
    private dialogRef: MatDialogRef<AreYouSureAsyncDialogComponent>
  ) {
  }

  async yes() {
    try {
      this.isWorking = true;
      this.errorMessage = '';
      await this.data.asyncFn();
      this.dialogRef.close(true);
    } catch (err) {
      this.isWorking = false;
      this.errorMessage = err.message;
    }

  }

  no() {
    this.dialogRef.close(false);
  }

}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterAccountService } from 'common-ui/services/master-account.service';
import { ApiSource } from 'common-ui/open-api';
import { UserService } from 'common-ui/services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MessageDialogComponent } from 'common-ui/message-dialog/message-dialog.component';
import { hyphenatedToRegular } from 'common-ui/pipes/hyphenated-to-regular';

@Component({
  selector: 'app-integration-oauth2-redirect',
  templateUrl: './integration-oauth2-redirect.component.html',
  styleUrls: ['./integration-oauth2-redirect.component.scss']
})
export class IntegrationOauth2RedirectComponent {

  isLoading = true;
  apiSource: ApiSource;
  errorMessage: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private masterAccountService: MasterAccountService,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog
  ) {
    this.activatedRoute.queryParams.subscribe(async params => {
      this.apiSource = params.api_source;
      if (params.code) {
        try {
          await this.masterAccountService.exchangeOAuthCode(params.api_source, params.code, params.state);
          await this.testConnection();
        } catch (err) {
          this.errorMessage = err.message;
        }
      } else {
        this.errorMessage = 'Consent was not provided.'
      }
      this.isLoading = false;
    });
  }

  async gotoIntegrationPage() {
    await this.router.navigateByUrl('account?tab=2');
  }

  public async testConnection(): Promise<void> {
    this.errorMessage = '';
    let header: string;
    let subHeader: string;
    try {
      const testConnectionResult = await this.masterAccountService.testConnection(this.userService.currentUser.masterAccountId);
      header = testConnectionResult.isConnected ? 'Connection Success' : 'Connection Failed';
      if (testConnectionResult.message) {
        subHeader = testConnectionResult.message;
      } else {
        subHeader = testConnectionResult.isConnected ?
          'Connection established with ' + hyphenatedToRegular(this.apiSource) + '.' :
          'Connection failed';
      }

    } catch (err) {
      header = 'Connection Failed';
      subHeader = err.message;
    }

    const ref = this.dialog.open(MessageDialogComponent, {
      data: {
        header: header,
        subHeader: subHeader
      }
    });

    ref.afterClosed().subscribe(() => {
      this.gotoIntegrationPage();
    })
  }

}

import { Component } from '@angular/core';
import { FilterType } from 'common-ui/services';
import { PaymentStatusEnum } from 'common-ui/open-api';
import { hyphenatedToRegular } from 'common-ui/pipes/hyphenated-to-regular';

@Component({
  selector: 'lib-payment-status-selector',
  templateUrl: './payment-status-selector.component.html',
  styleUrls: ['./payment-status-selector.component.css']
})
export class PaymentStatusSelectorComponent {
  paymentStatuses = Object.values(PaymentStatusEnum).map(status => ({
    value: status.toString(),
    description: hyphenatedToRegular(status)
  }));

  protected readonly FilterType = FilterType;
}

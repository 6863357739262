/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IntegrationTarget = 'Employee' | 'Company' | 'Payroll' | 'Timesheet' | 'Pay Code';

export const IntegrationTarget = {
    EMPLOYEE: 'Employee' as IntegrationTarget,
    COMPANY: 'Company' as IntegrationTarget,
    PAYROLL: 'Payroll' as IntegrationTarget,
    TIMESHEET: 'Timesheet' as IntegrationTarget,
    PAY_CODE: 'Pay Code' as IntegrationTarget
};


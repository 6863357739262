/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type InvoiceStatus = 'no-withdrawals' | 'pending' | 'paid' | 'exported' | 'submitted' | 'failed' | 'cancelled' | 'charged-back';

export const InvoiceStatus = {
    NO_WITHDRAWALS: 'no-withdrawals' as InvoiceStatus,
    PENDING: 'pending' as InvoiceStatus,
    PAID: 'paid' as InvoiceStatus,
    EXPORTED: 'exported' as InvoiceStatus,
    SUBMITTED: 'submitted' as InvoiceStatus,
    FAILED: 'failed' as InvoiceStatus,
    CANCELLED: 'cancelled' as InvoiceStatus,
    CHARGED_BACK: 'charged-back' as InvoiceStatus
};


import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IntegrationReportDto } from 'common-ui/open-api';

@Component({
  selector: 'lib-integration-report-dialog',
  templateUrl: './integration-report-dialog.component.html',
  styleUrls: ['./integration-report-dialog.component.css']
})
export class IntegrationReportDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<IntegrationReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IntegrationReportDto
  ) {
  }

  close() {
    this.dialogRef.close();
  }
}

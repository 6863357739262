import { Component, Input, Inject, OnDestroy, OnChanges, EventEmitter, Output } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  ValidationErrors,
  FormControl,
  FormGroup,
  FormBuilder,
  AbstractControl
} from '@angular/forms';
import { MasterAccountService } from 'common-ui/services';
import {
  MasterAccountDto,
  PayrollExportType,
  MasterAccountType,
  WithdrawalPeriodEndType,
  UpdateMasterAccountDto,
  PaymentApiType
} from 'common-ui/open-api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CurrencyPipe } from '@angular/common';
import { Environment } from 'common-ui/models/environment.type';

@Component({
  selector: 'lib-master-account-general-settings',
  templateUrl: './master-account-general-settings.component.html',
  styleUrls: ['./master-account-general-settings.component.css']
})
export class MasterAccountGeneralSettingsComponent implements OnChanges, OnDestroy {

  form: FormGroup;
  @Input() masterAccount: MasterAccountDto;
  @Output() masterAccountChange = new EventEmitter();
  isWorking: boolean;
  errorMessage: string;
  PayrollExportType = PayrollExportType;
  payrollExportTypes: PayrollExportType[] = Object.values(PayrollExportType);
  masterAccountTypes = Object.values(MasterAccountType);
  paymentApiTypes = Object.values(PaymentApiType);
  withdrawalPeriodEndTypes: WithdrawalPeriodEndType[] = Object.values(WithdrawalPeriodEndType);
  private ngUnsubscribe = new Subject();

  constructor(
    fb: FormBuilder,
    private masterAccountService: MasterAccountService,
    @Inject('env') private environment: Environment,
    private currencyPipe: CurrencyPipe
  ) {
    this.form = fb.group({
      name: ['', Validators.required],
      autoInviteNewEmployees: '',
      autoInviteTimesheetEmployeesOnly: '',
      noSignInBeforeInvite: '',
      autoInviteAmountAvailableThreshold: ['', [
        Validators.required,
        Validators.min(0)
      ]],
      activateWellbeingCampaign: '',
      activateAmountAvailableThresholdEmails: '',
      amountAvailableThreshold: ['', [
        Validators.required,
        Validators.min(10)
      ]],
      minimumWithdrawal: ['', [
        Validators.required,
        Validators.min(10)
      ]],
      withdrawalFee: ['', [
        Validators.required,
        this.validateFee.bind(this)
      ]],
      employerPaysFee: false,
      payrollExportType: PayrollExportType.STANDARD,
      withdrawalPeriodEndType: WithdrawalPeriodEndType.EOD_PLUS_ONE,
      deductionId: ['', Validators.maxLength(50)],
      defaultAllowance: [0.5, [Validators.required, Validators.min(0), Validators.max(100)]],
      overtimeAllowance: [0.8, [Validators.required, Validators.min(0), Validators.max(100)]],
      zeroHoursAllowance: [0.8, [Validators.required, Validators.min(0), Validators.max(80)]]
    }, {
      validators: this.deductionIdValidator.bind(this)
    });
    if (this.isConsole) {
      this.form.addControl('type', new FormControl());
      this.form.addControl('paymentApi', new FormControl());
      this.form.addControl('activatePushNotifications', new FormControl());
      this.form.addControl('discountCardsEnabled', new FormControl());
      this.form.addControl('savingsEnabled', new FormControl());
      this.form.addControl('screenLockEnabled', new FormControl());
    } else {
      this.form.disable();
    }

    this.form.controls.employerPaysFee.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(active => {
      if (active) {
        this.form.addControl('employerPaysFeeStartDate', new FormControl('', Validators.required));
        this.form.addControl('employerPaysFeeEndDate', new FormControl('', Validators.required));
      } else {
        this.form.removeControl('employerPaysFeeStartDate');
        this.form.removeControl('employerPaysFeeEndDate');
      }
    });

    this.form.controls.activateAmountAvailableThresholdEmails
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(active => {
      if (active) {
        this.form.controls.amountAvailableThreshold.setValidators([
          Validators.required,
          Validators.min(10)
        ]);
      } else {
        this.form.controls.amountAvailableThreshold.setValidators(null);
      }
    });
  }

  validateFee(formControl: AbstractControl) {
    const fee = this.parseFee(formControl);
    return fee < 10 ? {
      min: true
    } : null;
  }

  formatFee() {
    const fee = this.form.controls.withdrawalFee.value;
    const formattedFee = fee.toString().replace(/[^\d.]+/g, '');
    const transformedFee = this.currencyPipe.transform(formattedFee, 'GBP', 'symbol', '1.2-2');
    this.form.controls.withdrawalFee.patchValue(transformedFee, {
      emitEvent: false
    });
  }

  parseFee(formControl: AbstractControl<string>): number {
    const fee = formControl.value;
    if (fee) {
      const formattedFee = fee.toString().replace(/[^\d.]+/g, '');
      return Number.parseFloat(formattedFee) * 100;
    }
    return null;
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  deductionIdValidator(form: UntypedFormGroup): ValidationErrors | null {
    if (form.controls['payrollExportType'].value === PayrollExportType.SAGE_50C && !form.controls['deductionId'].value) {
      return {deductionId: true};
    }
    return null;
  }

  async ngOnChanges() {
    let formValue: any = {
      name: this.masterAccount.name,
      autoInviteNewEmployees: this.masterAccount.autoInviteNewEmployees || false,
      activateWellbeingCampaign: this.masterAccount.activateWellbeingCampaign || false,
      minimumWithdrawal: this.masterAccount.minimumWithdrawal / 100,
      withdrawalFee: this.masterAccount.fee / 100,
      employerPaysFee: this.masterAccount.employerPaysFee,
      payrollExportType: this.masterAccount.payrollExportType,
      withdrawalPeriodEndType: this.masterAccount.withdrawalPeriodEndType,
      deductionId: this.masterAccount.deductionId || '',
      autoInviteTimesheetEmployeesOnly: this.masterAccount.autoInviteTimesheetEmployeesOnly,
      autoInviteAmountAvailableThreshold: this.masterAccount.autoInviteAmountAvailableThreshold / 100,
      defaultAllowance: (this.masterAccount.defaultAllowance * 100).toString() || '50',
      overtimeAllowance: (this.masterAccount.overtimeAllowance * 100).toString() || '100',
      zeroHoursAllowance: (this.masterAccount.zeroHoursAllowance * 100).toString() || '80',
      activateAmountAvailableThresholdEmails: this.masterAccount.activateAmountAvailableThresholdEmails,
      amountAvailableThreshold: this.masterAccount.amountAvailableThreshold / 100,
      noSignInBeforeInvite: this.masterAccount.noSignInBeforeInvite ?? false
    };

    if (this.masterAccount.employerPaysFee) {
      formValue.employerPaysFeeStartDate = this.masterAccount.employerPaysFeeStartDate;
      formValue.employerPaysFeeEndDate = this.masterAccount.employerPaysFeeEndDate;
    }

    if (this.isConsole) {
      formValue = {
        ...formValue,
        type: this.masterAccount.type,
        paymentApi: this.masterAccount.paymentApi,
        activatePushNotifications: this.masterAccount.activatePushNotifications ?? false,
        discountCardsEnabled: this.masterAccount.discountCardsEnabled ?? false,
        savingsEnabled: this.masterAccount.savingsEnabled ?? false,
        screenLockEnabled: this.masterAccount.screenLockEnabled ?? false
      };
    }

    this.form.setValue(formValue);
    this.formatFee();
  }

  async update() {
    this.isWorking = true;
    this.errorMessage = '';
    try {
      this.formatFee();
      const updateMasterAccountDto: UpdateMasterAccountDto = {
        ...this.form.value,
        fee: undefined,
        defaultAllowance: undefined,
        overtimeAllowance: undefined,
        zeroHoursAllowance: undefined,
        minimumWithdrawal: this.form.value.minimumWithdrawal * 100,
        amountAvailableThreshold: this.form.value.amountAvailableThreshold * 100,
        autoInviteAmountAvailableThreshold: this.form.value.autoInviteAmountAvailableThreshold * 100
      };

      if (this.form.value.employerPaysFee) {
        updateMasterAccountDto.employerPaysFeeStartDate = this.form.value.employerPaysFeeStartDate;
        updateMasterAccountDto.employerPaysFeeEndDate = this.form.value.employerPaysFeeEndDate;
      }

      if (this.isConsole) {
        updateMasterAccountDto.defaultAllowance = parseInt(this.form.controls.defaultAllowance.value, 0) / 100;
        updateMasterAccountDto.overtimeAllowance = parseInt(this.form.controls.overtimeAllowance.value, 0) / 100;
        updateMasterAccountDto.zeroHoursAllowance = parseInt(this.form.controls.zeroHoursAllowance.value, 0) / 100;
        updateMasterAccountDto.fee = this.parseFee(this.form.controls.withdrawalFee);
        updateMasterAccountDto.employerPaysFee = this.form.controls.employerPaysFee.value;
        updateMasterAccountDto.paymentApi = this.form.controls.paymentApi.value;
        updateMasterAccountDto.activatePushNotifications = this.form.controls.activatePushNotifications.value;
        delete updateMasterAccountDto['withdrawalFee'];
        updateMasterAccountDto.noSignInBeforeInvite = this.form.value.autoInviteNewEmployees && this.form.value.noSignInBeforeInvite;
        updateMasterAccountDto.screenLockEnabled = this.form.value.screenLockEnabled;
      }

      await this.masterAccountService.update(this.masterAccount._id, updateMasterAccountDto);
      this.form.markAsPristine();
      this.masterAccountChange.emit();
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

}

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Payroll Export Type
 */
export type PayrollExportType = 'standard' | 'standard-single-name-field' | 'sage-50c';

export const PayrollExportType = {
    STANDARD: 'standard' as PayrollExportType,
    STANDARD_SINGLE_NAME_FIELD: 'standard-single-name-field' as PayrollExportType,
    SAGE_50C: 'sage-50c' as PayrollExportType
};


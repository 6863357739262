import { Pipe, PipeTransform } from '@angular/core';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import isValid from 'date-fns/isValid';

@Pipe({
  name: 'formatDateTimeString'
})
export class FormatDateTimeStringPipe implements PipeTransform {
  transform(dateStr: string, time: boolean): string {
    if (!dateStr) {
      return '-';
    }
    const date = parseISO(dateStr);
    if (!isValid(date) ) {
      return "Invalid Date"
    }
    const formatStr = time ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy';
    return format(date, formatStr);
  }
}

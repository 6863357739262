<button
  class="selector-menu-button"
  mat-stroked-button
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="menu">
  <div class="selector-button-title">
    {{heading}}
    <div class="mat-select-arrow ng-tns-c154-10"></div>
  </div>
</button>


<mat-menu
  #menu="matMenu"
  xPosition="before"
  (closed)="updateFilterService()">

  <form [formGroup]="form"
        (click)="$event.stopPropagation()"
        (ngSubmit)="closeMenu()">

    <div class="checkboxes">

      <lib-tri-state-checkbox
        class="checkbox"
        *ngFor="let item of list"
        [formControlName]="item.value">
        {{ item.description }}
      </lib-tri-state-checkbox>

      <div class="apply-button-container">
        <button
          mat-stroked-button
          color="primary"
          [disabled]="!form.valid"
          (click)="closeMenu()">
          Apply
        </button>
      </div>
    </div>

  </form>
</mat-menu>

<lib-spinner-container [isWorking]="isWorking">
  <div class="container">
    <form [formGroup]="form"
          (ngSubmit)="saveForm()"
          class="settings-form">

      <mat-checkbox
        formControlName="dailyWithdrawalLimitActive">
        Activate Daily Withdrawal Limit
      </mat-checkbox>

      <mat-form-field
        *ngIf="this.form.get('dailyWithdrawalLimitActive').value === true "
        class="conditional-field-indent">
        <mat-label>Max withdrawals per day</mat-label>
        <input matInput
               type="number"
               formControlName="maxWithdrawalsPerDay"
               placeholder="Max withdrawals per day" />
        <mat-error *ngIf="showError('maxWithdrawalsPerDay', 'required')">
          Daily Limit is required
        </mat-error>
        <mat-error *ngIf="showError('maxWithdrawalsPerDay', 'min')">
          Must be 1 or more
        </mat-error>
      </mat-form-field>

      <mat-checkbox
        formControlName="periodWithdrawalLimitActive">
        Activate Period Withdrawal Limit
      </mat-checkbox>

      <mat-form-field
        *ngIf="this.form.get('periodWithdrawalLimitActive').value === true"
        class="conditional-field-indent">
        <mat-label>Max withdrawals per period</mat-label>
        <input matInput
               type="number"
               formControlName="maxWithdrawalsPerPeriod"
               placeholder="Max withdrawals per period" />
        <mat-error *ngIf="showError('maxWithdrawalsPerPeriod', 'required')">
          Period limit is required
        </mat-error>
        <mat-error *ngIf="showError('maxWithdrawalsPerPeriod', 'min')">
          Must be 1 or more
        </mat-error>
      </mat-form-field>

      <mat-checkbox
        formControlName="creditLimitActive">
        Activate Credit Limit
      </mat-checkbox>

      <mat-form-field
        class="conditional-field-indent"
        *ngIf="showCreditLimit">
        <mat-label>Credit Limit</mat-label>
        <input formControlName="creditLimit"
               matInput
               mask="separator"
               required
               thousandSeparator=","
               [prefix]="currencySymbol"
               placeholder="Credit limit">
        <mat-error *ngIf="form.controls.creditLimit.invalid
                       && form.controls.creditLimit.dirty
                       && form.controls.creditLimit.touched">
          Credit Limit is required
        </mat-error>
      </mat-form-field>

      <lib-error-message [message]="errorMessage"></lib-error-message>

      <div class="button-panel">

        <button mat-flat-button
                color="primary"
                [disabled]="form.invalid || form.pristine"
                type="submit">
          Save
        </button>
      </div>

    </form>
  </div>

</lib-spinner-container>

import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, Inject, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ToolbarDataService, FilterType, FilterService, SystemService, TimesheetService } from 'common-ui/services';
import { TimesheetQuery, SortDirection, TimesheetDto, TimesheetOpenApiService } from 'common-ui/open-api';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { getJustDateRange, DateRangeType } from 'common-ui/custom-dates-range-selector/date-range.type';
import { TimesheetListDataSource } from 'common-ui/timesheet-list-page/timesheet-list-data-source';
import { Pages } from 'common-ui/services/filter.service';
import { Environment } from 'common-ui/models/environment.type';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TimesheetDialogComponent } from 'common-ui/timesheet-dialog/timesheet-dialog.component';
import { AreYouSureDialogComponent } from 'common-ui/are-you-sure-dialog/are-you-sure-dialog.component';
import { JsonDialogComponent } from 'common-ui/json-dialog/json-dialog.component';


@Component({
  selector: 'lib-timesheet-list-page',
  templateUrl: './timesheet-list-page.component.html',
  styleUrls: ['./timesheet-list-page.component.css']
})
export class TimesheetListPageComponent implements OnInit, AfterViewInit, OnDestroy {

  public masterAccountIds: string[];
  @Output() switchView = new EventEmitter<string>();
  public dataSource: TimesheetListDataSource;
  public FilterType = FilterType;
  public displayedColumns = [
    'firstName',
    'lastName',
    'masterAccountName',
    'companyName',
    'date',
    'type',
    'hourlyRate',
    'hours',
    'wages',
    'isApproved',
    'isCancelledOrDeleted',
    'isInCurrentAccrual',
    'actions'
  ];

  private ngUnsubscribe = new Subject();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private toolbarDataService: ToolbarDataService,
    private filterService: FilterService,
    private timesheetService: TimesheetService,
    private timesheetOpenApiService: TimesheetOpenApiService,
    private systemService: SystemService,
    @Inject('env') private environment: Environment,
    private dialog: MatDialog
  ) {
    this.dataSource = new TimesheetListDataSource(this.timesheetOpenApiService);
    if (!this.environment.adminConsole) {
      this.displayedColumns = this.displayedColumns.filter(
        column => column !== 'masterAccountName');
    }
  }

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle', title: 'Timesheets'
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => await this.loadData());

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      await this.loadData();
      this.paginator.firstPage();
    });

    const initialSort = this.filterService.getPageSort(Pages.TIMESHEET_LIST);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.TIMESHEET_LIST, newSort.active, newSort.direction as SortDirection);
      });

    await this.loadData();
  }

  async loadData() {
    const currentFilter = this.filterService.currentFilter;
    const justDateRange = getJustDateRange(currentFilter.dateRange, this.systemService.getSystemTime());

    const query: TimesheetQuery = {
      offset: this.paginator.pageIndex * this.environment.defaultPageSize,
      limit: this.environment.defaultPageSize,
      fromDate: justDateRange.fromDate,
      toDate: currentFilter.dateRange.dateRangeType === DateRangeType.CUSTOM ? justDateRange.toDate : null,
      employeeName: currentFilter.employeeSearch,
      masterAccountIds: currentFilter.masterAccounts,
      companyIds: currentFilter.companies
    };

    if (currentFilter.timesheetTypes) {
      query.timesheetTypes = currentFilter.timesheetTypes;
    }

    if (currentFilter.timesheetStatus.isApproved !== null) {
      query.isApproved = currentFilter.timesheetStatus.isApproved;
    }

    if (currentFilter.timesheetStatus.isCancelled !== null) {
      query.isCancelled = currentFilter.timesheetStatus.isCancelled;
    }

    if (currentFilter.masterAccounts) {
      this.masterAccountIds = currentFilter.masterAccounts;
    }

    const sort = this.filterService.getPageSort(Pages.TIMESHEET_LIST);
    if (sort && this.sort.sortables.has(sort.name)) {
      const sortClause = {};
      sortClause[sort.name] = sort.direction;
      query.sort = sortClause;
    }

    await this.dataSource.load(query);
  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  async switchToTableView() {
    this.switchView.emit('table');
  }

  openTimesheetDialog() {
    const ref = this.dialog.open(TimesheetDialogComponent);
    ref.afterClosed().subscribe(async value => {
      if (value) {
        await this.loadData();
      }
    });
  }

  isEditDisabled(timesheet: TimesheetDto) {
    return !!timesheet.timesheetIntegrationId;
  }

  openTimesheetEditDialog(timesheet: TimesheetDto) {
    const ref = this.dialog.open(TimesheetDialogComponent, {
      data: timesheet
    });
    ref.afterClosed().subscribe(async value => {
      if (value) {
        await this.loadData();
      }
    });
  }

  openDeleteTimesheetDialog(timesheet: TimesheetDto) {
    const ref = this.dialog.open(AreYouSureDialogComponent, {
      data: {
        message: 'Are you sure you want to delete timesheet?'
      }
    });
    ref.afterClosed().subscribe(async value => {
      if (value) {
        await this.timesheetService.deleteTimesheet(timesheet._id);
        await this.loadData();
      }
    });
  }

  openJsonDialog(timesheetDto: TimesheetDto) {
    this.dialog.open(JsonDialogComponent, {
      data: {
        header: 'Timesheet',
        subHeader: timesheetDto._id,
        data: timesheetDto
      }
    });
  }

}

import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { PeriodHistoryService, FilterService, FilterType } from 'common-ui/services';
import {
  ApiSource,
  PeriodHistoryDto,
  SortDirection,
  PeriodHistoryQuery,
  InvoiceStatus,
  FinanceExportsOpenApiService
} from 'common-ui/open-api';
import { ToolbarDataService } from 'common-ui/services/toolbar-data.service';
import { Subject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { PeriodExportType } from 'common-ui/models/period-export-type';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  PeriodHistoryEditDialogComponent
} from 'common-ui/period-history-edit-dialog/period-history-edit-dialog.component';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Pages } from 'common-ui/services/filter.service';
import { PeriodHistoryDataSource } from 'common-ui/invoice-history-page/period-history-data.source';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Environment } from 'common-ui/models/environment.type';
import {
  PaymentInstructionHistoryDialogComponent
} from 'common-ui/payment-instruction-history-dialog/payment-instruction-history-dialog.component';
import { PaymentInstructionService } from 'common-ui/services/payment-instruction.service';
import {
  ImportGcPaymentsExportDialogComponent
} from 'common-ui/import-gc-payments-export-dialog/import-gc-payments-export-dialog.component';
import { MessageDialogComponent } from 'common-ui/message-dialog/message-dialog.component';
import * as Sentry from '@sentry/angular';
import { FinanceExportsDialogComponent } from 'common-ui/finance-exports-dialog/finance-exports-dialog.component';
import { DateOperationDialogComponent } from 'common-ui/date-operation-dialog/date-operation-dialog.component';
import { FileDownloadService } from 'common-ui/services/file-download.service';

@Component({
  selector: 'lib-period-history-page',
  templateUrl: './period-history-page.component.html',
  styleUrls: ['./period-history-page.component.css']
})
export class PeriodHistoryPageComponent implements OnInit, OnDestroy, AfterViewInit {

  isLoading: boolean;
  dataSource: PeriodHistoryDataSource;
  ApiSource = ApiSource;
  PeriodExportType = PeriodExportType;
  private ngUnsubscribe = new Subject();
  rowsWorking: string[] = [];

  displayedColumns: string[] = [
    'companyName',
    'frequency',
    'invoiceNumber',
    'periodStart',
    'periodEnd',
    'ddChargeDate',
    'invoiceAmount',
    'status',
    'menu'
  ];
  FilterType = FilterType;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private toolbarDataService: ToolbarDataService,
    private periodHistoryService: PeriodHistoryService,
    private paymentInstructionsService: PaymentInstructionService,
    private filterService: FilterService,
    @Inject('env') private environment: Environment,
    private dialog: MatDialog,
    private financeExportService: FinanceExportsOpenApiService,
    private fileDownloadService: FileDownloadService
  ) {
    this.dataSource = new PeriodHistoryDataSource(this.periodHistoryService);
    if (this.isConsole) {
      this.displayedColumns = this.displayedColumns = [
        'masterAccountName',
        'legalEntityName',
        'companyName',
        'frequency',
        'invoiceNumber',
        'periodStart',
        'periodEnd',
        'ddChargeDate',
        'ddPayoutDate',
        'invoiceAmount',
        'paymentReference',
        'status',
        'menu'
      ];
    }
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  getIsRowWorking(periodId: string) {
    return this.rowsWorking.includes(periodId);
  }

  setIsRowWorking(periodId: string) {
    this.rowsWorking.push(periodId);
  }

  unsetIsRowWorking(periodId: string) {
    this.rowsWorking = this.rowsWorking.filter(r => r !== periodId);
  }

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle',
      title: 'Invoice History'
    });
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      this.loadData();
    });

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      this.loadData();
      this.paginator.firstPage();
    });

    const initialSort = this.filterService.getPageSort(Pages.PERIOD_HISTORY);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.PERIOD_HISTORY, newSort.active, newSort.direction as SortDirection);
      });

    this.loadData();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  loadData() {
    const query: PeriodHistoryQuery = {
      offset: this.paginator.pageIndex * this.pageSize,
      limit: this.pageSize
    };

    const currentFilter = this.filterService.currentFilter;
    query.companyIds = currentFilter.companies;
    query.statuses = currentFilter.invoiceStatuses;
    query.paymentReference = currentFilter.paymentReference;
    query.masterAccountIds = currentFilter.masterAccounts;

    if (this.isConsole) {
      query.companyName = currentFilter.companySearch;
    }

    const pageSort = this.filterService.getPageSort(Pages.PERIOD_HISTORY);
    if (pageSort && this.sort.sortables.has(pageSort.name)) {
      query.sort = FilterService.getSortObj(pageSort);
    }

    this.dataSource.load(query);
  }

  async downloadFile(periodId: string, exportType: PeriodExportType) {
    await this.periodHistoryService.downloadFile(periodId, exportType);
  }

  wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  async regenerateFiles(periodId: string) {
    this.setIsRowWorking(periodId);
    try {
      await this.periodHistoryService.regenerateFiles(periodId);
      this.loadData();
    } catch (err) {
      Sentry.captureException(err.message);
    }
    this.unsetIsRowWorking(periodId);
  }

  async openPeriodHistoryEditDialog(period: PeriodHistoryDto) {
    const ref = this.dialog.open(PeriodHistoryEditDialogComponent, {
      data: period,
      width: '450'
    });

    ref.afterClosed().subscribe(async reload => {
      if (reload) {
        this.loadData();
      }
    });
  }

  async openPaymentInstructionsHistoryDialog() {
    this.dialog.open(PaymentInstructionHistoryDialogComponent);
  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  protected readonly InvoiceStatus = InvoiceStatus;

  async generatePaymentInstructions() {
    try {
      await this.paymentInstructionsService.exportPending();
      this.loadData();
    } catch (err) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          header: 'Export Failed',
          subHeader: err.message
        }
      });
    }
  }

  openImportGcPaymentsExportDialog() {
    const ref = this.dialog.open(ImportGcPaymentsExportDialogComponent);
    ref.afterClosed().subscribe(() => {
      this.loadData();
    });
  }

  openFinanceExportsDialog() {
    this.dialog.open(FinanceExportsDialogComponent);
  }

  protected readonly open = open;

  openRegenerateExportsDialog() {
    const ref = this.dialog.open(DateOperationDialogComponent, {
      data: {
        heading: 'Generate Finance Exports',
        message: 'Select the report date to create or update',
        executeButtonText: 'Generate',
        asyncFn: async (date: string) => {
          console.log('date=' + date);
          const response = this.financeExportService.generateFinanceExportFiles({date}, 'response');
          await this.fileDownloadService.downloadFile(response);
        }
      }

    });
    ref.afterClosed().subscribe(() => {
      this.loadData();
    });
  }
}

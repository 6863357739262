import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

export interface JsonDialogData {
  header: string
  subHeader: string,
  data: any
}

@Component({
  selector: 'lib-json-dialog',
  templateUrl: './json-dialog.component.html',
  styleUrls: ['./json-dialog.component.css']
})
export class JsonDialogComponent {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: JsonDialogData,
    private dialogRef: MatDialogRef<JsonDialogComponent>
  ) {
  }

  close() {
    this.dialogRef.close();
  }

}

/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EmployeeAuditEvent = 'blocked' | 'unblocked' | 'set-is-vulnerable' | 'unset-is-vulnerable' | 'bank-details-updated' | 'leaving-date-set' | 'leaving-date-unset' | 'de-integrated';

export const EmployeeAuditEvent = {
    BLOCKED: 'blocked' as EmployeeAuditEvent,
    UNBLOCKED: 'unblocked' as EmployeeAuditEvent,
    SET_IS_VULNERABLE: 'set-is-vulnerable' as EmployeeAuditEvent,
    UNSET_IS_VULNERABLE: 'unset-is-vulnerable' as EmployeeAuditEvent,
    BANK_DETAILS_UPDATED: 'bank-details-updated' as EmployeeAuditEvent,
    LEAVING_DATE_SET: 'leaving-date-set' as EmployeeAuditEvent,
    LEAVING_DATE_UNSET: 'leaving-date-unset' as EmployeeAuditEvent,
    DE_INTEGRATED: 'de-integrated' as EmployeeAuditEvent
};


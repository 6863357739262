import { Component } from '@angular/core';
import { AuthService } from 'common-ui/services';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs';

@Component({
  selector: 'lib-email-changed',
  templateUrl: './email-changed.component.html',
  styleUrls: ['./email-changed.component.css']
})
export class EmailChangedComponent {

  newEmail: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams
      .pipe(take(1))
      .subscribe(params => {
        this.newEmail = params.updatedEmail;
      });
  }

  async gotoSignIn() {
    try {
      await this.authService.logout();
    } catch (err) {
      console.error(err);
    }
  }
}

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ApiSource, IntegrationTarget, ImportErrorDto } from 'common-ui/open-api';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { IntegrationService } from 'common-ui/services/integration.service';

@Component({
  selector: 'lib-integration-errors-table',
  templateUrl: './integration-errors-table.component.html',
  styleUrls: ['./integration-errors-table.component.scss']
})
export class IntegrationErrorsTableComponent implements OnInit, OnChanges {

  @Input() integrationReportId: string;
  @Input() searchText: string;
  @Input() apiSource: ApiSource | string = "External Id";

  dataSource: MatTableDataSource<ImportErrorDto> = new MatTableDataSource<ImportErrorDto>();
  displayedColumns = [
    'type',
    'employeeIdentifier',
    'errorMessage',
    'externalId'
  ];

  constructor(
    private integrationService: IntegrationService
  ) {
    this.dataSource.filterPredicate = (data, filterValue) => {
      return JSON.stringify(data).toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
    };
  }

  async ngOnInit() {
    this.dataSource.data = await this.integrationService.getIntegrationReportErrors(this.integrationReportId);
  }

  async ngOnChanges() {
    this.dataSource.data = await this.integrationService.getIntegrationReportErrors(this.integrationReportId);
    this.dataSource.filter = this.searchText;
  }

  getExternalId(row: ImportErrorDto) {
    switch (row.target) {
      case IntegrationTarget.EMPLOYEE:
        return row.employeeExternalId;
      case IntegrationTarget.TIMESHEET:
        return row.timesheetExternalId;
      case IntegrationTarget.COMPANY:
        return row.companyInternalId;
      default:
        return 'n/a';
    }
  }

}

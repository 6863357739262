import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { ToolbarDataService, FilterType, FilterService, MasterAccountService } from 'common-ui/services';
import {
  EmployeeDto,
  PayType,
  EmployeeQuery,
  EmailEventType,
  SortDirection,
  MasterAccountCoreDto,
  EmployeeOpenApiService
} from 'common-ui/open-api';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EmployeeEditDialogComponent, EmployeeEditData } from '../employee-edit-dialog/employee-edit-dialog.component';
import { EmployeeTimesheetDialogComponent } from '../employee-timesheet-dialog/employee-timesheet-dialog.component';
import { EmployeeWithdrawalDialogComponent } from '../employee-withdrawal-dialog/employee-withdrawal-dialog.component';
import { EmployeeStatusDialogComponent } from '../employee-status-dialog/employee-status-dialog.component';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Subject, catchError, of, tap, firstValueFrom } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AreYouSureDialogComponent } from 'common-ui/are-you-sure-dialog/are-you-sure-dialog.component';
import { ErrorDialogComponent } from 'common-ui/error-dialog/error-dialog.component';
import { EmployeeInviteDialogComponent } from 'common-ui/employee-invite-dialog/employee-invite-dialog.component';
import { EmployeeDataSource } from 'common-ui/employee-page/employee-data.source';
import { UserService } from 'common-ui/services/user.service';
import { Pages } from 'common-ui/services/filter.service';
import { JsonDialogComponent } from 'common-ui/json-dialog/json-dialog.component';
import { Environment } from 'common-ui/models/environment.type';
import {
  AvailableExplainerDialogComponent
} from 'common-ui/available-explainer-dialog/available-explainer-dialog.component';
import {
  EmployeeVulnerabilityDialogComponent
} from 'common-ui/employee-vulnerability-dialog/employee-vulnerability-dialog.component';
import {
  EmployeeAuditLogDialogComponent
} from 'common-ui/employee-audit-log-dialog/employee-audit-log-dialog.component';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'lib-employee-page',
  templateUrl: './employee-page.component.html',
  styleUrls: ['./employee-page.component.css']
})
export class EmployeePageComponent implements OnInit, AfterViewInit, OnDestroy {

  portalColumns = [
    'select',
    'firstName',
    'lastName',
    'companyName',
    'currency',
    'payFrequency',
    'payType',
    'amountAccrued',
    'amountAvailable',
    'amountWithdrawn',
    'employmentStatus',
    'inviteStatus',
    'blocked',
    'menu'
  ];

  consoleColumns = [
    'select',
    'firstName',
    'lastName',
    'usageRating',
    'masterAccountName',
    'companyName',
    'currency',
    'payFrequency',
    'payType',
    'amountAccrued',
    'amountAvailable',
    'amountWithdrawn',
    'employmentStatus',
    'inviteStatus',
    'blocked',
    'menu'
  ];

  get displayedColumns() {
    return this.isConsole ? this.consoleColumns : this.portalColumns;
  }

  dataSource: EmployeeDataSource;
  selection = new SelectionModel<EmployeeDto>(true, []);
  PayType = PayType;
  FilterType = FilterType;
  EmailEventType = EmailEventType;
  private ngUnsubscribe = new Subject();
  masterAccount: MasterAccountCoreDto;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private employeeService: EmployeeOpenApiService,
    public dialog: MatDialog,
    private toolbarDataService: ToolbarDataService,
    private filterService: FilterService,
    private masterAccountService: MasterAccountService,
    private userService: UserService,
    @Inject('env') public environment: Environment
  ) {
    this.dataSource = new EmployeeDataSource(employeeService);
  }

  get isPortal() {
    return !this.environment.adminConsole;
  }

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle', title: 'Employees'
    });

    if (this.isPortal) {
      const user = await this.userService.getCurrentUser();
      this.masterAccount = await this.masterAccountService.getCoreMasterAccount(user.masterAccountId);
    }
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      this.loadData();
    });

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      this.loadData();
      this.paginator.firstPage();
    });
    const initialSort = this.filterService.getPageSort(Pages.EMPLOYEE);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.EMPLOYEE, newSort.active, newSort.direction as SortDirection);
      });

    this.loadData();
  }

  loadData() {
    this.selection.clear();
    const query: EmployeeQuery = {
      offset: this.paginator.pageIndex * this.pageSize,
      limit: this.pageSize
    };

    const currentFilter = this.filterService.currentFilter;
    query.payTypes = currentFilter.payTypes;
    query.employeeName = currentFilter.employeeSearch;
    if (this.isConsole) {
      query.masterAccountIds = currentFilter.masterAccounts;
      query.employeeUsageRatings = currentFilter.employeeUsageRating;
      if (currentFilter.employeeVulnerable?.includes('yes')) {
        query.isVulnerable = true;
      } else if (currentFilter.employeeVulnerable?.includes('no')) {
        query.isVulnerable = false;
      }
    }
    query.companyIds = currentFilter.companies;
    query.payFrequencies = currentFilter.payFrequencies;
    query.employmentStatuses = currentFilter.employmentStatuses;
    query.inviteStatuses = currentFilter.emailEvents;

    const pageSort = this.filterService.getPageSort(Pages.EMPLOYEE);
    if (pageSort && this.sort.sortables.has(pageSort.name)) {
      query.sort = FilterService.getSortObj(pageSort);
    }

    this.dataSource.load(query);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }


  checkboxLabel(row?) {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} employee ${row.position + 1}`;
  }

  async openEmployeeInviteDialog() {
    const ref = this.dialog.open(EmployeeInviteDialogComponent, {
      data: {
        masterAccountId: this.masterAccount._id,
        selectedEmployees: this.selection.selected
      }
    });
    ref.afterClosed().subscribe(async reload => {
      if (reload) {
        await this.reloadEmployees();
      }
    });
  }

  async sendEmployeeInvite(selectedEmployee: EmployeeDto) {
    this.employeeService.inviteEmployees({
      employeeIds: [selectedEmployee._id],
      excludeActive: false,
      masterAccountId: selectedEmployee.masterAccountId,
      excludeInvited: false,
      excludeLeaving: false,
      excludeBlocked: false,
      timesheetEmployeesOnly: false,
      amountAvailableThreshold: 0
    }).subscribe(async () => await this.reloadEmployees());
  }

  openEmployeeEditDialog(selectedEmployee: EmployeeDto) {
    const employee = selectedEmployee;
    const data: EmployeeEditData = {
      masterAccountId: employee.masterAccountId,
      employee,
      create: false
    };

    const dialogRef = this.dialog.open(EmployeeEditDialogComponent, {data});
    dialogRef.afterClosed().subscribe(async reload => {
      if (reload) {
        await this.reloadEmployees();
      }
    });
  }

  openEmployeeCreateDialog() {
    const data: EmployeeEditData = {
      masterAccountId: this.masterAccount._id,
      create: true
    };

    const dialogRef = this.dialog.open(EmployeeEditDialogComponent, {data});
    dialogRef.afterClosed().subscribe(async reload => {
      if (reload) {
        await this.reloadEmployees();
      }
    });
  }

  openEmployeeTimesheetDialog(selectedEmployee?: EmployeeDto) {
    const employee = selectedEmployee || {...this.selection.selected[0]};
    this.dialog.open(EmployeeTimesheetDialogComponent, {
      data: {employee},
      autoFocus: false
    });
  }

  openEmployeeWithdrawalsDialog(selectedEmployee?: EmployeeDto) {
    const employee = selectedEmployee || {...this.selection.selected[0]};
    this.dialog.open(EmployeeWithdrawalDialogComponent, {
      data: {employee},
      autoFocus: false
    });
  }

  openEmployeeStatusDialog(employee: EmployeeDto, block: boolean) {
    const dialogRef = this.dialog.open(EmployeeStatusDialogComponent, {
      data: {employee, block},
      autoFocus: '#note-field'
    });
    dialogRef.afterClosed().subscribe(async yes => {
      if (yes) {
        await this.reloadEmployees();
      }
    });
  }

  openDeleteEmployeeDialog(employee: EmployeeDto) {
    let employeeName: string;
    if (employee.firstName && employee.lastName) {
      employeeName = `${employee.firstName} ${employee.lastName}`;
    } else if (employee.email) {
      employeeName = employee.email;
    } else {
      employeeName = 'employee';
    }
    const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
      data: {
        message: `Confirm delete of ${employeeName}.`
      }
    });
    dialogRef.afterClosed().subscribe(async yes => {
      if (yes) {
        this.employeeService.deleteEmployee(employee._id)
          .pipe(
            tap(async () => await this.reloadEmployees()),
            catchError(err => {
              this.dialog.open(ErrorDialogComponent, {
                data: {
                  operationName: 'Delete Employee',
                  message: err.message
                }
              });
              return of();
            }))
          .subscribe();
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = Math.min(this.dataSource.currentValue.length, this.pageSize);
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.currentValue.forEach(row => this.selection.select(row));
    }
  }

  private async reloadEmployees() {
    const oldSelection = this.selection.selected;
    const oldSelectionIds = oldSelection.map(e => e._id);

    this.loadData();
    const newSelected = this.dataSource.currentValue.filter(e => oldSelectionIds.indexOf(e._id) > -1);
    this.selection.select(...newSelected);
    this.selection.deselect(...oldSelection);
  }

  isEmployeeBlocked(employee: EmployeeDto): boolean {
    return employee.isBlocked || employee.isCompanyOrMasterAccountBlocked;
  }

  getBlockedTooltip(employee: EmployeeDto): string {
    if (employee.isCompanyOrMasterAccountBlocked) {
      return 'Company is blocked';
    } else if (employee.isBlocked) {
      return 'Employee is blocked';
    } else {
      return '';
    }
  }

  async updateAccrual(employee: EmployeeDto) {
    this.employeeService.updateAccrual(employee._id)
      .subscribe(() => this.loadData());
  }

  openJsonDialog(employee: EmployeeDto) {
    this.dialog.open(JsonDialogComponent, {
      data: {
        header: 'Employee',
        subHeader: employee._id,
        data: employee
      }
    });
  }

  async deleteSelected() {
    const selectedEmployeeIds = this.selection.selected.map(e => e._id);
    this.employeeService.deleteEmployees(selectedEmployeeIds)
      .subscribe(() => this.loadData());
  }

  openAvailabilityExplainerDialog(employeeDto: EmployeeDto) {
    this.dialog.open(AvailableExplainerDialogComponent, {
      data: {
        employeeId: employeeDto._id
      }
    });
  }

  openEmployeeVulnerabilityDialog(employee: EmployeeDto) {
    this.dialog.open(EmployeeVulnerabilityDialogComponent, {
      data: employee
    });
  }

  openAuditLogDialog(employee: EmployeeDto) {
    this.dialog.open(EmployeeAuditLogDialogComponent, {
      data: employee
    });
  }

  async deIntegrateEmployee(element: EmployeeDto) {
    const dialogRef = this.dialog.open(AreYouSureDialogComponent, {
      data: {
        message: `Confirm de-integration of ${element.firstName} ${element.lastName}.
        Updates will no longer be sourced from ${element.timesheetIntegrationSource}.
        Please note - this action cannot be undone.`
      }
    });
    dialogRef.afterClosed().subscribe(async yes => {
      if (yes) {
        try {
          await firstValueFrom(this.employeeService.deIntegrateEmployee(element._id));
          this.loadData();
        } catch (err) {
          Sentry.captureException(err.message);
        }
      }
    });
  }
}


import { Component, OnInit } from '@angular/core';
import { MasterAccountDto } from 'common-ui/open-api';
import { MasterAccountService } from 'common-ui/services/master-account.service';
import { ToolbarDataService } from 'common-ui/services/toolbar-data.service';
import { UserService } from 'common-ui/services/user.service';

@Component({
  selector: 'app-account-settings-page',
  styleUrls: ['./account-settings-page.component.css'],
  templateUrl: './account-settings-page.component.html'
})
export class AccountSettingsPageComponent implements OnInit {
  public masterAccount: MasterAccountDto;

  constructor(
    private toolbarDataService: ToolbarDataService,
    private masterAccountService: MasterAccountService,
    private userService: UserService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.toolbarDataService.setupToolbar({
      action: 'toggle',
      title: 'Account'
    });
    const user = await this.userService.getCurrentUser();
    this.masterAccount = await this.masterAccountService.get(user.masterAccountId);
  }

}


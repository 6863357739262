<div class="mat-typography dialog-container">
  <div class="table-panel">
    <table mat-table
           [dataSource]="dataSource"
           class="user-table">

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">{{element.displayEmail}} </td>
      </ng-container>

      <ng-container matColumnDef="roleType">
        <th mat-header-cell *matHeaderCellDef>Role</th>
        <td mat-cell *matCellDef="let element">{{element.role | hyphenatedToRegular }}</td>
      </ng-container>

      <ng-container matColumnDef="companies">
        <th *matHeaderCellDef mat-header-cell>Companies</th>
        <td *matCellDef="let element" mat-cell>
          <ng-container *ngIf="element.role===AdminUserRole.COMPANY_ADMIN; else all">
            <div *ngFor="let company of element.companies">{{company.name}}</div>
          </ng-container>
          <ng-template #all>
            All Companies
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th *matHeaderCellDef mat-header-cell>Actions</th>
        <!--      Clicking this column does not select the employee     -->
        <td *matCellDef="let element" mat-cell (click)="$event.stopPropagation()">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button (click)="openEditUserDialog(element.userDto)"
                    [disabled]="element.userDto.email === currentUserEmail"
                    mat-menu-item>
              <mat-icon>edit</mat-icon>
              <span>Edit Permissions</span>
            </button>

            <button (click)="openRemovePermissionsDialog(element.userDto)"
                    [disabled]="element.userDto.email === currentUserEmail"
                    mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span>Remove Permissions</span>
            </button>

            <button (click)="sendAdminInvite(element.userDto)"
                    [disabled]="element.userDto.email === currentUserEmail"
                    mat-menu-item>
              <mat-icon>send</mat-icon>
              <span>Send Invite</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">

      </tr>
    </table>
  </div>

  <lib-error-message [message]="errorMessage"></lib-error-message>

  <lib-button-panel>

    <button [disabled]="isWorking"
            type="button"
            data-e2e="create-admin-user"
            (click)="openCreateUserDialog()"
            mat-flat-button
            color="primary">
      Create User
    </button>
  </lib-button-panel>
</div>

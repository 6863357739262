import { CsvInputFile, ServerImportError } from './csv-input-file';
import {
  CsvInputError,
  CsvImportTemplate,
  CompanyDto,
  CreateCsvConfigDto,
  CsvFieldMapping,
  UpdateCsvConfigDto,
  ImportError,
  ConfigType
} from 'common-ui/open-api';
import { parseIntStrict } from 'common-ui/util/parse-int-strict';
import { TrafficLights } from 'common-ui/models/traffic-lights.model';
import { CsvColumnMapping, dateRegex } from './csv-column-mapping';
import { convertCamelToRegular } from 'common-ui/pipes/convert-camel-to-regular';
import { Subject } from 'rxjs';
import { CsvPayFrequencyValidator } from './csv-pay-frequency-validator';
import { CsvInputRow } from './csv-input-row';
import { CsvCompanyNameValidator } from './csv-company-name-validator';
import { CsvCurrencyValidator } from './csv-currency-validator';

export class CsvConfig {
  name: string;
  map = new Map<string, CsvColumnMapping>();
  csvInputFile: CsvInputFile;
  noHeaderRow: boolean;
  type: ConfigType;
  status = TrafficLights.STOP;
  definition: CsvImportTemplate;
  configChanges = new Subject();
  companyNameValidator: CsvCompanyNameValidator;

  static copy(input: CsvConfig): CsvConfig {
    const mappingCopy: CsvFieldMapping[] = input.mappings.map(mapping => ({
      targetFieldName: mapping.targetField.name,
      csvColumnName: mapping.csvColumnName
    }));

    const copy = new CsvConfig(input.name, input.definition, input.noHeaderRow, input.type, input.companyNameValidator.companyDtos, mappingCopy);
    if (input.csvInputFile) {
      const firstRowFile = CsvInputFile.copyFirstRow(input.csvInputFile);
      copy.loadFile(firstRowFile);
    }
    return copy;
  }

  constructor(
    name: string,
    definition: CsvImportTemplate,
    noHeaderRow: boolean,
    type: ConfigType,
    companies: CompanyDto[],
    mappings?: CsvFieldMapping[]
  ) {
    this.configChanges = new Subject<void>();
    this.type = type;
    this.name = name;
    this.definition = definition;
    this.noHeaderRow = noHeaderRow;
    this.companyNameValidator = new CsvCompanyNameValidator(companies);

    if (mappings) {
      definition.fields
        .filter(field => {
          return mappings.map(x => x.targetFieldName).includes(field.name);
        })
        .forEach(field => {
          this.map.set(field.name, new CsvColumnMapping(field, this.companyNameValidator));
        });
    } else {
      definition.fields
        .forEach(field => {
          this.map.set(field.name, new CsvColumnMapping(field, this.companyNameValidator));
        });
    }

    if (mappings) {
      mappings.forEach(mapping => {
        this.setMapping(mapping.targetFieldName, mapping.csvColumnName);
      });
    }
  }

  get templateType() {
    return this.definition.templateType;
  }

  get dataType() {
    return this.definition.dataType;
  }

  get postUrl() {
    return this.definition.postUrl;
  }

  get isFileLoaded() {
    return !!this.csvInputFile;
  }

  get mappings(): CsvColumnMapping[] {
    return Array.from(this.map.values());
  }

  get isMultiCompanyImport(): boolean {
    return this.definition.fields.filter(
      field =>
        field.validations.includes(CsvInputError.COMPANY_NAME)).length > 0;
  }

  get csvInputFileRows(): CsvInputRow[] {
    return this.csvInputFile ? this.csvInputFile.rows : [];
  }

  getMapping(targetFieldName: string): CsvColumnMapping {
    return this.map.get(targetFieldName);
  }

  toCreateDto(): CreateCsvConfigDto {
    return {
      name: this.name,
      templateType: this.templateType,
      dataType: this.dataType,
      noHeaderRow: this.noHeaderRow,
      mappings: this.mappings.map(mapping => ({
        targetFieldName: mapping.targetField.name,
        csvColumnName: mapping.csvColumnName
      }))
    };
  }

  toUpdateDto(): UpdateCsvConfigDto {
    return {
      name: this.name,
      templateType: this.templateType,
      dataType: this.dataType,
      noHeaderRow: this.noHeaderRow,
      mappings: this.mappings.map(mapping => ({
        targetFieldName: mapping.targetField.name,
        csvColumnName: mapping.csvColumnName
      }))
    };
  }

  loadFile(csvDataFile: CsvInputFile) {
    if (csvDataFile) {
      this.mappings.forEach(mapping => {
        mapping.loadColumnValues(csvDataFile);
      });
      this.csvInputFile = csvDataFile;
      this.updateStatus();
    }
  }

  clearFile() {
    this.loadFile(new CsvInputFile([], []));
  }

  loadServerImportErrors(errors: ImportError[]) {
    if (!errors) {
      return;
    }

    const mappedErrors: ServerImportError[] = errors.map(error => {
      let mapping: CsvColumnMapping;
      if (error.csvErrorColumn) {
        mapping = this.getMapping(error.csvErrorColumn);
      }
      if (!mapping) {
        mapping = this.mappings[0];
      }
      return {
        errorColumn: mapping.csvColumnName,
        message: error.errorMessage,
        rowId: error.csvRowId,
        isWarning: error.isWarning
      };
    });
    this.csvInputFile.addServerErrors(mappedErrors);
    this.updateStatus();
  }

  removeMapping(targetField: string) {
    const mapping = this.map.get(targetField);
    if (!mapping) {
      throw new Error(targetField + ' is not a target field for ' + this.templateType + ' csv');
    }
    mapping.removeMapping();
  }

  setMapping(targetField: string, csvColumnName: string) {
    const oldMapping = this.mappings.find(m => m.csvColumnName === csvColumnName);
    if (oldMapping) {
      oldMapping.removeMapping();
    }

    const mapping = this.map.get(targetField);
    if (!mapping) {
      return;
    }
    if (mapping.csvColumnName) {
      mapping.removeMapping();
    }
    mapping.setMapping(csvColumnName);

    if (this.csvInputFile) {
      mapping.loadColumnValues(this.csvInputFile);
    }
    this.updateStatus();
  }

  getOutput(companyId?: string): any[] {
    const ret = [];
    const namesToNumber = this.getColumnNameToNumberMap();
    const payFrequencyValidator = new CsvPayFrequencyValidator();
    const currencyValidator = new CsvCurrencyValidator();
    this.csvInputFile.rows
      .forEach(row => {
        const retRow: any = {
          csvRowId: row.id
        };
        if (companyId) {
          retRow['companyId'] = companyId;
        }
        this.mappings
          .forEach((columnMapping: CsvColumnMapping) => {
            if (row.hasClientError) {
              return;
            }

            if (!columnMapping.isValid || !columnMapping.csvColumnName) {
              return;
            }

            if (!namesToNumber.has(columnMapping.csvColumnName.toLowerCase())) {
              return;
            }

            const columnNumber = namesToNumber.get(columnMapping.csvColumnName.toLowerCase());
            let val: any = row.cells[columnNumber].value;

            if (columnMapping.targetField.isCaseInsensitive) {
              val = val.toLowerCase();
            }

            if (columnMapping.targetField.validations.includes(CsvInputError.INVALID_START_DATE)) {
              if (!val || val.length === 0) {
                val = '';
              } else {
                // eslint-disable-next-line
                val = val.replace(/[\/]/g, '-');
                if (!dateRegex.test(val)) {
                  val = val.slice(6) + '-' + val.slice(3, 5) + '-' + val.slice(0, 2);
                }
              }
            }

            if (columnMapping.targetField.validations.includes(CsvInputError.INVALID_LEAVING_DATE)) {
              if (!val || val.length === 0) {
                val = '';
              } else {
                // eslint-disable-next-line
                val = val.replace(/[\/]/g, '-');
                if (!dateRegex.test(val)) {
                  val = val.slice(6) + '-' + val.slice(3, 5) + '-' + val.slice(0, 2);
                }
              }
            }

            if (columnMapping.targetField.validations.includes(CsvInputError.INVALID_INT)) {
              val = parseIntStrict(val);
            }

            if (columnMapping.targetField.validations.includes(CsvInputError.INVALID_FLOAT)) {
              val = Number.parseFloat(val);
            }

            if (columnMapping.targetField.validations.includes(CsvInputError.INVALID_FREQUENCY)) {
              val = payFrequencyValidator.map(val);
            }

            if (columnMapping.targetField.validations.includes(CsvInputError.INVALID_CURRENCY)) {
              val = currencyValidator.map(val);
            }

            if (columnMapping.targetField.validations.includes(CsvInputError.COMPANY_NAME)) {
              val = this.companyNameValidator.map(val);
            }

            retRow[columnMapping.targetField.name] = val;
          });
        if (!row.hasClientError) {
          ret.push(retRow);
        }
      });
    return ret;
  }

  getColumnNameToNumberMap() {
    const namesToNumber = new Map<string, number>();
    this.csvInputFile.allColumnNames.forEach((columnName: string, columnPosition: number) => {
      namesToNumber.set(columnName.toLowerCase(), columnPosition);
    });
    return namesToNumber;
  }

  getExample(): string {
    let ret = '';
    const mappedMappings = this.mappings.filter(c => !!c.csvColumnName);

    ret = ret.substring(0, ret.length - 1);
    if (!this.noHeaderRow) {
      mappedMappings.forEach((columnMapping: CsvColumnMapping) => {
        ret += columnMapping.csvColumnName + ',';
      });
      ret += '\n';
      mappedMappings.forEach((columnMapping: CsvColumnMapping) => {
        if (columnMapping.csvColumnName === 'leavingDate') {
          ret += ',';
        } else {
          ret += columnMapping.targetField.exampleValues[0] + ',';
        }
      });
    } else {
      const row: string[] = [];
      mappedMappings.forEach(mapping => {
        const columnIndex = this.csvInputFile.getColumnIndex(mapping.csvColumnName);
        row[columnIndex] = mapping.targetField.exampleValues[0];
      });

      // eslint-disable-next-line
      for (let i = 0; i < row.length; i++) {
        ret += (row[i] || '') + ',';
      }
    }
    return ret;
  }

  private updateStatus() {
    if (!this.csvInputFile) {
      this.status = TrafficLights.STOP;
      this.configChanges.next(undefined);
      return;
    }

    const inValidMappings = this.mappings.filter(mapping => !!mapping.status);
    this.csvInputFile.clearFileErrors();
    inValidMappings.forEach(mapping => {
      this.csvInputFile.addFileError(convertCamelToRegular(mapping.targetField.name) + ' is required');
    });

    if (inValidMappings.length > 0) {
      this.status = TrafficLights.STOP;
      this.configChanges.next(undefined);
      return;
    }

    const validRows = this.csvInputFile.rows.filter(row => row.status !== TrafficLights.STOP);
    if (validRows.length > 0) {
      this.status = TrafficLights.GO;
    } else {
      this.status = TrafficLights.WARN;
    }
    this.configChanges.next(undefined);
  }

  get clientErrorCount() {
    return this.csvInputFile.rows.filter(row => row.hasClientError).length;
  }
}

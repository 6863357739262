import { Component } from '@angular/core';
import { BankTransactionService } from 'common-ui/services/bank-transaction.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { RecBreakDto } from 'common-ui/open-api';

@Component({
  selector: 'lib-import-gc-payments-export-dialog',
  templateUrl: './import-gc-payments-export-dialog.component.html',
  styleUrls: ['./import-gc-payments-export-dialog.component.css']
})
export class ImportGcPaymentsExportDialogComponent {
  file: File;
  errorMessage: string;
  isWorking = false;
  isComplete = false;
  recBreaks: RecBreakDto[];

  constructor(
    private bankTransactionService: BankTransactionService,
    private dialogRef: MatDialogRef<ImportGcPaymentsExportDialogComponent>
  ) {
  }

  async importFile() {
    try {
      this.isWorking = true;
      this.errorMessage = '';
      this.recBreaks = await this.bankTransactionService.importGcPaymentsExport(this.file);
      this.isComplete = true;
    } catch (err) {
      this.errorMessage = err.message;
    }
    this.isWorking = false;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  fileSelected(file: File) {
    this.errorMessage = '';
    this.file = file;
  }
}

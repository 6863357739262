<mat-form-field class="form-field">
  <mat-label>Company</mat-label>
  <mat-select
    [disabled]="disabled"
    [(ngModel)]="selectedCompany"
    (selectionChange)="companySelected($event)">
    <mat-option *ngFor="let company of companies" [value]="company">
      {{ company.name }}
    </mat-option>
  </mat-select>
</mat-form-field>
<div *ngIf="importResult"
     class="mat-typography">

  <div class="status-panel" *ngIf="importResult.errorMessage">
    <lib-error-message [message]="importResult.errorMessage"></lib-error-message>
  </div>

  <div *ngIf="importResult.counts"
       class="results-panel">

    <lib-integration-counts
      [importCounts]="importResult.counts"
      *ngIf="importResult.counts?.length > 0">
    </lib-integration-counts>

    <div class="errors-panel"
         *ngIf="reportHasErrors()">

      <div class="error-title">Errors for this run are listed below.</div>
      <mat-form-field>
        <input [(ngModel)]="searchText"
               matInput
               appFirstFocus
               placeholder="Search errors and warnings"
               type="text">
      </mat-form-field>
      <lib-integration-errors-table
        [integrationReportId]="importResult._id"
        [apiSource]="importResult.apiSource"
        [searchText]="searchText">
      </lib-integration-errors-table>
    </div>
  </div>
</div>

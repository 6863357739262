import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, Inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { ToolbarDataService, FilterType, FilterService, SystemService } from 'common-ui/services';
import { SortDirection, DiscountsOpenApiService, DiscountCardQuery, DiscountCardDto } from 'common-ui/open-api';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Pages } from 'common-ui/services/filter.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Environment } from 'common-ui/models/environment.type';
import { JsonDialogComponent } from 'common-ui/json-dialog/json-dialog.component';
import { DiscountCardQueryDataSource } from 'common-ui/discount-card-page/discount-card-query-data-source';
import { getJustDateRange } from 'common-ui/custom-dates-range-selector/date-range.type';


@Component({
  selector: 'lib-discount-card-page',
  templateUrl: './discount-card-page.component.html',
  styleUrls: ['./discount-card-page.component.css']
})
export class DiscountCardPageComponent implements OnInit, AfterViewInit, OnDestroy {

  public masterAccountIds: string[];

  constructor(
    private toolbarDataService: ToolbarDataService,
    private filterService: FilterService,
    private discountCardService: DiscountsOpenApiService,
    private dialog: MatDialog,
    @Inject('env') public environment: Environment,
    private systemService: SystemService
  ) {
    this.dataSource = new DiscountCardQueryDataSource(this.discountCardService);
    if (this.isConsole) {
      this.displayedColumns = this.consoleColumns;
    }
  }

  public dataSource: DiscountCardQueryDataSource;
  public FilterType = FilterType;
  public displayedColumns = [
    'employeeFirstName',
    'employeeLastName',
    'masterAccountName',
    'companyName',
    'brandName',
    'purchaseDate',
    'time',
    'faceValueInMinor',
    'customerDiscount',
    'customerCostInMinor',
    'actions'
  ];

  public consoleColumns = [
    'employeeFirstName',
    'employeeLastName',
    'masterAccountName',
    'companyName',
    'brandName',
    'purchaseDate',
    'time',
    'brandDiscount',
    'faceValueInMinor',
    'customerDiscount',
    'customerCostInMinor',
    'flexEarnMarginInMinor',
    'actions'
  ];

  private ngUnsubscribe = new Subject();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit() {
    this.toolbarDataService.setupToolbar({
      action: 'toggle', title: 'Discount Cards'
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(undefined);
    this.ngUnsubscribe.complete();
  }

  async ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => await this.loadData());

    this.filterService.filter$.pipe(
      debounceTime(300),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(async () => {
      await this.loadData();
      this.paginator.firstPage();
    });

    const initialSort = this.filterService.getPageSort(Pages.DISCOUNT_CARDS);
    if (initialSort && this.sort.sortables.has(initialSort.name)) {
      this.sort.sort({
        id: initialSort.name,
        start: initialSort.direction === SortDirection.ASC ? 'asc' : 'desc',
        disableClear: false
      });
    }

    this.sort.sortChange
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(newSort => {
        this.filterService.setPageSort(Pages.DISCOUNT_CARDS, newSort.active, newSort.direction as SortDirection);
      });

    await this.loadData();
  }

  async loadData() {
    const currentFilter = this.filterService.currentFilter;
    const justDateRange = getJustDateRange(currentFilter.dateRange, this.systemService.getSystemTime());

    const query: DiscountCardQuery = {
      offset: this.paginator.pageIndex * this.environment.defaultPageSize,
      limit: this.environment.defaultPageSize,
      fromDate: justDateRange.fromDate,
      toDate: justDateRange.toDate,
      employeeName: currentFilter.employeeSearch,
      masterAccountIds: currentFilter.masterAccounts,
      companyIds: currentFilter.companies
    };

    const pageSort = this.filterService.getPageSort(Pages.DISCOUNT_CARDS);
    if ( pageSort && this.sort.sortables.has(pageSort.name)) {
      query.sort = FilterService.getSortObj(pageSort);
    }

    this.masterAccountIds = currentFilter.masterAccounts;
    await this.dataSource.load(query);
  }

  get pageSize() {
    return this.environment.defaultPageSize;
  }

  get isConsole() {
    return this.environment.adminConsole;
  }

  openJsonDataDialog(discountCard: DiscountCardDto) {
    this.dialog.open(JsonDialogComponent, {
      data: {
        header: 'Discount Card JSON',
        data: discountCard
      },
      autoFocus: false
    });
  }


}

import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { WithdrawalService } from 'common-ui/services';
import { WithdrawalPeriodHistoryDto, PaymentStatusEnum } from 'common-ui/open-api';
import { getCurrencySymbol } from 'common-ui/util';
import { Environment } from 'common-ui/models/environment.type';
import { CustomerService } from 'common-ui/services/customer.service';
import { takeWhile, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-employee-portal-withdrawals-page',
  templateUrl: './employee-portal-withdrawal-history.component.html',
  styleUrls: ['./employee-portal-withdrawal-history.component.css']
})
export class EmployeePortalWithdrawalHistoryComponent implements OnInit, OnDestroy {

  history: WithdrawalPeriodHistoryDto[];
  PaymentStatus = PaymentStatusEnum;
  isLoading = true;
  assetsUrl: string;
  isMultiCompany: boolean;
  ngUnsubscribe$ = new Subject();

  constructor(
    private withdrawalService: WithdrawalService,
    private customerService: CustomerService,
    @Inject('env') environment: Environment
  ) {
    this.assetsUrl = environment.locations.images;
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  async ngOnInit() {
    this.history = await this.withdrawalService.getWithdrawalPeriodHistory();
    this.isMultiCompany = this.customerService.isMultiCompany;
    this.isLoading = false;

    if (this.customerService.withdrawalInProgress$.value.status === PaymentStatusEnum.PENDING) {
      this.customerService.withdrawalInProgress$
        .pipe(
          takeWhile(value => {
            return value.status === PaymentStatusEnum.PENDING;
          }, true),
          takeUntil(this.ngUnsubscribe$)
        ).subscribe(async status => {
        if (status.status === PaymentStatusEnum.SUCCESS || status.status === PaymentStatusEnum.FAILED) {
          this.history = await this.withdrawalService.getWithdrawalPeriodHistory();
        }
      });
    }
  }

  get activeHistory() {
    return this.history.filter(period => period.withdrawals.length > 0);
  }

  get noWithdrawalsYet(): boolean {
    if (this.history.length === 0) {
      return true;
    } else {
      return this.history.filter(period => period.withdrawals.length > 0).length === 0;
    }
  }

  getWithdrawalPeriodHeader(period: WithdrawalPeriodHistoryDto) {
    const periodAmount = (period.periodTotal / 100).toFixed(2);
    return `From ${period.periodStart} to ${period.periodEnd} - ${getCurrencySymbol(period.currency)}${periodAmount}`;
  }
}

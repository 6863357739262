<lib-spinner-container [isWorking]="isWorking">
  <div class="reset-page mat-typography">
    <div class="reset-container">

      <lib-dialog-header>
        {{ resetComplete ? 'Your password has been set' : 'Set your password'}}
      </lib-dialog-header>

      <div *ngIf="!token">
        <p>This page expects a login token url parameter!</p>
      </div>

      <div *ngIf="token">

        <div *ngIf="isLoading" class="checking-token">
          Checking token....
        </div>

        <div *ngIf="!isLoading">

          <div *ngIf="!tokenStatus">
            <lib-error-message message="Failed to check token"></lib-error-message>
          </div>

          <div *ngIf="tokenStatus === TokenStatus.UNKNOWN_TOKEN || tokenStatus === TokenStatus.TOKEN_EXPIRED">

            <div *ngIf="!resetLinkSent">

              <div class="message-text">
                Link expired - request a new link.
              </div>

              <form [formGroup]="resetForm"
                    (ngSubmit)="requestNewPasswordResetLink()">

                <mat-form-field appearance="outline" class="reset-email">
                  <mat-label>Email</mat-label>
                  <input formControlName="email"
                         type="text"
                         matInput
                         placeholder="Email">

                  <mat-error *ngIf="resetForm.get('email').hasError('required')">
                    You must enter an email address
                  </mat-error>

                  <mat-error *ngIf="resetForm.get('email').hasError('email')">
                    Please enter a valid email address
                  </mat-error>

                </mat-form-field>

                <lib-error-message [message]="errorMessage"></lib-error-message>

                <div class="reset-button">
                  <button mat-flat-button
                          [disabled]="resetForm.invalid"
                          color="primary"
                          type="submit">
                    Send New Link
                  </button>
                </div>

              </form>

            </div>

            <div *ngIf="resetLinkSent" class="message-text">
              We have emailed you a new link.
            </div>

          </div>

          <div *ngIf="tokenStatus === TokenStatus.OK ">

            <div *ngIf="resetComplete"
                 class="password-reset-success">
              <div class="message-text">
                <div *ngIf="user.isEmployee">
                  <p>Enter the Employee Web Portal, or install the Mobile App and login with this password.</p>
                </div>
              </div>

              <button mat-flat-button
                      class="enter-portal-button"
                      color="primary"
                      (click)="enterPortal()">
                Enter {{ isConsole ? 'Console' : user.isEmployee ? 'Employee Portal' : 'Portal' }}
              </button>
            </div>

            <form [formGroup]="passwordForm"
                  *ngIf="!resetComplete"
                  class="reset-form">

              <mat-form-field
                class="password"
                appearance="outline">
                <mat-label>Password</mat-label>
                <input formControlName="password"
                       appFirstFocus
                       type="password"
                       matInput
                       placeholder="Password">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input formControlName="confirmPassword"
                       type="password"
                       matInput
                       placeholder="Confirm Password">
              </mat-form-field>

              <lib-icon-error [control]="passwordForm.controls['password']"
                              errorName="hasUpperCase"
                              errorText="At least 1 upper case letter">
              </lib-icon-error>

              <lib-icon-error [control]="passwordForm.controls['password']"
                              errorName="hasLowerCase"
                              errorText="At least 1 lower case letter">
              </lib-icon-error>

              <lib-icon-error [control]="passwordForm.controls['password']"
                              errorName="hasNumber"
                              errorText="At least 1 number"></lib-icon-error>

              <lib-icon-error [control]="passwordForm.controls['password']"
                              errorName="hasSpecialCharacters"
                              errorText="At least 1 special character">
              </lib-icon-error>

              <lib-icon-error [control]="passwordForm.controls['password']"
                              errorName="minlength"
                              errorText="Minimum 8 characters">
              </lib-icon-error>

              <lib-icon-error [control]="passwordForm.controls['confirmPassword']"
                              errorName="noPasswordMatch"
                              errorText="Passwords must match">
              </lib-icon-error>

              <lib-error-message [message]="errorMessage"></lib-error-message>
              <lib-button-panel>
                <button class="btn btn-outline-secondary"
                        mat-flat-button
                        color="primary"
                        type="submit"
                        [disabled]="!token || passwordForm.invalid"
                        (click)="setPassword()">
                  Set Password
                </button>
              </lib-button-panel>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</lib-spinner-container>

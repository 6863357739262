import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { PaymentStatusEnum, PayrollType, ProfileDto } from 'common-ui/open-api';
import { getCurrencySymbol } from 'common-ui/util';
import { FormGroup, AbstractControl, Validators, FormBuilder } from '@angular/forms';
import { CustomerService } from 'common-ui/services/customer.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formatCcy } from 'common-ui/util/format-ccy';

@Component({
  selector: 'lib-employee-portal-company-withdrawal',
  templateUrl: './employee-portal-company-withdrawal.component.html',
  styleUrls: ['./employee-portal-company-withdrawal.component.css']
})
export class EmployeePortalCompanyWithdrawalComponent implements OnChanges, OnInit, OnDestroy {

  protected readonly PaymentStatusEnum = PaymentStatusEnum;
  protected readonly PayrollType = PayrollType;

  @Input() profile: ProfileDto;
  form: FormGroup;
  errorMessage: string;
  isWorking = true;
  pendingWithdrawalStatus: PaymentStatusEnum;
  ngUnsubscribe$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService
  ) {
  }

  ngOnInit() {
    if (this.withdrawalLimitReached) {
      console.log('disable form');
      this.form.controls.amount.disable();
    } else {
      this.form.controls.amount.enable();
    }

    this.customerService.withdrawalInProgress$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(status => {
        this.errorMessage = status.errorMessage;
        this.pendingWithdrawalStatus = status.status;
        this.isWorking = status.status === PaymentStatusEnum.PENDING;
        if (this.isWorking || this.withdrawalLimitReached) {
          this.form.disable();
        } else {
          this.form.enable();
        }
        if (status.updatedProfile) {
          this.profile = status.updatedProfile;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next(undefined);
    this.ngUnsubscribe$.complete();
  }

  ngOnChanges(): void {
    this.form = this.fb.group({
      amount: ['', [Validators.required,
        this.getAmountAvailableValidator(),
        Validators.pattern(/^[0-9]+$/),
        Validators.max(200),
        this.getMinimumWithdrawalValidator()]]
    });

    this.isWorking = !!this.profile.pendingWithdrawalClientId;

    if (this.isWorking) {
      this.form.patchValue({
        amount: this.profile.amountPending / 100
      });
    }

  }

  get subMessage() {
    if (this.profile.isSalaried) {
      return undefined;
    }
    let message = '';
    if (this.profile.unapprovedWages > 0) {
      message += `Unapproved wages: ${getCurrencySymbol(this.profile.currency)}${(this.profile.unapprovedWages / 100).toFixed(2)}`;
    }
    if (this.profile.hoursWithoutRate) {
      if (message.length > 0) {
        message += ' / ';
      }
      message += `Hourly rate missing: ${this.profile.hoursWithoutRate} hours`;
    }
    return message.length > 0 ? message : undefined;
  }


  async acknowledgeWithdrawal() {
    if (this.customerService.withdrawalInProgress$.value.requireAck) {
      await this.customerService.acknowledgeWithdrawal();
    } else {
      this.customerService.withdrawalInProgress$.next({
        withdrawalClientId: null,
        status: null,
        requireAck: false
      });
    }
  }

  getAmountAvailableValidator() {
    return (control: AbstractControl) => {
      if (control.value * 100 > this.profile.amountAvailable) {
        return {moreThanAvailable: true};
      }
    };
  }

  getMinimumWithdrawalValidator() {
    return (control: AbstractControl) => {
      if (control.value && control.value * 100 < this.profile.minimumWithdrawal) {
        return {lessThanMinimum: true};
      }
    };
  }

  async withdraw() {
    this.isWorking = true;
    await this.customerService.initiateWithdrawal(this.profile.employeeId, this.form.value.amount * 100);
  }

  get withdrawalFee() {
    return this.profile.employerPaysFee ? 'FREE' : formatCcy(this.profile.companyFee, this.profile.currency);
  }

  get withdrawalLimitReached() {
    return this.profile.periodWithdrawalLimitActive && this.profile.periodWithdrawalsRemaining === 0;
  }
}

<lib-employee-portal-page>
  <lib-spinner-container [isWorking]="isWorking">

    <div class="mat-title">Bank Details</div>
    <p>Tell us where to deposit your withdrawals?</p>

    <div class="container mat-typography"
         *ngIf="form">

      <form (ngSubmit)="next()"
            [formGroup]="form"
            class="form-area">

        <div class="sub-form">

          <lib-account-number-input
            *ngIf="currency=== Currency.GBP"
            formControlName="accountNumber"
            color="accent"
            class="input-field"
            appFirstFocus
            [clearable]="false"
            [required]="true">
          </lib-account-number-input>

          <lib-sort-code-input
            *ngIf="currency=== Currency.GBP"
            formControlName="accountSortCode"
            color="accent"
            class="input-field"
            [clearable]="false"
            [required]="true">
          </lib-sort-code-input>

          <mat-form-field class="iban"
                          *ngIf="currency=== Currency.EUR">
            <input
              class="iban"
              matInput
              formControlName="iban"
              placeholder="IBAN"
            />
          </mat-form-field>

        </div>

        <button
          [disabled]="form.invalid"
          color="accent"
          type="submit"
          class="employee-portal-button"
          mat-raised-button>
          Continue
        </button>
      </form>
    </div>

  </lib-spinner-container>
</lib-employee-portal-page>
